import { PageViewCard } from '@enpxio/components';
import { FC, useState } from 'react';

import MonthlyPaymentEdit from './monthlyPaymentEdit';
import MonthlyPaymentView from './monthlyPaymentView';

interface MonthlyPaymentProps {
  changePayment: boolean;
}

const MonthlyPayment: FC<MonthlyPaymentProps> = (props: MonthlyPaymentProps) => {
  const [editMode, setEditMode] = useState(props.changePayment);
  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };

  return <PageViewCard>{!editMode ? <MonthlyPaymentView toggleFunc={toggleEditMode} /> : <MonthlyPaymentEdit toggleFunc={toggleEditMode} />}</PageViewCard>;
};

export default MonthlyPayment;
