import { useQuery, gql } from '@apollo/client';
import { Container, Grid, useAsyncEffect } from '@enpxio/components';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../portalPages';
import { SuccessCard } from './cards/registrationSuccess';
import { useAuth0 } from '~/providers';
import { RegistrationBenefitsMessage } from './registrationBenefitsMessage';

const QUERY = gql`
  query pageRegistrationCollection {
    pageRegistrationCollection(limit: 1) {
      items {
        backgroundImage {
          url
        }
      }
    }
  }
`;

const useStyles = makeStyles<{
  backgroundImageURL: string;
}>()((_theme, { backgroundImageURL }) => ({
  background: {
    minWidth: 275,
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -1,
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${backgroundImageURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));
const useThemeStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    spacing: 2,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

const Verification: FC = () => {
  const { data, loading } = useQuery(QUERY);
  const imageUrl = data?.pageRegistrationCollection?.items[0]?.backgroundImage?.url;
  const { classes } = useStyles({ backgroundImageURL: loading || !imageUrl ? '' : imageUrl });
  const { classes: themeClasses } = useThemeStyles();
  const { isAuthenticated } = useAuth0();
  const query = useSearchParams()[0];
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (isAuthenticated || (query.get('supportSignUp') === 'true' && query.get('success') === 'true')) {
      await navigate(PortalPages.dashboard.absolutePathname);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className={classes.background} />
      <Container>
        <Grid className={themeClasses.container}>
          <Grid item xs={12} sm={10} md={6} lg={6} xl={6}>
            <RegistrationBenefitsMessage />
          </Grid>
          <Grid item xs={12} sm={10} md={6} lg={6} xl={6}>
            <SuccessCard />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Verification;
