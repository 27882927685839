import { DependencyList, useLayoutEffect, useRef } from 'react';

import { AsyncEffectCallback, CleanupFunction } from './asyncEffect';

export function useAsyncLayoutEffect(effect: AsyncEffectCallback, deps?: DependencyList, cleanup?: CleanupFunction): void {
  const abortController = new AbortController();
  const isMountedRef = useRef(false);

  useLayoutEffect(() => {
    isMountedRef.current = true;
    effect(abortController.signal, isMountedRef);
    return (): void => {
      isMountedRef.current = false;
      abortController.abort();
      if (cleanup) {
        cleanup();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
