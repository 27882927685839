import { EnergyType } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface CounterNameLocaleFormatterProps {
  energyType?: EnergyType;
}

export const CounterNameLocaleFormatter: FC<CounterNameLocaleFormatterProps> = (props: CounterNameLocaleFormatterProps) => {
  switch (props.energyType) {
    case EnergyType.ELECTRICITY:
      return <>Stromzähler</>;
    case EnergyType.GAS:
      return <>Gaszähler</>;
    default:
      return <>Zähler</>;
  }
};
