import { DateMessage } from '@enpowerx/apis/lib/google/type';
import { FC, ReactNode } from 'react';

import { DateLocaleFormatter, getDateLocale } from '../date';

interface DateMessageLocaleFormatterProps {
  date: DateMessage | undefined;
  prefix?: ReactNode;
  fallback?: string;
}

export const DateMessageLocaleFormatter: FC<DateMessageLocaleFormatterProps> = (props: DateMessageLocaleFormatterProps) => {
  if (!props.date) {
    return <>{props.fallback ?? ''}</>;
  }

  const parsedDate = new Date();
  parsedDate.setFullYear(props.date.year, props.date.month - 1, props.date.day);
  return (
    <>
      {props.prefix}
      <DateLocaleFormatter date={parsedDate} />
    </>
  );
};

export function formatDateMessageLocale(date: DateMessage | undefined): string {
  if (!date) {
    return '';
  }

  const parsedDate = new Date();
  parsedDate.setFullYear(date.year, date.month - 1, date.day);
  return getDateLocale(parsedDate);
}
