import { FC } from 'react';

interface IbanObfuscationProps {
  iban: string | undefined;
}

export const IbanObfuscationFormatter: FC<IbanObfuscationProps> = (props: IbanObfuscationProps) => {
  const iban = props.iban;
  if (!iban) {
    return null;
  }

  if (iban.length < 22) {
    return <>Ungültige IBAN</>;
  }

  const obfuscationChar = 'x';
  const obfuscationLocations = [
    { index: 2, len: 2 },
    { index: 7, len: 12 },
  ];

  let obfuscatedIban = '';
  for (let i = 0; i < iban.length; i++) {
    const ibanChar: String = iban[i];
    let obfuscateChar = false;
    for (let j = 0; j < obfuscationLocations.length; j++) {
      const obfscIndex = obfuscationLocations[j].index;
      const obfscLength = obfuscationLocations[j].len;
      if (i >= obfscIndex && i < obfscIndex + obfscLength) {
        obfuscateChar = true;
        break;
      }
    }

    obfuscatedIban += obfuscateChar ? obfuscationChar : ibanChar;
  }

  return <>{obfuscatedIban}</>;
};
