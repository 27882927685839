import { Box, Grid, Link, Modal, Paper, Theme, Typography } from '@enpxio/components';
import CloseIcon from '@mui/icons-material/Close';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

type SimpleModalProps = {
  header: string | ReactNode;
  content: string | ReactNode;
  open: boolean;
  onClose: (event: {}) => void;
};

export const SimpleModal: FC<SimpleModalProps> = (props: SimpleModalProps) => {
  const { header, content, open, onClose } = props;
  const { classes } = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.box}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={0.5} />
                <Grid item xs={9}>
                  {typeof header === 'string' ? <Typography variant="h2">{header}</Typography> : <>{header}</>}
                </Grid>
                <Grid item xs={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justifyContent="flex-end">
                        <Grid item style={{ alignItems: 'center' }}>
                          <Link className={classes.closeLink} onClick={onClose} underline="none" color="secondary">
                            <CloseIcon />
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0.5} />
              </Grid>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={11}>
              <Grid container alignItems="center">
                {typeof content === 'string' ? <Typography variant="h2">{content}</Typography> : <>{content}</>}
              </Grid>
            </Grid>
            <Grid item xs={0.5} />
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    WebkitTransform: 'translate(-50%, -50%)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1000px',
    },
  },
  paper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  closeLink: {
    cursor: 'pointer',
  },
}));
