import { PageViewCard, PageViewCardTitle, Typography } from '@enpxio/components';
import { FC, ReactNode, useEffect, useState } from 'react';

import CustomerEdit from './customerEdit';
import CustomerView from './customerView';

const CustomerInfoCard: FC = () => {
  const [view, setView] = useState<ReactNode>(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (edit) {
      setView(<CustomerEdit onCancel={onEditCancelled} onSaved={onSaved} />);
    } else {
      setView(<CustomerView onEditClick={onEditClick} />);
    }
  }, [edit]);

  const onEditClick = () => {
    setEdit(true);
  };

  const onEditCancelled = () => {
    setEdit(false);
  };

  const onSaved = () => {
    setEdit(false);
  };

  return (
    <PageViewCard>
      <PageViewCardTitle
        title={
          <Typography variant="h2" component="div">
            Meine Kunden- und Kontaktdaten
          </Typography>
        }
      />
      {view}
    </PageViewCard>
  );
};

export default CustomerInfoCard;
