import { Contract } from '@enpowerx/apis/lib/contracting/v2';
import { FunctionComponent } from 'react';

type ContractDeliveryAddressProps = {
  contract: Contract | undefined;
  hidden?: boolean;
};

export const ContractDeliveryAddress: FunctionComponent<ContractDeliveryAddressProps> = (props: ContractDeliveryAddressProps) => {
  const postalAddress = props.contract?.delivery?.address;
  if (!postalAddress || props.hidden) {
    return null;
  }

  return (
    <>
      {postalAddress.street} {postalAddress.houseNumber}, {postalAddress.city}
    </>
  );
};
