import { Relocation_UseNewAddress } from '@enpowerx/apis/lib/contracting/v2';
import { Money } from '@enpowerx/apis/lib/google/type';
import { mapCounterType, MoneyLocaleFormatter } from '@enpxio/formatters';
import { FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { ChangeEvent, FC, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormCard } from './cards/FormCard';
import { GraduatedPricesModal } from '~/contracts/pageControls/graduatedPricesModal';
import { useSelectedContract } from '~/providers/selectedContract';

const defaultMoney: Money = { currencyCode: 'EUR', units: '0', nanos: 0 };

export const FormStepContract: FC = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();

  const [graduatedPricesModalOpen, setGraduatedPricesModalOpen] = useState<boolean>(false);
  let oldFixedCost: ReactNode = null;
  let oldWorkingPrice: ReactNode = null;

  const graduatedPricesModalOnClose = (): void => {
    setGraduatedPricesModalOpen(false);
  };

  const showGraduatedPrices = (): void => {
    setGraduatedPricesModalOpen(true);
  };

  if (selectedContract.tariff?.graduatedPrices && selectedContract.tariff?.graduatedPrices.length > 0) {
    for (const graduatedPrice of selectedContract.tariff.graduatedPrices) {
      if (
        selectedContract.delivery !== undefined &&
        selectedContract.delivery?.annualUsage > graduatedPrice.start &&
        selectedContract.delivery?.annualUsage < graduatedPrice.end
      ) {
        oldFixedCost = (
          <Typography paragraph>
            <MoneyLocaleFormatter value={graduatedPrice.basePrice?.gross} />
          </Typography>
        );
        if (graduatedPrice.workingPrices.length > 1) {
          const oldWorkingPrices: ReactNode[] = [];
          for (const workingPrice of graduatedPrice.workingPrices) {
            oldWorkingPrices.push(
              <>
                <Typography paragraph className={classes.price}>
                  <MoneyLocaleFormatter showFractionUnit value={workingPrice.price?.gross} suffix={` (${mapCounterType(workingPrice.registerType)})`} />
                </Typography>
                <br />
              </>
            );
          }

          oldWorkingPrice = <>{oldWorkingPrices}</>;
        } else if (graduatedPrice.workingPrices.length === 1) {
          oldWorkingPrice = (
            <>
              <Typography paragraph className={classes.price}>
                <MoneyLocaleFormatter showFractionUnit value={graduatedPrice.workingPrices[0].price?.gross} />
              </Typography>
              <br />
            </>
          );
        }

        oldWorkingPrice = (
          <>
            {oldWorkingPrice}
            <Link className={classes.priceLink} onClick={showGraduatedPrices} underline="none" color="secondary">
              Stufenpreise anzeigen
            </Link>
          </>
        );
      }
    }
  } else {
    oldFixedCost = (
      <Typography paragraph>
        <MoneyLocaleFormatter
          value={
            selectedContract.tariff !== undefined
              ? selectedContract.tariff?.fixedCost !== undefined
                ? selectedContract.tariff?.fixedCost.price !== undefined
                  ? selectedContract.tariff?.fixedCost.price.gross
                  : defaultMoney
                : defaultMoney
              : defaultMoney
          }
        />
      </Typography>
    );

    oldWorkingPrice = (
      <Typography paragraph>
        <MoneyLocaleFormatter
          showFractionUnit
          value={
            selectedContract.tariff !== undefined
              ? selectedContract.tariff?.workingPrice !== undefined
                ? selectedContract.tariff?.workingPrice.gross
                : defaultMoney
              : defaultMoney
          }
        />
      </Typography>
    );
  }

  return (
    <Grid container spacing={4}>
      <GraduatedPricesModal open={graduatedPricesModalOpen} onClose={graduatedPricesModalOnClose} />
      <Grid item xs={12} md={6}>
        <FormCard title="Mein aktueller Vertrag">
          <Grid container>
            <Grid item xs={12} md={12} className={classes.gridRowContainer}>
              <Grid item xs={12} md={12}>
                {selectedContract.tariff?.displayName ?? selectedContract.tariff?.product}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography paragraph>Ihr jährlicher Grundpreis</Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph>{oldFixedCost}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography paragraph>Ihr Arbeitspreis pro kWh</Typography>
                </Grid>
                <Grid item>
                  <div>{oldWorkingPrice}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormCard title="Mein neuer Vertrag">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography paragraph>{selectedContract.tariff?.displayName ?? selectedContract.tariff?.product}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>
                Grund- und Verbrauchspreis, Vertragslaufzeit und Kündigungsfrist bleiben unverändert. Diese und weitere Informationen können Sie hier im
                Kundenportal unter Verträge einsehen
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Vertragsrelevante Post an die neue Adresse senden</Typography>
              <FormControl margin="normal" fullWidth>
                <Field
                  component={RadioGroup}
                  name="useNewAddress"
                  value={values.useNewAddress}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                    setFieldValue('useNewAddress', event.target.value);
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        checked={values.useNewAddress === Relocation_UseNewAddress.IMMEDIATELY}
                        value={Relocation_UseNewAddress.IMMEDIATELY}
                        control={<Radio size="small" className={classes.radioButtonColor} />}
                        label="Ab sofort"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <FormControlLabel
                        checked={values.useNewAddress === Relocation_UseNewAddress.ON_MOVE_IN}
                        value={Relocation_UseNewAddress.ON_MOVE_IN}
                        control={<Radio size="small" className={classes.radioButtonColor} />}
                        label="Ab Einzugsdatum"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        checked={values.useNewAddress === Relocation_UseNewAddress.NO}
                        value={Relocation_UseNewAddress.NO}
                        control={<Radio size="small" className={classes.radioButtonColor} />}
                        label="Nein"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  gridRowContainer: {
    marginBottom: theme.spacing(5),
  },
  box: {
    margin: theme.spacing(10),
  },
  section: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  formGrid: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
  },
  formGridCell: {
    padding: theme.spacing(10),
  },
  bottomGrid: {
    marginTop: theme.spacing(10),
  },
  buttonContainer: {
    display: 'inline-flex',
    float: 'right',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
  },
  radioButtonColor: {
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },

  price: {
    float: 'right',
    marginBottom: 0,
  },
  priceLink: {
    cursor: 'pointer',
  },
}));
