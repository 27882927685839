import { useAsyncEffect, useConfig } from '@enpxio/components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PortalPages } from '../portalPages';
import { useAuth0 } from '~/providers';

const IndexPage: FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const config = useConfig();

  useAsyncEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorParam = urlParams.get('error');
    const descriptionParam = urlParams.get('error_description');
    if (errorParam && descriptionParam) {
      await navigate(`${PortalPages.error.absolutePathname}`, {
        state: {
          error: errorParam,
          description: descriptionParam,
        },
      });
    } else if (!isAuthenticated) {
      await loginWithRedirect({ connection: config.oauth.connection });
    } else {
      await navigate(PortalPages.dashboard.absolutePathname);
    }
  }, [isAuthenticated]);

  return null;
};

export default IndexPage;
