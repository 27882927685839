import { Grid } from '@enpxio/components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import MarketingEntry from './marketingEntry';

const useStyles = makeStyles()((theme) => ({
  showMoreText: {
    fontSize: '14px',
    color: theme.palette.secondary.main,
  },
  bgWrapper: {
    width: 'calc(100% + 30px)',
    left: '-15px',
    top: theme.namedSpacing('large', 'xxxl'),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.marketingEntry.main,
  },
  wrapper: {
    maxWidth: '1192px',
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(11),
    },
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(9),
    },
  },
}));

interface MarketingItem {
  image?: MarketingImage;
  title?: string;
  description?: string;
  buttonText: string;
  linkedPage: LinkedPage;
}

interface LinkedPage {
  linkedPage: string;
}

interface MarketingImage {
  url?: string;
}

type MarketingViewProps = {
  marketingItems: MarketingItem[];
};

const MarketingViewCard: FC<MarketingViewProps> = (props: MarketingViewProps) => {
  const { classes } = useStyles();
  const { marketingItems } = props;

  return (
    <div className={classes.bgWrapper}>
      <Grid container spacing={4} justifyContent="center" className={classes.wrapper}>
        <>
          {marketingItems.map((item: MarketingItem, index: number) => {
            return (
              <Grid key={String(index)} item sm={6} lg={6} justifyContent="center" container>
                <MarketingEntry
                  title={item.title}
                  buttonText={item.buttonText}
                  linkedPage={item.linkedPage?.linkedPage}
                  description={item.description}
                  image={item.image?.url}
                />
              </Grid>
            );
          })}
        </>
      </Grid>
    </div>
  );
};

export default MarketingViewCard;
