import { gql, useQuery } from '@apollo/client';
import { PageView, useConfig } from '@enpxio/components';
import { Location } from 'history';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MarketingViewCard from '../marketing/components/marketingViewCard';
import InvoiceList from './pageControls/invoiceList';
import MonthlyPayment from './pageControls/monthlyPayment';
import AccountBalanceList from "./pageControls/accountBalance";

interface LocationState extends Location {
  changePayment?: boolean;
}

const QUERY = gql`
  query pageInvoicesCollectionQuery {
    pageInvoicesCollection(limit: 1) {
      items {
        accountBalanceNoteTitle
        accountBalanceNoteText
        marketingEntryCollection {
          items {
            title
            description
            buttonText
            linkedPage {
              linkedPage
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

const PageInvoices: FC = () => {
  let changePayment = false;
  const location = useLocation();
  const { data, loading } = useQuery(QUERY);
  const marketingItems = data?.pageInvoicesCollection?.items[0]?.marketingEntryCollection?.items;
  const accountBalanceNoteTitle = data?.pageInvoicesCollection?.items[0]?.accountBalanceNoteTitle
  const accountBalanceNoteText = data?.pageInvoicesCollection?.items[0]?.accountBalanceNoteText
  const config = useConfig();
  const accountBalanceEnabled = config.feature.some((feat) => feat.key === 'accountBalance');


  if ((location?.state as LocationState)?.changePayment) {
    changePayment = true;
  }

  return (
    <>
      <PageView title="Rechnung & Zahlung" subTitle="Hier finden Sie alle Details zu Ihren Rechnungen und Zahlungen." wide>
        <MonthlyPayment changePayment={changePayment} />
        <InvoiceList />
        {accountBalanceEnabled? <AccountBalanceList noteTitle={accountBalanceNoteTitle} noteText={accountBalanceNoteText} /> : <></>}
      </PageView>
      {loading || marketingItems?.length === 0 ? null : <MarketingViewCard marketingItems={marketingItems} />}
    </>
  );
};

export default PageInvoices;
