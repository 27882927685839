import { Meter } from '@enpowerx/apis/lib/metering/v2';

import { useCache } from './useCache';
import { useAPI, useSelectedContract } from '~/providers';

export interface MetersState {
  meters: Meter[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useMeters = (): MetersState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data: meters,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(
    api.isInitialized && selectedContract.id ? `contracts/${selectedContract.id}/meters` : null,
    async () => (await api.currentMetering.meters.list())?.meters
  );

  return {
    meters,
    error,
    isLoading,
    invalidate,
  };
};
