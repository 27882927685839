/* eslint-disable max-depth */
import { Meter, ReadingQuality, RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { formatDateMessageLocale, mapChannel, mapOrigin, mapQuality } from '@enpxio/formatters';
import { useEffect, useState } from 'react';
import { clone, cloneDeep } from 'lodash-es';

import { dateMessageSortComparerByField } from '../../sortComparer/dateMessage';
import { useCache } from './useCache';
import { useMeters } from './useMeters';
import { useAPI, useSelectedContract } from '~/providers';


export interface ReadingDataList {
  date: Date;
  meter?: string;
  meterType?: string;
  valueNt?: number;
  valueHt?: number;
  origin: string;
  channel: string;
  quality: ReadingQuality;
  obisCode?: string;
}

export interface ReadingDataListMeter {
  meter?: string;
  meterType: string;
  meterReadings?: ReadingDataList[];
}

interface MeterReadingsState {
  meterReadings: ReadingDataListMeter[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useMeterReadingData = (meterNumber: string | undefined): MeterReadingsState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data,
    error: readingsError,
    revalidate,
    isValidating: isReadingsLoading,
  } = useCache(selectedContract.id ? `readingData/${selectedContract.id}/${meterNumber}` : null, async () => api.currentMetering.readings.list());
  const { meters, error: metersError, isLoading: isMetersLoading } = useMeters();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [meterReadings, setMeterReadings] = useState<ReadingDataListMeter[] | undefined>();
  const [meterType, setMeterType] = useState('EZ');

  useEffect(() => {
    setError(readingsError ?? metersError);
  }, [readingsError, metersError]);

  useEffect(() => {
    setIsLoading(isReadingsLoading || isMetersLoading);
  }, [isReadingsLoading, isMetersLoading]);

  useEffect(() => {
    try {
      if (!meterNumber || !data?.meters || !data?.meters[meterNumber]) {
        setMeterReadings(undefined);
        return;
      }

      let meterList: Meter[] | undefined = cloneDeep(meters?.filter((meter) => meter.id === meterNumber));

      if (meterList === undefined) {
        setMeterReadings(undefined);
        return;
      }

      if (meterList.length > 1) {
        const meterCombined = cloneDeep(meterList[0]);

        for (let i = 1; i < meterList.length; i++) {

          const meterRegister = meterList[i].registers;
          meterCombined.registers = { ...meterCombined.registers, ...meterRegister };

        }

        meterList = [meterCombined];
      }

      const registersMap = meterList[0].registers;
      const registersList = Object.entries(registersMap).map(([obisCode, register], i) => {
        return { ...register, obisCode, id: i };
      });

      const { obisCode } = registersList[0];


      if (meterList[0].registers[obisCode].type === RegisterType.DOUBLE_TARIFF_PEAK || meterList[0].registers[obisCode].type === RegisterType.DOUBLE_TARIFF_OFF_PEAK) {
        setMeterType('DZ');
      }

      const meterReadingList: ReadingListMeter[] = [];
      for (const meter of meterList) {
        let readingList: ReadingList[] = [];
        const readingListA: ReadingList[] = [];
        const readingListB: ReadingList[] = [];

        if (Object.keys(meter.registers).length === 2 && meterType === 'DZ') {
          for (const [obisCode, register] of Object.entries(meter.registers)) {
            const sortedReadings = data.meters[meter.id].registers[obisCode].readings
              .sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
              .reverse();
            for (const reading of sortedReadings) {
              const readingListItem: ReadingList = {
                date: formatDateMessageLocale(reading.date),
                meter: meter.id.toString(),
                meterType: 'DZ',
                valueHt: register.type === RegisterType.DOUBLE_TARIFF_PEAK ? reading.value : 0,
                valueNt: register.type === RegisterType.DOUBLE_TARIFF_OFF_PEAK ? reading.value : 0,
                origin: mapOrigin(reading.origin),
                channel: mapChannel(reading.channel) ? `(${mapChannel(reading.channel)})` : '',
                quality: mapQuality(reading.quality),
                obisCode,
              };
              if (register.type === RegisterType.DOUBLE_TARIFF_PEAK) {
                readingListA.push(readingListItem);
              } else {
                readingListB.push(readingListItem);
              }
            }
          }

          for (const itemA of readingListA) {
            for (const itemB of readingListB) {
              if (itemB.date === itemA.date) {
                itemA.valueNt = itemB.valueNt;
              }
            }
          }

          readingList = readingListA;
        } else {
          //const combinedReadings: ReadingList_Reading[] = Object.values(registers)[0].readings.concat(Object.values(registers)[1].readings);
          //const sortedReadings = Object.values(combinedReadings).sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
          // .reverse();

          // let registerData = Object.values(data.meters[meter.id].registers)[0].readings;

          // if (Object.keys(meter.registers).length === 2) {
          //const combinedReadings: ReadingList_Reading[] = Object.values(registers)[0].readings.concat(Object.values(registers)[1].readings);
          //   registerData =  Object.values(data.meters[meter.id].registers)[0].readings.concat(Object.values(data.meters[meter.id].registers)[1]).readings;
          // }

          //const sortedReadings = registerData.readings.sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date)).reverse();

          for (const [obisCode, register] of Object.entries(meter.registers)) {
            const sortedReadings = data.meters[meter.id].registers[obisCode].readings
              .sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
              .reverse();
            for (const reading of sortedReadings) {
              const readingListItem: ReadingList = {
                date: reading.date,
                meter: meter.id.toString(),
                meterType: 'EZ',
                valueHt: reading.value ?? 0,
                valueNt: 0,
                origin: mapOrigin(reading.origin),
                channel: mapChannel(reading.channel) ? `(${mapChannel(reading.channel)})` : '',
                quality: mapQuality(reading.quality),
                obisCode,
              };

              readingList.push(readingListItem);

            }
          }
        }

        meterReadingList.push({ meter: meter.id.toString(), meterType, meterReadings: readingList });
      }

      setMeterReadings(meterReadingList);
    } catch (error) {
      console.log(error)
    }
  }, [data, meterNumber, meters]);

  return {
    meterReadings,
    error,
    isLoading,
    invalidate: revalidate,
  };
};
