import { Contract } from '@enpowerx/apis/lib/contracting/v2';
import { EnergyDelivery_UsageType, EnergyType } from '@enpowerx/apis/lib/types';
import { Button, Divider, Grid, Typography } from '@enpxio/components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FunctionComponent } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ContractTypeIcon } from '../../svg/contractTypeIcon';
import { defaultContract, useSelectedContract } from '~/providers';

type ContractSelectorProps = {
  selectionTriggered?: CallableFunction;
};

export const ContractSelector: FunctionComponent<ContractSelectorProps> = (props) => {
  const { classes } = useStyles();
  const { isLoading, selectedContract, setSelectedContract, availableContracts } = useSelectedContract();
  if (!selectedContract.id) {
    return null;
  }

  return availableContracts.length === 0 ? (
    <div className={classes.container}>
      <Grid container>
        {/* Note: Passing `classes` is not supported by ContractTypeIcon: classes={iconClasses} */}
        <ContractTypeIcon contract={selectedContract} isLoading={isLoading} />
        <ContractTypeText contract={selectedContract} />
      </Grid>
    </div>
  ) : (
    <div className={classes.container}>
      <Grid container className={classes.container}>
        {/* Note: Passing `classes` is not supported by ContractTypeIcon: classes={iconClasses}. */}
        <Button
          startIcon={<ContractTypeIcon contract={selectedContract} isLoading={isLoading} />}
          endIcon={<ChevronRightIcon />}
          sx={{
            color: {
              xs: "black",
              md: "primary.contrastText"
            },
            "&:hover": {
              color: "primary.light",
            },
            
          }} onClick={() => {
            setSelectedContract(defaultContract);
            if (props.selectionTriggered) {
              props.selectionTriggered();
            }
          }}
        >
          <Grid container>
            <Grid item>
              <ContractTypeText contract={selectedContract} />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" variant="middle" sx={{
                borderColor: "primary.contrastText",
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '0px',
                marginBottom: '0px'
              }} />
            </Grid>
            <Grid item>
              <Typography fontSize="18px">Vertragsauswahl</Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </div>
  );
};

const ContractTypeText: FunctionComponent<{ contract: Contract }> = (props: { contract: Contract }) => {
  const { contract } = props;
  switch (contract.delivery?.energyType) {
    case EnergyType.ELECTRICITY:
      switch (contract.delivery?.usageType) {
        case EnergyDelivery_UsageType.STORAGE_HEATER:
        case EnergyDelivery_UsageType.HEAT_PUMP:
          return <Typography fontSize="18px">Wärmestrom</Typography>;
        case EnergyDelivery_UsageType.CHARGING_CURRENT:
          return <Typography fontSize="18px">Ladestrom</Typography>;
        default:
          return <Typography fontSize="18px">Strom</Typography>;
      }

    case EnergyType.GAS:
      return <Typography fontSize="18px">Gas</Typography>;
    default:
      return null;
  }
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    left: '50px',
    width: '350px',
    height: '70px',
    [theme.breakpoints.down('lg')]: {
      left: '10px',
    },
  },
}));

// const useIconStyles = makeStyles()(theme => ({
//   root: {
//     color: theme.palette.text.primary,
//   },
// }));
