import { FC } from 'react';

interface ConsumptionLocaleFormatterProps {
  value: number;
  formatOptions?: Intl.NumberFormatOptions;
}

export const ConsumptionLocaleFormatter: FC<ConsumptionLocaleFormatterProps> = (props: ConsumptionLocaleFormatterProps) => {
  const numberFormatter = new Intl.NumberFormat(window.navigator.language, props.formatOptions);
  return <>{numberFormatter.format(props.value)} kWh</>;
};
