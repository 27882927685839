import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

type FormCardProps = PropsWithChildren<{
  title: string | React.ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
}>;

interface EmptyFormViewProps {
  isEmptyMessage?: string | ReactNode;
}

export const FormCard: FC<FormCardProps> = (props: FormCardProps) => {
  const { classes } = useStyles();

  const view = useMemo(() => {
    if (props.isLoading) {
      return <LoadingView />;
    }

    if (props.isError) {
      return <ErrorView />;
    }

    if (props.isEmpty) {
      return <EmptyFormView isEmptyMessage={props.isEmptyMessage} />;
    }

    return props.children;
  }, [props]);

  return (
    <Card elevation={1} className={classes.card}>
      <CardContent>
        <Typography variant="h2" className={classes.title}>
          <Box fontWeight="bold">{props.title}</Box>
        </Typography>
        {view}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: 0,
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& > .MuiCardContent-root': {
      padding: theme.spacing(3),
      margin: 0,
    },
  },
  title: {
    paddingBottom: theme.namedSpacing('large', 'xl'),
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.namedSpacing('small', 'xl'),
    },
    [theme.breakpoints.down(875)]: {
      paddingBottom: theme.spacing(5),
    },
  },
}));

const EmptyFormView: FC<EmptyFormViewProps> = (props: EmptyFormViewProps) => {
  return (
    <Typography variant="subtitle1" component="h2">
      {props.isEmptyMessage}
    </Typography>
  );
};

const ErrorView: FC = () => {
  return (
    <Typography variant="subtitle1" component="h2">
      Informationen konnten nicht abgerufen werden.
    </Typography>
  );
};

export const LoadingView: FC = () => {
  const { classes } = useLoadingViewStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};

const useLoadingViewStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
