import { Box, Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@enpxio/components';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

export interface DashboardCardButton {
  text: string;
  link: string;
  hidden?: boolean;
  state?: Record<string, unknown>;
}

type DashboardCardProps = PropsWithChildren<{
  title: string;
  children: ReactNode;
  button?: DashboardCardButton;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
}>;

interface EmptyViewProps {
  isEmptyMessage?: string | ReactNode;
}

export const DashboardCard: FC<DashboardCardProps> = (props: DashboardCardProps) => {
  const { classes } = useStyles();
  const button = useMemo(() => {
    if (props.isLoading || !props.button) {
      return null;
    }

    return (
      <CardActions>
        <Link to={props.button.link} state={props.button.state} className={classes.button}>
          <Button variant="outlined" fullWidth color="secondary">
            <Box fontWeight="bold">{props.button.text}</Box>
          </Button>
        </Link>
      </CardActions>
    );
  }, [props]);

  const view = useMemo(() => {
    if (props.isLoading) {
      return <LoadingView />;
    }

    if (props.isError) {
      return <ErrorView />;
    }

    if (props.isEmpty) {
      return <EmptyView isEmptyMessage={props.isEmptyMessage} />;
    }

    return props.children;
  }, [props]);

  return (
    <Card elevation={1} className={classes.card}>
      <CardContent>
        <Typography variant="h2" className={classes.title}>
          <Box fontWeight="bold">{props.title}</Box>
        </Typography>
        {view}
      </CardContent>
      <div>{button}</div>
    </Card>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: 0,
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& > .MuiCardContent-root': {
      padding: theme.namedSpacing('large', 'md', 'md', 'zero', 'md'),
    },
    '& .MuiCardActions-root': {
      padding: theme.namedSpacing('large', 'zero', 'md', 'md', 'md'),
    },
    '& .MuiTypography-h1': {
      [theme.breakpoints.down(1024)]: {
        fontSize: '28px',
        paddingBottom: theme.namedSpacing('small', 'sm'),
      },
    },
    '& .MuiTypography-h2': {
      [theme.breakpoints.down(1024)]: {
        fontSize: '20px',
        paddingBottom: theme.namedSpacing('small', 'sm'),
      },
    },
    '& .MuiTypography-body1, .MuiButton-root': {
      [theme.breakpoints.down(1024)]: {
        fontSize: '14px',
      },
    },
  },
  title: {
    paddingBottom: theme.namedSpacing('large', 'xl'),
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.namedSpacing('small', 'xl'),
    },
    [theme.breakpoints.down(875)]: {
      paddingBottom: theme.spacing(5),
    },
  },
  button: {
    width: '100%',
    textDecoration: 'none',
  },
}));

const EmptyView: FC<EmptyViewProps> = (props: EmptyViewProps) => {
  return (
    <Typography variant="subtitle1" component="h2">
      {props.isEmptyMessage}
    </Typography>
  );
};

const ErrorView: FC = () => {
  return (
    <Typography variant="subtitle1" component="h2">
      Informationen konnten nicht abgerufen werden.
    </Typography>
  );
};

export const LoadingView: FC = () => {
  const { classes } = useLoadingViewStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};

const useLoadingViewStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
