import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { PropsWithChildren } from 'react';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

export function MuiStyleEngine(props: PropsWithChildren) {
  return <CacheProvider value={muiCache}>{props.children}</CacheProvider>;
}
