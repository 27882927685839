import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress, Link, Theme } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DownloadFileDelegate, Downloader } from './downloader';

export interface DownloadItem {
  firstColumn: string | React.ReactNode;
  secondColumn?: string | React.ReactNode;
  thirdColumn?: string | React.ReactNode;
}

interface FileDownloader extends DownloadItem {
  downloadFileDelegate: DownloadFileDelegate;
}

interface FileLink extends DownloadItem {
  downloadURL: string;
}

function isFileDownloader(val: FileDownloader | FileLink): val is FileDownloader {
  return (val as FileLink).downloadURL === undefined;
}

export type DownloadableFile = FileDownloader | FileLink;

export interface DownloadListProps {
  files: DownloadableFile[];
  className?: string;
}

export const DownloadList: FC<DownloadListProps> = (props: DownloadListProps) => {
  const { classes, cx } = useStyles();
  const list = useMemo(() => props.files.map((file, index) => <DownloadEntry file={file} key={`${index}`} />), [props.files]);
  return <div className={cx(classes.root, props.className)}>{list}</div>;
};

interface DownloadEntryProps {
  file: DownloadableFile;
}

const DownloadEntry: FC<DownloadEntryProps> = (props: DownloadEntryProps) => {
  const { classes, cx } = useStyles();
  const [isDownloading, setIsDownloading] = useState(false);
  return useMemo(() => {
    const entry = (
      <div className={cx(classes.file, isDownloading ? classes.isDownloading : '')}>
        <div className={classes.icon}>{isDownloading ? <CircularProgress color="secondary" size={24} /> : <GetAppIcon color="secondary" />}</div>
        <div className={classes.contentWrapper}>
          <div className={classes.firstColumn}>{props.file.firstColumn}</div>
          {props.file.secondColumn ? <div className={classes.secondColumn}>{props.file.secondColumn}</div> : null}
        </div>
        <div className={classes.thirdColumn}>{props.file.thirdColumn}</div>
      </div>
    );
    if (isFileDownloader(props.file)) {
      return (
        <Downloader downloadFileDelegate={props.file.downloadFileDelegate} setIsDownloadingDelegate={setIsDownloading}>
          {entry}
        </Downloader>
      );
    }

    return (
      <Link href={props.file.downloadURL} target="_blank" rel="noreferrer" className={classes.link}>
        {entry}
      </Link>
    );
  }, [props, isDownloading]);
};

const useStyles = makeStyles({ name: 'EpxFileList' })((theme: Theme) => ({
  root: {},
  file: {
    cursor: 'pointer',
    maxHeight: '62px',
    [theme.breakpoints.down('md')]: {
      // For multiline text we need more than the 62px required by default
      // Not sure actually why this was set in first place.
      maxHeight: '200px'
    },
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    marginBottom: '4px',
    lineHeight: 1.25,
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': { textDecoration: 'none' },
  },
  icon: {
    padding: '19px 0px 19px 24px',
    display: 'flex',
  },
  contentWrapper: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    width: '100%',
  },
  firstColumn: {
    paddingLeft: '24px',
    display: 'flex',
    // Gap is a better approach here than the previous &nbsp; as this has
    // no ugly side effects when wrapping is in place.
    gap: "3px",
    // This padding helps preventing layout crashes of text on the edge of
    // the box when in mobile rendering.
    paddingTop: '12px',
    paddingBottom: '12px',
    // Allow cells to wrap under each other - required for small and mobile.
    flexWrap: "wrap",
    alignSelf: 'center',
  },
  secondColumn: {
    marginTop: '3px',
    marginBottom: '3px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
  thirdColumn: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: '16px',
  },
  isDownloading: {
    pointerEvents: 'none',
  },
}));
