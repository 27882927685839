import { Channel as CommunicationChannel, CustomerPreference_Payload } from '@enpowerx/apis/lib/communication/v2';
import { Button, CardActions, Checkbox, FormControlLabel, PageViewCardTitle, Theme, Typography } from '@enpxio/components';
import { SaveOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAPI } from '../../../providers';
import { concatValues } from '../../../utils/stringConcatenation';
import { Channel, ConsentData, Topic } from './advertisingConsentData';

interface EditModeProps {
  data: ConsentData;
  preferences: CustomerPreference_Payload;
  setPreferences: (p: CustomerPreference_Payload) => void;
  setEdit: (edit: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  channelsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  checkBox: {
    width: '49%',
  },
  checkBoxColor: {
    color: theme.palette.secondary.main,
  },
}));

const EditMode: FC<EditModeProps> = (props: EditModeProps) => {
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();

  const { data, preferences, setPreferences } = props;
  const { classes } = useStyles();

  const preSelectedChannels = new Map<CommunicationChannel, boolean>(preferences.marketing?.channels?.map((c) => [c, true]));
  const preSelectedTopics = new Map<string, boolean>(preferences.marketing?.allowedTopics?.map((t) => [t, true]));

  const [channels, setChannels] = useState<Map<CommunicationChannel, boolean>>(preSelectedChannels);
  const [topics, setTopics] = useState(preSelectedTopics);

  const anyChannelSelected: boolean = data.channels.some((c) => channels.get(c.key));
  const anyTopicSelected: boolean = data.topics.some((t) => topics.get(t.key));
  const anyError = anyChannelSelected !== anyTopicSelected;

  const updateCommunicationPreferences = async (): Promise<void> => {
    if (anyError) return;

    try {
      const selectedChannels = data.channels.filter((c) => channels.get(c.key));
      const selectedTopics = data.topics.filter((t) => topics.get(t.key));
      const unselectedTopics = data.topics.filter((t) => !topics.get(t.key));
      const newPrefs = { ...preferences };
      if (!newPrefs.marketing) {
        newPrefs.marketing = {
          channels: [],
          allowedTopics: [],
          deniedTopics: [],
        };
      }

      newPrefs.marketing.channels = selectedChannels.map((c) => c.key);
      newPrefs.marketing.allowedTopics = selectedTopics.map((t) => t.key);
      newPrefs.marketing.deniedTopics = unselectedTopics.map((t) => t.key);
      newPrefs.consentText = buildConsentText(data, selectedChannels, selectedTopics);

      await api.communication.me.communicationPreference.set(newPrefs);
      setPreferences(newPrefs);
      enqueueSnackbar('Ihre Werbeeinwilligung wurde erfolgreich aktualisiert');
      props.setEdit(false);
    } catch {
      enqueueSnackbar('Ihre Werbeeinwilligung konnte nicht aktualisiert werden.', {
        variant: 'error',
      });
    }
  };

  const onChannelSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target;
    setChannels((prevValue) => {
      const newValue = new Map(prevValue);
      newValue.set(target.name as CommunicationChannel, target.checked);
      return newValue;
    });
  };

  const onTopicSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target;
    setTopics((prevValue) => {
      const newValue = new Map(prevValue);
      newValue.set(target.name, target.checked);
      return newValue;
    });
  };

  if (!preferences) {
    return <>Loading</>;
  }

  return (
    <div>
      <PageViewCardTitle
        title={
          <Typography variant="h2" component="div">
            Werbeeinwilligungen bearbeiten
          </Typography>
        }
      />
      <Typography variant="body1">{data.editDescription}</Typography>
      <br />
      <br />
      <Typography variant="body1">
        {data.editPreChannels}
        <br />
        <br />
      </Typography>
      <div className={classes.channelsWrapper}>
        {data.channels.map((channel: Channel) => (
          <FormControlLabel
            control={<Checkbox checked={Boolean(channels.get(channel.key))} onChange={onChannelSelect} name={channel.key} className={classes.checkBoxColor} />}
            label={channel.displayName}
            key={channel.key}
            className={classes.checkBox}
          />
        ))}
      </div>
      <Typography variant="body1">
        {anyTopicSelected && !anyChannelSelected ? (
          <>
            <span style={{ color: 'red' }}>Wählen Sie mindestens einen Kanal aus!</span>
            <br />
          </>
        ) : null}
        <br />
        {data.editPreTopics}
        <br />
        <br />
      </Typography>
      {data.topics.map((topic: Topic) => (
        <FormControlLabel
          control={<Checkbox checked={Boolean(topics.get(topic.key))} onChange={onTopicSelect} name={topic.key} className={classes.checkBoxColor} />}
          label={topic.displayName}
          key={topic.key}
          className={classes.checkBox}
        />
      ))}
      <Typography variant="body1">
        {anyChannelSelected && !anyTopicSelected ? (
          <>
            <span style={{ color: 'red' }}>Wählen Sie mindestens ein Thema aus!</span>
            <br />
          </>
        ) : null}
        <br />
        {data.editEnd}
        <br />
        <br />
      </Typography>
      <CardActions style={{ justifyContent: 'center' }}>
        <Button disabled={anyError} variant="outlined" color="secondary" onClick={updateCommunicationPreferences} startIcon={<SaveOutlined />}>
          Speichern
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            props.setEdit(false);
            e.preventDefault();
          }}
        >
          Abbrechen
        </Button>
      </CardActions>
      <br />
    </div>
  );
};

export default EditMode;

function buildConsentText(data: ConsentData, marketingChannels: Channel[], marketingTopics: Topic[]): string {
  if (marketingChannels.length === 0 && marketingTopics.length === 0) return 'Keine Werbeeinwilligung erteilt.';
  return [
    data.editPreChannels,
    concatValues(marketingChannels.map((c) => c.displayName)),
    data.editPreTopics,
    concatValues(marketingTopics.map((t) => t.displayName)),
    data.editEnd,
  ].join(' ');
}
