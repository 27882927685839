import { Theme, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Container, WideContainer } from '../container';

type PageViewContentProps = PropsWithChildren<{
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
}>;

type PageViewProps = PageViewContentProps & {
  wide?: boolean;
  className?: string;
};

const useContentStyles = makeStyles({ name: 'EpxPageViewContent' })((theme: Theme) => ({
  title: {
    paddingBottom: theme.namedSpacing('small', 'xl'),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.namedSpacing('large', 'xl'),
    },
  },
  titleContainer: {
    paddingBottom: theme.namedSpacing('small', 'xxl'),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.namedSpacing('large', 'xxl'),
    },
  },
}));

const PageViewContent: FC<PageViewContentProps> = (props) => {
  const { classes } = useContentStyles();
  return (
    <>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {typeof props.title === 'string' ? (
            <Typography variant="h1" data-test-id="page-view-title">
              {props.title}
            </Typography>
          ) : (
            <>{props.title}</>
          )}
        </div>
        <div>
          {typeof props.subTitle === 'string' ? (
            <Typography variant="body1" data-test-id="page-view-title-sub" hidden={props.subTitle === ''}>
              {props.subTitle}
            </Typography>
          ) : (
            <>{props.subTitle}</>
          )}
        </div>
      </div>
      {props.children}
    </>
  );
};

export const PageView: FC<PageViewProps> = (props) => (
  <div className={props.className}>
    {props.wide ? (
      <WideContainer>
        <PageViewContent title={props.title} subTitle={props.subTitle}>
          {props.children}
        </PageViewContent>
      </WideContainer>
    ) : (
      <Container>
        <PageViewContent title={props.title} subTitle={props.subTitle}>
          {props.children}
        </PageViewContent>
      </Container>
    )}
  </div>
);
