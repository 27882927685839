import { ContractCancellationReason, Relocation, Relocation_UseNewAddress } from '@enpowerx/apis/lib/contracting/v2';
import { CustomerType } from '@enpowerx/apis/lib/identities/v2';
import { EnergyDelivery_UsageType, EnergyType } from '@enpowerx/apis/lib/types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { RelocationValues } from '../pageRelocation';
import MultiStepForm, { FormStep } from './MultiStepForm';
import { FormStepCheck } from './formCheck';
import { FormStepAddress } from './formStepAdress';
import { FormStepContract } from './formStepContract';
import { FormStepMeter } from './formStepMeter';
import { PortalPages } from '~/portalPages';
import { GoogleProtobufAny, useAPI, useReportingAPI } from '~/providers';
import { useAuth0 } from '~/providers/auth0';
import { useSelectedContract } from '~/providers/selectedContract';

const mandatoryField = 'Dies ist ein Pflichtfeld.';
const validationSchemaStep2 = yup.object({
  street: yup.string().required('Street is required'),
  city: yup.string().required('City is Required'),
});

const validationSchema = yup.object({
  moveoutDate: yup
    .date()
    .required(mandatoryField)
    .test('moveoutDate', 'Ihr Einzugsdatum darf höchstens 42 Tage in der Vergangenheit liegen.', (value) => dayjs().diff(dayjs(value), 'd') <= 42),
  postalCode: yup
    .string()
    .required(mandatoryField)
    .matches(/^[\d +]{5}$/, 'Bitte geben Sie eine gültige Postleitzahl ein.'),
  houseNumber: yup.string().required(mandatoryField),
  city: yup.string().required(mandatoryField).nullable(),
  street: yup.string().required(mandatoryField).nullable(),
});

const validationSchemaStep3 = yup.object({
  meterNumberFlag: yup.boolean(),
  meterNumber: yup.string().when('meterNumberFlag', { is: true, then: yup.string().required(mandatoryField) }),
  annualUsage: yup.number().required(mandatoryField),
});

interface RelocationFormProps {
  withTarifChange?: boolean;
}

const RelocationForm: FC<RelocationFormProps> = (props: RelocationFormProps) => {
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { selectedContract, setIsLoading, setSelectedContract } = useSelectedContract();
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const { trackEvent } = useReportingAPI();

  const disableNext = (value: boolean): void => {
    setIsNextDisabled(value);
  };

  const handleSubmit = async (values: RelocationValues): Promise<void> => {
    const event: GoogleProtobufAny = {
      '@type': 'type.googleapis.com/enpowerx.reporting.v1.RelocationStarted',
      contract: selectedContract.id,
      customer: selectedContract.customer,
      address: {
        region: 'DE',
        postalCode: values.postalCode,
        city: values.city,
        street: values.street,
        houseNumber: values.houseNumber,
        postboxNumber: '',
        additionalAddressLines: [''],
        district: '',
      },
      moveinDate: {
        day: values.moveinDate.getDate(),
        month: values.moveinDate.getMonth() + 1,
        year: values.moveinDate.getFullYear(),
      },
    };
    trackEvent('RelocationStarted', event);
    const request: Relocation = {
      moveoutDate: {
        day: values.moveoutDate.getDate(),
        month: values.moveoutDate.getMonth() + 1,
        year: values.moveoutDate.getFullYear(),
      },
      moveinDate: {
        day: values.moveinDate.getDate(),
        month: values.moveinDate.getMonth() + 1,
        year: values.moveinDate.getFullYear(),
      },
      meter: values.meterNumber,
      delivery: {
        energyType: selectedContract.delivery !== undefined ? selectedContract.delivery.energyType : EnergyType.UNRECOGNIZED,
        customerType: selectedContract.delivery !== undefined ? selectedContract.delivery.customerType : CustomerType.UNRECOGNIZED,
        annualUsage: Number.parseInt(values.annualUsage, 10),
        annualUsageNt: 0,
        usageType: selectedContract.delivery?.usageType ?? EnergyDelivery_UsageType.UNRECOGNIZED,
        address: {
          region: 'DE',
          postalCode: values.postalCode,
          city: values.city,
          street: values.street,
          houseNumber: values.houseNumber,
          postboxNumber: '',
          additionalAddressLines: [''],
          district: '',
        },
      },
      useNewAddress: values.useNewAddress,
    };

    if (values.reading !== undefined && request !== undefined) {
      request.lastReading = {
        date: values.reading.date,
        values: values.reading.values,
        meter: values.oldMeterNumber,
      };
    }

    api.currentContract.relocations.workflow
      .invoke(request)
      .then(() => {
        const updatedSelectedContract = selectedContract;
        updatedSelectedContract.cancellationReason = ContractCancellationReason.RELOCATION_WITH_CONTRACT_TRANSFER;
        setSelectedContract(updatedSelectedContract);

        setIsLoading(false);
        navigate(PortalPages.relocationSuccess.absolutePathname);
      })
      .catch((error: unknown) => {
        console.error(error);
        setIsLoading(false);
        enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
          variant: 'error',
        });
      });
  };

  const initialValues: RelocationValues = {
    moveoutDate: new Date(),
    moveinDate: new Date(),
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    meterNumberFlag: true,
    meterNumber: '',
    annualUsage: '',
    reading: undefined,
    useNewAddress: Relocation_UseNewAddress.IMMEDIATELY,
    oldPostalCode:
      selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.postalCode : '') : '',
    oldCity: selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.city : '') : '',
    oldStreet: selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.street : '') : '',
    oldHouseNumber:
      selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.houseNumber : '') : '',
    oldMeterNumber: selectedContract.meterNumber,
    oldAnnualUsage: selectedContract.delivery !== undefined ? selectedContract.delivery.annualUsage.toString() : '',
    newTariff: undefined,
    email: user.email ?? '',
  };

  // SelectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.postalCode : '') : '',
  return (
    <Box maxWidth="m">
      <MultiStepForm
        initialValues={initialValues}
        isNextDisabled={isNextDisabled}
        isTariffChange={false}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <FormStep stepName="Adresse" validationSchema={validationSchema}>
          <FormStepAddress />
        </FormStep>

        <FormStep stepName="Vertrag" validationSchema={validationSchemaStep2}>
          <FormStepContract disableNext={disableNext} />
        </FormStep>

        <FormStep stepName="Zähler" validationSchema={validationSchemaStep3}>
          <FormStepMeter />
        </FormStep>

        <FormStep stepName="Prüfen">
          <FormStepCheck />
        </FormStep>
      </MultiStepForm>
    </Box>
  );
};

export default RelocationForm;
