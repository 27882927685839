import { CustomerType } from '@enpowerx/apis/lib/identities/v2';
import { Person, Person_SalutationForm } from '@enpowerx/apis/lib/types';
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextFieldProps,
} from '@enpxio/components';
import { SalutationLocaleFormatter, getSelectableSalutations } from '@enpxio/formatters';
import { SaveOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { useCustomer } from '../../../customers/hooks/useCustomer';
import { usePerson } from '../../../customers/hooks/usePerson';
import { useAPI, useSelectedContract } from '../../../providers';

interface Values {
  salutation: Person_SalutationForm;
  lastname: string;
  firstname: string;
  birthday?: Date | undefined;
  email: string;
  phone?: string;
  mobilePhone?: string;
  title: string;
}

interface CustomerEditProps {
  onSaved: () => void;
  onCancel: () => void;
}

export const CustomerEdit: FC<CustomerEditProps> = (props: CustomerEditProps) => {
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();
  const { selectedContract, invalidateContracts } = useSelectedContract();
  const { customer, invalidate: invalidateCustomer } = useCustomer();
  const { invalidate: invalidatePerson } = usePerson();
  const api = useAPI();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showError, setShowError] = useState(false);
  const currentBirthday = customer?.person?.birthday
    ? new Date(customer?.person.birthday.year, customer?.person.birthday.month - 1, customer?.person.birthday.day)
    : null;
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(currentBirthday ? currentBirthday : null);
  const initialValues: Values = {
    salutation: customer?.person?.salutation ?? Person_SalutationForm.MR,
    title: customer?.person?.title ?? '',
    lastname: customer?.person?.lastname ?? '',
    firstname: customer?.person?.firstname ?? '',
    birthday: currentBirthday,
    email: selectedContract?.billing?.contact?.email ?? '',
    phone: customer?.phoneNumber ?? '',
    mobilePhone: customer?.mobilePhoneNumber ?? '',
    isPrivate: customer?.type === CustomerType.PRIVATE,
  };

  useEffect(() => {
    const abortController = new AbortController();
    setAbortSignal(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>): Promise<void> => {
    setShowError(false);
    try {
      const person: Person = {
        salutation: values.salutation,
        title: values.title ?? '',
        company: customer?.person?.company ?? '',
        firstname: values.firstname,
        lastname: values.lastname,
        birthday: selectedDate
          ? {
              day: selectedDate.getDate(),
              month: selectedDate.getMonth() + 1,
              year: selectedDate.getFullYear(),
            }
          : {
              day: 0,
              month: 0,
              year: 0,
            },
      };
      await api.identities.me.person.set(person, abortSignal);
      await api.identities.me.phoneNumber.set(values.phone ?? '', abortSignal);
      await api.identities.me.mobilePhoneNumber.set(values.mobilePhone ?? '', abortSignal);
      const contact = selectedContract?.billing?.contact;
      if (contact) {
        contact.email = values.email;
        await api.contracting.me.billing.contact.set(contact, abortSignal);
        await invalidateContracts(abortSignal);
      }

      await Promise.all([await invalidatePerson(), await invalidateCustomer()]);
      enqueueSnackbar('Kundendaten erfolgreich geändert', { variant: 'success' });
      props.onSaved();
    } catch (error_) {
      interface ExtendedError {
        name: string;
      }
      const error = error_ as ExtendedError;
      if (error.name === 'AbortError') {
        enqueueSnackbar('Änderung der Kundendaten abgebrochen.', { variant: 'warning' });
      } else {
        setShowError(true);
      }

      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange>
      {({ submitForm, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <CardContent className={classes.alignCenter}>
            <Grid direction="row" justifyContent="center" alignItems="center" spacing={10} container>
              <Grid xs={12} sm={6} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="salutation">Anrede</InputLabel>
                  <Field label="Anrede" as={Select} name="salutation" labelWidth={100} disabled>
                    {getSelectableSalutations().map((val) => (
                      <MenuItem key={val} value={val}>
                        <SalutationLocaleFormatter salutation={val} />
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText error>
                    <ErrorMessage name="salutation" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="title">Titel</InputLabel>
                  <Field
                    label="title"
                    as={OutlinedInput}
                    name="title"
                    labelWidth={100}
                    disabled={customer?.type === CustomerType.PRIVATE ? isSubmitting : true}
                  />
                  <FormHelperText error>
                    <ErrorMessage name="title" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="firstname">Vorname</InputLabel>
                  <Field label="Vorname" as={OutlinedInput} name="firstname" labelWidth={100} disabled />
                  <FormHelperText error>
                    <ErrorMessage name="firstname" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="lastname">Nachname</InputLabel>
                  <Field
                    label="Nachname"
                    as={OutlinedInput}
                    name="lastname"
                    labelWidth={100}
                    disabled
                  />
                  <FormHelperText error>
                    <ErrorMessage name="lastname" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} justifyContent="center" alignContent="center" item>
                Zur Namensänderung wenden Sie sich bitte an unseren Kundenservice oder schreiben Sie uns eine Nachricht.
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <Field
                    component={DatePicker}
                    inputVariant="outlined"
                    name="birthday"
                    className={classes.formControl}
                    variant="inline"
                    margin="normal"
                    label="Geburtstag"
                    disabled={isSubmitting}
                    fullWidth
                    renderInput={(params: TextFieldProps) => <TextField {...params} />}
                    value={selectedDate}
                    onChange={(newValue: number | undefined) => {
                      const newDateValue = new Date(newValue ?? 0);
                      if (newValue) setSelectedDate(newDateValue);
                    }}
                  />
                </FormControl>
                <FormHelperText error>
                  <ErrorMessage name="birthday" />
                </FormHelperText>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="email">E-Mail-Adresse</InputLabel>
                  <Field label="E-Mail-Adresse" inputVariant="outlined" as={OutlinedInput} name="email" labelWidth={100} disabled={isSubmitting} />
                  <FormHelperText error>
                    <ErrorMessage name="email" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="mobilePhone">Mobil</InputLabel>
                  <Field label="Mobil" as={OutlinedInput} name="mobilePhone" labelWidth={100} disabled={isSubmitting} />
                  <FormHelperText error>
                    <ErrorMessage name="mobilePhone" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="phone">Festnetz</InputLabel>
                  <Field label="Festnetz" as={OutlinedInput} name="phone" labelWidth={100} disabled={isSubmitting} />
                  <FormHelperText error>
                    <ErrorMessage name="phone" />
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {showError ? <Alert severity="error">Leider ist ein Fehler bei der Änderung aufgetreten. Bitte versuchen Sie es später noch einmal.</Alert> : null}
          </CardContent>
          <CardActions style={{ justifyContent: 'center' }}>
            <Button variant="outlined" color="secondary" disabled={isSubmitting} onClick={submitForm} startIcon={<SaveOutlined />}>
              Speichern
            </Button>
            <Button variant="outlined" color="secondary" disabled={isSubmitting} onClick={props.onCancel}>
              Abbrechen
            </Button>
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

const validationSchema = yup.object({
  isPrivate: yup.boolean(),
  salutation: yup.mixed<string>().when(['isPrivate'], {
    is: true,
    then: yup.mixed<Person_SalutationForm>().required('Bitte wählen Sie eine Anrede aus.'),
    otherwise: yup.mixed<Person_SalutationForm>().notRequired(),
  }),
  birthday: yup.date().nullable().notRequired(),
  email: yup.string().email('Ungültige E-Mail-Adresse.'),
  mobilePhone: yup.string().matches(/^$|^[+0][\d\s()/-]+$/, 'Keine gültige Mobilfunknummer.'),
  phone: yup.string().matches(/^$|^[+0][\d\s()/-]+$/, 'Keine gültige Festnetznummer.'),
});

const useStyles = makeStyles()(() => ({
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
  },
  formControl: {
    marginTop: '0px',
    minWidth: '120px',
  },
}));

export default CustomerEdit;
