import PageContact from './contact/pageContact';
import CancellationPage from './contracts/cancellationPage';
import PageContractOverview from './contracts/pageContractOverview';
import PageDashboard from './dashboard/pageDashboard';
import PageAuth0Error from './errors/pageAuth0Error';
import PageError from './errors/pageError';
import IntentDeclarationPage from './intentDeclaration/intentDeclarationPage';
import InvoiceExplanationPage from './invoiceExplanation/pageInvoiceExplanation';
import PageInvoices from './invoices/pageInvoice';
import PageAdConsentDoubleOptIn from './marketing/pageAdConsentDoubleOptIn';
import PageMarketingOptIn from './marketing/pageMarketingOptIn';
import PageLoginlessReading from './meters/pageLoginlessReading';
import PageMeter from './meters/pageMeter';
import PageMyData from './mydata/pageMyData';
import IndexPage from './pages';
import NotFoundPage from './pages/404';
import { LoginPage } from './pages/login';
import PasswordReset from './passwordReset/passwordResetPage';
import PostAcquisitionPage from './postAcquisition/postAcquisitionPage';
import { Page, PageFlags } from './providers/pages/pages';
import RegistrationPage from './registration/registrationPage';
import VerificationPage from './registration/registrationVerification';
import PageRelocationSuccess from './relocation/page/RelocationSuccess';
import PageRelocation from './relocation/pageRelocation';
import PageTariffChange from './tariffChange/pageTariffChange';
import PageNonCommodity from './nonCommodity/pageNonCommodity'

export const PortalPages = {
  None: undefined,
  nonCommodity: new Page('NonCommodity','/non-commodity',<PageNonCommodity />,PageFlags.None),
  notFound: new Page('NotFound', '/*', <NotFoundPage />, PageFlags.None),
  home: new Page('Startseite', '/', <IndexPage />, PageFlags.None),
  login: new Page('Login', '/login', <LoginPage />, PageFlags.None),
  dashboard: new Page('Start', '/dashboard', <PageDashboard />, PageFlags.Default),
  invoicesAndPayment: new Page('Rechnungen & Zahlung', '/invoices', <PageInvoices />, PageFlags.Default),
  invoicesRedirect: new Page('Rechnungen Redirect', '/goto/*', <PageInvoices />, PageFlags.Authenticated),
  meters: new Page('Zähler', '/meters', <PageMeter />, PageFlags.Default),
  contract: new Page('Vertrag', '/contract', <PageContractOverview />, PageFlags.Default),
  cancellation: new Page('Cancellation', '/jetzt-kuendigen', <CancellationPage />, PageFlags.None),
  tariffChange: new Page('Tarifwechsel', '/tariffs', <PageTariffChange />, PageFlags.Authenticated),
  myData: new Page('Meine Daten', '/mydata', <PageMyData />, PageFlags.Default),
  relocation: new Page('Umzug', '/relocation', <PageRelocation />, PageFlags.Default),
  relocationSuccess: new Page('Umzug erfolgreich', '/relocationSuccess', <PageRelocationSuccess />, PageFlags.None),
  contact: new Page('Hilfe & Kontakt', '/contact', <PageContact />, PageFlags.NavigationBar | PageFlags.SideMenu),
  marketingOptIn: new Page('Marketing Optin', '/marketing-optin/:token', <PageMarketingOptIn />, PageFlags.None),
  marketingOptInWithoutToken: new Page('Marketing Optin', '/marketing-optin', <PageMarketingOptIn />, PageFlags.None),
  postAcquisition: new Page('Nachbearbeitung der Daten', '/missing-data/:token', <PostAcquisitionPage />, PageFlags.None),
  IntentDeclaration: new Page('Willenserklärung', '/intentdeclaration/:token', <IntentDeclarationPage />, PageFlags.None),
  registration: new Page('Registrierung', '/registration', <RegistrationPage />, PageFlags.AuthenticatedNoMenu),
  verification: new Page('Verifizierung', '/verification', <VerificationPage />, PageFlags.None),
  passwordReset: new Page('Password Zurücksetzen', '/password-reset-email', <PasswordReset />, PageFlags.None),
  reading: new Page('Zählerstandserfassung', '/ablesung/:token', <PageLoginlessReading />, PageFlags.None),
  error: new Page('Fehler', '/error', <PageError />, PageFlags.None),
  errorAuth: new Page('AuthFehler', '/autherror', <PageAuth0Error />, PageFlags.None),
  doubleOptin: new Page('Werbezustimmung bestätigen', '/doubleoptin/:token', <PageAdConsentDoubleOptIn />, PageFlags.None),
  invoiceExplanation: new Page('Rechnungs­erläuterung', '/invoiceExplanation', <InvoiceExplanationPage />, PageFlags.None),
};
