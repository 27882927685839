import { gql, useQuery } from '@apollo/client';
import { ApiError } from '@enpowerx/apis';
import { Backdrop, CircularProgress, Link, PageView, Typography, useConfig } from '@enpxio/components';
import { RecaptchaNotice, loadRecaptcha } from '@enpxio/components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PortalPages } from '../portalPages';
import { useAPI } from '~/providers';

const EmailQuery = gql`
  query supportCollectionQuery {
    supportCollection {
      items {
        email
      }
    }
  }
`;

type IntentState = 'Loading' | 'OrderCreatedSuccess' | 'ChangeTariffSuccess' | 'Error';

const LoadingState = () => {
  return (
    <Backdrop open>
      <CircularProgress color="inherit" disableShrink />
    </Backdrop>
  );
};

const ChangeTariffSuccess = () => {
  return (
    <PageView
      title="Vielen Dank für die Bestätigung Ihres Tarifwechsels durch Ihre Willenserklärung"
      subTitle="Sie werden auch zukünftig in Ihrem neuen Tarif sicher durch Erdgas Südwest versorgt."
      wide
    >
      <Typography>
        Hier geht&apos;s zur{' '}
        <Link href={PortalPages.home.absolutePathname} color="secondary">
          Startseite
        </Link>
        .
      </Typography>
      <br />
      <RecaptchaNotice />
    </PageView>
  );
};

const OrderCreatedSuccess = () => {
  return (
    <PageView
      title="Vielen Dank für die Bestätigung Ihres Vertragsverhältnisses"
      subTitle="Durch die Abgabe Ihrer Willenserklärung, kann der Prozess nun bei uns weiter laufen. Wir melden uns bei Ihnen sobald die Formalitäten Ihres Wechsels geklärt sind."
      wide
    >
      <Typography>
        Hier geht&apos;s zur{' '}
        <Link href={PortalPages.home.absolutePathname} color="secondary">
          Startseite
        </Link>
        .
      </Typography>
      <br />
      <RecaptchaNotice />
    </PageView>
  );
};

interface IntentDefaultInfoProps {
  error?: Error;
  token: string;
}

const IntentDefaultInfo = ({ error, token }: IntentDefaultInfoProps) => {
  const { data } = useQuery(EmailQuery);
  const email = data?.supportCollection?.items[0]?.email ?? '...';

  let title = 'Jetzt Daten ergänzen';
  let subTitle = 'Bitte ergänzen Sie jetzt die fehlenden Daten für Ihren Wechsel zu uns.';
  if (error instanceof ApiError && error?.message?.includes('verification token not found')) {
    title = 'Die Datenerfassung kann nicht mehr durchgeführt werden';
    subTitle = `Diese Seite (${token}) wurde entweder bereits verwendet, oder sie ist wegen der zeitlichen Begrenzung abgelaufen. Bei Fragen und Fehlern kontaktieren Sie bitte unseren Kundenservice unter der Email-Adresse ${data?.supportCollection?.items[0]?.email}.`;
  } else if (error) {
    title = 'Unbekannter Fehler';
    subTitle = `Bitte kontaktieren Sie unseren Kundenservice unter der Email-Adresse ${email}.`;
  }

  return (
    <PageView title={title} subTitle={subTitle} wide>
      <Typography>
        Hier geht&apos;s zur{' '}
        <Link href={PortalPages.home.absolutePathname} color="secondary">
          Startseite
        </Link>
        .
      </Typography>
      <br />
      <RecaptchaNotice />
    </PageView>
  );
};

const IntentDeclarationPage: FC = () => {
  const params = useParams();
  const token = params?.token ?? '';

  const config = useConfig();
  const { siteKey } = config.recaptcha;

  const api = useAPI();

  const [state, setState] = useState<IntentState>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    /** Ignore destroyed component updates */
    let ignoreUpdates = false;

    async function updateState() {
      try {
        setState('Loading');
        await loadRecaptcha(siteKey);
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'confirm_order' });
        const verificationToken = await api.infra.verificationTokens.get(token ?? '').read();
        const payload = verificationToken.payload ?? {};

        if (ignoreUpdates) {
          return;
        }

        setError(undefined);

        switch (payload.context) {
          case 'create_order':
            await api.contracting.confirmations.get(token ?? '').set(recaptchaToken);
            setState('OrderCreatedSuccess');
            break;
          case 'change_tariff':
            await api.contracting.confirmations.get(token ?? '').set(recaptchaToken);
            setState('ChangeTariffSuccess');
            break;
          default:
            setState('Error');
            break;
        }
      } catch (error) {
        if (ignoreUpdates) {
          return;
        }

        console.error(error);
        setState('Error');
        setError(error as Error);
      }
    }

    updateState();
    return () => {
      ignoreUpdates = true;
    };
  }, [token, siteKey]);

  switch (state) {
    case 'Loading':
      return <LoadingState />;

    case 'ChangeTariffSuccess':
      return <ChangeTariffSuccess />;

    case 'OrderCreatedSuccess':
      return <OrderCreatedSuccess />;

    // case 'Error':
    // pass

    default:
      return <IntentDefaultInfo token={token} error={error} />;
  }
};

export default IntentDeclarationPage;
