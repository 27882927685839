import { Avatar, Button, Dialog, DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography } from '@enpxio/components';
import { Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: 300,
    color: theme.palette.text.primary,
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
  bulletPoint: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    width: '20px',
    height: '20px',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}));

interface Props {
  setCategory: (c: string) => void;
}

export default function RegistrationHelpCustomer({ setCategory }: Props) {
  const { classes } = useStyles();

  function clearCategory(): void {
    setCategory('');
  }

  return (
    <Dialog open className={classes.root}>
      <Typography align="right">
        <Button color="secondary" onClick={clearCategory} autoFocus>
          <CloseIcon onClick={clearCategory} fontSize="large" color="secondary" />
        </Button>
      </Typography>
      <DialogContent>
        <Typography variant="body2">
          <strong>Informationen zur Kundennummer</strong>
        </Typography>
        <Typography variant="body2" className={classes.spaceXL}>
          Ihre Kundennummer finden Sie auf folgenden Dokumenten:
        </Typography>

        <List className={classes.spaceXL}>
          <ListItem className={classes.root}>
            <ListItemIcon>
              <Avatar className={classes.bulletPoint}>
                <Check />
              </Avatar>
            </ListItemIcon>
            <ListItemText>
              <Typography>Ihrer Jahresrechnung</Typography>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.root}>
            <ListItemIcon>
              <Avatar className={classes.bulletPoint}>
                <Check />
              </Avatar>
            </ListItemIcon>
            <Typography>
              <ListItemText>
                <Typography>Ihrer Vertragsbestätigung</Typography>
              </ListItemText>
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}
