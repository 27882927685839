import { Trait, hasTrait } from '@enpowerx/apis';
import { Billing, Contract } from '@enpowerx/apis/lib/contracting/v2';
import { BankAccount, PaymentMethod } from '@enpowerx/apis/lib/types';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  useConfig,
} from '@enpxio/components';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { useCustomer } from '../../../customers/hooks/useCustomer';
import { GoogleProtobufAny, useAPI, useReportingAPI, useSelectedContract } from '../../../providers';
import { MultiContractApplyButton } from '~/mydata/multiContractApplyButton';

interface EditProps {
  rootAnchor: React.RefObject<HTMLDivElement>;
  focusBankAccount?: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

interface Values {
  bankAccount: BankAccount | undefined;
}

const Edit: FC<EditProps> = (props: EditProps) => {
  const { classes } = useStyles();
  const { customer } = useCustomer();
  const { availableContracts, selectedContract, invalidateContracts } = useSelectedContract();
  const api = useAPI();
  const { trackEvent } = useReportingAPI();
  const { enqueueSnackbar } = useSnackbar();
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();
  const [saveForAllContracts, setSaveForAllContracts] = useState(true);
  const [bic, setBIC] = useState<string>(selectedContract.billing?.bankAccount?.bic ?? '');
  const [bank, setBank] = useState<string>(selectedContract.billing?.bankAccount?.bank ?? '');
  const [validatedIBANs, setValidatedIBANs] = useState<Map<string, BankAccount | undefined>>(new Map<string, BankAccount | undefined>());
  const [initialValues, setInitialValues] = useState<Values>(() => getInitialFormValues(selectedContract));
  const [showError, setShowError] = useState(false);
  const [open, setPopupOpen] = useState(false);
  const config = useConfig();

  if (props.focusBankAccount && props.rootAnchor.current) {
    props.rootAnchor.current.scrollIntoView({ block: 'start' });
  }

  useEffect(() => {
    const abortController = new AbortController();
    setAbortSignal(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    setInitialValues(getInitialFormValues(selectedContract));
  }, [customer, selectedContract]);

  const validateIBAN = async (iban: string): Promise<string | undefined> => {
    const errInvalidIBAN = 'Bitte geben Sie eine gültige IBAN an.';
    let bic = '';
    let bank = '';
    try {
      const ibanSchema = yup
        .string()
        .required('Bitte geben Sie eine IBAN an.')
        .matches(/^[A-Z]{2}\d{2}[\dA-Za-z]{4}\d{7}([\dA-Za-z]?){0,16}$/, errInvalidIBAN);

      const validationResult = await ibanSchema.validate(iban);
      if (validationResult !== iban) {
        return validationResult;
      }

      if (validatedIBANs.has(iban)) {
        const ibanInfo = validatedIBANs.get(iban);
        if (!ibanInfo) {
          return errInvalidIBAN;
        }

        bic = ibanInfo.bic;
        bank = ibanInfo.bank;
        return undefined;
      }

      const ibanInfo = await api.financial.bankAccounts.get(iban).read(abortSignal);
      bic = ibanInfo.bic;
      bank = ibanInfo.bank;
      setValidatedIBANs((prevIBANs) => prevIBANs.set(iban, ibanInfo));
    } catch (error) {
      if (hasTrait(error as Error, Trait.InvalidArgument)) {
        setValidatedIBANs((prevIBANs) => prevIBANs.set(iban, undefined));
        return errInvalidIBAN;
      }
    } finally {
      setBIC(bic);
      setBank(bank);
    }

    return undefined;
  };

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>): Promise<void> => {
    setShowError(false);
    const newBilling: Billing = {
      paymentMethod: PaymentMethod.DIRECT_DEBIT,
      contact: undefined,
      bankAccount: {
        iban: values.bankAccount?.iban ?? '',
        accountHolder: values.bankAccount?.accountHolder ?? '',
        bic,
        bank,
      },
    };
    try {
      const event: GoogleProtobufAny = {
        '@type': 'type.googleapis.com/enpowerx.reporting.v1.ChangeOfBankAccount',
        contract: selectedContract.id,
        customer: selectedContract.customer,
        iban: newBilling.bankAccount?.iban,
      };
      trackEvent('ChangeOfBankAccount', event);
      await (saveForAllContracts ? api.contracting.me.billing.payment.set(newBilling) : api.currentContract.billing.payment.set(newBilling));

      await invalidateContracts(abortSignal);
      enqueueSnackbar('Zahlungsinformationen erfolgreich geändert', { variant: 'success' });
      props.onSaved();
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        enqueueSnackbar('Änderung der Zahlungsinformationen abgebrochen.', { variant: 'warning' });
      } else {
        setShowError(true);
      }

      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange enableReinitialize>
      {({ submitForm, isSubmitting, setFieldError, setFieldTouched }) => (
        <Form style={{ width: '100%' }}>
          <CardContent className={classes.alignCenter}>
            <Grid direction="row" justifyContent="center" alignItems="center" spacing={10} container>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="bankAccount.accountHolder">Kontoinhaber</InputLabel>
                  <Field as={OutlinedInput} name="bankAccount.accountHolder" label="Kontoinhaber" disabled={isSubmitting} />
                  <FormHelperText error>
                    <ErrorMessage name="bankAccount.accountHolder" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="bankAccount.iban">IBAN</InputLabel>
                  <Field
                    as={OutlinedInput}
                    label="IBAN"
                    name="bankAccount.iban"
                    labelWidth={100}
                    disabled={isSubmitting}
                    validate={async (val: string): Promise<string | undefined> => {
                      const err = await validateIBAN(val);
                      if (err) {
                        setFieldTouched('bankAccount.iban', true, false);
                        setFieldError('bankAccount.iban', err);
                        return err;
                      }

                      return undefined;
                    }}
                  />
                  <FormHelperText error>
                    <ErrorMessage name="bankAccount.iban" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="bic">BIC</InputLabel>
                  <OutlinedInput label="BIC" name="bic" value={bic} disabled />
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="bank">Kreditinstitut</InputLabel>
                  <OutlinedInput label="Kreditinstitut" name="bank" value={bank} disabled />
                </FormControl>
              </Grid>
            </Grid>
            <Typography className={classes.sepaText}>
              {config.profile.payment.sepaConsentShort}.{' '}
              <a
                className={classes.additionalInfo}
                onClick={() => {
                  setPopupOpen(true);
                }}
              >
                Weitere Infos
              </a>{' '}
            </Typography>
            <SepaConsentModal
              open={open}
              close={() => {
                setPopupOpen(false);
              }}
            />
            {showError ? <Alert severity="error">Leider ist ein Fehler bei der Änderung aufgetreten. Bitte versuchen Sie es später noch einmal.</Alert> : null}
          </CardContent>
          <CardActions className={classes.buttonWrapper}>
            <MultiContractApplyButton
              contracts={availableContracts}
              disabled={isSubmitting}
              setSaveForAllContracts={setSaveForAllContracts}
              submitForm={submitForm}
            />
            <Button variant="outlined" color="secondary" disabled={isSubmitting} onClick={props.onCancel} className={classes.cancelButton}>
              Abbrechen
            </Button>
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

function getInitialFormValues(contract: Contract): Values {
  return {
    bankAccount: contract.billing?.bankAccount ?? {
      bic: '',
      iban: '',
      bank: '',
      accountHolder: '',
    },
  };
}

const useStyles = makeStyles()((theme) => ({
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
  },
  buttonWrapper: {
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cancelButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
    width: 'fit-content',
  },
  sepaText: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(5),
    },
  },
  additionalInfo: {
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  popup: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 4),
  },
  formControl: {
    marginTop: '0px',
    minWidth: '120px',
  },
  buttonExpand: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

const validationSchema = yup.object({
  bankAccount: yup
    .object()
    .shape({
      accountHolder: yup.string().required('Bitte geben Sie den Kontoinhaber an.'),
      iban: yup.string().required('Bitte geben Sie eine gültige IBAN an.'),
      bank: yup.string(),
      bic: yup.string(),
    })
    .required(),
});

interface ModalProps {
  open: boolean;
  close: () => void;
}

const SepaConsentModal: FC<ModalProps> = (props: ModalProps) => {
  const { open, close } = props;
  const { classes } = useStyles();
  const config = useConfig();

  return (
    <Modal open={open} onClose={close} disableAutoFocus disableEnforceFocus>
      <Card className={classes.popup}>
        <CardContent>
          <Typography variant="body1">{config.profile.payment.sepaConsentLong}</Typography>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default Edit;
