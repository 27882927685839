import { ApiError } from '@enpowerx/apis';
import { BillingContact } from '@enpowerx/apis/lib/contracting/v2';
import { Alert, Button, Card, CardActions, CardContent, Divider, Modal, PageViewCard, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { usePerson } from '../../../customers/hooks/usePerson';
import { useAPI, useSelectedContract } from '../../../providers';
import Edit from './addressEdit';
import View from './addressView';

const useStyles = makeStyles()((theme) => ({
  value: {
    width: 200,
  },
  optional: {
    fontSize: 14,
  },
  popup: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 4),
  },
  cardDivider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
  },
}));

interface BillingAddressCardProps {
  edit?: boolean;
  focusBillingAddress?: boolean;
}

const AddressCard: FC<BillingAddressCardProps> = (props: BillingAddressCardProps) => {
  const { isLoading } = useSelectedContract();
  const [view, setView] = useState(<LoadingView />);
  const [edit, setEdit] = useState(props.edit);
  const [open, setPopupOpen] = useState(false);
  const rootAnchor = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoading) {
      setView(<LoadingView />);
      return;
    }

    if (edit) {
      setView(
        <Edit
          onCancel={() => {
            setEdit(false);
          }}
          rootAnchor={rootAnchor}
          onSaved={onSaved}
          focusBillingAddress={props.focusBillingAddress}
        />
      );
      return;
    }

    setView(
      <View
        onEditClicked={onEditClicked}
        onDeleteClicked={() => {
          setPopupOpen(true);
        }}
      />
    );
  }, [isLoading, edit, open]);

  const onSaved = async (): Promise<void> => {
    setEdit(false);
  };

  const onEditClicked = (): void => {
    setEdit(true);
  };

  return (
    <div ref={rootAnchor} style={{ scrollMargin: '150px' }}>
      <DeleteBillingAddressModal
        open={open}
        close={() => {
          setPopupOpen(false);
        }}
      />
      <PageViewCard>
        <PageViewCardTitle
          title={
            <Typography variant="h2" component="div">
              Meine Lieferadresse
            </Typography>
          }
        />
        {isLoading ? <LoadingView /> : <>{view}</>}
      </PageViewCard>
    </div>
  );
};

const LoadingView: FC = () => {
  return (
    <CardContent>
      <Skeleton width="100%" height={200} variant="rectangular" />
    </CardContent>
  );
};

export default AddressCard;

interface DeleteBillingAddressModalProps {
  open: boolean;
  close: () => void;
}

const DeleteBillingAddressModal: FC<DeleteBillingAddressModalProps> = (props: DeleteBillingAddressModalProps) => {
  const { open, close } = props;
  const { classes } = useStyles();
  const api = useAPI();
  const { selectedContract, invalidateContracts } = useSelectedContract();
  const { person } = usePerson();
  const { enqueueSnackbar } = useSnackbar();
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    setAbortSignal(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  async function confirm(): Promise<void> {
    const newContact: BillingContact = {
      address: {
        person,
        careof: '',
        postalAddress: selectedContract.delivery?.address,
      },
      email: selectedContract.billing?.contact?.email ?? '',
      phoneNumber: selectedContract.billing?.contact?.phoneNumber ?? '',
      mobilePhoneNumber: selectedContract.billing?.contact?.mobilePhoneNumber ?? '',
    };
    try {
      setIsSubmitting(true);
      await api.currentContract.billing.contact.set(newContact);
      await invalidateContracts(abortSignal);
      enqueueSnackbar('Rechnungsadresse erfolgreich geändert', { variant: 'success' });
      close();
    } catch (err) {
      interface ExtendedError extends ApiError {
        name: string;
      }
      const error = err as ExtendedError;
      if (error.name === 'AbortError') {
        enqueueSnackbar('Änderung der Rechnungsadresse abgebrochen.', { variant: 'warning' });
        close();
      } else {
        setShowError(true);
      }
    }

    setIsSubmitting(false);
  }

  return (
    <Modal open={open} onClose={close} disableAutoFocus disableEnforceFocus>
      <Card className={classes.popup}>
        <CardContent>
          <Typography variant="h2">Rechnungsadresse Löschen</Typography>
          <Divider className={classes.cardDivider} />
          <Typography variant="body1">
            Wenn Sie diesen Dialog bestätigen, übernehmen wir die Lieferadresse und Ihre Kundendaten als Rechnungsadresse.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="secondary" onClick={close} variant="outlined" disabled={isSubmitting}>
            Abbrechen
          </Button>
          <Button size="small" color="secondary" onClick={confirm} variant="outlined" disabled={isSubmitting}>
            Rechnungsadresse löschen
          </Button>
        </CardActions>
        {showError ? <Alert severity="error">Leider ist ein Fehler bei der Löschung aufgetreten. Bitte versuchen Sie es später noch einmal.</Alert> : null}
      </Card>
    </Modal>
  );
};
