import { CardContent, Typography } from '@enpxio/components';
import { FC } from 'react';

import { useActiveMeterNumber } from '../hooks/useActiveMeter';
import MeterPointInputForm from './meterpointInputForm';
import { useSelectedContract } from '~/providers';

interface AddReadingDialogProps {
  toggleFunc: CallableFunction;
}

const AddReadingDialog: FC<AddReadingDialogProps> = (props: AddReadingDialogProps) => {
  const { toggleFunc } = props;
  const { selectedContract } = useSelectedContract();
  const { activeMeterNumber } = useActiveMeterNumber();
  return (
    <>
      <CardContent>
        <Typography style={{ display: 'inline-block' }} variant="h2" component="h2">
          Ihre Zählernummer: {activeMeterNumber}
        </Typography>
      </CardContent>
      <MeterPointInputForm toggleFunc={toggleFunc} meterPoint={activeMeterNumber ? activeMeterNumber : ''} energyType={selectedContract.delivery?.energyType} />
    </>
  );
};

export default AddReadingDialog;
