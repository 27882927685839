import {
  Box,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Theme,
  Typography,
  useConfig,
  useMediaQuery,
} from '@enpxio/components';
import { createMUIThemeFromCMS } from '@enpxio/theme';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

export type KeyValueStyles = 'EpxKeyValueView-root' | 'EpxKeyValueTable';
export type KeyValueEntries = Array<KeyValue | KeyValueEntryDividerType>;

interface KeyValueViewProps {
  entries: KeyValueEntries;
  footer?: ReactNode;
  className?: string;
}

export interface KeyValue {
  key: ReactNode;
  value?: ReactNode;
  highlight?: boolean;
  alternativeValues?: AlternativeValue | AlternativeValue[];
}

export type KeyValueEntryDividerType = 'divider';
export const KeyValueEntryDivider: KeyValueEntryDividerType = 'divider';

interface AlternativeValue {
  value: ReactNode;
  condition: (() => boolean) | undefined;
}

function isKeyValue(entry: KeyValue | KeyValueEntryDividerType): entry is KeyValue {
  return (entry as KeyValue).key !== undefined;
}

function isKeyValueView(val: ReactNode): val is React.ReactElement {
  return (val as React.ReactElement)?.props?.entries !== undefined;
}

function isAlternativeValueArray(obj?: AlternativeValue | AlternativeValue[]): obj is AlternativeValue[] {
  return Array.isArray(obj);
}

function isAlternativeValue(obj?: AlternativeValue | AlternativeValue[]): obj is AlternativeValue {
  return !isAlternativeValueArray(obj);
}

const useKeyValueViewStyles = makeStyles({ name: 'EpxKeyValueView' })((theme: Theme) => ({
  root: {},

  highlighted: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.defaultDark,
    borderStyle: 'solid',
    borderWidth: '1px 0px 1px 0px',
  },
}));

export const KeyValueView: FC<KeyValueViewProps> = (props: KeyValueViewProps) => {
  const config = useConfig();
  const theme = createMUIThemeFromCMS(config);
  const { classes, cx } = useKeyValueViewStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [entries, setEntries] = useState<Array<KeyValue | KeyValueEntryDividerType>>([]);
  const getView = useCallback(
    (entries: Array<KeyValue | KeyValueEntryDividerType>) => {
      return (
        <div className={cx(classes.root, props.className)}>
          {isMobile ? <KeyValueList entries={entries} footer={props.footer} /> : <KeyValueTable entries={entries} footer={props.footer} />}
        </div>
      );
    },
    [isMobile, props]
  );

  const validateAlternative = (entry: KeyValue, alternative: AlternativeValue | undefined): boolean => {
    return alternative !== undefined && ((!alternative.condition && !entry.value) || (alternative.condition !== undefined && alternative.condition()));
  };

  useEffect(() => {
    const filteredEntries = props.entries
      .map((entry) => {
        if (isKeyValue(entry)) {
          let alternative: AlternativeValue | undefined;
          if (isAlternativeValueArray(entry.alternativeValues)) {
            alternative = entry.alternativeValues.find((alternative) => validateAlternative(entry, alternative));
          } else if (isAlternativeValue(entry.alternativeValues)) {
            if (validateAlternative(entry, entry.alternativeValues)) {
              alternative = entry.alternativeValues;
            }
          }

          if (alternative) {
            entry.value = alternative.value;
          }
        }

        return entry;
      })
      .filter((entry) => !isKeyValue(entry) || entry.value);
    setEntries(filteredEntries);
  }, [props.entries]);

  return getView(entries);
};

const KeyValueList: FC<KeyValueViewProps> = (props: KeyValueViewProps) => {
  const { classes } = useKeyValueListStyles();
  const { classes: viewClasses, cx } = useKeyValueViewStyles();
  return (
    <List className={classes.list}>
      {props.entries.map((entry, index) => (
        <ListItem
          key={index}
          divider={!isKeyValue(entry)}
          className={cx(classes.listItem, isKeyValue(entry) && entry.highlight ? viewClasses.highlighted : '')}
        >
          {isKeyValue(entry) ? (
            <ListItemText
              primary={entry.key}
              secondary={entry.value}
              primaryTypographyProps={{ component: 'div' }}
              secondaryTypographyProps={{ color: 'textPrimary' }}
              className={classes.listItemText}
            />
          ) : null}
        </ListItem>
      ))}
      <div className={classes.footer}>{props.footer}</div>
    </List>
  );
};

const useKeyValueListStyles = makeStyles({ name: 'EpxKeyValueList' })((theme: Theme) => ({
  list: {
    paddingTop: '0px',
  },

  listItemText: {
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.text.primary,
    },
  },

  listItem: {
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
    },
    '&.MuiListItem-divider': {
      marginBottom: '15px',
    },
  },

  footer: {
    fontSize: 'x-small',
  },
}));

const KeyValueTable: FC<KeyValueViewProps> = (props: KeyValueViewProps) => {
  const { classes } = useKeyValueTableStyles();
  const { classes: viewClasses, cx } = useKeyValueViewStyles();
  return (
    <TableContainer>
      <Table className={cx(classes.root, props.className)}>
        <TableBody>
          {props.entries.map((entry, index) => (
            <TableRow key={index} className={isKeyValue(entry) && entry.highlight ? viewClasses.highlighted : ''}>
              {isKeyValue(entry) ? (
                <>
                  <TableCell className={isKeyValueView(entry.value) ? classes.keyWithInnerTable : ''}>
                    <Typography variant="body1" component="div">
                      <Box fontWeight="bold">{entry.key}</Box>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {isKeyValueView(entry.value) ? (
                      <div className={classes.inner}>{entry.value}</div>
                    ) : (
                      <Typography variant="body1" className={isKeyValueView(entry.value) ? classes.inner : ''} component="div">
                        {entry.value}
                      </Typography>
                    )}
                  </TableCell>
                </>
              ) : (
                <TableCell>
                  <div />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>{props.footer}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const useKeyValueTableStyles = makeStyles({ name: 'EpxKeyValueTable' })((theme: Theme) => ({
  root: {
    display: 'table',
    paddingLeft: 0,
    '& td:nth-of-type(odd)': {
      verticalAlign: 'top',
    },
    '& td:nth-of-type(even) > :not(.EpxKeyValueTable-inner)': {
      marginLeft: '10px',
    },
    '& td:first-child': {
      whiteSpace: 'nowrap',
    },
    '& td': {
      borderBottom: '0px',
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
      paddingLeft: 0,
    },
    '& .MuiTableFooter-root': {
      fontSize: 'small',
      display: 'flex',
      paddingTop: theme.spacing(3),
      paddingLeft: '10px',
    },
  },

  inner: {
    '& .EpxKeyValueView-root': {
      '& tr:nth-of-type(n+1) td > :first-child': {
        marginTop: '10px',
      },
      '& td:nth-of-type(odd) > :first-child': {
        marginLeft: '10px',
      },
      '& td:nth-of-type(even) > :first-child': {
        marginRight: '10px',
      },
    },
  },

  keyWithInnerTable: {
    '& :first-child': {
      marginTop: '10px',
    },
  },
}));
