import { CardContent, PageViewCard, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useSelectedContract } from '../../../providers';
import Edit from './paymentEdit';
import View from './paymentView';

interface PaymentInformationCardProps {
  edit?: boolean;
  focusBankAccount?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  text: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(5),
    },
  },
}));

const PaymentInfoCard: FC<PaymentInformationCardProps> = (props: PaymentInformationCardProps) => {
  const { isLoading, selectedContract } = useSelectedContract();
  const [view, setView] = useState(<LoadingView />);
  const [edit, setEdit] = useState(props.edit);
  const rootAnchor = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    if (isLoading) {
      setView(<LoadingView />);
      return;
    }

    if (edit) {
      setView(
        <Edit
          onCancel={() => {
            setEdit(false);
          }}
          rootAnchor={rootAnchor}
          onSaved={onSaved}
          focusBankAccount={props.focusBankAccount}
        />
      );
      return;
    }

    setView(<View contract={selectedContract} onButtonClick={onButtonClick} />);
  }, [isLoading, edit, selectedContract, props.focusBankAccount]);

  const onSaved = async (): Promise<void> => {
    setEdit(false);
  };

  const onButtonClick = (): void => {
    setEdit(true);
  };

  return (
    <div ref={rootAnchor} style={{ scrollMargin: '150px' }}>
      <PageViewCard>
        <PageViewCardTitle
          title={
            <Typography variant="h2" component="div">
              Meine Bankverbindung
            </Typography>
          }
        />
        <Typography variant="body1" className={classes.text}>
          Wird verwendet für alle Abbuchungen und Gutschriften zu Ihrem Vertrag
        </Typography>
        {isLoading ? <LoadingView /> : <>{view}</>}
      </PageViewCard>
    </div>
  );
};

const LoadingView: FC = () => {
  return (
    <CardContent>
      <Skeleton width="100%" height={200} variant="rectangular" />
    </CardContent>
  );
};

export default PaymentInfoCard;
