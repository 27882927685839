import { FC } from 'react';

interface NumberLocaleFormatterProps {
  value: number;
  formatOptions?: Intl.NumberFormatOptions;
  prefix?: string;
  suffix?: string;
}

export const NumberLocaleFormatter: FC<NumberLocaleFormatterProps> = (props: NumberLocaleFormatterProps) => {
  const numberFormatter = new Intl.NumberFormat(window.navigator.language, props.formatOptions);
  return (
    <>
      {props.prefix}
      {numberFormatter.format(props.value)}
      {props.suffix}
    </>
  );
};
