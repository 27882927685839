import { InstallmentResponse } from '@enpowerx/apis/lib/contracting/v2';

import { useCache } from '../meters/hooks/useCache';
import { useAPI, useSelectedContract } from '~/providers';

interface InstallmentState {
  installment: InstallmentResponse | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useInstallment = (): InstallmentState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data: installment,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(selectedContract.id ? `installment/${selectedContract.id}` : null, async () => api.currentContract.installment.read());
  return {
    installment,
    error,
    invalidate,
    isLoading,
  };
};
