import { City, Street } from '@enpowerx/apis/lib/market/v2';
import { Autocomplete, FormControl, FormHelperText, Grid, TextField, TextFieldProps, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import deLocale from 'date-fns/locale/de';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormField } from './FormField';
import { FormCard } from './cards/FormCard';
import { useAPI } from '~/providers/api';

export const FormStepAddress: FC = () => {
  const postalCodeRegexp = new RegExp('^[+ 0-9]{5}$');
  const { values, initialValues, handleChange, setFieldValue, setFieldError, isSubmitting } = useFormikContext();
  const { classes } = useStyles();
  const api = useAPI();
  const [abortSignal, setAbortSignal] = useState<AbortSignal | undefined>();
  const [selectedMoveoutDate, setSelectedMoveoutDate] = useState<Date>(values.moveoutDate ? values.moveoutDate : new Date());
  const [invalidDateMessage, setInvalidDateMessage] = useState<string>('');

  const [postalCode, setPostalCode] = useState('');
  const [cities, setCities] = useState(new Array<string>());
  const [selectedCity, setSelectedCity] = useState(values.city);
  const [streets, setStreets] = useState(new Array<string>());
  const [selectedStreet, setSelectedStreet] = useState(values.street);
  const { enqueueSnackbar } = useSnackbar();

  const clearFields = (): void => {
    setCities([]);
    setStreets([]);
    setFieldValue('street');
    setFieldValue('city');
    values.city = undefined;
    values.street = undefined;
  };

  const onPostalCodeChange = async (postalCode: string): Promise<string> => {
    if (postalCodeRegexp.test(postalCode)) {
      try {
        if (!postalCode) {
          return await Promise.reject();
        }

        const cityList = await api.region.postalCodes.get(postalCode).cities.list(abortSignal);
        setCities(cityList.cities.map((city: City) => city.canonicalName));
        setPostalCode(postalCode);
        if (cityList.cities[0].canonicalName) {
          return await Promise.resolve(cityList.cities[0].canonicalName);
        }
      } catch (error: unknown) {
        console.error(error);
        // ClearFields();
        throw new Error(` ${error}`);
      }
    } else {
      clearFields();
    }
  };

  const onCityChange = async (city: string, zip?: string): Promise<string> => {
    try {
      if ((!zip && !postalCode) || !city) {
        return await Promise.reject();
      }

      const streetsList = await api.region.postalCodes
        .get(zip || postalCode)
        .cities.get(city)
        .streets.list(abortSignal);
      setStreets(streetsList.streets.map((street: Street) => street.canonicalName));
      if (streetsList.streets[0].canonicalName) {
        setSelectedStreet(streetsList.streets[0].canonicalName);
        return await Promise.resolve(streetsList.streets[0].canonicalName);
      }
    } catch (error: unknown) {
      console.error(error);
      setStreets([]);
      throw new Error(` ${error}`);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FormCard title="Meine bisherige Adresse">
          <Grid container>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.streetConatainer}>
                <Grid item xs={12} md={12}>
                  <b>Straße / Hausnummer</b>
                </Grid>
                <Grid item xs={12} md={12}>
                  {initialValues.oldStreet} {initialValues.oldHouseNumber}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <b>Postleitzahl / Ort</b>
            </Grid>
            <Grid item xs={12} md={12}>
              {initialValues.oldPostalCode} {initialValues.oldCity}
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormCard title="Meine neue Adresse">
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <FormControl fullWidth>
                <Field
                  name="postalCode"
                  component={TextField}
                  variant="standard"
                  label="Postleitzahl"
                  value={values.postalCode}
                  renderInput={(params: TextFieldProps) => <TextField {...params} label="Postleitzahl" name="postalCode" />}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    handleChange(event);
                    setFieldValue('postalCode', event.target.value);
                    values.postalCode = event.target.value;
                    onPostalCodeChange(event.target.value)
                      .then((city) => {
                        setFieldValue('city', city, true);
                        setSelectedCity(city);
                        onCityChange(city, event.target.value)
                          .then((street) => {
                            setFieldValue('street', street);
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                        setFieldValue('city', city);
                        setFieldValue('postalCode', event.target.value);
                        values.postalCode = event.target.value;
                      })
                      .catch((error) => {
                        console.error(error);
                        enqueueSnackbar('Bitte geben Sie eine gültige Postleitzahl ein', {
                          variant: 'error',
                        });
                        setFieldError('postalCode', 'Bitte geben Sie eine gültige Postleitzahl ein.');
                      });
                  }}
                  onError={() => {
                    setFieldError('postalCode', 'Bitte geben Sie eine gültige Postleitzahl ein.');
                  }}
                />
                <FormHelperText className={classes.root} error>
                  <ErrorMessage name="postalCode" />
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={7}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <Field
                  label="Ort"
                  component={Autocomplete}
                  name="city"
                  disabled={cities.length === 0}
                  options={cities}
                  //value={values.city}
                  value={selectedCity}
                  renderInput={(params: TextFieldProps) => <TextField variant="standard" {...params} label="Ort" />}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>, value: string) => {
                    handleChange(event);
                    setSelectedCity(value);
                    onCityChange(value ? value : '')
                      .then((street) => {
                        setFieldValue('street', street);
                        values.city = value;
                        values.street = street;
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={8}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <Field
                  label="Strasse"
                  component={Autocomplete}
                  name="street"
                  variant="standard"
                  disabled={cities.length === 0}
                  options={streets}
                  //value={values.street}
                  value={selectedStreet}
                  renderInput={(params: TextFieldProps) => <TextField variant="standard" {...params} label="Strasse" />}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>, value: string) => {
                    handleChange(event);
                    setSelectedStreet(value);
                    setFieldValue('street', value);
                    values.street = value;
                  }}
                />
                <FormHelperText error>
                  <ErrorMessage name="contactAddress.street" />
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormField name="houseNumber" label="Hausnummer" disabled={cities.length === 0} />
            </Grid>

            <Grid item xs={6} md={12}>
              <FormControl fullWidth>
                <LocalizationProvider locale={deLocale} dateAdapter={AdapterDateFns}>
                  <Field
                    component={DatePicker}
                    name="moveoutDate"
                    label="Einzugsdatum"
                    value={selectedMoveoutDate}
                    onChange={(newValue: Date | undefined) => {
                      if (newValue) {
                        setSelectedMoveoutDate(newValue);
                        values.moveoutDate = newValue;
                      }
                    }}
                    renderInput={(params: TextFieldProps) => <TextField {...params} variant="standard" helperText={invalidDateMessage} />}
                    minDate={new Date(Date.now() - 42 * 24 * 60 * 60 * 1000)}
                    onError={() => {
                      setInvalidDateMessage('Ihr Einzugsdatum darf höchstens 42 Tage in der Vergangenheit liegen.');
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  streetConatainer: {
    marginBottom: theme.spacing(5),
  },
  root: {
    MuiFormHelperText: {
      root: {
        marginLeft: 0,
        '&$error': {
          marginLeft: 0,
        },
      },
    },
  },
}));
