import { Contract } from '@enpowerx/apis/lib/contracting/v2';
import { Button } from '@enpxio/components';
import { SaveOutlined } from '@mui/icons-material';
import { Dispatch, FC, SetStateAction } from 'react';

import { SplitButton } from './splitButton';

interface MultiContractApplyButtonProps {
  contracts: Contract[];
  submitForm: () => void;
  setSaveForAllContracts: Dispatch<SetStateAction<boolean>>;
  disabled: boolean | undefined;
}

export const MultiContractApplyButton: FC<MultiContractApplyButtonProps> = (props: MultiContractApplyButtonProps) => {
  const { contracts, submitForm, setSaveForAllContracts, disabled } = props;
  if (contracts.length === 1) {
    return (
      <Button onClick={submitForm} variant="outlined" color="secondary" startIcon={<SaveOutlined />} disabled={disabled}>
        Speichern
      </Button>
    );
  }

  return (
    <SplitButton
      icon={<SaveOutlined />}
      disabled={disabled}
      options={[
        {
          text: 'Für alle Verträge übernehmen',
          onClick: async () => {
            setSaveForAllContracts(true);
            await submitForm();
          },
        },
        {
          text: 'Für den aktuellen Vertrag übernehmen',
          onClick: async () => {
            setSaveForAllContracts(false);
            await submitForm();
          },
        },
      ]}
    />
  );
};
