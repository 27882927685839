import { PostalAddress } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface PostalAddressLocaleFormatterProps {
  value: PostalAddress | undefined;
}

export const PostalAddressLocaleFormatter: FC<PostalAddressLocaleFormatterProps> = (props: PostalAddressLocaleFormatterProps) => (
  <>{formatPostalAddress(props.value)}</>
);

export function formatPostalAddress(address: PostalAddress | undefined): string {
  if (!address) {
    return '';
  }

  return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`;
}
