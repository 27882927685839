import { EnergyType } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

import { EnergyUnitLocaleFormatter } from './energyUnit';

interface CounterReadingLocaleFormatterProps {
  value: number;
  energyType?: EnergyType;
  obisCode?: string;
}

export const CounterReadingLocaleFormatter: FC<CounterReadingLocaleFormatterProps> = (props: CounterReadingLocaleFormatterProps) => {
  const numberFormatter = new Intl.NumberFormat(window.navigator.language);
  return (
    <>
      {numberFormatter.format(props.value)} <EnergyUnitLocaleFormatter energyType={props.energyType}/>  {props.obisCode ? <>(OBIS: {props.obisCode})</>:''}
    </>
  );
};
