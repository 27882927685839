import { Hidden, Typography } from '@enpxio/components';
import Search from '@mui/icons-material/Search';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAuth0, useSelectedContract } from '~/providers';

interface SearchBoxProps {
  toggleOpenSearch: () => void;
  searchBarIsOpen: boolean;
}

export const SearchBox: FC<SearchBoxProps> = (props: SearchBoxProps) => {
  const { classes } = useStyles();
  const { isAuthenticated } = useAuth0();
  const { selectedContract } = useSelectedContract();

  if (isAuthenticated && selectedContract.id) {
    return (
      <div className={props.searchBarIsOpen ? classes.searchBoxContainerOpen : classes.searchBoxContainer}>
        <div onClick={props.toggleOpenSearch} className={props.searchBarIsOpen ? classes.searchBoxToggleOpen : classes.searchBoxToggle}>
          <Search sx={{ mr: 1, my: 0.5 }} />
          <Hidden mdDown>
            <Typography fontSize="18px">Suche</Typography>
          </Hidden>
        </div>
      </div>
    );
  }

  return null;
};

const useStyles = makeStyles()((theme) => ({
  searchBoxToggle: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '20px',
    cursor: 'pointer',
    fontSize: '1.1em',
    [theme.breakpoints.down('md')]: {
      marginRight: 'inherit',
    },
  },
  searchBoxToggleOpen: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '20px',
    cursor: 'pointer',
    fontSize: '1.1em',
    [theme.breakpoints.down('md')]: {
      marginRight: 'inherit',
    },
  },
  searchBoxContainer: {
    maxWidth: '400px',
    display: 'flex',
    position: 'relative',
    minWidth: '30px',
    float: 'right',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'inherit',
      position: 'absolute',
      left: 'calc( 100vw / 5)',
      zIndex: '1',
    },
  },
  searchBoxContainerOpen: {
    maxWidth: '400px',
    display: 'flex',
    position: 'relative',
    minWidth: '30px',
    float: 'right',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    '& > *': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'inherit',
      position: 'absolute',
      left: 'calc( 100vw / 5)',
      zIndex: '1',
    },
  },
}));
