import { DateMessage } from '@enpowerx/apis/lib/google/type';

interface DateMessagePeriodDaysLocaleFormatterProps {
  startDate: DateMessage | undefined;
  endDate: DateMessage | undefined;
  prefix?: string;
  suffix?: string;
}

export const DateMessagePeriodDaysLocaleFormatter = (props: DateMessagePeriodDaysLocaleFormatterProps) => {
  if (!props.startDate || !props.endDate) {
    return null;
  }

  return (
    <>
      {props.prefix}
      {differenceInDays(props.startDate, props.endDate)}
      {props.suffix}
    </>
  );
};

export const differenceInDays = (dateLeft: DateMessage | undefined, dateRight: DateMessage | undefined): number => {
  if (!dateLeft || !dateRight) {
    return 0;
  }

  const startDate = new Date(dateLeft.year, dateLeft.month - 1, dateLeft.day);
  const endDate = new Date(dateRight.year, dateRight.month - 1, dateRight.day);

  const diffSeconds = Math.abs(startDate.valueOf() - endDate.valueOf()) / 1000;
  const diffDays = Math.round(diffSeconds / 60 / 60 / 24);

  return diffDays;
};
