import { ReadingList_Reading, RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { useEffect, useState } from 'react';

import { dateMessageSortComparerByField } from '../../sortComparer/dateMessage';
import { useActiveMeterNumber } from './useActiveMeter';
import { useMeterReadings } from './useMeterReadings';
import { useMeters } from './useMeters';

export interface Register {
  obisCode: string;
  type: RegisterType;
  reading: ReadingList_Reading;
}

interface LatestMeterReadingState {
  registers: Register[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
}

export const useLatestMeterReading = (): LatestMeterReadingState => {
  const { activeMeterNumber, error: activeMeterNumberError, isLoading: isActiveMeterLoading } = useActiveMeterNumber();
  const { registers, error: registersError, isLoading: isMeterReadingsLoading } = useMeterReadings(activeMeterNumber);
  const [latestReadings, setLatestReadings] = useState<Register[]>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const { meters, error: metersError, isLoading: isMetersLoading } = useMeters();

  useEffect(() => {
    setError(activeMeterNumberError ?? registersError);
  }, [activeMeterNumberError, registersError]);

  useEffect(() => {
    setIsLoading(isActiveMeterLoading || isMeterReadingsLoading);
  }, [isActiveMeterLoading, isMeterReadingsLoading]);

  useEffect(() => {
    if (!registers) {
      setLatestReadings(undefined);
      return;
    }

    const activeMeter = meters
      .sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.activeRange?.start))
      .reverse()
      .find((meter) => !meter.activeRange?.end);
    const currentMeterObisCode: string[] = [];
    if(activeMeter !== undefined){
     Object.entries(activeMeter?.registers).forEach(([obisCode]) => currentMeterObisCode.push(obisCode));
    }
    
    const filteredRegisterMap = Object.keys(registers).filter(key => currentMeterObisCode.includes(key)).reduce((obj,key) => { obj[key] = registers[key] ; return obj;},{});

    const result: Register[] = [];
    Object.entries(filteredRegisterMap).forEach(([obisCode, register]) => {
      if (register.readings.length === 0) {
        return;
      }

      result.push({
        obisCode,
        type: register.type,
        reading: register.readings.sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date)).reverse()[0],
      });
    });
    setLatestReadings(result);
  }, [registers]);

  return {
    registers: latestReadings,
    error,
    isLoading,
  };
};
