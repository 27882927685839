import { BillingPaymentMethodLocaleFormatter, ContractNameLocaleFormatter } from '@enpxio/formatters';
import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { FormCard } from './cards/FormCard';
import { useSelectedContract } from '~/providers/selectedContract';

type FormStepCheckProps = PropsWithChildren<{
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
}>;

export const FormStepCheck: FC<FormStepCheckProps> = () => {
  const { values, initialValues } = useFormikContext();
  const { selectedContract } = useSelectedContract();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FormCard title="Mein aktueller Vertrag">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {selectedContract.tariff?.displayName ?? selectedContract.tariff?.product}
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>
                <h3>Zahlungsart</h3>
              </Typography>
              <Typography paragraph>
                <BillingPaymentMethodLocaleFormatter paymentMethod={selectedContract.billing?.paymentMethod} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <b>Meine bisherige Lieferadresse</b>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>
                {initialValues.oldStreet} {initialValues.oldHouseNumber} <br />
                {initialValues.oldPostalCode} {initialValues.oldCity}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <b>Meine bisherige Zählernummer</b>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography paragraph>{initialValues.oldMeterNumber}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <b>Mein bisheriger Jahresverbrauch (kWh)</b>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography paragraph>{initialValues.oldAnnualUsage}</Typography>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormCard title="Mein neuer Vertrag">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {values.newTariff?.product?.displayName ?? (selectedContract.tariff?.displayName ?? selectedContract.tariff?.product)}
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph> 
                <h3>Zahlungsart</h3>
              </Typography>
              <Typography paragraph>
                <BillingPaymentMethodLocaleFormatter paymentMethod={selectedContract.billing?.paymentMethod} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <b>Meine neue Lieferadresse</b>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>
                {values.street} {values.houseNumber} <br />
                {values.postalCode} {values.city}
              </Typography>
            </Grid>

            {values.meterNumber ? (
              <>
                <Grid item xs={12} md={12}>
                  <b>
                    Meine neue <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} suffix={'zählernummer'} />
                  </b>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography paragraph>{values.meterNumber}</Typography>
                </Grid>
              </>
            ) : null}
            {values.meterNumber ? (
              <>
                <Grid item xs={12} md={12}>
                  <b>Geschätzter neuer Jahresverbauch (kWh)</b>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography paragraph>{values.annualUsage}</Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};
