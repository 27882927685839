import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@enpxio/components';
import { ExpandMore } from '@mui/icons-material';
import { FC } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { FAQ } from '../pageContact';

interface FAQQuestionsProps {
  searchPhrase: string;
  faqs: FAQ[];
}

export const FAQQuestions: FC<FAQQuestionsProps> = (props: FAQQuestionsProps) => {
  const { classes } = useStyles();
  const searchWords = props.searchPhrase.match(/([[\wüäöß0-9]+)/g)?.map((val) => val.toUpperCase());
  const items = props.faqs
    ?.filter((faq: FAQ) => filterFAQ(faq, searchWords))
    .map((faq: FAQ, index: number) => (
      <StyledAccordion key={`faq-${index}`}>
        <StyledAccordionSummary expandIcon={<ExpandMore />} aria-controls={`faq-${index}`} id={`faq-${index}`}>
          <Typography className={classes.question}>{faq.question}</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <div className={classes.answer}>{faq.answer}</div>
        </StyledAccordionDetails>
      </StyledAccordion>
    ));
  return <>{items}</>;
};

interface Node {
  props?: {
    children: object;
  };
}

function getNodeText(node: Node): unknown {
  if (['string', 'number'].includes(typeof node)) return node;
  if (Array.isArray(node)) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props ? node.props.children : {});
}

function filterFAQ(faq: FAQ, searchWords: string[] | undefined): boolean {
  if (!searchWords || searchWords.length === 0) {
    return true;
  }

  for (const searchWord of searchWords) {
    const lookupWord = searchWord.toUpperCase();
    if (faq.question.toUpperCase().includes(lookupWord) || (getNodeText(faq.answer as unknown as Node) as string).toUpperCase().includes(lookupWord)) {
      return true;
    }
  }

  return false;
}

const useStyles = makeStyles()((theme) => ({
  question: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#363636',
    marginLeft: '10px',
  },
  answer: {
    display: 'block',
    marginLeft: '20px',
    color: '#636363',
    fontSize: theme.typography.pxToRem(14),
  },
}));

const StyledAccordion = withStyles(Accordion, {
  root: {
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:before': {
      display: 'none',
    },
  },
});

const StyledAccordionSummary = withStyles(AccordionSummary, (theme, _params, classes) => ({
  root: {
    backgroundColor: theme.palette.primary.main[100],
    marginBottom: -1,
    minHeight: 0,
    [`&.${classes.expanded}`]: {
      marginBottom: -15,
      minHeight: 0,
    },
  },
  content: {
    [`&.${classes.expanded}`]: {
      margin: '0px',
    },
  },
  expanded: {},
}));

const StyledAccordionDetails = withStyles(AccordionDetails, (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
    },
  },
}));

export default FAQQuestions;
