import { TerminationInquiry, TerminationInquiry_Reason } from '@enpowerx/apis/lib/communication/v2';
import { DateMessage } from '@enpowerx/apis/lib/google/type';
import {
  Alert,
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  PageView,
  PageViewCard,
  Select,
  TextField,
  TextFieldProps,
  useAsyncEffect,
  useConfig,
} from '@enpxio/components';
import { RecaptchaNotice, loadRecaptcha } from '@enpxio/components';
import { Print, SaveOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { FC, useState } from 'react';
import * as yup from 'yup';

import { useAPI, useSelectedContract } from '~/providers';

enum CancellationType {
  ORDINARY = 'ordinary',
  EXTRAORDINARY = 'extraordinary',
}

interface Values {
  customer: string;
  contract: string;
  email: string;
  earliestPossible?: boolean;
  cancellationDate?: Date;
  cancellationType: CancellationType;
  cancellationReason: TerminationInquiry_Reason;
}

const CancellationPage: FC = () => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const api = useAPI();
  const { selectedContract } = useSelectedContract();

  const initialValues: Values = {
    customer: selectedContract.customer ?? '',
    contract: selectedContract.id ?? '',
    email: selectedContract.billing?.contact?.email ?? '',
    earliestPossible: true,
    cancellationDate: undefined,
    cancellationType: CancellationType.ORDINARY,
    cancellationReason: TerminationInquiry_Reason.MOVE_OUT,
  };

  useAsyncEffect(async () => {
    await loadRecaptcha(siteKey);
  }, [siteKey]);

  const handleSubmit = async (values: Values): Promise<void> => {
    setShowError(false);
    const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'confirm_contract_termination' });
    let dateMessage: DateMessage | undefined;
    if (selectedDate) {
      dateMessage = { year: selectedDate.getFullYear(), month: selectedDate.getMonth() + 1, day: selectedDate.getDate() };
    }

    const terminationInquiry: TerminationInquiry = {
      contract: values?.contract?.trim(),
      customer: values?.customer?.trim(),
      email: values?.email?.trim(),
      reason: values?.cancellationType === CancellationType.ORDINARY ? TerminationInquiry_Reason.ORDINARY : values?.cancellationReason,
      desiredDate: dateMessage,
      tenant: config.tenant,
    };

    try {
      await api.communication.terminationInquiries(recaptchaToken).invoke(terminationInquiry);
      setShowSuccess(true);
      return;
    } catch {
      setShowError(true);
    }
  };

  return (
    <PageView
      title="Sie wollen Ihren Vertrag bei uns kündigen?"
      subTitle="Das finden wir sehr schade. Bitte tragen Sie ihre Daten ein und wir werden Ihre Kündigung zum nächstmöglichen Zeitpunkt umsetzen."
      wide
    >
      <PageViewCard>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange>
          {({ submitForm, isSubmitting, values }) => (
            <Form style={{ width: '100%' }}>
              <CardContent
                sx={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '70%',
                }}
              >
                <Grid direction="row" justifyContent="center" alignItems="center" spacing={6} container>
                  <Grid xs={12} item>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: '0px',
                        minWidth: '120px',
                      }}
                    >
                      <InputLabel htmlFor="customer">Kundennummer</InputLabel>
                      <Field
                        label="Kundennummer"
                        inputVariant="outlined"
                        as={OutlinedInput}
                        name="customer"
                        labelWidth={100}
                        disabled={isSubmitting || showSuccess}
                      />
                      <FormHelperText error>
                        <ErrorMessage name="customer" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} item>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: '0px',
                        minWidth: '120px',
                      }}
                    >
                      <InputLabel htmlFor="contract">Vertragsnummer</InputLabel>
                      <Field
                        label="Vertragsnummer"
                        inputVariant="outlined"
                        as={OutlinedInput}
                        name="contract"
                        labelWidth={100}
                        disabled={isSubmitting || showSuccess}
                      />
                      <FormHelperText error>
                        <ErrorMessage name="contract" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} item>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: '0px',
                        minWidth: '120px',
                      }}
                    >
                      <InputLabel htmlFor="email">E-Mail-Adresse</InputLabel>
                      <Field
                        label="E-Mail-Adresse"
                        inputVariant="outlined"
                        as={OutlinedInput}
                        name="email"
                        labelWidth={100}
                        disabled={isSubmitting || showSuccess}
                      />
                      <FormHelperText error>
                        <ErrorMessage name="email" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <Field
                        name="earliestPossible"
                        component={CheckboxWithLabel}
                        Label={{ label: 'zum nächstmöglichen Zeitpunkt' }}
                        disabled={isSubmitting || showSuccess}
                        type="checkbox"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </FormControl>
                  </Grid>
                  {values.earliestPossible === false ? (
                    <Grid xs={12} item>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{
                          marginTop: '0px',
                          minWidth: '120px',
                        }}
                      >
                        <Field
                          component={DatePicker}
                          inputVariant="outlined"
                          name="cancellationDate"
                          sx={{
                            marginTop: '0px',
                            minWidth: '120px',
                          }}
                          variant="inline"
                          margin="normal"
                          label="gewünschtes Kündigungsdatum"
                          disabled={isSubmitting || showSuccess}
                          fullWidth
                          minDate={new Date(Date.now())}
                          renderInput={(params: TextFieldProps) => <TextField {...params} />}
                          value={selectedDate}
                          onChange={(newValue: number | null) => {
                            const newDateValue= new Date(newValue ?? 0)
                            if (newValue) setSelectedDate(newDateValue);
                          }}
                        />
                      </FormControl>
                      <FormHelperText error>
                        <ErrorMessage name="cancellationDate" />
                      </FormHelperText>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                      <InputLabel htmlFor="cancellationType">Kündigungsart</InputLabel>
                      <Field label="Kündigungsart" name="cancellationType" as={Select} labelWidth={150} disabled={isSubmitting || showSuccess}>
                        <MenuItem key={CancellationType.ORDINARY} value={CancellationType.ORDINARY}>
                          ordentlich
                        </MenuItem>
                        <MenuItem key={CancellationType.EXTRAORDINARY} value={CancellationType.EXTRAORDINARY}>
                          außerordentlich
                        </MenuItem>
                      </Field>
                      <FormHelperText error>
                        <ErrorMessage name="cancellationType" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {values.cancellationType === CancellationType.EXTRAORDINARY ? (
                    <Grid item xs={12}>
                      <FormControl margin="normal" sx={{ width: '100%' }}>
                        <InputLabel htmlFor="cancellationReason">Kündigungsgrund</InputLabel>
                        <Field name="cancellationReason" as={Select} label="Kündigungsgrund" disabled={isSubmitting || showSuccess}>
                          <MenuItem key={TerminationInquiry_Reason.MOVE_OUT} value={TerminationInquiry_Reason.MOVE_OUT}>
                            Auszug
                          </MenuItem>
                          <MenuItem key={TerminationInquiry_Reason.PRICE_ADJUSTMENT} value={TerminationInquiry_Reason.PRICE_ADJUSTMENT}>
                            Preisanpassung
                          </MenuItem>
                          <MenuItem key={TerminationInquiry_Reason.RELOCATION} value={TerminationInquiry_Reason.RELOCATION}>
                            Umzug
                          </MenuItem>
                          <MenuItem key={TerminationInquiry_Reason.SUPPLIER_CHANGE} value={TerminationInquiry_Reason.SUPPLIER_CHANGE}>
                            Lieferantenwechsel
                          </MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
                {showError ? (
                  <Alert severity="error">
                    Wir konnten Ihre Kündigungsanfrage bei diesen Angaben nicht entgegen nehmen. Bitte überprüfen Sie Ihre Eingaben.
                  </Alert>
                ) : null}
                {showSuccess ? <Alert severity="success">Wir haben Ihre Kündigungsanfrage entgegen genommen.</Alert> : null}
              </CardContent>
              <CardActions style={{ justifyContent: 'center' }} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <Button variant="outlined" color="secondary" disabled={isSubmitting || showSuccess} onClick={submitForm} startIcon={<SaveOutlined />}>
                  Jetzt kündigen
                </Button>
                {showSuccess ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      window.print();
                    }}
                    startIcon={<Print />}
                  >
                    drucken
                  </Button>
                ) : null}
              </CardActions>
            </Form>
          )}
        </Formik>
      </PageViewCard>
      <Box>
        <RecaptchaNotice />
      </Box>
    </PageView>
  );
};

const validationSchema = yup.object({
  customer: yup.string().required('Bitte geben Sie Ihre Kundennummer an.'),
  contract: yup.string().required('Bitte geben Sie Ihre Vertragsnummer an.'),
  email: yup.string().email('Ungültige E-Mail-Adresse.').required('Bitte geben Sie Ihre E-Mail-Adresse an.'),
  earliestPossible: yup.bool(),
  cancellationDate: yup.date(),
  cancellationType: yup.mixed<CancellationType>().required('Bitte geben Sie eine Kündigungsart an.'),
  cancellationReason: yup.mixed<TerminationInquiry_Reason>(),
});

export default CancellationPage;
