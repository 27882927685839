import { gql, useApolloClient } from '@apollo/client';
import { Document } from '@enpowerx/apis/lib/contracting/v2';
import { DateMessage } from '@enpowerx/apis/lib/google/type';
import {
  Box,
  CardActions,
  Collapse,
  DownloadList,
  DownloadableFile,
  DownloadedFile,
  Hidden,
  IconButton,
  MimeType,
  PageViewCard,
  PageViewCardTitle,
  Typography,
  useAsyncEffect,
} from '@enpxio/components';
import { DateMessageLocaleFormatter } from '@enpxio/formatters';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { GoogleProtobufAny, useAPI, useReportingAPI, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => {
  return {
    showMoreText: {
      fontSize: '14px',
      color: theme.palette.secondary.main,
    },
    expand: {
      marginLeft: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    collapse: {
      padding: '16px',
    },
    endContent: {
      display: 'flex',
    },
    pdfWrapper: {
      backgroundColor: 'white',
      padding: '7px 13px 7px 13px',
    },
    downloadList: {
      '& .MuiLink-root': {
        color: theme.palette.text.primary,
      },
    },
  };
});

const QUERY = gql`
  query pageContractCollectionQuery {
    pageContractCollection(limit: 1) {
      items {
        documentsCollection {
          items {
            primaryText
            secondaryText
            date
            file {
              url
            }
          }
        }
      }
    }
  }
`;

interface Query {
  pageContractCollection: {
    items: Array<{
      documentsCollection: {
        items: ContractDocument[];
      };
    }>;
  };
}

interface ContractDocument {
  __typename: string;
  primaryText: string;
  secondaryText?: string;
  date?: string;
  file: File;
}

interface File {
  url: string;
}

const ContractDocumentsCard: FC = () => {
  const { classes, cx } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [linkText, setLinkText] = useState<string>('Mehr anzeigen ＋');
  const apolloClient = useApolloClient();
  const api = useAPI();
  const { trackEvent } = useReportingAPI();


  const [loading, setLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<DownloadableFile[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedContract } = useSelectedContract();

  useAsyncEffect(async (abortSignal) => {
    try {
      const documentList = await api.currentContract.documents.list(100, '', abortSignal);
      const docs =
        documentList.documents !== undefined && documentList.documents !== null
          ? Object.values(documentList.documents)
              .sort((docA, docB) => {
                return (docB.createTime !== undefined ? docB.createTime?.getTime() : 0) - (docA.createTime !== undefined ? docA.createTime?.getTime() : 0);
              })
              .map<DownloadableFile>(toDownloadableFile)
          : [];
      const contentfulResponse = await apolloClient.query<Query>({ query: QUERY });
      const contentfulDocs = contentfulResponse?.data.pageContractCollection?.items[0]?.documentsCollection?.items ?? [];
      const all = docs.concat(contentfulDocs.map<DownloadableFile>(toDownloadableFile));

      // Test Data for long texts
      // all.push(toDownloadableFile({
      //   id: "foo1",
      //   createTime: new Date(),
      //   contentKind: "Preisanpassung Ankündigung",
      //   subject: "Ihr korrigierter Gaspreis ab 1. November 2022",
      //   kind: "" as any
      // }))

      setFiles(all);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error && error.message !== 'The user aborted a request.') {
        enqueueSnackbar(`Leider konnten Ihre Vertragsdokumente nicht geladen werden`, { variant: 'error' });
      }
    }
  }, []);

  const toggleDocuments = (): void => {
    if (expanded) {
      setLinkText('Mehr anzeigen ＋');
    } else {
      setLinkText('Weniger anzeigen ×');
    }

    setExpanded(!expanded);
  };

  const downloadDocument = useCallback(
    async (document: Document): Promise<DownloadedFile> => {
      const event: GoogleProtobufAny = {
        '@type': 'type.googleapis.com/enpowerx.reporting.v1.DocumentDownload',
        contract: selectedContract?.id,
        customer: selectedContract?.customer,
        archiveId: document?.id,
      };
      trackEvent('DocumentDownload', event);
      const doc = await api.currentContract.documents.get(document.id).file.read();
      return {
        filename: `${document.contentKind}`,
        payload: doc.content,
        mimeType: doc.mimeType as unknown as MimeType,
      };
    },
    [api]
  );

  const toDownloadableFile = (document: Document | ContractDocument): DownloadableFile => {
    function isDocument(val: Document | ContractDocument): val is Document {
      return (val as Document).id !== undefined;
    }

    let date: Date | undefined;
    let dateMessage: DateMessage | undefined;
    let title;
    let subtitle = '';
    let link: unknown;
    if (isDocument(document)) {
      title = `${document.contentKind}:`;
      subtitle = document.subject;
      link = {
        downloadFileDelegate: async () => downloadDocument(document),
      };
      if (document.createTime) {
        date = new Date(document.createTime);
      }
    } else {
      title = document.primaryText;
      subtitle = document.secondaryText ?? '';
      link = {
        downloadURL: document.file.url,
      };
      if (document.date) {
        date = new Date(document.date);
      }
    }

    if (date) {
      dateMessage = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    const downloadableFile: unknown = {
      firstColumn: (
        <>
          <strong>{title}</strong>
          {subtitle}
        </>
      ),
      thirdColumn: (
        <div className={classes.endContent}>
          {date ? (
            <Hidden mdDown>
              <Box paddingRight="45px" marginTop="auto" marginBottom="auto">
                <DateMessageLocaleFormatter date={dateMessage} />
              </Box>
            </Hidden>
          ) : null}
          <div className={classes.pdfWrapper}>
            <Typography variant="body2" component="div">
              <Box fontWeight="bold" fontSize="smaller">
                PDF
              </Box>
            </Typography>
          </div>
        </div>
      ),
    };
    return { ...(downloadableFile as Record<string, unknown>), ...(link as Record<string, unknown>) } as DownloadableFile;
  };

  return (
    <PageViewCard>
      <PageViewCardTitle title="Meine Vertragsdokumente" />
      <Typography variant="body1">Hier stehen alle Ihre Vertragsdokumente zum Download zur Verfügung.</Typography>
      <CardActions disableSpacing>
        <IconButton className={cx(classes.expand)} onClick={toggleDocuments} aria-expanded={expanded} aria-label="show more" size="large">
          <span className={cx(classes.showMoreText)}>{linkText}</span>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
        {loading ? null : <DownloadList className={classes.downloadList} files={files} />}
      </Collapse>
    </PageViewCard>
  );
};

export default ContractDocumentsCard;
