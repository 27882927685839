import { ContractTariff_GraduatedPrice } from '@enpowerx/apis/lib/contracting/v2';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@enpxio/components';
import { DateMessageLocaleFormatter, MoneyLocaleFormatter, NumberLocaleFormatter, mapCounterType } from '@enpxio/formatters';
import { FC, ReactNode, useEffect, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { useSelectedContract } from '~/providers';

type GraduatedPricesTableProps = {
  showPriceAdjustment?: boolean;
  showFutureTariff?: boolean;
};

const GraduatedPricesTable: FC<GraduatedPricesTableProps> = (props: GraduatedPricesTableProps) => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const [rows, setRows] = useState<ReactNode[]>([]);

  useEffect(() => {
    setRows([]);
    if (props.showFutureTariff && selectedContract.futureTariff?.graduatedPrices) {
      const row = buildPriceAdjustmentHeaderRow(0, true);
      setRows((rows) => [...rows, row]);
      for (const [index, graduatedPrice] of selectedContract.futureTariff?.graduatedPrices.entries()) {
        const row = buildRow(index, graduatedPrice);
        setRows((rows) => [...rows, row]);
      }
    } else if (selectedContract.tariff?.graduatedPrices) {
      let indexOfset = 0;
      let indexCounter = 0;
      if (props.showPriceAdjustment) {
        const row = buildPriceAdjustmentHeaderRow(indexOfset, false);
        setRows((rows) => [...rows, row]);
        indexCounter++;
      }

      indexOfset = indexCounter;
      for (const [index, graduatedPrice] of selectedContract.tariff?.graduatedPrices.entries()) {
        const row = buildRow(indexOfset + index, graduatedPrice);
        setRows((rows) => [...rows, row]);
        indexCounter++;
      }

      indexOfset = indexCounter;
      if (props.showPriceAdjustment && selectedContract.futureTariff?.graduatedPrices) {
        const row = buildPriceAdjustmentHeaderRow(indexOfset, true);
        setRows((rows) => [...rows, row]);
        indexOfset++;
        for (const [index, graduatedPrice] of selectedContract.futureTariff?.graduatedPrices.entries()) {
          const row = buildRow(indexOfset + index, graduatedPrice);
          setRows((rows) => [...rows, row]);
        }
      }
    }
  }, [selectedContract]);

  const buildRow = (index: number, graduatedPrice: ContractTariff_GraduatedPrice) => {
    const row = (
      <StyledTableRow key={`graduated-price-row-${index}`}>
        <StyledTableCell component="th" scope="row">
          <Typography variant="body1">
            <NumberLocaleFormatter value={!graduatedPrice.start ? 0 : graduatedPrice.start} /> kWh bis <NumberLocaleFormatter value={graduatedPrice.end} /> kWh
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body1" component="div">
            {graduatedPrice.workingPrices.length > 1 ? (
              graduatedPrice.workingPrices.map((workingPrice) => (
                <>
                  <MoneyLocaleFormatter value={workingPrice.price?.gross} showFractionUnit /> ({mapCounterType(workingPrice.registerType)})
                  <br />
                </>
              ))
            ) : (
              <MoneyLocaleFormatter value={graduatedPrice.workingPrices[0].price?.gross} showFractionUnit />
            )}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body1">
            <MoneyLocaleFormatter value={graduatedPrice.basePrice?.gross} />
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
    return row;
  };

  const buildPriceAdjustmentHeaderRow = (index: number, futureTariff: boolean) => {
    const row = (
      <StyledTableRow key={`graduated-price-row-${index}`}>
        <StyledTableCell component="th" scope="row">
          <Typography variant="body1">
            {futureTariff ? (
              <>
                ab <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />
              </>
            ) : (
              <>
                bis <DateMessageLocaleFormatter date={selectedContract.tariff?.activeRange?.end} />
              </>
            )}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body1" component="div">
            null
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body1">null</Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
    return row;
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Stufe
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Verbrauchspreis (kWh)
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Grundpreis pro Jahr
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableRow = withStyles(TableRow, (theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const StyledTableCell = withStyles(TableCell, (theme) => ({
  root: {
    borderBottom: 'none',
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles()(() => ({
  table: {
    table: {
      minWidth: 700,
    },
  },
}));

export default GraduatedPricesTable;
