import { Contract } from '@enpowerx/apis/lib/contracting/v2';
import { EnergyDelivery_UsageType, EnergyType } from '@enpowerx/apis/lib/types';
import { CircularProgress } from '@enpxio/components';
import { FunctionComponent } from 'react';

import { ChargingCurrentIcon } from './chargingCurrentIcon';
import { CurrentIcon } from './currentIcon';
import { GasIcon } from './gasIcon';
import { HeatPumpIcon } from './heatPumpIcon';
import { NoContractsIcon } from './noContractsIcon';
import { StorageHeaterIcon } from './storageHeaterIcon';

type ContractTypeIconProps = {
  isLoading: boolean;
  contract: Contract;
  responsive?: boolean;
};

export const ContractTypeIcon: FunctionComponent<ContractTypeIconProps> = (props: ContractTypeIconProps) => {
  let className = '';
  if (props.responsive) {
    className = 'contractTypeIconResponsive';
  }

  if (props.isLoading) {
    return <CircularProgress size={20} className={className} style={{ marginRight: '5px' }} disableShrink />;
  }

  if (!props.contract?.id) {
    return <NoContractsIcon className={className} />;
  }

  switch (props.contract.delivery?.energyType) {
    case EnergyType.ELECTRICITY:
      switch (props.contract.delivery?.usageType) {
        case EnergyDelivery_UsageType.STORAGE_HEATER:
          return <StorageHeaterIcon className={className} />;
        case EnergyDelivery_UsageType.HEAT_PUMP:
          return <HeatPumpIcon className={className} />;
        case EnergyDelivery_UsageType.CHARGING_CURRENT:
          return <ChargingCurrentIcon className={className} />;
        default:
          return <CurrentIcon className={className} />;
      }

    case EnergyType.GAS:
      return <GasIcon className={className} />;
    default:
      return null;
  }
};
