import { Customer } from '@enpowerx/apis/lib/identities/v2';

import { useCache } from '../../meters/hooks/useCache';
import { useAPI, useAuth0 } from '~/providers';

interface CustomerState {
  customer: Customer | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useCustomer = (): CustomerState => {
  const api = useAPI();
  const { isAuthenticated } = useAuth0();
  const {
    data: customer,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(api.isInitialized && isAuthenticated ? 'customer' : null, async () => api.identities.me.read());
  return {
    customer,
    error,
    isLoading,
    invalidate,
  };
};
