import { Grid, List, ListItem, PageView, Skeleton, Typography } from '@enpxio/components';
import { RecaptchaNotice } from '@enpxio/components';

interface ClassMap {
  consentTextButton: string;
  consentButtonContainer: string;
  buttonContainer: string;
}

type SkeletonAdConsentDoubleOptInProps = {
  pageViewTitle: string;
  classes: ClassMap;
};

const SkeletonAdConsentDoubleOptIn = (props: SkeletonAdConsentDoubleOptInProps) => {
  const { pageViewTitle, classes } = props;
  return (
    <PageView title={pageViewTitle} subTitle={<Skeleton width="75%" />} wide>
      <Typography variant="h2">
        <Skeleton width="65%" />
      </Typography>
      <List>
        <ListItem>
          <Skeleton width="20%" />
        </ListItem>
        <ListItem>
          <Skeleton width="25%" />
        </ListItem>
      </List>
      <Typography variant="h2">
        <Skeleton width="60%" />
      </Typography>
      <List>
        <ListItem>
          <Skeleton width="30%" />
        </ListItem>
        <ListItem>
          <Skeleton width="25%" />
        </ListItem>
        <ListItem>
          <Skeleton width="20%" />
        </ListItem>
      </List>
      <Grid container className={classes.consentButtonContainer} alignItems="center">
        <Grid item md={6} xs={12}>
          <List>
            <ListItem>
              <Skeleton width="65%" />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={6} xs={12} className={classes.buttonContainer} />
      </Grid>
      <RecaptchaNotice />
    </PageView>
  );
};

export default SkeletonAdConsentDoubleOptIn;
