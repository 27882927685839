import { PageView, useAsyncEffect } from '@enpxio/components';
import { Location } from 'history';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PortalPages } from '../portalPages';

type LocationState = Location & {
  error: string;
  description: string;
};

const PageError: FC = () => {
  const location = useLocation();
  const { error } = location?.state as LocationState;
  const { description } = location?.state as LocationState;
  const navigate = useNavigate();
  useAsyncEffect(async () => {
    if (!error) await navigate(PortalPages.dashboard.absolutePathname);
  }, []);

  let title = 'Fehler';
  switch (error) {
    case 'unauthorized':
      title = 'Fehler beim Login';
      break;
    default:
  }

  return <PageView title={title} subTitle={description} wide />;
};

export default PageError;
