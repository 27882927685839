import { TerminationInquiry, TerminationInquiry_Reason } from '@enpowerx/apis/lib/communication/v2';
import { CustomerOnboardingRequest_Payload, CustomerOnboardingResponse, InputChannel, Relocation_UseNewAddress } from '@enpowerx/apis/lib/contracting/v2';
import { DateMessage } from '@enpowerx/apis/lib/google/type';
import { CustomerType } from '@enpowerx/apis/lib/identities/v2';
import { EnergyDelivery, EnergyDelivery_UsageType, EnergyType, PaymentMethod, Person, Person_SalutationForm } from '@enpowerx/apis/lib/types';
import { loadRecaptcha, useConfig } from '@enpxio/components';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { RelocationValues } from '../pageRelocation';
import MultiStepForm, { FormStep } from './MultiStepForm';
import { FormStepCheck } from './formCheck';
import { FormStepAddress } from './formStepAdress';
import { FormStepMeter } from './formStepMeter';
import { FormStepNewContract } from './formStepNewContract';
import { useCustomer } from '~/customers/hooks/useCustomer';
import { PortalPages } from '~/portalPages';
import { useAPI } from '~/providers/api';
import { useAuth0 } from '~/providers/auth0';
import { useSelectedContract } from '~/providers/selectedContract';

const mandatoryField = 'Dies ist ein Pflichtfeld.';

const validationSchemaStep2NewTarrif = yup.object({
  newTariff: yup.object().required('Bitte wählen Sie einen Tarif aus'),
});

const validationSchema = yup.object({
  moveoutDate: yup
    .date()
    .required(mandatoryField)
    .test('moveoutDate', 'Ihr Einzugsdatum darf höchstens 42 Tage in der Vergangenheit liegen.', (value) => dayjs().diff(dayjs(value), 'd') <= 42),
  postalCode: yup
    .string()
    .required(mandatoryField)
    .matches(/^[\d +]{5}$/, 'Bitte geben Sie eine gültige Postleitzahl ein.'),
  houseNumber: yup.string().required(mandatoryField),
  city: yup.string().required(mandatoryField).nullable(),
  street: yup.string().required(mandatoryField).nullable(),
});

const validationSchemaStep3 = yup.object({
  meterNumberFlag: yup.boolean(),
  meterNumber: yup.string().when('meterNumberFlag', { is: true, then: yup.string().required(mandatoryField) }),
  annualUsage: yup.number().required(mandatoryField),
});

interface RelocationFormProps {
  withTarifChange?: boolean;
}

const RelocationFormNewTariff: FC<RelocationFormProps> = (props: RelocationFormProps) => {
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { selectedContract, setIsLoading } = useSelectedContract();
  const { customer } = useCustomer();
  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const [formError, setFormError] = useState<string>('');
  const [apiError, setApiError] = useState<boolean>(false);

  const disableNext = (value: boolean): void => {
    setIsNextDisabled(value);
  };

  const handleSubmit = (val: RelocationValues) => {
    if (val.newTariff === undefined && !apiError) {
      setFormError('Sie Müssen einen Tarif auswählen.');
      return;
    }

    setFormError('');

    if (!apiError) {
      if (val.newTariff !== undefined) {
        try {
          loadRecaptcha(siteKey)
            .then(() => {
              handleRelocationNewTariff(val)
                .then(() => {
                  navigate(PortalPages.relocationSuccess.absolutePathname);
                })
                .catch((error) => {
                  throw new Error(`${error}`);
                });
            })
            .catch(() => {
              throw new Error('tri');
            });
        } catch (error: unknown) {
          console.error(error);
          setFormError('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.');
          setApiError(true);
          enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
            variant: 'error',
            autoHideDuration: 5000,
          });
        }
      } else {
        enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
    }
  };

  const handleRelocationNewTariff = async (val: RelocationValues): Promise<void> => {
    handleCancelation(val)
      .then(() => {
        handleNewOrder(val)
          .then((res) => {
            enqueueSnackbar('Vielen Dank wir haben den Umzug für die Weitergeleitet.', {
              variant: 'success',
            });
            navigate(PortalPages.relocationSuccess.absolutePathname);
          })
          .catch((error: unknown) => {
            enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
              variant: 'error',
            });
            setApiError(true);
            setFormError(`${error}`);
            throw new Error(`${error}`);
          });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setApiError(true);
        setFormError(`${error}`);
        enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
          variant: 'error',
        });
        throw new Error(`${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelation = async (val: RelocationValues): Promise<void> => {
    const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'confirm_contract_termination' });
    let dateMessage: DateMessage | undefined;
    if (val.moveoutDate) {
      dateMessage = { year: val.moveoutDate.getFullYear(), month: val.moveoutDate.getMonth() + 1, day: val.moveoutDate.getDate() };
    }

    const terminationInquiry: TerminationInquiry = {
      customer: selectedContract.customer ?? '',
      contract: selectedContract.id ?? '',
      email: user.email ?? '',
      reason: TerminationInquiry_Reason.MOVE_OUT,
      desiredDate: dateMessage,
      tenant: config.tenant,
    };

    await api.communication.terminationInquiries(recaptchaToken).invoke(terminationInquiry);
  };

  const buildCustomerOnboardingRequestPayload = (val: RelocationValues): CustomerOnboardingRequest_Payload => {
    const person: Person = {
      company: '',
      firstname: customer?.person?.firstname ?? '',
      lastname: customer?.person?.lastname ?? '',
      salutation: customer?.person?.salutation ?? Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED,
      title: '',
      birthday: {
        day: customer?.person?.birthday ? customer?.person?.birthday.day : new Date().getDate(),
        month: customer?.person?.birthday ? customer?.person?.birthday.month : new Date().getMonth() + 1,
        year: customer?.person?.birthday ? customer?.person?.birthday.year : new Date().getFullYear(),
      },
    };

    const request: CustomerOnboardingRequest_Payload = {
      marketingOptin: false,
      portalLogin: false,
      order: {
        product: val.newTariff?.product?.id ?? '',
        meterNumber: val.meterNumber ?? '',
        delivery: buildDelivery(val),
        billing: {
          contact: {
            email: val.email ?? '',
            phoneNumber: customer?.phoneNumber ?? '',
            mobilePhoneNumber: '',
            address: {
              careof: '',
              person,
              postalAddress: {
                city: val.city ?? '',
                houseNumber: val.houseNumber,
                postalCode: val.postalCode ?? '',
                street: val.street,
                region: 'DE',
                postboxNumber: '',
                district: '',
                additionalAddressLines: [],
              },
            },
          },
          paymentMethod: selectedContract.billing?.paymentMethod ?? PaymentMethod.PAYMENT_METHOD_UNSPECIFIED,
          bankAccount: {
            iban: selectedContract.billing?.bankAccount?.iban ?? '',
            accountHolder: selectedContract.billing?.bankAccount?.accountHolder ?? '',
            bank: '',
            bic: '',
          },
        },
        campaign: '',
        person,
        scheduledDate: {
          day: val.moveinDate.getDate(),
          month: val.moveinDate.getMonth() + 1,
          year: val.moveinDate.getFullYear(),
        },
        inputChannel: InputChannel.INPUT_CHANNEL_WEB,
        marketLocationId: '',
        addons: [],
        customerId: Number(customer?.id),
      },
    };
    return request;
  };

  const buildDelivery = (val: RelocationValues): EnergyDelivery | undefined => {
    const usage: number = val.annualUsage ? Number(val.annualUsage) : selectedContract.delivery?.annualUsage ?? 0;
    const usageNt: number = val.annualUsage ? 0 : selectedContract.delivery?.annualUsageNt ?? 0;
    return {
      annualUsage: usage,
      annualUsageNt: usageNt,
      customerType: selectedContract.delivery?.customerType ?? CustomerType.CUSTOMER_TYPE_UNSPECIFIED,
      energyType: selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED,
      usageType: selectedContract.delivery?.usageType ?? EnergyDelivery_UsageType.USAGE_TYPE_UNSPECIFIED,
      address: {
        city: val.city ?? '',
        houseNumber: val.houseNumber,
        postalCode: val.postalCode ?? '',
        street: val.street,
        region: 'DE',
        postboxNumber: '',
        district: '',
        additionalAddressLines: [],
      },
    };
  };

  const handleNewOrder = async (val: RelocationValues): Promise<CustomerOnboardingResponse | undefined> => {
    const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'registration' });
    const request = buildCustomerOnboardingRequestPayload(val);

    try {
      const response = await api.contracting.customerOnboarding(recaptchaToken).invoke(request);
      if (response) {
        return response;
      }
    } catch (error: unknown) {
      setApiError(true);
      setFormError(error);
      enqueueSnackbar('Leider kann dieser Vertrag nicht umgezogen werden. Wenden Sie sich bitte an unseren Support.', {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return undefined;
    }
  };

  const initialValues: RelocationValues = {
    moveoutDate: new Date(),
    moveinDate: new Date(),
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    meterNumberFlag: true,
    meterNumber: '',
    annualUsage: '',
    reading: undefined,
    useNewAddress: Relocation_UseNewAddress.IMMEDIATELY,
    oldPostalCode:
      selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.postalCode : '') : '',
    oldCity: selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.city : '') : '',
    oldStreet: selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.street : '') : '',
    oldHouseNumber:
      selectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.houseNumber : '') : '',
    oldMeterNumber: selectedContract.meterNumber,
    oldAnnualUsage: selectedContract.delivery !== undefined ? selectedContract.delivery.annualUsage.toString() : '',
    newTariff: undefined,
    email: user.email ?? '',
  };

  // SelectedContract.delivery !== undefined ? (selectedContract.delivery.address !== undefined ? selectedContract.delivery.address.postalCode : '') : '',
  return (
    <Box maxWidth="m">
      {formError ? <div>{formError}</div> : undefined}
      <MultiStepForm
        initialValues={initialValues}
        isTariffChange={true}
        isNextDisabled={isNextDisabled}
        onSubmit={(val) => {
          handleSubmit(val);
        }}
      >
        <FormStep
          stepName="Adresse"
          onSubmit={() => {
            // Do nothing
          }}
          validationSchema={validationSchema}
        >
          <FormStepAddress />
        </FormStep>

        <FormStep
          stepName="Vertrag"
          onSubmit={(val) => {
            if (props.withTarifChange && val.newTariff === undefined) {
              enqueueSnackbar('Bitte wählen Sie einen Tarif aus.', {
                variant: 'error',
                autoHideDuration: 5000,
              });
            }
          }}
          validationSchema={validationSchemaStep2NewTarrif}
        >
          <FormStepNewContract disableNext={disableNext} />
        </FormStep>

        <FormStep
          stepName="Zähler"
          validationSchema={validationSchemaStep3}
        >
          <FormStepMeter />
        </FormStep>

        <FormStep
          stepName="Prüfen"
        >
          <FormStepCheck  />
        </FormStep>
      </MultiStepForm>
    </Box>
  );
};

export default RelocationFormNewTariff;
