import { Step, StepLabel, Stepper } from '@enpxio/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, StepIconProps } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export enum WhenClickable {
  ON_NEXT,
}

export interface CustomStep {
  name: string;
  clickable?: boolean;
  whenClickable?: WhenClickable;
}

const CustomConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'inherit',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'inherit',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 0,
  },
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  display: 'flex',
  height: 35,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#000000',
  }),
  '& .CustomStepIcon-completedIcon': {
    zIndex: 1,
    fontSize: 35,
    color: 'green',
  },
}));

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const getIcon = (index: string, active: boolean): React.ReactElement => {
    const border = active ? '2px solid black' : '2px solid grey';
    return <Box sx={{ width: '30px', height: '30px', border, textAlign: 'center', borderRadius: '15px' }}>{index}</Box>;
  };

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <CheckCircleIcon className="CustomStepIcon-completedIcon" /> : active ? getIcon(props.icon, active) : getIcon(props.icon, active)}
    </CustomStepIconRoot>
  );
}

interface CustomStepperProps {
  state: number;
  setState: React.Dispatch<React.SetStateAction<number>>;
  steps: string[] | CustomStep[];
}

const isCustomStep = (step: string | CustomStep): step is CustomStep => (step as CustomStep).name !== undefined;

const useStyles = makeStyles()(() => ({
  step: {
    cursor: 'pointer',
  },
}));

export const CustomStepper: React.FC<CustomStepperProps> = (props: CustomStepperProps) => {
  const steps: ReactNode[] = [];
  if (isCustomStep(props.steps[0])) {
    const { classes } = useStyles();
    (props.steps as CustomStep[]).forEach((step: CustomStep, index: number) => {
      if (step.clickable !== undefined && step.whenClickable !== undefined) {
        switch (step.whenClickable) {
          case WhenClickable.ON_NEXT:
            steps.push(
              <Step
                className={classes.step}
                key={step.name}
                onClick={() => {
                  if (props.state === index + 1) props.setState(index);
                }}
              >
                <StepLabel StepIconComponent={CustomStepIcon}>{step.name}</StepLabel>
              </Step>
            );
            break;
          default:
            steps.push(
              <Step
                className={classes.step}
                key={step.name}
                onClick={() => {
                  props.setState(index);
                }}
              >
                <StepLabel>{step.name}</StepLabel>
              </Step>
            );
        }
      } else {
        steps.push(
          <Step key={step.name}>
            <StepLabel>{step.name}</StepLabel>
          </Step>
        );
      }
    });
  } else {
    for (const step of props.steps as string[]) {
      steps.push(
        <Step key={step}>
          <StepLabel>{step}</StepLabel>
        </Step>
      );
    }
  }

  return (
    <Stepper activeStep={props.state} connector={<CustomConnector />} alternativeLabel>
      {steps}
    </Stepper>
  );
};
