import { ApiError, Trait, hasTrait } from '@enpowerx/apis';
import { Button, PageView, Paper, Typography, useAsyncEffect } from '@enpxio/components';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PortalPages } from '../portalPages';
import { useAPI } from '~/providers';

const PageMarketingOptIn: FC = () => {
  console.log('page marketing optin');
  const params = useParams();
  let token: string | undefined;
  if (params && 'token' in params) {
    token = params.token ?? undefined;
  }

  console.log(token);
  const api = useAPI();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useAsyncEffect(
    async (signal) => {
      console.log('a');
      if (!api.isInitialized) {
        return;
      }

      console.log('b');
      console.log(token);
      if (!token) {
        await navigate(PortalPages.home.absolutePathname);
        return;
      }

      console.log('c');
      try {
        await api.infra.verificationTokens.get(token).redeem(signal);
        success();
      } catch (e) {
        error(e as ApiError);
      }
    },
    [token, api.isInitialized]
  );

  const success = (): void => {
    setLoading(false);
    setErrorMessage('');
  };

  const error = (e: Error): void => {
    setLoading(false);
    if (!hasTrait(e, Trait.NotFound)) {
      setErrorMessage(
        'Ihre Werbezustimmung wurde nicht gefunden. Sie wurde womöglich schon verarbeitet. Sie können Ihre Werbezustimmung in Ihrem Endkundenportal anpassen.'
      );
    } else {
      setErrorMessage('Bei der Verarbeitung Ihrer Werbezustimmung ist ein Fehler aufgetreten.');
    }
  };

  return (
    <PageView title="">
      <Paper style={{ height: '100%', margin: 'auto', padding: '25px' }}>
        <Typography variant="h2" style={{ marginBottom: '25px' }}>
          Ihre Werbezustimmung
        </Typography>
        {isLoading ? (
          <Typography variant="body1">Bitte warten Sie, während wir Ihre Werbezustimmung übernehmen</Typography>
        ) : errorMessage !== '' ? (
          <Typography variant="body1"> {errorMessage}</Typography>
        ) : (
          <Typography variant="body1"> Wir haben Ihre Werbezustimmung übernommen.</Typography>
        )}
        <Button href={PortalPages.home.absolutePathname} variant="outlined" color="secondary" style={{ marginTop: '25px' }}>
          Zur Hauptseite
        </Button>
      </Paper>
    </PageView>
  );
};

export default PageMarketingOptIn;
