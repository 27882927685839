import { Card, CardContent, Theme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const PageViewCard: FC<PropsWithChildren> = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Card data-test-id="page-view-card">
        <CardContent data-test-id="page-view-card-content">{props.children}</CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles({ name: 'EpxPageViewCard' })((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(10),
    },
    '& > .MuiCard-root, > .MuiCard-root > .MuiCardContent-root': {
      padding: 0,
    },
    '& > .MuiCard-root': {
      padding: theme.namedSpacing('small', 'md'),
      [theme.breakpoints.up('lg')]: {
        padding: theme.namedSpacing('large', 'md'),
      },
    },
  },
}));
