import { Person_SalutationForm } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface SalutationLocaleFormatterProps {
  salutation: Person_SalutationForm;
}

export const SalutationLocaleFormatter: FC<SalutationLocaleFormatterProps> = (props: SalutationLocaleFormatterProps) => (
  <>{formatSalutation(props.salutation)}</>
);

export function formatSalutation(salutation: Person_SalutationForm): string {
  switch (salutation) {
    case Person_SalutationForm.MR:
      return 'Herr';
    case Person_SalutationForm.MRS:
      return 'Frau';
    case Person_SalutationForm.FAMILY:
      return 'Familie';
    case Person_SalutationForm.DIVERSE:
      return 'Divers';
    default:
      return 'Unbekannt';
  }
}

export function getSelectableSalutations(): Person_SalutationForm[] {
  const excludedSalutations = [Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED, Person_SalutationForm.UNRECOGNIZED, Person_SalutationForm.DIVERSE];
  return Object.values(Person_SalutationForm).filter((val) => !excludedSalutations.includes(val));
}

export function getSalutationOrFallback(
  salutation: Person_SalutationForm | undefined,
  fallback: Person_SalutationForm | undefined
): Person_SalutationForm | undefined {
  if (!salutation || !getSelectableSalutations().includes(salutation)) {
    return fallback;
  }

  return salutation;
}
