import { Typography } from '@enpxio/components';
import { SvgIconComponent } from '@mui/icons-material';
import { FC, ReactNode, createElement } from 'react';
import { makeStyles } from 'tss-react/mui';

type IconMsgAdConsentDoubleOptInProps = {
  icon: SvgIconComponent;
  title: string | ReactNode;
  body: string | ReactNode;
  color: string;
};

const IconMsgAdConsentDoubleOptIn: FC<IconMsgAdConsentDoubleOptInProps> = (props: IconMsgAdConsentDoubleOptInProps) => {
  const { title, body, icon, color } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.flexContainerAct}>
      {createElement(icon, { className: classes.circleIcon, style: { color } })}
      <Typography variant="h2">{title}</Typography>
      <div>{body}</div>
    </div>
  );
};

export default IconMsgAdConsentDoubleOptIn;

const useStyles = makeStyles()((theme) => ({
  flexContainerAct: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '&:first-child': {
      paddingTop: theme.namedSpacing('large', 'xxxl'),
      [theme.breakpoints.down('xl')]: {
        paddingTop: theme.namedSpacing('small', 'xl'),
      },
    },
    '& > *': {
      padding: theme.namedSpacing('large', 'lg', 'md', 'zero', 'md'),
      textAlign: 'center',
    },
  },
  circleIcon: {
    fontSize: 200,
  },
}));
