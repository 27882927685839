import { ReadingChannel,  ReadingList_Register, ReadingOrigin, ReadingQuality, RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';
import {
  Avatar,
  Box,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@enpxio/components';
import { formatDateMessageLocale, formatReading, getEnergyUnitByEnergyType, mapChannel, mapOrigin, mapQuality } from '@enpxio/formatters';
import { DateRange } from '@mui/icons-material';
import dayjs from 'dayjs';
import { FC, ReactNode, useEffect, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { dateMessageSortComparerByField } from '../../sortComparer/dateMessage';
import { useActiveMeterNumber } from '../hooks/useActiveMeter';
import { HistReadingListMeter, useHistMeterReadings } from '../hooks/useHistMeterReadings';
import { Registers, useMeterReadings } from '../hooks/useMeterReadings';
import { useSelectedContract } from '~/providers';
import { useMeterReadingData } from '../hooks/useMeterReadingData';
interface HistReadingsProps {
  readingList: HistReadingListMeter[] | undefined;
  registers: Registers | undefined;
  energyType: EnergyType | undefined;
  energyTypeUnit: string;
}
interface ReadingsProps {
  readingData: ReadingData[] | undefined;
  registers: Registers | undefined;
  energyType: string;
}

interface ReadingData {
  date: ReactNode;
  meter?: ReactNode;
  meterNt?: ReactNode;
  meterHt?: ReactNode;
  readingNote: ReactNode;
}

interface HtNtRow {
  date: string;
  htValue?: number;
  ntValue?: number;
  origin: string;
  channel: string;
}

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: 440,
  },
  tableHeadRow: {
    '& .MuiTableCell-root:nth-of-type(1)': {
      width: '160px',
    },
    '& .MuiTableCell-root:nth-of-type(2)': {
      minWidth: '80px',
    },
    '& .MuiTableCell-root:nth-of-type(3)': {
      minWidth: '160px',
      [theme.breakpoints.down(700)]: {
        minWidth: '80px',
      },
    },
    '& .MuiTableCell-root:nth-of-type(4)': {
      width: '100%',
    },
  },
  histTableConatiner: {
    marginTop: theme.namedSpacing('small', 'xl'),
  },
}));

const StyledTableCell = withStyles(TableCell, (theme) => ({
  root: {
    borderBottom: 'none',
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = withStyles(TableRow, (theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const getReadingNote = (quality: ReadingQuality, origin: ReadingOrigin, channel: ReadingChannel): string => {
  let readingNote = mapQuality(quality);
  if (mapOrigin(origin)) {
    readingNote = `${readingNote} - ${mapOrigin(origin)}`;
  }

  if (mapChannel(channel) && origin == ReadingOrigin.CUSTOMER) {
    readingNote = `${readingNote} (${mapChannel(channel)}) `;
  }

  return readingNote;
};

const ReadingsCard: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { activeMeterNumber } = useActiveMeterNumber();
  const { meterReadings } = useMeterReadingData(activeMeterNumber);
  const { registers } = useMeterReadings(activeMeterNumber);
  const { histMeterReadings } = useHistMeterReadings(activeMeterNumber);
  const { selectedContract } = useSelectedContract();
  const [readingData, setReadingData] = useState<ReadingData[]>([]);

  useEffect(() => {
    if (readingData) {
      setReadingData([]);
    }

    if (registers != undefined && meterReadings != undefined) {
      if (Object.entries(registers).length === 2 && meterReadings[0]?.meterType === 'DZ') {
        const buildRows = (register: ReadingList_Register): HtNtRow[] => {
          const rows: HtNtRow[] = [];
          const sortedReadings = register.readings.sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date)).reverse();
          for (const reading of sortedReadings) {
            const newRow: HtNtRow = {
              date: formatDateMessageLocale(reading.date),
              origin: mapOrigin(reading.origin),
              channel: mapChannel(reading.channel) ? `(${mapChannel(reading.channel)})` : '',
            };
            switch (register.type) {
              case RegisterType.DOUBLE_TARIFF_PEAK:
                newRow.htValue = reading.value ?? 0;
                break;
              case RegisterType.DOUBLE_TARIFF_OFF_PEAK:
                newRow.ntValue = reading.value ?? 0;
                break;
              default:
                newRow.htValue = reading.value ?? 0;
                console.error(`Unknown register type ${register.type}`);
                break;
            }

            rows.push(newRow);
          }

          return rows;
        };

        const rowsA = buildRows(Object.values(registers)[0]);
        const rowsB = buildRows(Object.values(registers)[1]);

        rowsA.forEach((rowA, indexA, arrayA) => {
          rowsB.forEach((rowB, indexB, arrayB) => {
            if (rowB.date === rowA.date) {
              arrayA[indexA] = { ...rowA, ...rowB };
              arrayB.splice(indexB, 1);
            }
          });
        });

        const mergedRows = [...rowsA, ...rowsB];
        const sortedRows = mergedRows.sort((a, b) => dayjs(a.date, 'DD.MM.YYYY').toDate().getTime() - dayjs(b.date, 'DD.MM.YYYY').toDate().getTime()).reverse();

        sortedRows.forEach((sortedRow, index, array) => {
          let comparativeHt;
          let comparativeHtElement = null;
          let comparativeNt;
          let comparativeNtElement = null;
          try {
            comparativeHt = array[index + 1].htValue;
          } catch {}

          try {
            comparativeNt = array[index + 1].ntValue;
          } catch {}

          if (comparativeHt && sortedRow.htValue !== undefined)
            comparativeHtElement = (
              <div style={{ color: theme.palette.grey[500] }}>+{formatReading(sortedRow.htValue - comparativeHt, selectedContract?.delivery?.energyType)}</div>
            );
          if (comparativeNt && sortedRow.ntValue !== undefined)
            comparativeNtElement = (
              <div style={{ color: theme.palette.grey[500] }}>+{formatReading(sortedRow.ntValue - comparativeNt, selectedContract?.delivery?.energyType)}</div>
            );
          const readingDataItem: ReadingData = {
            date: <Typography variant="body1">{sortedRow.date}</Typography>,
            meterHt: (
              <Typography variant="body1">
                {sortedRow.htValue !== undefined ? formatReading(sortedRow.htValue, selectedContract?.delivery?.energyType) : null}
                {comparativeHtElement}
              </Typography>
            ),
            meterNt: (
              <Typography variant="body1">
                {sortedRow.ntValue !== undefined ? formatReading(sortedRow.ntValue, selectedContract?.delivery?.energyType) : null}
                {comparativeNtElement}
              </Typography>
            ),
            readingNote: (
              <Typography variant="body1">
                {sortedRow.origin} {sortedRow.channel}
              </Typography>
            ),
          };
          setReadingData((prevReadingData) => [...prevReadingData, readingDataItem]);
        });
      } else if (Object.entries(registers).length === 1) {
        
        const sortedReadings = Object.values(registers)[0]
          .readings?.sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
          .reverse();
        sortedReadings.map((reading, index, array) => {
          let comparativeElement = null;
          try {
            const comparative = array[index + 1].value;
            comparativeElement = (
              <div style={{ color: theme.palette.grey[500] }}>+{formatReading((reading.value ?? 0) - comparative, selectedContract?.delivery?.energyType)}</div>
            );
          } catch {}

          const readingDataItem: ReadingData = {
            date: <Typography variant="body1">{formatDateMessageLocale(reading.date)}</Typography>,
            meter: (
              <Typography variant="body1" component="div">
                {formatReading(reading.value ?? 0, selectedContract?.delivery?.energyType)}
                {comparativeElement}
              </Typography>
            ),
            readingNote: <Typography variant="body1">{getReadingNote(reading.quality, reading.origin, reading.channel)}</Typography>,
          };
          setReadingData((prev) => [...prev, readingDataItem]);
        });

      }else{
        //const combinedReadings: ReadingList_Reading[] = Object.values(registers)[0].readings.concat(Object.values(registers)[1].readings);
        const sortedReadings = Object.values(meterReadings[0].meterReadings).sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
          .reverse();
        sortedReadings.map((reading, index, array) => {
          let comparativeElement = null;
          try {
            const comparative = array[index + 1].valueHt;
            comparativeElement = (
              <div style={{ color: theme.palette.grey[500] }}>+{formatReading((reading.valueHt ?? 0) - comparative, selectedContract?.delivery?.energyType)}</div>
            );
          } catch {}

          const readingDataItem: ReadingData = {
            date: <Typography variant="body1">{formatDateMessageLocale(reading.date)}</Typography>,
            meter: (
              <Typography variant="body1" component="div">
                {formatReading(reading.valueHt ?? 0, selectedContract?.delivery?.energyType)}
                {comparativeElement} 
                OBIS-Kennung: {reading.obisCode}
              </Typography>
            ),
            readingNote: <Typography variant="body1">{reading.quality} - {reading.origin} {reading.channel}</Typography>,
          };
          setReadingData((prev) => [...prev, readingDataItem]);
        });
      }
    }
  }, [histMeterReadings, meterReadings ,registers, selectedContract]);

  if (isMobile)
    return (
      <>
        <ReadingsList registers={registers} readingData={readingData} energyType={getEnergyUnitByEnergyType(selectedContract?.delivery?.energyType)} />
        <HistMeterList
          registers={registers}
          readingList={histMeterReadings}
          energyTypeUnit={getEnergyUnitByEnergyType(selectedContract?.delivery?.energyType)}
          energyType={selectedContract?.delivery?.energyType}
        />
      </>
    );
  return (
    <>
      <ReadingsTable registers={registers} readingData={readingData} energyType={getEnergyUnitByEnergyType(selectedContract?.delivery?.energyType)} />
      <HistMeterReadingsTable
        registers={registers}
        readingList={histMeterReadings}
        energyTypeUnit={getEnergyUnitByEnergyType(selectedContract?.delivery?.energyType)}
        energyType={selectedContract?.delivery?.energyType}
      />
    </>
  );
};

const ReadingsTable: FC<ReadingsProps> = (props: ReadingsProps) => {
  const { classes } = useStyles();

  const head = (
    <TableRow>
      <StyledTableCell>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Ablesedatum
        </Typography>
      </StyledTableCell>
      {props?.readingData?.[0]?.meterHt || props?.readingData?.[0]?.meterNt ? (
        <>
          <StyledTableCell align="left">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Zählerstand HT ({props.energyType})
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Zählerstand NT ({props.energyType})
            </Typography>
          </StyledTableCell>
        </>
      ) : (
        <StyledTableCell align="left">
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Zählerstand ({props.energyType})
          </Typography>
        </StyledTableCell>
      )}

      <StyledTableCell align="left">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Abgelesen
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>{head}</TableHead>
        <TableBody>
          {props.readingData?.map((row, index) => (
            <StyledTableRow key={`reading-row-${index}`}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="body1">{row.date}</Typography>
              </StyledTableCell>
              {row.meterHt || row.meterNt ? (
                <>
                  <StyledTableCell align="left">{row.meterHt ?? '0'}</StyledTableCell>
                  <StyledTableCell align="left">{row.meterNt ?? '0'}</StyledTableCell>
                </>
              ) : (
                <StyledTableCell align="left">{row.meter ?? '0'}</StyledTableCell>
              )}
              <StyledTableCell align="left">{row.readingNote}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useListStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiListItem-root': {
      padding: 0,
    },
    '& .MuiListItem-divider': {
      paddingTop: theme.namedSpacing('small', 'xl'),
      marginBottom: theme.namedSpacing('small', 'xl'),
    },
    '& .MuiCard-root': {
      width: '100%',
      boxShadow: 'unset',
      padding: 0,
      borderRadius: 'unset',
    },
    '& .MuiCardContent-root': {
      padding: 0,
      paddingTop: theme.namedSpacing('small', 'sm'),
    },
    '& .MuiCardHeader-root': {
      padding: 0,
    },
    '& .MuiCardHeader-content': {
      paddingTop: 0,
    },
  },
  avatar: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.getContrastText(theme.palette.background.default),
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 'large',
    paddingBottom: '5px',
  },
}));

const ReadingsList: FC<ReadingsProps> = (props: ReadingsProps) => {
  const { classes } = useListStyles();
  const theme = useTheme();
  return (
    <List className={classes.root}>
      {props.readingData?.map((row, index) => (
        <>
          {index > 0 ? <ListItem divider key={`${index}-divider`} /> : null}
          <ListItem key={index}>
            <Card style={{ flex: 1 }}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar}>
                    <DateRange className={classes.icon} />
                  </Avatar>
                }
                title={row.date}
              />

              <CardContent>
                {row.meterHt || row.meterNt ? (
                  <Grid direction="row" container>
                    <Grid item xs={6} textAlign="center">
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Zählerstand HT ({props.energyType}):
                      </Typography>
                      {row.meterHt ?? '0'}
                    </Grid>
                    <Grid item xs={6} textAlign="center" style={{ backgroundColor: theme.palette.grey[200] }}>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Zählerstand NT ({props.energyType}):
                      </Typography>
                      {row.meterNt ?? '0'}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Zählerstand ({props.energyType}):
                    </Typography>
                    {row.meter}
                  </>
                )}
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Abgelesen:
                </Typography>
                {row.readingNote}
              </CardContent>
            </Card>
          </ListItem>
        </>
      ))}
    </List>
  );
};

const HistMeterList: FC<HistReadingsProps> = (props: HistReadingsProps) => {
  const { classes } = useListStyles();
  const theme = useTheme();
  return (<>
  <Divider />
    <Box sx={{marginTop:"20px"}}>
      {props.readingList?.map((meter) => {
        return (
          <div key={meter.meter}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              <>Zählernummer (alt) {meter.meter}</>
            </Typography>
            <List className={classes.root}>
              {meter?.histMeterReadings?.map((row, index) => (
                <>
                  {index > 0 ? <ListItem divider key={`${index}-divider`} /> : null}
                  <ListItem key={index}>
                    <Card style={{ flex: 1 }}>
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar}>
                            <DateRange className={classes.icon} />
                          </Avatar>
                        }
                        title={row.date}
                      />

                      <CardContent>
                        {row.valueHt && row.valueNt ? (
                          <Grid direction="row" container>
                            <Grid item xs={6} textAlign="center">
                              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                Zählerstand HT ({props.energyTypeUnit}):
                              </Typography>
                              {formatReading(row.valueHt ?? 0, props.energyType) ?? '0'}
                            </Grid>
                            <Grid item xs={6} textAlign="center" style={{ backgroundColor: theme.palette.grey[200] }}>
                              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                Zählerstand NT ({props.energyTypeUnit}):
                              </Typography>
                              {formatReading(row.valueNt ?? 0, props.energyType) ?? '0'}
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                              Zählerstand ({props.energyTypeUnit}):
                            </Typography>
                            {formatReading(row.valueHt ?? 0, props.energyType) ?? '0'}
                          </>
                        )}
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                          Abgelesen:
                        </Typography>
                        {row.origin}
                      </CardContent>
                    </Card>
                  </ListItem>
                </>
              ))}
            </List>
          </div>
        );
      })}
    </Box>
    </>
  );
};

const HistMeterReadingsTable: FC<HistReadingsProps> = (props: HistReadingsProps) => {
  const { classes } = useStyles();

  const head = (
    <TableRow>
      <StyledTableCell>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Ablesedatum
        </Typography>
      </StyledTableCell>
      {props?.readingList?.[0]?.histMeterReadings?.[0].meterType === 'DZ' ? (
        <>
          <StyledTableCell align="left">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Zählerstand HT ({props.energyTypeUnit})
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Zählerstand NT ({props.energyTypeUnit})
            </Typography>
          </StyledTableCell>
        </>
      ) : (
        <StyledTableCell align="left">
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Zählerstand ({props.energyTypeUnit})
          </Typography>
        </StyledTableCell>
      )}

      <StyledTableCell align="left">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Abgelesen
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
  return (
    <>
      {props.readingList?.map(({ meter, histMeterReadings }) => {
        return (
          <div className={classes.histTableConatiner} key={meter}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Zählernummer (alt) {meter}
            </Typography>

            <TableContainer component={Paper} elevation={0}>
              <Table className={classes.table}>
                <TableHead>{head}</TableHead>
                <TableBody>
                  {histMeterReadings?.map((reading) => {
                    return (
                      <StyledTableRow key={reading.date}>
                        <StyledTableCell component="th" scope="row">
                          <Typography variant="body1">{reading.date}</Typography>
                        </StyledTableCell>
                        {reading.meterType === 'DZ' ? (
                          <>
                            <StyledTableCell align="left">{formatReading(reading.valueHt ?? 0, props.energyType) ?? '0'}</StyledTableCell>
                            <StyledTableCell align="left">{formatReading(reading.valueNt ?? 0, props.energyType) ?? '0'}</StyledTableCell>
                          </>
                        ) : (
                          <StyledTableCell align="left">{formatReading(reading.valueHt ?? 0, props.energyType) ?? '0'}</StyledTableCell>
                        )}
                        <StyledTableCell align="left">
                          {reading.quality} - {reading.origin} {reading.channel}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </>
  );
};

export default ReadingsCard;
