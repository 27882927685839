import React from 'react';
import { Route, Routes } from 'react-router-dom';
import urljoin from 'url-join';

import { AuthenticatedPage } from './authenticatedPage';

export enum PageFlags {
  None,

  /**
   * The page is only accessible when authenticated.
   */
  Authenticated = 1 << 0,

  /**
   * The page is visible in the side menu.
   */
  SideMenu = 1 << 1,

  /**
   * The page is visible in the navigation bar.
   */
  NavigationBar = 1 << 2,

  /**
   * The page is only accessible when authenticated. The SideMenu and NavigationBar are hidden.
   */
  AuthenticatedNoMenu = 2 << 3,

  /**
   * Default flags for a page.
   * @see PageFlags.Authenticated
   * @see PageFlags.SideMenu
   * @see PageFlags.NavigationBar
   */
  Default = Authenticated | SideMenu | NavigationBar,
}

type PageModifierFunc = (page: Page) => void;
type PredicateFunc = (page: Page) => boolean;

type ArrayWithoutPush = new <T>(entries?: ReadonlyArray<readonly [T]> | null) => { [P in Exclude<keyof T[], 'push'>]: T[][P] };
const ArrayWithoutPush: ArrayWithoutPush = Array;

export class Page extends ArrayWithoutPush<Page> {
  constructor(
    public readonly displayName: string,
    public readonly relativePathname: string,
    public readonly viewComponent: React.ReactElement,
    public readonly flags: PageFlags,
    public readonly parent?: Page
  ) {
    super();
    if ((PageFlags.Authenticated & flags) === PageFlags.Authenticated || (PageFlags.AuthenticatedNoMenu & flags) === PageFlags.AuthenticatedNoMenu) {
      this.viewComponent = <AuthenticatedPage inner={viewComponent} />;
    }
  }

  public get absolutePathname(): string {
    return this.traversePath(this.parent, this.relativePathname);
  }

  public hasFlag(flag: PageFlags): boolean {
    return (flag & this.flags) === flag;
  }

  public pushChild(name: string, relativeURL: string, element: React.ReactElement, flags: PageFlags, pageModifier?: PageModifierFunc): this {
    const createdPage = new Page(name, relativeURL, element, flags, this);
    Array.prototype.push.call(this, createdPage);
    if (pageModifier) {
      pageModifier(createdPage);
    }

    return this;
  }

  public findTransitive(predicate: PredicateFunc): Page | undefined {
    return findPageTransitive(this, predicate);
  }

  public filterTransitive(predicate: PredicateFunc): Page[] {
    return filterPagesTransitive(this, predicate);
  }

  public buildRouter(): React.ReactElement {
    return buildRouter(this);
  }

  private traversePath(page: Page | undefined, url: string): string {
    if (!page) {
      return url;
    }

    return this.traversePath(page.parent, urljoin(page.relativePathname, url));
  }
}

export class Pages extends Array<Page> {
  constructor(pages: Page[]) {
    super();
    pages.map((page) => this.push(page));
  }

  public findTransitive(predicate: PredicateFunc): Page | undefined {
    return findPageTransitive(this, predicate);
  }

  public filterTransitive(predicate: PredicateFunc): Page[] {
    return filterPagesTransitive(this, predicate);
  }

  public buildRouter(): React.ReactElement {
    return buildRouter(this);
  }
}

function findPageTransitive(pages: Pages | Page, predicate: PredicateFunc): Page | undefined {
  for (let i = 0; i < pages.length; i++) {
    const page: Page = pages[i];
    if (predicate(page)) {
      return page;
    }

    const result = findPageTransitive(page, predicate);
    if (result) {
      return result;
    }
  }

  return undefined;
}

function filterPagesTransitive(pages: Pages | Page, predicate: PredicateFunc): Page[] {
  const result: Page[] = [];
  pages.forEach((page) => {
    if (predicate(page)) {
      result.push(page);
    }

    result.push(...filterPagesTransitive(page, predicate));
  });
  return result;
}

function buildRouter(pages: Pages | Page): React.ReactElement {
  const routerChildren: React.ReactElement[] = [];
  const allPages = pages.filterTransitive(() => true);
  if (pages instanceof Page) {
    allPages.push(pages);
  }

  allPages.forEach((page) => {
    routerChildren.push(
      <Route
        path={page.absolutePathname}
        key={page.displayName}
        element={React.createElement(page.viewComponent.type, { ...page.viewComponent.props, path: page.absolutePathname, key: page.displayName })}
      />
    );
  });
  return <Routes>{routerChildren}</Routes>;
}
