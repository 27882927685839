import { Typography } from '@enpxio/components';
import { ContractNameLocaleFormatter } from '@enpxio/formatters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';


import { PortalPages } from '../../portalPages';
import { FormCard } from '../form/cards/FormCard';
import { useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  container: {
    color: 'blue',
  },
  containerStep: {
    marginBottom: '2em',
  },
  spaceLG: {
    paddingBottom: theme.spacing(3),
  },
  containerStepItem: {
    height: '35px',
    lineHeight: '35px',
    paddingRight: '10px',
  },
  containerItem: {
    height: '20px',
    lineHeight: '20px',
    paddingRight: '10px',
  },
  box: {
    width: '30px',
    height: '30px',
    border: '1px solid black',
    textAlign: 'center',
    borderRadius: '15px',
  },
  icon: {
    color: theme.palette.success.main,
    width: '35px',
    height: '35px',
  },
  iconBlue: {
    color: '#6B9AC4',
    width: '20px',
    height: '20px',
  },
  button: {
    width: '100%',
    textDecoration: 'none',
  },
}));

const RelocationStatus: FC = () => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  return (
    <>
      <div className={classes.containerStep}>
        <Grid container justifyContent="space-between">
          <Grid item className={classes.containerStepItem}>
            <Grid container alignItems="center" alignContent="center">
              <Grid item className={classes.containerStepItem}>
                <CheckCircleIcon className={classes.icon} />
              </Grid>
              <Grid item className={classes.containerStepItem} alignContent="center">
                Neue Adresse
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.containerStepItem}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className={classes.containerStepItem}>
                <CheckCircleIcon className={classes.icon} />
              </Grid>
              <Grid item className={classes.containerStepItem}>
                <span>Ihr Vertrag</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.containerStepItem}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className={classes.containerStepItem}>
                <Box>
                  <CheckCircleIcon className={classes.icon} />
                </Box>
              </Grid>
              <Grid item className={classes.containerStepItem}>
                <div>Neue Zählernummer</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.containerStepItem}>
            <Grid container spacing={1}>
              <Grid item className={classes.containerStepItem}>
                <CheckCircleIcon className={classes.icon} />
              </Grid>
              <Grid item className={classes.containerStepItem}>
                <div>Angaben prüfen</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <FormCard title="Vielen Dank!">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography paragraph>
                  Sie haben den Umzug für Ihren <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} suffix="vertrag" />{' '}
                  erfolgreich gemeldet. Bitte haben Sie ein wenig Geduld, da die Bearbeitung etwas Zeit in Anspruch nehmen kann. Wir senden Ihnen zeitnah eine
                  Bestätigung zu.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography paragraph>
                  <b>So geht es jetzt weiter</b>
                </Typography>

                <Grid container className={classes.spaceLG}>
                  <Grid item className={classes.containerItem}>
                    <CheckCircleIcon className={classes.iconBlue} />
                  </Grid>
                  <Grid item className={classes.containerItem}>
                    Ihren umgezogenen Vertrag finden Sie in Ihrer Vertragsauswahl.
                  </Grid>
                </Grid>
                <Grid container className={classes.spaceLG}>
                  <Grid item className={classes.containerItem}>
                    <CheckCircleIcon className={classes.iconBlue} />
                  </Grid>
                  <Grid item className={classes.containerItem}>
                    Im Kundenportal können Sie in Kürze Ihren Umzugsstatus verfolgen.
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormCard title="Zählerstand für die Endabrechnung übermitteln.">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography paragraph>Haben Sie Ihren Zählerstand für die Endabrechnung parat? Teilen Sie ihn doch bequem hier im Kundenportal mit.</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Link to={PortalPages.meters.absolutePathname} className={classes.button}>
                  <Button variant="outlined" fullWidth color="secondary">
                    <Box fontWeight="bold">Zählerstand mitteilen</Box>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
      </Grid>
    </>
  );
};

export default RelocationStatus;
