import { List, ListItem, ListItemIcon, ListItemText } from '@enpxio/components';
import CheckIcon from '@mui/icons-material/Check';
import { FC } from 'react';

interface TopicListAdConsentDoubleOptInProps {
  topics: string[];
}

const TopicListAdConsentDoubleOptIn: FC<TopicListAdConsentDoubleOptInProps> = (props) => {
  const { topics } = props;
  return (
    <List>
      {topics.map((topic, index) => (
        <ListItem key={`topic-${index}`}>
          <ListItemIcon>
            <CheckIcon color="success" />
          </ListItemIcon>
          <ListItemText>
            {topic
              .replace('oe', 'ö')
              .replace('ae', 'ä')
              .replace('ue', 'ü')
              .split(/(?=[A-Z])/)
              .join(' ')}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default TopicListAdConsentDoubleOptIn;
