import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "./providers";
import { PortalPages } from './portalPages';

const Auth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const onRedirectCallback = useCallback(
        async (appState: AppState): Promise<void> => {
            if (appState && appState.locationPathname) {
                await navigate(appState.locationPathname);
                return;
            }
            await navigate(PortalPages.dashboard.absolutePathname);
        },
        [navigate]
    );
    return (
        <Auth0Provider
            clientOptions={{
                audience: 'https://apis.enpowerx.io',
                useRefreshTokens: true,
                redirect_uri: window.location.origin,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth;
