import { ReadingChannel, ReadingOrigin, ReadingQuality, RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';

export function formatReading(readingValue: number, energyType: string | undefined): string {
  let maximumFractionDigits = 4;
  if (energyType === EnergyType.ELECTRICITY) {
    maximumFractionDigits = 2;
  }

  if (!readingValue && readingValue !== 0) {
    return '';
  }

  return readingValue.toLocaleString('de-DE', {
    maximumFractionDigits,
  });
}

export function mapCounterType(counterType: RegisterType): string {
  switch (counterType) {
    case 'DOUBLE_TARIFF_PEAK':
      return 'HT';
    case 'DOUBLE_TARIFF_OFF_PEAK':
      return 'NT';
    default:
      return 'EZ';
  }
}

export function mapChannel(channel: ReadingChannel): string {
  switch (channel) {
    case ReadingChannel.EMAIL:
      return 'E-Mail';
    case ReadingChannel.LETTER:
      return 'postalisch';
    case ReadingChannel.MSCONS:
      return 'Datenaustausch';
    case ReadingChannel.PHONE:
      return 'telefonisch';
    case ReadingChannel.PORTAL:
      return 'Portal';
    default:
      return '';
  }
}

export function mapOrigin(origin: ReadingOrigin): string {
  switch (origin) {
    case ReadingOrigin.NETWORK_OPERATOR:
      return 'von Ihrem Netzbetreiber';
    case ReadingOrigin.PREVIOUS_PROVIDER:
      return 'von Ihrem Versorger';
    case ReadingOrigin.CUSTOMER:
      return 'von Ihnen';
    case ReadingOrigin.METER_POINT_OPERATOR:
      return 'von Ihrem Messstellenbetreiber';
    case ReadingOrigin.THIRD_PARTY:
      return 'von Ihnen';
    default:
      return 'von uns';
  }
}

export function mapQuality(quality: ReadingQuality): string {
  switch (quality) {
    case ReadingQuality.READ:
      return 'abgelesen';
    case ReadingQuality.ESTIMATED:
      return 'geschätzt';
    default:
      return '';
  }
}

const floatRegex = /\d+(\.\d+)?/g;
const gasRegex = /\d+(,\d{1,3})?/g;
const electricityRegex = /\d+(,\d)?/g;

export function validateGasReadingValue(gasReading: string): string {
  // Check if comma and number
  if (gasReading.match(floatRegex) === null || gasReading.includes('.')) {
    return 'Bitte verwenden Sie zur Eingabe ausschließlich Ziffern und ein Komma vor Nachkommastellen.';
  }

  const matchRegex = gasReading.match(gasRegex);
  if (matchRegex === null || matchRegex[0] !== gasReading) {
    return 'Bitte geben Sie maximal drei Nachkommastellen an.';
  }

  return '';
}

export function validateElectricityReadingValue(electricityReading: string): string {
  // Check if comma and number
  if (electricityReading.match(floatRegex) === null || electricityReading.includes('.')) {
    return 'Bitte verwenden Sie zur Eingabe ausschließlich Ziffern und ein Komma vor Nachkommastellen.';
  }

  const matchRegex = electricityReading.match(electricityRegex);
  if (matchRegex === null || matchRegex[0] !== electricityReading) {
    return 'Bitte geben Sie maximal eine Nachkommastellen an.';
  }

  return '';
}
