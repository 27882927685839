import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useCustomer } from '../customers/hooks/useCustomer';
import loadEPilot from './ePilot';
import { useAuth0 } from 'src/providers/auth0';

import { useNavigate } from "react-router-dom";
import { useConfig } from '@enpxio/components';

const NonCommodityPage: FC = (): JSX.Element => {
    const config = useConfig();
    const navigate = useNavigate();
    const nonCommodityEnabled = config.feature.some((feat) => feat.key === 'ePilot');
    const { isAuthenticated, isLoading } = useAuth0();
    const { customer } = useCustomer();

     useEffect(() => {
         if (!nonCommodityEnabled) {
             navigate("/page-not-found")
         }
     }, [nonCommodityEnabled])

    useEffect(() => {
        if (isLoading) return;
        if (!isAuthenticated) {
          loadEPilot('new customer');
        }
        if (customer) {
          loadEPilot(customer?.id);
        }
      });

      return (
        <>
          <div id="epilot-journey-5b448970-01da-11ed-9df4-6d51696cab3a"></div>
        </>
      );
}

export default NonCommodityPage;