import { FC, PropsWithChildren } from 'react';

type ContainerProps = PropsWithChildren<{
  className?: string;
}>;

export const Container: FC<ContainerProps> = (props) => (
  <div style={{ margin: '0 auto', maxWidth: '840px' }} className={props.className}>
    {props.children}
  </div>
);
