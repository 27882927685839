import { ReadingAddRequest_Reading } from '@enpowerx/apis/lib/metering/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';
import LinkIcon from '@mui/icons-material/Link';
import {
  Autocomplete,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { ChangeEvent, FC, PropsWithChildren, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormField } from './FormField';
import { FormCard } from './cards/FormCard';
import { MeterHelpModalModal } from '~/meters/pageControls/meterHelpModal';
import MeterPointInputForm from '~/meters/pageControls/meterpointInputForm';
import { useSelectedContract } from '~/providers';
import { ContractNameLocaleFormatter } from '@enpxio/formatters';

type FormStepMeterProps = PropsWithChildren<{
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
}>;

export const FormStepMeter: FC<FormStepMeterProps> = (props: FormStepMeterProps) => {
  const { values, initialValues, handleChange, setFieldValue } = useFormikContext();
  const { classes } = useStyles();
  const [meterHelpModalModalOpen, setMeterHelpModalModalOpen] = useState<boolean>(false);
  const { selectedContract } = useSelectedContract();
  const [showReadingInput, setShowReadingInput] = useState<boolean>(false);
  const [showMeterNumberInput, setShowMeterNumberInput] = useState<boolean>(values.meterNumberFlag);

  const consumptionGas = ['4000', '7000', '14000', '20000'];
  const consumptionEnergy = ['1500', '2500', '3500', '4500', '5500'];

  const consumptionOption = selectedContract.delivery?.energyType === EnergyType.GAS ? consumptionGas : consumptionEnergy;

  const meterHelpModalModalOnClose = (): void => {
    setMeterHelpModalModalOpen(false);
  };

  const showMeterHelpModal = (): void => {
    setMeterHelpModalModalOpen(true);
  };

  const setReadingValue = (readingValue: ReadingAddRequest_Reading | undefined): void => {
    setFieldValue('reading', readingValue);
    values.reading = readingValue;
  };


  let oldTitle = (
    <>
      Meine bisherige <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} suffix={'zählernummer'} />
    </>
  );

  let newTitle = (
    <>
      Meine neue <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} suffix={'zählernummer'} />
    </>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FormCard title={oldTitle}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography paragraph>{initialValues.oldMeterNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>Bisheriger Jahresverbrauch (kWh)</Typography>
              <Typography paragraph>{initialValues.oldAnnualUsage}</Typography>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormCard title={newTitle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'meterNumberFlag'}
                    size={'small'}
                    onChange={() => {
                      setFieldValue('meterNumberFlag', !showMeterNumberInput);
                      values.meterNumberFlag = !showMeterNumberInput;
                      setShowMeterNumberInput(!showMeterNumberInput);
                    }}
                    checked={showMeterNumberInput}
                  />
                }
                label={'Zähler angeben'}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Collapse in={showMeterNumberInput}>
                <FormField name="meterNumber" label="Meine neue Zählernummer" />
                <MeterHelpModalModal open={meterHelpModalModalOpen} onClose={meterHelpModalModalOnClose} />
                <Link className={classes.meternumberhelpLink} onClick={showMeterHelpModal} underline="none" color="secondary">
                  Wo finde ich meine <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} suffix={'zählernummer'} />? <LinkIcon />
                </Link>
              </Collapse>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography paragraph>
                Geschätzter neuer Jahrsverbrauch (kWh)
              </Typography>
              <Typography paragraph>
                <FormField name="annualUsage" label="Verbrauch" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'reading-opt'}
                    size={'small'}
                    onChange={(e) => {
                      setShowReadingInput(!showReadingInput);
                    }}
                    checked={showReadingInput}
                  />
                }
                label={'Aktuellen Zählerstand angeben'}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Collapse in={showReadingInput}>
                <MeterPointInputForm
                  meterPoint={selectedContract.meterNumber}
                  energyType={selectedContract.delivery !== undefined ? selectedContract.delivery.energyType : EnergyType.UNRECOGNIZED}
                  setReadingValue={setReadingValue}
                  toggleFunc={() => {
                    // Do nothing.
                  }}
                />
              </Collapse>
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()(() => ({
  meternumberhelpLink: {
    cursor: 'pointer',
  },
}));
