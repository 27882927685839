import { Typography } from '@enpxio/components';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DashboardCard, DashboardCardButton } from './dashboardCard';

interface SimpleDashboardCardProps {
  title: string;
  value: string | ReactNode;
  footer: string | ReactNode;
  button?: DashboardCardButton;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
}

export const SimpleDashboardCard: FC<SimpleDashboardCardProps> = (props: SimpleDashboardCardProps) => {
  const { classes } = useSimpleDashboardCardStyles();
  return (
    <DashboardCard
      title={props.title}
      button={props.button}
      isLoading={props.isLoading}
      isError={props.isError}
      isEmpty={props.isEmpty}
      isEmptyMessage={props.isEmptyMessage}
    >
      <div>
        <Typography variant="h1" className={classes.value} component="div">
          {props.value}
        </Typography>
        <div className={classes.footer}>{props.footer}</div>
      </div>
    </DashboardCard>
  );
};

const useSimpleDashboardCardStyles = makeStyles()((theme) => ({
  value: {
    paddingBottom: theme.namedSpacing('large', 'lg'),
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.namedSpacing('small', 'lg'),
    },
  },
  footer: {
    paddingBottom: theme.namedSpacing('large', 'lg'),
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.namedSpacing('small', 'lg'),
    },
  },
}));
