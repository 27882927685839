import { FailedPreconditionTariffChangeNotAllowedForContractError } from '@enpowerx/apis';
import { Product } from '@enpowerx/apis/lib/contracting/v2';

import { useCache } from '../../meters/hooks/useCache';
import { useAPI, useSelectedContract } from '~/providers';

interface AvailableProductsState {
  availableProducts: Product[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useAvailableProducts = (): AvailableProductsState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data: availableTariffs,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(
    selectedContract.id ? `contracts/${selectedContract.id}/products` : null,
    async () => (await api.contracting.me.contracts.get(selectedContract.id).products.list())?.products ?? [],
    {
      onErrorRetry: (error: Error, _key, config, revalidate, { retryCount }) => {
        if (error instanceof FailedPreconditionTariffChangeNotAllowedForContractError) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    }
  );

  return {
    availableProducts: availableTariffs,
    error,
    isLoading,
    invalidate,
  };
};
