import { useConfig } from '@enpxio/components';
import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

type SEOProps = PropsWithChildren<{
  title?: string;
  description?: string;
}>;

const SEO: FC<SEOProps> = (props: SEOProps) => {
  const config = useConfig();
  const metaDescription = props.description || config.website.description;

  return (
    <Helmet
      title={props.title}
      titleTemplate={`%s | ${config.website.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
};

export default SEO;
