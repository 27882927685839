import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, StepIconProps } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';

import { FormNavigation } from './FormNavigation';
interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  isNextDisabled: boolean;
  isTariffChange: boolean;
}

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  display: 'flex',
  height: 35,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#000000',
  }),
  '& .CustomStepIcon-completedIcon': {
    zIndex: 1,
    fontSize: 35,
    color: 'green',
  },
}));

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const getIcon = (index: string, active: boolean): React.ReactElement => {
    const border = active ? '2px solid black' : '2px solid grey';
    return <Box sx={{ width: '30px', height: '30px', border, textAlign: 'center', borderRadius: '15px' }}>{index}</Box>;
  };

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <CheckCircleIcon className="CustomStepIcon-completedIcon" /> : active ? getIcon(props.icon, active) : getIcon(props.icon, active)}
    </CustomStepIconRoot>
  );
}

const MultiStepForm = ({ children, initialValues, onSubmit, isNextDisabled, isTariffChange }: Props) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const hanldeSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    }

    actions.setTouched({});
    next(values);
  };

  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'inherit',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'inherit',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: 0,
    },
  }));

  const FormNavContainer = styled('div')(() => ({
    marginTop: 30,
  }));

  const FormStepContainer = styled('div')(() => ({
    marginTop: 30,
  }));

  return (
    <div>
      <Formik initialValues={snapshot} onSubmit={hanldeSubmit} validationSchema={step.props.validationSchema}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Stepper activeStep={stepNumber} connector={<CustomConnector />}>
              {steps.map((currentStep) => {
                const label = currentStep.props.stepName;
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <FormStepContainer>{step}</FormStepContainer>
            <FormNavContainer>
              <FormNavigation
                disableNext={isNextDisabled}
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                isTariffChange={isTariffChange}
                onBackClick={() => {
                  previous(formik.values);
                }}
              />
            </FormNavContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepForm;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
export const FormStep = ({ stepName = '', children }: any) => children;
