import { useAsyncEffect, useConfig } from '@enpxio/components';
import React, { FC, ReactElement, useState } from 'react';

import { useAuth0 } from '../auth0';

interface AuthenticatedPageProps {
  inner: ReactElement | null;
}

export const AuthenticatedPage: FC<AuthenticatedPageProps> = (props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [innerElement, setInnerElement] = useState<ReactElement | null>(null);
  const cfg = useConfig();

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        redirect_uri: window.location.origin,
        connection: cfg.oauth.connection,
      });
      return;
    }

    const { inner, ...inheritedProps } = props;
    if (inner) {
      setInnerElement(React.createElement(inner.type, { ...inner.props, ...inheritedProps }));
    } else {
      setInnerElement(null);
    }
  }, [isAuthenticated, cfg]);

  return innerElement;
};
