import { ApiError } from '@enpowerx/apis';
import { MeterTokenContext, ReadingChannel } from '@enpowerx/apis/lib/metering/v2';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useConfig,
} from '@enpxio/components';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { CSSProperties, ChangeEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { useAPI } from '~/providers';

function getModalStyle(): CSSProperties {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'absolute',
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 4),
  },
  cell: {
    paddingTop: '5px',
    textAlign: 'left',
    width: '100px',
    borderBottom: '0px',
  },
  title: {
    margin: theme.spacing(5, 5, 5, 5),
  },
  formControl: {
    margin: theme.spacing(5, 5, 5, 5),
    minWidth: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(5, 0, 5, 5),
  },
}));

interface UnplausibleReadingModalProps {
  open: boolean;
  onClose: () => void;
  counters: string[];
  values: { amountCounter1: string; amountCounter2?: string; date: Date };
  meterpoint: string;
  token?: string;
  meterTokenContext?: MeterTokenContext;
  callback?: CallableFunction;
}

const ImplausibleReadingModal: FC<UnplausibleReadingModalProps> = (props: UnplausibleReadingModalProps) => {
  const { open, onClose, counters, values, meterpoint, token, meterTokenContext, callback } = props;
  const { classes } = useStyles();
  const api = useAPI();
  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const navigate = useNavigate();

  const [modalStyle] = useState(getModalStyle);
  const [selectedDeviation, setSelectedDeviation] = useState<string>('');
  const [readingSubmitted, setReadingSubmitted] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const readingValues = (): Record<string, number> => {
    const readingValues = new Map<string, number>();
    readingValues.set(counters[0], Number.parseFloat(values.amountCounter1));
    if (counters && counters.length > 1 && values.amountCounter2) readingValues.set(counters[1], Number.parseFloat(values.amountCounter2));
    return Object.fromEntries(readingValues);
  };

  const saveReading = async (): Promise<void> => {
    setReadingSubmitted(true);
    if (selectedDeviation === '') {
      setReadingSubmitted(false);
      enqueueSnackbar('Zählerstand konnte nicht übermittelt werden. Bitte wählen Sie einen Grund aus.', {
        variant: 'error',
      });
      return;
    }

    const reading = {
      channel: ReadingChannel.PORTAL,
      date: {
        year: values.date.getFullYear(),
        month: values.date.getMonth() + 1,
        day: values.date.getDate(),
      },
      values: readingValues(),
      ignorePlausibilityCheckReason: selectedDeviation,
    };
    if (token) {
      try {
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'add_reading' });
        await api.metering.tokens
          .get(token)
          .readings(recaptchaToken)
          .add({
            contract: meterTokenContext?.contract ?? '',
            meter: meterTokenContext?.meter?.id ?? '',
            reading,
          });
        if (callback) callback();
      } catch (error) {
        setReadingSubmitted(false);
        console.error(error);
        enqueueSnackbar('Zählerstand konnte nicht übermittelt werden', {
          variant: 'error',
        });
      }
    } else {
      try {
        await api.currentMetering.meters.get(meterpoint).readings.add(reading);
        enqueueSnackbar('Zählerstand erfolgreich übermittelt', {
          variant: 'success',
        });
        setReadingSubmitted(false);
        await navigate(PortalPages.dashboard.absolutePathname);
      } catch (err) {
        const error = err as ApiError;
        setReadingSubmitted(false);
        console.error(`Error Occured ${JSON.stringify(error)}`);
        enqueueSnackbar('Zählerstand konnte nicht übermittelt werden', {
          variant: 'error',
        });
      }
    }
  };

  const closeModal = (): void => {
    onClose();
  };

  const handleDeviationChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedDeviation(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      {readingSubmitted ? (
        <Skeleton variant="rectangular" width="100%" height={100} />
      ) : (
        <div style={modalStyle} className={classes.paper}>
          <Typography className={classes.title} variant="h2">
            Der Zählerstand könnte falsch sein
          </Typography>
          <br />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cell}>{counters[0]}</TableCell>
                <TableCell className={classes.cell}>{values.amountCounter1}</TableCell>
              </TableRow>
              {counters && counters.length > 1 ? (
                <TableRow>
                  <TableCell className={classes.cell}>{counters[1]}</TableCell>
                  <TableCell className={classes.cell}>{values.amountCounter2}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          <Typography className={classes.title} variant="h2">
            Warum erscheint hier eine Fehlermeldung?
          </Typography>
          <Typography className={classes.title} variant="body1">
            Um Tippfehler zu vermeiden vergleicht unser System Ihren eingegebenen Zählerstand mit einem prognostizierten Wert und reagiert bei großen
            Abweichungen.
          </Typography>
          <br />
          <Typography className={classes.title} variant="body1">
            Wenn Sie der Meinung sind, dass Ihr Zählerstand doch stimmt, können Sie uns helfen, Rückfragen zu vermeiden, indem Sie uns eine Bemerkung zu Ihrem
            Zählerstand mitschicken.
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="simple-select-outlined-label">Abweichung</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select-outlined"
              value={selectedDeviation}
              onChange={handleDeviationChange as (o: unknown) => void}
              label="Abweichung"
              required
            >
              <MenuItem value="Leerstand / längere Abwesenheit">Leerstand / längere Abwesenheit</MenuItem>
              <MenuItem value="Geändertes Verbrauchsverhalten">Geändertes Verbrauchsverhalten</MenuItem>
              <MenuItem value="Einzug / Auszug Mitbewohner">Einzug / Auszug Mitbewohner</MenuItem>
              <MenuItem value="Zählerstand falsch geschätzt">Zählerstand falsch geschätzt</MenuItem>
              <MenuItem value="Baumaßnahme / Wasserschaden">Baumaßnahme / Wasserschaden</MenuItem>
              <MenuItem value="Heizung">Heizung</MenuItem>
              <MenuItem value="Photovoltaik">Photovoltaik</MenuItem>
              <MenuItem value="Neuer Zähler">Neuer Zähler</MenuItem>
              <MenuItem value="Keine Angabe von Gründen">Keine Angabe von Gründen</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined" color="secondary" size="small" className={classes.button} startIcon={<SaveIcon />} onClick={saveReading}>
            Zählerstand ist richtig
          </Button>
          <Button variant="outlined" color="secondary" size="small" className={classes.button} startIcon={<CreateIcon />} onClick={closeModal}>
            Zählerstand korrigieren
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ImplausibleReadingModal;
