import { Button, CardActions, Skeleton } from '@enpxio/components';
import { DateMessageLocaleFormatter, PersonLocaleFormatter } from '@enpxio/formatters';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useCustomer } from '../../../customers/hooks/useCustomer';
import { usePerson } from '../../../customers/hooks/usePerson';
import { useSelectedContract } from '../../../providers';
import { KeyValueView } from '~/keyValueView';

interface CustomerViewProps {
  onEditClick: () => void;
}

const useStyles = makeStyles()(() => ({
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
}));

const CustomerView: FC<CustomerViewProps> = (props: CustomerViewProps) => {
  const { selectedContract } = useSelectedContract();
  const { customer, isLoading: customerIsLoading } = useCustomer();
  const { person, isLoading: personIsLoading } = usePerson();
  const { classes } = useStyles();

  return (
    <>
      <KeyValueView
        className={classes.keyValueView}
        entries={[
          {
            key: 'Kundennummer',
            value: customer?.id,
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => customerIsLoading || !customer,
            },
          },
          {
            key: 'Firma',
            value: person?.company,
          },
          {
            key: 'Name',
            value: <PersonLocaleFormatter value={person} />,
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => personIsLoading || !person,
            },
          },
          {
            key: 'Geburtstag',
            value: <DateMessageLocaleFormatter date={person?.birthday} fallback="Nicht hinterlegt." />,
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => personIsLoading,
            },
          },
          {
            key: 'E-Mail-Adresse',
            value: <span>{!isEmpty(selectedContract.billing?.contact?.email) ? selectedContract.billing?.contact?.email : 'Nicht hinterlegt.'}</span>,
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => customerIsLoading,
            },
          },
          {
            key: 'Mobil',
            value: customer?.mobilePhoneNumber ?? 'Nicht hinterlegt.',
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => customerIsLoading,
            },
          },
          {
            key: 'Festnetz',
            value: customer?.phoneNumber ?? 'Nicht hinterlegt.',
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => customerIsLoading,
            },
          },
        ]}
      />
      <CardActions style={{ justifyContent: 'center' }} disableSpacing>
        <Button variant="outlined" color="secondary" onClick={props.onEditClick}>
          Bearbeiten
        </Button>
      </CardActions>
    </>
  );
};

function isEmpty(str: string | undefined): boolean {
  return !str || str.length === 0;
}

export default CustomerView;
