/* eslint-disable complexity */
import { Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { ContractTariff, ContractTariff_GraduatedPrice_WorkingPrice } from '@enpowerx/apis/lib/contracting/v2/contracts';
import { CalendarPeriod, DateMessage, Money } from '@enpowerx/apis/lib/google/type';
import { EnergyType, Price } from '@enpowerx/apis/lib/types';
import { Alert, AlertTitle, Box, Grid, Link, PageViewCard, PageViewCardTitle, Skeleton, Typography, useTheme } from '@enpxio/components';
import {
  ContractNameLocaleFormatter,
  DateMessageLocaleFormatter,
  DateMessagePeriodLocaleFormatter,
  DurationLocaleFormatter,
  mapCounterType,
  MoneyLocaleFormatter,
  NumberLocaleFormatter,
  PostalAddressLocaleFormatter,
  getNumberFromMoney,
} from '@enpxio/formatters';

import { HouseOutlined, Unsubscribe } from '@mui/icons-material';
import { FC, ReactElement, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { KeyValueEntries, KeyValueEntryDivider, KeyValueView } from '~/keyValueView';
import { useSelectedContract } from '~/providers';
import { useCustomer } from '../../customers/hooks/useCustomer';
import { useInstallment } from '../../installments/useInstallments';
import { PortalPages } from '../../portalPages';
import { ContractBadge } from '../components/contractBadge';
import { GraduatedPricesModal } from './graduatedPricesModal';

const ContractCard: FC = () => {
  const { classes } = useStyles();
  const { customer, isLoading: isCustomerLoading } = useCustomer();
  const { selectedContract, isLoading: isContractLoading } = useSelectedContract();
  const { installment, isLoading: isInstallmentLoading, error: installmentError } = useInstallment();
  const theme = useTheme();
  const [graduatedPricesModalOpen, setGraduatedPricesModalOpen] = useState<boolean>(false);

  const graduatedPricesModalOnClose = (): void => {
    setGraduatedPricesModalOpen(false);
  };

  const showGraduatedPrices = (): void => {
    setGraduatedPricesModalOpen(true);
  };

  let priceEntries: KeyValueEntries = [];
  let priceEntriesPcPa: KeyValueEntries = [];
  const today = new Date();

  let basePrice: Price | undefined;
  let workingPrices: ContractTariff_GraduatedPrice_WorkingPrice[] = [];
  let basePriceFt: Price | undefined;
  let workingPricesFt: ContractTariff_GraduatedPrice_WorkingPrice[] = [];
  const priceRange = {
    start: 0,
    end: 0,
  };
  const priceRangeFt = {
    start: 0,
    end: 0,
  };

  const futureTariffIsPriceAdjustment = selectedContract.futureTariff?.isPriceAdjustment ?? false;
  let showPriceAdjustment = false;
  let showFutureTarif = false;
  let dateMessagePaDate: DateMessage | undefined;

  if (selectedContract.futureTariff?.activeRange?.start) {
    showFutureTarif = true;
  }

  if (selectedContract.futureTariff?.activeRange?.start && futureTariffIsPriceAdjustment) {
    const showPaDate = new Date(
      selectedContract.futureTariff.activeRange?.start?.year,
      selectedContract.futureTariff?.activeRange?.start?.month - 1,
      selectedContract.futureTariff?.activeRange?.start?.day
    );
    const latestCancellationDatePA = new Date(showPaDate);
    showPaDate.setDate(showPaDate.getDate() - 42);
    latestCancellationDatePA.setDate(latestCancellationDatePA.getDate() - 1);

    showPriceAdjustment = today.getTime() >= showPaDate.getTime();
    showFutureTarif = showPriceAdjustment;

    dateMessagePaDate = {
      year: latestCancellationDatePA.getFullYear(),
      month: latestCancellationDatePA.getMonth() + 1,
      day: latestCancellationDatePA.getDate(),
    };
  }

  const buildPriceDisplay = (money?: Money) => {
    const curr = getUnitCurrency(money);
    return (
      <>
        <MoneyLocaleFormatter value={money} showFractionUnit hideCurrency /> <span className="surrencyClass">{curr}</span>
      </>
    );
  };

  const getUnitCurrency = (money?: Money): string => {
    const currency = (money === null || money === void 0 ? void 0 : money.currencyCode) !== null && money !== void 0 ? money.currencyCode : 'EUR';
    if ((money === null || money === void 0 ? void 0 : money.units) === '0' || (money === null || money === void 0 ? void 0 : money.units) === undefined) {
      switch (money?.currencyCode) {
        case 'EUR':
          return 'ct';
        default:
          return '';
      }
    }

    return currency;
  };

  const getVat = (gross?: Money, net?: Money): number => {
    if (gross === null || gross === undefined || net === null || net === undefined) {
      return 19;
    }

    let ret: number;

    const [moneyValueGross] = getNumberFromMoney(gross, false);
    const [moneyValueNet] = getNumberFromMoney(net, false);

    if (moneyValueGross.toNumber() === 0 || moneyValueNet.toNumber() === 0) {
      ret = 19;
    } else {
      ret = moneyValueGross.sub(moneyValueNet).mul(100).div(moneyValueNet).round(0, 1).toNumber();
    }

    return ret;
  };

  const buildPriceElement = (tariff: ContractTariff, showCurrentAmount: boolean): KeyValueEntries => {
    const priceEntriesRet: KeyValueEntries = [];
    const entries: KeyValueEntries = [];

    if (selectedContract.tariff?.graduatedPrices && selectedContract.tariff?.graduatedPrices.length > 0) {
      const rangeValues: number[] = [];
      for (const graduatedPrice of selectedContract.tariff.graduatedPrices) {
        graduatedPrice.start ? rangeValues.push(graduatedPrice.start) : rangeValues.push(0);
        rangeValues.push(graduatedPrice.end);
        if (
          selectedContract.delivery !== undefined &&
          selectedContract.delivery?.annualUsage > (graduatedPrice.start ?? 0) &&
          selectedContract.delivery?.annualUsage < graduatedPrice.end
        ) {
          priceRange.start = graduatedPrice.start ?? 0;
          priceRange.end = graduatedPrice.end;
          basePrice = graduatedPrice.basePrice;
          workingPrices = graduatedPrice.workingPrices;
        }
      }

      if (selectedContract.futureTariff?.activeRange?.start && selectedContract.futureTariff?.graduatedPrices) {
        for (const graduatedPriceFt of selectedContract.futureTariff?.graduatedPrices) {
          graduatedPriceFt.start ? rangeValues.push(graduatedPriceFt.start) : rangeValues.push(0);
          rangeValues.push(graduatedPriceFt.end);
          if (
            selectedContract.delivery !== undefined &&
            selectedContract.delivery?.annualUsage > (graduatedPriceFt.start ?? 0) &&
            selectedContract.delivery?.annualUsage < graduatedPriceFt.end
          ) {
            priceRangeFt.start = graduatedPriceFt.start ?? 0;
            priceRangeFt.end = graduatedPriceFt.end;
            basePriceFt = graduatedPriceFt.basePrice;
            workingPricesFt = graduatedPriceFt.workingPrices;
          }
        }
      }

      entries.push({
        key: (
          <span className={classes.priceLinkText}>
            Stufenpreis von <NumberLocaleFormatter value={priceRange.start} /> bis <NumberLocaleFormatter value={priceRange.end} /> kWh
          </span>
        ),
        value: (
          <Grid container className={classes.priceBox}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link className={classes.priceLink} onClick={showGraduatedPrices} underline="none" color="secondary">
                  Stufenpreise anzeigen
                </Link>
              </Grid>
            </Grid>
          </Grid>
        ),
      });
    }

    if (showPriceAdjustment) {
      entries.push(
        {
          key: (
            <>
              Grundpreis pro Jahr bis <DateMessageLocaleFormatter date={tariff.activeRange?.end} />
            </>
          ),
          value: <>{buildPriceDisplay((tariff.graduatedPrices && tariff.graduatedPrices.length > 0) ? (basePrice ? basePrice.gross : undefined) : tariff.fixedCost?.price?.gross)}</>,
          alternativeValues: [
            {
              value: <Skeleton width={200} />,
              condition: () => isContractLoading,
            },
            {
              value: (
                <Typography variant="body1">
                  <i>Information konnte nicht abgerufen werden</i>
                </Typography>
              ),
              condition: () => !tariff.fixedCost?.price?.gross,
            },
          ],
        },
        {
          key: (
            <>
              Grundpreis pro Jahr von <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />
            </>
          ),
          value: <>{buildPriceDisplay((tariff.graduatedPrices && tariff.graduatedPrices.length > 0) ? (basePriceFt ? basePriceFt.gross : undefined) : tariff.fixedCost?.price?.gross)}</>,
          highlight: true,
          alternativeValues: [
            {
              value: <Skeleton width={200} />,
              condition: () => isContractLoading,
            },
            {
              value: (
                <Typography variant="body1">
                  <i>Information konnte nicht abgerufen werden</i>
                </Typography>
              ),
              condition: () => !tariff.fixedCost?.price?.gross,
            },
          ],
        }
      );
      entries.push(
        {
          key: (
            <>
              Verbrauchspreis pro kWh bis <DateMessageLocaleFormatter date={tariff.activeRange?.end} />
            </>
          ),
          value: (tariff.graduatedPrices && tariff.graduatedPrices.length > 0) ? (
            workingPrices.length === 1 ? (
              <>{buildPriceDisplay(workingPrices[0].price?.gross)}</>
            ) : (
              (workingPrices.map((workingPrice) => (
                <>
                  {buildPriceDisplay(workingPrice.price?.gross)} ({mapCounterType(workingPrice.registerType)})<br />
                </>
              )) as unknown as ReactElement)
            )
          ) : (
            <>{buildPriceDisplay(tariff.workingPrice?.gross)}</>
          ),
          alternativeValues: [
            {
              value: <Skeleton width={200} />,
              condition: () => isContractLoading,
            },
            {
              value: (
                <Typography variant="body1">
                  <i>Information konnte nicht abgerufen werden</i>
                </Typography>
              ),
              condition: () => !tariff.workingPrice?.gross,
            },
          ],
        },
        {
          key: (
            <>
              Verbrauchspreis pro kWh von <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />
            </>
          ),
          value: (selectedContract.futureTariff?.graduatedPrices && selectedContract.futureTariff?.graduatedPrices.length > 0) ? (
            workingPricesFt.length === 1 ? (
              <>{buildPriceDisplay(workingPricesFt[0].price?.gross)}</>
            ) : (
              (workingPricesFt.map((workingPrice) => (
                <>
                  {buildPriceDisplay(workingPrice.price?.gross)} ({mapCounterType(workingPrice.registerType)})<br />
                </>
              )) as unknown as ReactElement)
            )
          ) : (
            <>{buildPriceDisplay(selectedContract.futureTariff?.workingPrice?.gross)}</>
          ),
          highlight: true,
          alternativeValues: [
            {
              value: <Skeleton width={200} />,
              condition: () => isContractLoading,
            },
            {
              value: (
                <Typography variant="body1">
                  <i>Information konnte nicht abgerufen werden</i>
                </Typography>
              ),
              condition: () => !selectedContract.futureTariff?.workingPrice?.gross,
            },
          ],
        }
      );
    } else {
      entries.push({
        key: 'Grundpreis pro Jahr',
        value: <>{buildPriceDisplay((tariff.graduatedPrices && tariff.graduatedPrices.length > 0) ? (basePrice ? basePrice.gross : undefined) : tariff.fixedCost?.price?.gross)}</>,
        alternativeValues: [
          {
            value: <Skeleton width={200} />,
            condition: () => isContractLoading,
          },
          {
            value: (
              <Typography variant="body1">
                <i>Information konnte nicht abgerufen werden</i>
              </Typography>
            ),
            condition: () => !tariff.fixedCost?.price?.gross,
          },
        ],
      });
      entries.push({
        key: 'Verbrauchspreis pro kWh',
        value:(tariff.graduatedPrices && tariff.graduatedPrices.length > 0) ? (
          workingPrices.length === 1 ? (
            <>{buildPriceDisplay(workingPrices[0].price?.gross)}</>
          ) : (
            (workingPrices.map((workingPrice) => (
              <>
                {buildPriceDisplay(workingPrice.price?.gross)} ({mapCounterType(workingPrice.registerType)})<br />
              </>
            )) as unknown as ReactElement)
          )
        ) : (
          <>{buildPriceDisplay(tariff.workingPrice?.gross)}</>
        ),
        highlight: true,
        alternativeValues: [
          {
            value: <Skeleton width={200} />,
            condition: () => isContractLoading,
          },
          {
            value: (
              <Typography variant="body1">
                <i>Information konnte nicht abgerufen werden</i>
              </Typography>
            ),
            condition: () => !tariff.workingPrice?.gross,
          },
        ],
      });
    }

    if (showCurrentAmount) {
      entries.push({
        key: 'Monatlicher Abschlag',
        value: <>{buildPriceDisplay(installment?.current?.amount)}</>,
        alternativeValues: [
          {
            value: undefined,
            condition: () => selectedContract.state === Contract_State.TERMINATED || selectedContract.state === Contract_State.TERMINATION_REQUESTED,
          },
          {
            value: (
              <Typography variant="body1">
                <i>Information konnte nicht abgerufen werden</i>
              </Typography>
            ),
            condition: () => installmentError !== undefined,
          },
          {
            value: <Skeleton width={200} />,
            condition: () => isInstallmentLoading,
          },
        ],
      });
    }

    const mwSt = getVat(selectedContract.tariff?.fixedCost?.price?.gross, selectedContract.tariff?.fixedCost?.price?.net);

    priceEntriesRet.push({
      key: 'Preis',
      value: (
        <KeyValueView
          className={classes.priceTable}
          entries={entries}
          footer={
            <Box color={theme.palette.text.primary}>
              <Box fontWeight="bold">Grund- und Verbrauchspreis:</Box>
              <Box>Bruttopreise inkl. {mwSt}% MwSt. sind gerundet.</Box>
            </Box>
          }
        />
      ),
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
      ],
    });

    return priceEntriesRet;
  };

  if (selectedContract.tariff?.activeRange?.start?.day) {
    priceEntries = buildPriceElement(selectedContract.tariff, true);
  }

  if (selectedContract.futureTariff?.activeRange?.start?.day) {
    priceEntriesPcPa = buildPriceElement(selectedContract.futureTariff, false);
  }

  const keyValueEntries: KeyValueEntries = [
    {
      key: 'Kundennummer',
      value: customer?.id,
      alternativeValues: {
        value: <Skeleton width={200} />,
        condition: () => isCustomerLoading,
      },
    },
    {
      key: 'Vertragsnummer',
      value: selectedContract.id,
      alternativeValues: {
        value: <Skeleton width={200} />,
        condition: () => isContractLoading,
      },
    },
    KeyValueEntryDivider,
    {
      key: 'Tarif',
      value: selectedContract.tariff?.displayName,
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
        {
          value: (
            <Typography variant="body1">
              <i>Information konnte nicht abgerufen werden</i>
            </Typography>
          ),
          condition: () => !selectedContract.tariff?.displayName,
        },
      ],
    },
    {
      key: 'Lieferadresse',
      value: <PostalAddressLocaleFormatter value={selectedContract.delivery?.address} />,
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
        {
          value: (
            <Typography variant="body1">
              <i>Information konnte nicht abgerufen werden</i>
            </Typography>
          ),
          condition: () => !selectedContract.delivery?.address,
        },
      ],
    },
    {
      key: (selectedContract.activeRange?.end === undefined || selectedContract.activeRange?.end === null)  ? 'Vertragsbeginn' : 'Laufzeit',
      value: <DateMessageLocaleFormatter date={selectedContract.activeRange?.start} />,
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
        {
          value: <DateMessagePeriodLocaleFormatter startDate={selectedContract.activeRange?.start} endDate={selectedContract.activeRange?.end} />,
          condition: () => selectedContract.activeRange?.end !== undefined && selectedContract.activeRange?.end !== null,
        },
      ],
    },
    {
      key: 'Kündigungsfrist',
      value: (
        <>
          <DurationLocaleFormatter duration={selectedContract.cancellationPeriod} /> zum Vertragsende (Kündigung bis zum{' '}
          <DateMessageLocaleFormatter date={selectedContract.latestCancellationDate} /> möglich)
        </>
      ),
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
        {
          value: <DateMessagePeriodLocaleFormatter startDate={selectedContract.activeRange?.start} endDate={selectedContract.activeRange?.end} />,
          condition: () => (selectedContract.activeRange?.end !== undefined && selectedContract.activeRange?.end !== null ),
        },
        {
          value: (
            <>
              Kündigung bis zum <DateMessageLocaleFormatter date={dateMessagePaDate} /> möglich.
            </>
          ),
          condition: () => showPriceAdjustment,
        },
      ],
    },
    {
      key: 'Vertragsverlängerung',
      value: (
        <>
          Automatisch um <DurationLocaleFormatter duration={selectedContract.renewalPeriod ?? { units: 0, type: CalendarPeriod.MONTH }} />
        </>
      ),
      alternativeValues: [
        {
          value: <Skeleton width={200} />,
          condition: () => isContractLoading,
        },
      ],
    },
  ];

  let keyValueEntriesPc: KeyValueEntries = [];
  if (selectedContract.futureTariff?.activeRange?.start?.day) {
    keyValueEntriesPc = [
      KeyValueEntryDivider,
      {
        key: 'Tarif',
        value: selectedContract.futureTariff?.displayName,
        alternativeValues: [
          {
            value: <Skeleton width={200} />,
            condition: () => isContractLoading,
          },
          {
            value: selectedContract.futureTariff?.product,
            condition: () => selectedContract.futureTariff?.product !== undefined && !selectedContract.futureTariff?.displayName,
          },
          {
            value: (
              <Typography variant="body1">
                <i>Information konnte nicht abgerufen werden</i>
              </Typography>
            ),
            condition: () => !selectedContract.futureTariff?.displayName,
          },
        ],
      },
      {
        key: (selectedContract.activeRange?.end === undefined || selectedContract.activeRange?.end === null) ? 'Vertragsbeginn' : 'Laufzeit',
        value: <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />,
        alternativeValues: [
          {
            value: <Skeleton width={200} />,
            condition: () => isContractLoading,
          },
          {
            value: (
              <DateMessagePeriodLocaleFormatter
                startDate={selectedContract.futureTariff?.activeRange?.start}
                endDate={selectedContract.futureTariff?.activeRange?.end}
              />
            ),
            condition: () => selectedContract.futureTariff?.activeRange?.end !== undefined,
          },
        ],
      },
    ];
  }

  return (
    <>
      <PageViewCard>
        <GraduatedPricesModal
          open={graduatedPricesModalOpen}
          onClose={graduatedPricesModalOnClose}
          showFutureTariff={!futureTariffIsPriceAdjustment}
          showPriceAdjustment={showPriceAdjustment}
        />
        <PageViewCardTitle
          title={
            <Typography variant="h2" component="div">
              <Box className={classes.gridBox}>
                Mein <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} />
                <ContractBadge contract={selectedContract} exclude={[Contract_State.ACTIVE]} className={classes.badge} />
                {showPriceAdjustment ? (
                  <Box component="span" className={classes.paBox}>
                    Preisanpassung zum <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />
                  </Box>
                ) : null}
              </Box>
            </Typography>
          }
        />
        <KeyValueView className={classes.keyValueView} entries={keyValueEntries} />
        <KeyValueView className={classes.keyValueViewPices} entries={priceEntries} />
        {selectedContract.state === Contract_State.TERMINATED ? null : (
          <Alert severity="info" icon={<HouseOutlined fontSize="inherit" />}>
            <AlertTitle>Sie ziehen um?</AlertTitle>
            <Typography variant="body1">
              Für Ihren&nbsp;
              <Link component={RouterLink} to={PortalPages.relocation.absolutePathname} color="secondary">
                Umzug
              </Link>
              &nbsp;müssen Sie nur kurz Ihre neue Anschrift mitteilen.
            </Typography>
          </Alert>
        )}
        <Alert sx={{ marginTop: '10px' }} severity="info" icon={<Unsubscribe fontSize="inherit" />}>
          <AlertTitle>Sie möchten diesen Vertrag kündigen?</AlertTitle>
          <Typography variant="body1">
            <Link component={RouterLink} to={PortalPages.cancellation.absolutePathname} color="secondary">
              Vertrag kündigen
            </Link>
          </Typography>
        </Alert>
      </PageViewCard>
      {showFutureTarif && !futureTariffIsPriceAdjustment ? (
        <PageViewCard>
          <PageViewCardTitle
            title={
              <Typography variant="h2" component="div">
                Mein zukünftiger <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} />
                <ContractBadge contract={selectedContract} exclude={[Contract_State.ACTIVE]} className={classes.badge} /> zum{' '}
                <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />
              </Typography>
            }
          />
          <KeyValueView className={classes.keyValueView} entries={keyValueEntriesPc} />
          <KeyValueView className={classes.keyValueViewPices} entries={priceEntriesPcPa} />
        </PageViewCard>
      ) : null}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  badge: {
    position: 'absolute',
    right: 0,
  },
  priceBox: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    width: '100%',
  },
  priceLink: {
    cursor: 'pointer',
  },
  priceLinkText: {
    fontWeight: '400',
  },
  gridBox: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '3',
    alignItems: 'center',
    justifyItems: 'stretch',
  },
  paBox: {
    padding: '0.5em',
    marginLeft: '1em',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
  },
  keyValueView: {
    '& table': {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
      maxWidth: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
      maxWidth: '33%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  keyValueViewPices: {
    '& table': {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
      maxWidth: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'right',
      maxWidth: '33%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  surrencyClass: {
    display: 'inline-block',
    minWidth: '2em',
    textAlign: 'left',
  },
  priceTable: {
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'right',
      minWidth: '30%',
    },
    '& td:nth-of-type(odd)': {
      width: 'auto',
      textAlign: 'left',
      minWidth: '30%',
    },
    '& td:first-child': {
      whiteSpace: 'normal',
    },
  },
}));

export default ContractCard;
