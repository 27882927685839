import { gql, useQuery } from '@apollo/client';
import { Box, Card, CardContent, Container, Typography } from '@enpxio/components';
import ErrorIcon from '@mui/icons-material/Error';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';



const QUERY = gql`
  query relocationCollection {
    pageRegistrationCollection(limit: 1) {
      items {
        backgroundImage {
          url
        }
      }
    }
    iconRelocationNotPossibleCollection(limit: 1) {
      items {
        image {
          url
          alt
        }
      }
    }
    supportCollection {
      items {
        phonenumber
        email
        servicePeriodTel
      }
    }
  }
`;

const useStyles = makeStyles<{
  backgroundImageURL: string;
}>()((theme, { backgroundImageURL }) => ({
  background: {
    minWidth: 275,
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -1,
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${backgroundImageURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  root: {},
  icon: {
    color: theme.palette.success.main,
    width: '100%',
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
  center: {
    display: 'grid',
    justifyItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  iconContainer: {
    width: '20px',
    height: '30px',
  },
  iconClass: {
    maxWidth: '20px',
    maxHeight: '20px',
  },
}));

const RelocationNotPossible: FC = (): JSX.Element => {
  const { data, loading } = useQuery(QUERY);
  const imageUrl = data?.pageRegistrationCollection?.items[0]?.backgroundImage?.url;
  const phonenumber = data?.supportCollection?.items[0]?.phonenumber;
  const email = data?.supportCollection?.items[0]?.email;
  const servicePeriodTel = data?.supportCollection?.items[0]?.servicePeriodTel;
  const iconRelocationNotPossibleUrl = data?.iconRelocationNotPossibleCollection?.items[0]?.image?.url;
  const iconRelocationNotPossibleAlt = data?.iconRelocationNotPossibleCollection?.items[0]?.image?.alt;
  const mailto = `mailto:${email}`;
  const { classes } = useStyles({ backgroundImageURL: loading || !imageUrl ? '' : imageUrl });

  return (
    <>
      <div className={classes.background} />
      <Container>
        <Card className={classes.root} square>
          <CardContent>
            <ErrorIcon className={classes.icon} style={{ fontSize: 80 }} />
            <Box component="div" className={classes.center}>
              <Typography variant="h2" className={classes.spaceXL}>
                <div className={classes.iconContainer}>
                  <img src={iconRelocationNotPossibleUrl} className={classes.iconClass} alt={iconRelocationNotPossibleAlt} />
                </div>
              </Typography>
              <Typography variant="h2" className={classes.spaceLG}>
                Ihr Vertag kann leider nicht online <br /> umgezogen werden.
              </Typography>

              <Typography variant="body2" className={classes.spaceLG}>
                Wenn Sie einen Umzug anmelden möchten, wenden Sie <br /> sich bitte an den Kundenservice
                {phonenumber ? (
                  <a className={classes.link} href={phonenumber}>
                    {phonenumber}
                  </a>
                ) : null}
              </Typography>

              {servicePeriodTel ? (
                <Typography variant="body2" className={classes.spaceLG}>
                  {servicePeriodTel}
                </Typography>
              ) : null}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default RelocationNotPossible;
