import { Contract, ContractCancellationReason, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Chip, Grid, PageView, Typography, useAsyncEffect, useConfig } from '@enpxio/components';
import { GreetingFormatter } from '@enpxio/formatters';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useCustomer } from '../customers/hooks/useCustomer';
import { PortalPages } from '../portalPages';
import InstallmentCard from './cards/installmentCard';
import InvoiceCard from './cards/invoiceCard';
import ReadingsCard from './cards/readingsCard';
import MarketingSlider from './slider/marketingSlider';
import { useAuth0, useSelectedContract } from '~/providers';

const PageDashboard: FC = () => {
  const { classes } = useStyles();
  const cfg = useConfig();
  const { customer } = useCustomer();
  const { isAuthenticated } = useAuth0();
  const { selectedContract, setSelectedContract, availableContracts } = useSelectedContract();
  const [infoChip, setInfoChip] = useState<ReactNode>(null);
  const [customerName, setCustomerName] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!customer?.person?.firstname || !customer?.person?.lastname) {
      return;
    }

    setCustomerName(`${customer?.person?.firstname} ${customer?.person?.lastname}`);
  }, [customer]);
  useAsyncEffect(async () => {
    if (!cfg) {
      return;
    }

    const clickOnRelocationInfo = async (): Promise<void> => {
      await navigate(PortalPages.relocation.absolutePathname);
    };

    const clickOnNewContract = async (newContract: Contract): Promise<void> => {
      setSelectedContract(newContract);
    };

    if (selectedContract.cancellationReason === ContractCancellationReason.RELOCATION_WITH_CONTRACT_TRANSFER) {
      const newContractArr = availableContracts.filter((contract) => contract.previousContract === selectedContract.id);
      if (newContractArr.length === 1) {
        const newContract = newContractArr[0];
        if (newContract.state === Contract_State.WAITING_FOR_SUPPLY || newContract.state === Contract_State.ACTIVE) {
          setInfoChip(
            <Chip
              label={`Dieser Vertrag wurde umgezogen zu Vertrag ${newContract.id}`}
              onClick={() => {
                clickOnNewContract(newContract);
              }}
              color="secondary"
            />
          );
        } else {
          setInfoChip(<Chip label={cfg.contract.dashboardInfo.isRelocated} onClick={clickOnRelocationInfo} color="secondary" />);
        }
      } else {
        setInfoChip(<Chip label={cfg.contract.dashboardInfo.isRelocated} onClick={clickOnRelocationInfo} color="secondary" />);
      }
    } else if (selectedContract.state === Contract_State.WAITING_FOR_NETWORK_PROVIDER || selectedContract.state === Contract_State.WAITING_FOR_SUPPLY || selectedContract.state === Contract_State.TERMINATING_PREVIOUS_PROVIDER) {
      setInfoChip(<Chip label={cfg.contract.dashboardInfo.waitingForNetworkProvider} color="secondary" />);
    } else {
      setInfoChip(null);
    }
  }, [cfg, isAuthenticated, selectedContract]);

  return (
    <PageView
      title={
        <Typography variant="h1">
          <GreetingFormatter name={customerName || ''} />
        </Typography>
      }
      subTitle={
        <Typography variant="h2" component="div">
          {infoChip}
        </Typography>
      }
      wide
    >
      <Grid spacing={2} container className={classes.gridContainer}>
        <Grid xl={4} lg={4} md={4} sm={6} xs={12} className={classes.root} item>
          <ReadingsCard />
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={6} xs={12} className={classes.root} item>
          <InstallmentCard />
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={6} xs={12} className={classes.root} item>
          <InvoiceCard />
        </Grid>
      </Grid>
      <div className={classes.sliderContainer}>
        <MarketingSlider />
      </div>
    </PageView>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  sliderContainer: {
    marginBottom: theme.spacing(11),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  gridContainer: {
    marginBottom: theme.spacing(9),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(10),
    },
  },
}));

export default PageDashboard;