import { CardContent, Theme, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

type PageViewCardTitleProps = PropsWithChildren<{
  title: string | React.ReactNode;
}>;

export const PageViewCardTitle: FC<PageViewCardTitleProps> = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <CardContent data-test-id="page-card-title-content">
        <Typography variant="h2" data-test-id="page-card-title-content-value">
          {props.title}
        </Typography>
        {props.children}
      </CardContent>
    </div>
  );
};

const useStyles = makeStyles({ name: 'EpxPageViewCardTitle' })((theme: Theme) => ({
  root: {
    '& > .MuiCardContent-root': {
      display: 'flex',
      width: '100%',
      position: 'relative',
      padding: 0,
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing(6),
      },
    },
  },
}));
