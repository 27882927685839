import { ApisEndpoint, AuthCallback, TenantEndpoint } from '@enpowerx/apis/lib';
import { CommunicationV2Endpoint } from '@enpowerx/apis/lib/communication/v2';
import { ContractEndpoint, ContractingV2Endpoint } from '@enpowerx/apis/lib/contracting/v2';
import { FinancialV2Endpoint } from '@enpowerx/apis/lib/financial/v2';
import { IdentitiesV2Endpoint } from '@enpowerx/apis/lib/identities/v2';
import { InfraV2Endpoint } from '@enpowerx/apis/lib/infra/v2';
import { MarketV2Endpoint, RegionEndpoint } from '@enpowerx/apis/lib/market/v2';
import { ContractEndpoint as ContractMeterEndpoint, MeteringV2Endpoint } from '@enpowerx/apis/lib/metering/v2';
import { ReportingV1Endpoint } from "@enpowerx/apis/lib/reporting/v1";
import { WorkflowsV2Endpoint } from '@enpowerx/apis/lib/workflows/v2';
import { useAsyncEffect, useConfig } from '@enpxio/components';
import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import { HttpHeader } from 'typedrest/http';

import { useAuth0 } from './auth0';
import { useSelectedContract } from './selectedContract';

export class APIContextState implements APIContextState {
  private readonly authCallback?: AuthCallback;

  constructor(private readonly tenant?: string, private readonly contract?: string, getToken?: CallableFunction) {
    this.authCallback = getToken
      ? async (headers: Headers): Promise<void> => {
          headers.set(HttpHeader.Authorization, `Bearer ${await getToken()}`);
        }
      : undefined;
  }

  get isInitialized(): boolean {
    return this.tenant != null;
  }

  get entry(): ApisEndpoint {
    return new ApisEndpoint(new URL('/', window?.location.href ?? 'http://localhost'), this.authCallback);
  }

  get currentTenant(): TenantEndpoint {
    if (!this.tenant) {
      throw new Error('tenant not specified');
    }

    return this.entry.tenants.get(this.tenant);
  }

  get communication(): CommunicationV2Endpoint {
    return this.currentTenant.communication.v2Beta;
  }

  get identities(): IdentitiesV2Endpoint {
    return this.currentTenant.identities.v2Beta;
  }

  get workflows(): WorkflowsV2Endpoint {
    return this.currentTenant.workflows.v2Beta;
  }

  get contracting(): ContractingV2Endpoint {
    return this.currentTenant.contracting.v2Beta;
  }

  get currentContract(): ContractEndpoint {
    if (!this.contract) {
      throw new Error('contract not specified');
    }

    return this.contracting.me.contracts.get(this.contract);
  }

  get market(): MarketV2Endpoint {
    return this.currentTenant.market.v2Beta;
  }

  get region(): RegionEndpoint {
    return this.market.regions.get('DE');
  }

  get infra(): InfraV2Endpoint {
    return this.currentTenant.infra.v2Beta;
  }

  get userReporting(): ReportingV1Endpoint {
    return this.currentTenant.reporting.v1Alpha
  }

  get metering(): MeteringV2Endpoint {
    return this.currentTenant.metering.v2Alpha;
  }

  get currentMetering(): ContractMeterEndpoint {
    if (!this.contract) {
      throw new Error('contract not specified');
    }

    return this.metering.me.contracts.get(this.contract);
  }

  get financial(): FinancialV2Endpoint {
    return this.currentTenant.financial.v2Alpha;
  }
}

const APIContext = React.createContext(new APIContextState());
export const useAPI = (): APIContextState => useContext(APIContext);

export const APIContextProvider: FC<PropsWithChildren> = (props) => {
  const [tenant, setTenant] = useState<string>();
  const config = useConfig();
  useAsyncEffect(async () => {
    setTenant(config.tenant);
  }, []);

  const { isAuthenticated, getTokenSilently } = useAuth0();
  const { selectedContract } = useSelectedContract();
  return (
    <APIContext.Provider value={new APIContextState(tenant, selectedContract?.id, isAuthenticated ? getTokenSilently : undefined)}>
      {props.children}
    </APIContext.Provider>
  );
};
