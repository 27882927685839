import { Channel, CustomerPreference_Payload } from '@enpowerx/apis/lib/communication/v2';
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  PageView,
  Theme,
  Typography,
  useAsyncEffect,
  useConfig,
  useTheme,
} from '@enpxio/components';
import { DateLocaleFormatter } from '@enpxio/formatters';
import { RecaptchaNotice, loadRecaptcha } from '@enpxio/components';
import { CheckCircleRounded } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../portalPages';
import ChannelListAdConsentDoubleOptIn from './components/channelListAdConsentDoubleOptIn';
import IconMsgAdConsentDoubleOptIn from './components/iconMsgAdConsentDoubleOptIn';
import SkeletonAdConsentDoubleOptIn from './components/skeletonAdConsentDoubleOptIn';
import TopicListAdConsentDoubleOptIn from './components/topicListAdConsentDoubleOptIn';
import { useAPI } from '~/providers';
import { SimpleModal } from './simpleModal';

enum PageAdConsentDoubleOptInState {
  LOADING,
  UNCONFIRMED,
  CONFIRMED,
  DISCARDED,
  FAILED,
  UNKNOWN,
}

interface ApiError {
  stack: string;
  message: string;
  status: number;
  data?: unknown;
}

const PageAdConsentDoubleOptIn: FC = () => {
  const params = useParams();
  let token: string | undefined = '';
  if (params && 'token' in params) {
    token = params.token ?? '';
  }

  token = token ? (token.includes('&') ? token.split('&')[0] : token) : undefined;
  const api = useAPI();
  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const theme = useTheme();
  const { classes } = useStyles();
  const [state, setState] = useState<PageAdConsentDoubleOptInState>(PageAdConsentDoubleOptInState.LOADING);
  const [contactDate, setContactDate] = useState<Date | undefined>();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [topics, setTopics] = useState<string[]>([]);
  const [consentText, setConsentText] = useState<string>('');
  const [consentTextModalOpen, setConsentTextModalOpen] = useState<boolean>(false);
  const [customer, setCustomer] = useState<string>('');
  const [payload, setPayload] = useState<CustomerPreference_Payload | undefined>();
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useAsyncEffect(async (abortSignal) => {
    try {
      if (token) {
        await loadRecaptcha(siteKey);
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'get_communication_preference_draft' });
        const draftResponse = await api.communication.communicationPreference(recaptchaToken).drafts.get(token).read(abortSignal);
        setContactDate(draftResponse.createTime);
        setCustomer(draftResponse.customer);
        if (draftResponse.draft) {
          setPayload(draftResponse.draft);
          setConsentText(draftResponse.draft.consentText);
          if (draftResponse.draft.marketing) {
            if (draftResponse.draft.marketing.channels) setChannels(draftResponse.draft.marketing.channels);
            if (draftResponse.draft.marketing.allowedTopics) setTopics(draftResponse.draft.marketing.allowedTopics);
          }
        }

        setState(PageAdConsentDoubleOptInState.UNCONFIRMED);
      }
    } catch (e) {
      console.error(e);
      if (!(typeof e === 'object')) {
        setState(PageAdConsentDoubleOptInState.FAILED);
      }

      const error = e as ApiError;
      switch (error.status) {
        case 404:
          setState(PageAdConsentDoubleOptInState.UNKNOWN);
          break;
        case 409:
          setState(PageAdConsentDoubleOptInState.CONFIRMED);
          break;
        case 400:
          setState(PageAdConsentDoubleOptInState.DISCARDED);
          break;
        default:
          setState(PageAdConsentDoubleOptInState.FAILED);
      }
    }
  }, []);

  const confirm = async (): Promise<void> => {
    setButtonsDisabled(true);
    setConfirming(true);
    try {
      const recaptchaToken = await grecaptcha.enterprise.execute(siteKey, { action: 'confirm_communication_preference_draft' });
      if (!(customer && token && recaptchaToken && payload)) {
        throw new Error(`something is missing - token: ${token}, recaptcha token: ${recaptchaToken}, customer: ${customer}, payload: ${payload}`);
      }

      await api.communication.customers.get(customer).communicationPreference.drafts.get(token).confirm(recaptchaToken).invoke(payload);
      setState(PageAdConsentDoubleOptInState.CONFIRMED);
    } catch (error) {
      console.error(error);
      setConfirming(false);
      setButtonsDisabled(false);
      enqueueSnackbar('Leider konnte Ihre Werbezustimmung nicht bestätigt werden.', { variant: 'error' });
    }
  };

  const discard = async (): Promise<void> => {
    setButtonsDisabled(true);
    setRejecting(true);
    try {
      const recaptchaToken = await grecaptcha.enterprise.execute(siteKey, { action: 'discard_communication_preference_draft' });
      if (!(token && recaptchaToken)) {
        throw new Error(`something is missing - token: ${token}, recaptcha token: ${recaptchaToken}`);
      }

      await api.communication.communicationPreference(recaptchaToken).drafts.get(token).discard();
      setState(PageAdConsentDoubleOptInState.DISCARDED);
    } catch (error) {
      console.error(error);
      setRejecting(false);
      setButtonsDisabled(false);
      enqueueSnackbar('Leider konnte Ihre unbestätigte Werbezustimmung nicht verworfen werden.', { variant: 'error' });
    }
  };

  const showConsentText = async (): Promise<void> => {
    setConsentTextModalOpen(true);
  };

  const onConsentTextModalClose = (): void => {
    setConsentTextModalOpen(false);
  };

  const renderState = (state: PageAdConsentDoubleOptInState) => {
    const pageViewTitle = 'Ihre Werbezustimmung bestätigen';
    switch (state) {
      case PageAdConsentDoubleOptInState.LOADING:
        return <SkeletonAdConsentDoubleOptIn pageViewTitle={pageViewTitle} classes={classes} />;
      case PageAdConsentDoubleOptInState.UNCONFIRMED:
        let subTitle = <>Sie haben uns telefonisch eine Werbezustimmung erteilt. Bitte bestätigen Sie uns diese noch.</>;
        if (contactDate !== undefined) {
          subTitle = (
            <>
              Am <DateLocaleFormatter date={contactDate} /> haben Sie uns telefonisch eine Werbezustimmung erteilt. Bitte bestätigen Sie uns diese noch.
            </>
          );
        }

        return (
          <PageView title={pageViewTitle} subTitle={subTitle} wide>
            <SimpleModal
              header="Werbezustimmung"
              content={<Typography>{consentText}</Typography>}
              open={consentTextModalOpen}
              onClose={onConsentTextModalClose}
            />
            {channels ? (
              <>
                <Typography variant="h2">Ihre noch nicht bestätigten Kommunikationskanäle</Typography>
                <ChannelListAdConsentDoubleOptIn channels={channels} />
              </>
            ) : null}
            {topics ? (
              <>
                <Typography variant="h2">Ihre noch nicht bestätigten Themen</Typography>
                <TopicListAdConsentDoubleOptIn topics={topics} />
              </>
            ) : null}
            <Grid container className={classes.consentButtonContainer} alignItems="center">
              <Grid item md={6} xs={12}>
                {consentText ? (
                  <List>
                    <ListItemButton className={classes.consentTextButton} onClick={showConsentText}>
                      <ListItemIcon>
                        <InfoIcon className={classes.consentTextButton} />
                      </ListItemIcon>
                      <ListItemText>Vollständigen Werbezustimmungstext anzeigen</ListItemText>
                    </ListItemButton>
                  </List>
                ) : null}
              </Grid>
              <Grid item md={6} xs={12} className={classes.buttonContainer}>
                <Grid container justifyContent={{ md: 'flex-end', sm: 'center', xs: 'center' }}>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Button color="secondary" variant="outlined" onClick={confirm} disabled={buttonsDisabled}>
                          {!confirming ? 'Bestätigen' : <CircularProgress color="inherit" size={34} />}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button style={{ marginLeft: '5px' }} color="secondary" onClick={discard} disabled={buttonsDisabled}>
                          {!rejecting ? 'Ablehnen' : <CircularProgress color="inherit" size={34} />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <RecaptchaNotice />
          </PageView>
        );
      case PageAdConsentDoubleOptInState.CONFIRMED:
        return (
          <IconMsgAdConsentDoubleOptIn
            title="Vielen Dank!"
            body="Wir haben Ihre Bestätigung hinterlegt."
            icon={CheckCircleRounded}
            color={theme.palette.success.dark}
          />
        );
      case PageAdConsentDoubleOptInState.DISCARDED:
        return (
          <IconMsgAdConsentDoubleOptIn
            title="Schade."
            body={
              <>
                Sie haben Ihre Werbezustimmung nicht bestätigt. Sollten Sie es sich anders überlegen, können Sie diese natürlich jederzeit im{' '}
                <Link href={PortalPages.myData.absolutePathname} color="secondary">
                  Kundenportal
                </Link>{' '}
                bearbeiten.
              </>
            }
            icon={SentimentDissatisfiedIcon}
            color={theme.palette.grey['500']}
          />
        );
      case PageAdConsentDoubleOptInState.UNKNOWN:
        return (
          <IconMsgAdConsentDoubleOptIn
            icon={HelpIcon}
            title="Leider ist uns dieser Vorgang unbekannt."
            body={
              <>
                Der Vorgang: <b>{token}</b> ist uns leider unbekannt. Bitte wenden Sie sich an unseren Kundenservice.
              </>
            }
            color={theme.palette.secondary.main}
          />
        );
      case PageAdConsentDoubleOptInState.FAILED:
      default:
        return (
          <IconMsgAdConsentDoubleOptIn
            icon={ErrorIcon}
            title="Leider ist ein Fehler aufgetreten."
            body={<>Bitte versuchen Sie es später nochmals, oder wenden Sie sich an unseren Kundenservice.</>}
            color={theme.palette.error.dark}
          />
        );
    }
  };

  return renderState(state);
};

export default PageAdConsentDoubleOptIn;

const useStyles = makeStyles()((theme: Theme) => ({
  consentTextButton: {
    color: theme.palette.secondary.main,
  },
  consentButtonContainer: {
    marginBottom: theme.spacing(5),
  },
  buttonContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));
