import { AccountBalance } from '@enpowerx/apis/lib/contracting/v2';
import { useAPI } from '../../providers/api';
import { useSelectedContract } from '../../providers/selectedContract';
import { useCache } from "../../meters/hooks/useCache";


interface AccountBalanceState {
  accountBalanceList: AccountBalance[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}
export const useAccountBalance = (): AccountBalanceState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();

  const {
    data: accountBalanceList,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(selectedContract.id ? `account-balances/${selectedContract.id}` : null, async () => (await api.contracting.me.accountBalances.list())?.balances ?? []);

  return {
    accountBalanceList,
    error,
    invalidate,
    isLoading,
  };
};

