import { Channel } from '@enpowerx/apis/lib/communication/v2';
import {
  Button,
  Config,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useAsyncEffect,
  useConfig,
} from '@enpxio/components';
import { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { concatValues } from '../../utils/stringConcatenation';
import { useAPI, useAuth0 } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      ' & .MuiGrid-container': {
        justifyContent: 'center',
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: '25px',
    },
  },
  white: {
    backgroundColor: 'white',
  },
  permissionText: {
    paddingTop: theme.spacing(5),
    color: theme.palette.text.primary,
  },
  spaceM: {
    paddingTop: theme.spacing(3),
  },
  spaceL: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
}));

type EnableProps = {
  enabled: boolean;
};

export const buildConsentText: (config: Config) => string = (config: Config) => {
  const channels = concatValues(config?.customerCommunicationPreferences?.channels.map((c) => c.displayName));
  const topics = concatValues(config?.customerCommunicationPreferences?.topics.map((t) => t.displayName));
  const preChannels = config?.customerCommunicationPreferences?.editPreChannels;
  const preTopics = config?.customerCommunicationPreferences?.editPreTopics;
  const editEnd = config?.customerCommunicationPreferences?.editEnd;
  return `${preChannels} ${channels} ${preTopics} ${topics} ${editEnd}`;
};

export const PermissionsDialog: FC<EnableProps> = (props: EnableProps) => {
  if (!props.enabled) {
    return null;
  }

  const { classes } = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const api = useAPI();
  const config = useConfig();
  const { isAuthenticated } = useAuth0();

  useAsyncEffect(
    async (abortSignal) => {
      if (!api.isInitialized || !isAuthenticated || !props.enabled) {
        return;
      }

      try {
        const metadata = await api.communication.me.communicationPreference.metadata.read(abortSignal);
        setIsDialogOpen(!metadata.payload?.nextAskTime || metadata.payload?.nextAskTime <= new Date());
      } catch {
        setIsDialogOpen(true);
      }
    },
    [api.isInitialized, isAuthenticated]
  );

  const handleAbort = async (): Promise<void> => {
    try {
      await api.communication.me.communicationPreference.metadata.set({ askTime: new Date() });
    } finally {
      setIsDialogOpen(false);
    }
  };

  async function handleSave(): Promise<void> {
    try {
      setIsSubmitting(true);
      await api.communication.me.communicationPreference.set({
        consentText: buildConsentText(config),
        marketing: {
          channels: Object.values(Channel).filter((c) => config.customerCommunicationPreferences.channels.map((c) => c.key).includes(c)),
          allowedTopics: config?.customerCommunicationPreferences?.topics.map((t) => t.key),
          deniedTopics: [],
        },
      });
    } finally {
      setIsDialogOpen(false);
      setIsSubmitting(false);
    }
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleAbort}
      aria-labelledby="permissions-dialog-title"
      aria-describedby="permissions-dialog-description"
      fullWidth
      maxWidth="md"
      scroll="body"
      disableScrollLock
      disableRestoreFocus
    >
      <DialogTitle id="permissions-dialog-title">
        <Typography component="h1" variant="h1" className={classes.spaceL}>
          <strong>Es gibt Neuigkeiten, die Sie interessieren könnten.</strong>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.permissionText}>
        <DialogContentText sx={{ color: (theme) => theme.palette.text.primary }} id="permissions-dialog-description">
          <strong>Wir würden gerne mit Ihnen in Kontakt bleiben.</strong>
        </DialogContentText>
        <Typography className={classes.spaceM}>{buildConsentText(config)}</Typography>
        <Typography className={classes.spaceM} />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Grid container spacing={2}>
          <Grid item>
            <Button name="submit" onClick={handleSave} color="secondary" variant="outlined" disabled={isSubmitting} className={classes.white}>
              Ja, ich stimme zu!
            </Button>
          </Grid>
          <Grid item>
            <Button name="abort" onClick={handleAbort} color="secondary" disabled={isSubmitting}>
              Später fragen {' >'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
