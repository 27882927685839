import { EnergyDelivery_UsageType, EnergyType } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface ContractNameLocaleFormatterProps {
  energyType?: EnergyType;
  suffix?: string;
  usageType?: EnergyDelivery_UsageType;
}

export const ContractNameLocaleFormatter: FC<ContractNameLocaleFormatterProps> = (props: ContractNameLocaleFormatterProps) => {
  const { energyType, suffix, usageType } = props;
  switch (energyType) {
    case EnergyType.ELECTRICITY:
      switch (usageType) {
        case EnergyDelivery_UsageType.STORAGE_HEATER:
        case EnergyDelivery_UsageType.HEAT_PUMP:
          return <>Wärmestrom{suffix ?? ''}</>;
        case EnergyDelivery_UsageType.CHARGING_CURRENT:
          return <>Ladestrom{suffix ?? ''}</>;
        default:
          return <>Strom{suffix ?? ''}</>;
      }

    case EnergyType.GAS:
      return <>Gas{suffix ?? ''}</>;
    default:
      return <>Vertrag</>;
  }
};

ContractNameLocaleFormatter.defaultProps = {
  suffix: 'vertrag',
};
