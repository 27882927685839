import { CssBaseline, StyledEngineProvider, ThemeProvider, useConfig } from '@enpxio/components';
import { createMUIThemeFromCMS } from '@enpxio/theme';
import { CSSProperties, FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import Footer from '../../layout/footer/footer';
import { PortalPages } from '../../portalPages';

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
} as CSSProperties;

const contentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  flex: '1 0 auto',
} as CSSProperties;

const buttonStyles = {
  padding: '8px',
  cursor: 'pointer',
  backgroundColor: '#f57c00',
  borderRadius: '4px',
  border: 'none',
  width: '100%',
  margin: '8px auto',
} as CSSProperties;

const footerStyles = {
  margin: 0,
  flexShrink: 0,
} as CSSProperties;

const ErrorFallback: FC<FallbackProps> = (props: FallbackProps) => {
  const { resetErrorBoundary } = props;
  const config = useConfig();
  const theme = createMUIThemeFromCMS(config);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="eb-container" style={containerStyles}>
          <div className="eb-content" style={contentStyles}>
            <div className="eb-logo-container">
              <a aria-current="page" href="/">
                <img
                  src="//images.ctfassets.net/bpe9cql8k5hd/uIyiFxCdjp4NaIyGJ8Tf0/6d4fa39edae9fc4b907a7ebafd538803/EnpowerX_Kundenportal.png"
                  alt="logo"
                  height="35"
                />
              </a>
            </div>
            <div className="eb-error-message" role="alert">
              <h2>Leider ist es zu einem Fehler gekommen...</h2>
              <p>Sie können es erneut probieren oder zur Startseite zurückkehren.</p>
              <button style={buttonStyles} onClick={resetErrorBoundary}>
                Erneut versuchen
              </button>
              <button
                style={buttonStyles}
                onClick={(): void => {
                  window.location.assign(PortalPages.home.absolutePathname);
                }}
              >
                Zur Startseite
              </button>
            </div>
          </div>
          <div style={footerStyles}>
            <Footer />
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ErrorFallback;
