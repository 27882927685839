import { SvgIcon, SvgIconProps } from '@enpxio/components';
import { FC } from 'react';

export const NoContractsIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g transform="matrix(0.042969, 0, 0, 0.042969, 1.194061, 0.876398)">
        <g>
          <g>
            <path d="M444.875,109.792L338.208,3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.146,0,64,19.135,64,42.667v426.667 C64,492.865,83.146,512,106.667,512h298.667C428.854,512,448,492.865,448,469.333v-352 C448,114.5,446.875,111.792,444.875,109.792z M341.333,36.417l70.25,70.25h-48.917c-11.771,0-21.333-9.573-21.333-21.333V36.417z M426.667,469.333c0,11.76-9.563,21.333-21.333,21.333H106.667c-11.771,0-21.333-9.573-21.333-21.333V42.667 c0-11.76,9.563-21.333,21.333-21.333H320v64C320,108.865,339.146,128,362.667,128h64V469.333z" />
            <path d="M373.333,298.667H224c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h149.333 c5.896,0,10.667-4.771,10.667-10.667C384,303.438,379.229,298.667,373.333,298.667z" />
            <path d="M138.667,128h128c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-128 c-5.896,0-10.667,4.771-10.667,10.667C128,123.229,132.771,128,138.667,128z" />
            <path d="M373.333,234.667H138.667c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h234.667 c5.896,0,10.667-4.771,10.667-10.667C384,239.438,379.229,234.667,373.333,234.667z" />
            <path d="M128,181.333c0,5.896,4.771,10.667,10.667,10.667h234.667c5.896,0,10.667-4.771,10.667-10.667 c0-5.896-4.771-10.667-10.667-10.667H138.667C132.771,170.667,128,175.438,128,181.333z" />
            <path d="M280.521,387.063c-15.688,15.438-35.458,32.833-43.292,38.125c-1.854-4.198-3.958-11.219-5.229-15.479 C227.667,395.344,224.271,384,213.333,384c-9.354,0-13.563,8.552-19.938,21.49c-3.125,6.333-9.604,19.521-11.958,21.177 c-2.313-0.24-6.271-2.938-10.625-7C182.563,404.49,192,386.917,192,373.333c0-23.313-19.625-32-32-32 c-12.583,0-32,18.323-32,42.667c0,10.792,5.979,24.125,14.458,35.823c-3.375,3.375-6.813,6.438-10.188,8.979 c-4.708,3.531-5.667,10.219-2.146,14.927c2.104,2.802,5.313,4.271,8.542,4.271c2.229,0,4.479-0.698,6.396-2.135 c3.688-2.76,7.646-6.229,11.625-10.188c8.354,7.438,17.229,12.323,24.646,12.323c14.417,0,22.979-16.406,30.417-31.5 c4.917,16.292,9.667,31.5,22.917,31.5c3.563,0,14.375,0,60.813-45.729c4.208-4.135,4.25-10.885,0.125-15.083 C291.479,382.969,284.729,382.948,280.521,387.063z M156.75,402.917c-4.333-6.604-7.417-13.458-7.417-18.917 c0-13.01,9.542-20.625,10.667-21.333c1.771,0,10.667,0.51,10.667,10.667C170.667,380.135,164.917,391.615,156.75,402.917z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
