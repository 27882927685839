import { useQuery, gql } from '@apollo/client';
import { Box, Grid, Link, Modal, Paper, Typography } from '@enpxio/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

type MeterHelpModalModalProps = {
  open: boolean;
  onClose: (event: {}) => void;
};


const QUERY = gql`
query imageMeter1Collection {
  imageMeter1Collection(limit: 1) {
    items {
      image {
        url
      }
      alt
    }
  }
}
`;

export const MeterHelpModalModal: FC<MeterHelpModalModalProps> = (props: MeterHelpModalModalProps) => {
  const { open, onClose } = props;
  const { classes } = useStyles();
  const { data, loading } = useQuery(QUERY);
  const imageMeter1Url = data?.imageMeter1Collection?.items[0]?.image?.url;
  const imageMeter1Alt = data?.imageMeter1Collection?.items[0]?.alt;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.box}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={0.5} />
                <Grid item xs={9}>
                  <Typography variant="h2" className={classes.head}>
                    Informationen zur Zählernummer
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Grid container xs={12} alignItems="center" justifyContent="flex-end">
                    <Grid item style={{ alignItems: 'center' }}>
                      <Link className={classes.closeLink} onClick={onClose} underline="none" color="secondary">
                        <CloseIcon />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0.5} />
              </Grid>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={11}>
              <Typography paragraph className={classes.text}>
                Ihre Zählernummer finden Sie auf Ihrem neuen Stromzähler, der sich meist im Keller oder in Hausflur befindet und so aussehen kann:
              </Typography>

              <Typography variant="h2" className={classes.imgContainer}>
                 <img src={imageMeter1Url} className={classes.imgContainer} alt={imageMeter1Alt} />
              </Typography>

              <Typography paragraph className={`${classes.text} ${classes.bold}`}>
                Hier noch ein paar Tipps, um die richtige Nummer am Zähler zu identifizieren:
              </Typography>

              <Grid container className={classes.spaceLG}>
                <Grid item className={classes.containerItem}>
                  <CheckCircleIcon className={classes.iconBlue} />
                </Grid>
                <Grid item className={classes.containerItem}>
                  Die Zählernummer besteht aus Zahlen oder einer Kombination aus Zahlen und Buchstaben.
                </Grid>
              </Grid>
              <Grid container className={classes.spaceLG}>
                <Grid item className={classes.containerItem}>
                  <CheckCircleIcon className={classes.iconBlue} />
                </Grid>
                <Grid item className={classes.containerItem}>
                  Die Zählernummer ist NICHT durch Punkte getrennt.
                </Grid>
              </Grid>
              <Grid container className={classes.spaceLG}>
                <Grid item className={classes.containerItem}>
                  <CheckCircleIcon className={classes.iconBlue} />
                </Grid>
                <Grid item className={classes.containerItem}>
                  Meist befindet sich die Zählernummer direkt neben dem Namen des Netzbetreibers.
                </Grid>
              </Grid>
              <Grid container className={classes.spaceLG}>
                <Grid item className={classes.containerItem}>
                  <CheckCircleIcon className={classes.iconBlue} />
                </Grid>
                <Grid item className={classes.containerItem}>
                  Bei neueren Zählern befindet sie sich oft in der Nähe des Barcodes.
                </Grid>
              </Grid>
              <Grid container className={classes.spaceLG}>
                <Grid item className={classes.containerItem}>
                  <CheckCircleIcon className={classes.iconBlue} />
                </Grid>
                <Grid item className={classes.containerItem}>
                  Bei der Zählernummer handelt es sich nicht un die rotierenden Ziffern.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.5} />
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles()((theme) => ({
  box: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    WebkitTransform: 'translate(-50%, -50%)',
    width: '100%',
    fontSize: '20px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1000px',
    },
  },
  paper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  closeLink: {
    cursor: 'pointer',
  },
  head: {
    paddingBottom: theme.spacing(5),
    fontSize: '34px',
    lineHeight: '40px',
  },
  text: {
    fontSize: '20px',
    paddingBottom: theme.spacing(3),
  },
  imgContainer: {
    height: '361px',
  },
  bold: {
    fontWeight: 'bold',
  },
  spaceLG: {
    paddingBottom: theme.spacing(3),
  },
  iconBlue: {
    color: '#6B9AC4',
    width: '20px',
    height: '20px',
  },
  containerItem: {
    height: '20px',
    lineHeight: '20px',
    paddingRight: '10px',
  },
  meternumberhelpLink: { cursor: 'pointer' },
}));
