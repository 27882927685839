import { ReadingList_Register } from '@enpowerx/apis/lib/metering/v2';
import { useEffect, useState } from 'react';

import { useCache } from './useCache';
import { useMeters } from './useMeters';
import { useAPI, useSelectedContract } from '~/providers';

export type Registers = Record<string,string, ReadingList_Register>;

interface MeterReadingsState {
  registers: Registers | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useMeterReadings = (meterNumber: string | undefined): MeterReadingsState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data,
    error: readingsError,
    revalidate,
    isValidating: isReadingsLoading,
  } = useCache(selectedContract.id ? `readings/${selectedContract.id}` : null, async () => api.currentMetering.readings.list());
  const { meters, error: metersError, isLoading: isMetersLoading } = useMeters();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [registers, setRegisters] = useState<Registers | undefined>();
  const sameObisDiffChannel = (obisCode: string, comparedObisCode: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [medium, _, measurand, measurementType, tariff] = obisCode.split(/[\-:.]+/);
    const regex = new RegExp(`${medium}-([0-9]|[1-5][0-9]|6[0-4]):${measurand}.${measurementType}.${tariff}`, 'gm');
    return Boolean(regex.test(comparedObisCode));
  };

  useEffect(() => {
    setError(readingsError ?? metersError);
  }, [readingsError, metersError]);

  useEffect(() => {
    setIsLoading(isReadingsLoading || isMetersLoading);
  }, [isReadingsLoading, isMetersLoading]);

  useEffect(() => {
    if (!meterNumber || !data?.meters || !data?.meters[meterNumber]) {
      setRegisters(undefined);
      return;
    }

    const registersMap = data.meters[meterNumber].registers;
    const registersList = Object.entries(registersMap).map(([obisCode, register], i) => {
      return { ...register, obisCode, id: i };
    });
    const currentMeter = meters?.find((meter) => meter.id === meterNumber);
    if (currentMeter) {
      for (const register of registersList) {
        Object.entries(currentMeter.registers).forEach(([obisCode]) => {
          if (register.obisCode !== obisCode) {
            if (sameObisDiffChannel(obisCode, register.obisCode)) register.obisCode = obisCode;
          }
        });
      }
    }

    const reducedRegisters = [];
    const processedRegisters = [];
    for (let i = 0; i < registersList.length; i++) {
      const newRegister = registersList[i];
      const filteredRegister = registersList.filter((register) => register.obisCode === newRegister.obisCode && register.id !== newRegister.id);
      if (filteredRegister && filteredRegister.length > 0) {
        processedRegisters.push(filteredRegister[0].id);
        newRegister.readings = registersList[i].readings.concat(filteredRegister[0].readings);
      }

      if (!processedRegisters.includes(registersList[i].id)) {
        reducedRegisters.push(newRegister);
      }
    }

    const resultMap: typeof registersMap = {};
    for (const register of reducedRegisters) {
      resultMap[register.obisCode] = { type: register.type, obisCode: register.obisCode, readings: register.readings };
    }

    setRegisters(resultMap);
  }, [data, meterNumber, meters]);

  return {
    registers,
    error,
    isLoading,
    invalidate: revalidate,
  };
};
