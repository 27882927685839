import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { PropsWithChildren } from 'react';

import { useConfig } from '../config-provider';

export const ContentProvider = (props: PropsWithChildren) => {
  const config = useConfig();

  const { spaceId } = config.content;
  const { token } = config.content;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
