import { PaymentMethod } from '@enpowerx/apis/lib/types';

interface BillingPaymentMethodProps {
  paymentMethod: PaymentMethod | undefined;
}

export const BillingPaymentMethodLocaleFormatter = (props: BillingPaymentMethodProps) => {
  switch (props.paymentMethod) {
    case PaymentMethod.DIRECT_DEBIT:
      return <>Lastschrift</>;
    case PaymentMethod.MANUAL_TRANSFER:
      return <>Überweisung</>;
    default:
      return <>Unbekannt</>;
  }
};
