import { List, ListItem, Typography } from '@enpxio/components';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PageFlags } from '../../providers/pages/pages';
import { usePages } from '../../providers/pages/provider';
import { useAuth0 } from '~/providers';

type SideMenuItemsProps = {
  onItemSelected: CallableFunction;
};

export const SideMenuItems: FC<SideMenuItemsProps> = (props) => {
  const { classes } = styles();
  return (
    <List className={classes.list}>
      <MenuItems onItemSelected={props.onItemSelected} />
    </List>
  );
};

type AccountMenuItemsProps = {
  onItemSelected: CallableFunction;
};
const MenuItems: FC<AccountMenuItemsProps> = (props) => {
  const { classes } = styles();
  const { activePage, pages } = usePages();
  const { isAuthenticated } = useAuth0();

  const menuItems = pages
    .filterTransitive(
      (page) => page.hasFlag(PageFlags.SideMenu) && (!page.hasFlag(PageFlags.Authenticated) || (isAuthenticated && page.hasFlag(PageFlags.Authenticated)))
    )
    .map((page) => {
      return (
        <ListItem
          component={Link}
          to={page.absolutePathname}
          key={page.displayName}
          onClick={(): void => props.onItemSelected()}
          className={classes.link}
          selected={page === activePage}
          button
        >
          <Typography variant="subtitle1">{page.displayName}</Typography>
        </ListItem>
      );
    });
  return <>{menuItems}</>;
};

const styles = makeStyles()((theme) => ({
  list: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    padding: '0',
  },
  link: {
    padding: '10px 12px',
  },
}));
