import { FunctionComponent, useCallback, useEffect, useState } from 'react';

type GreetingFormatterProps = {
  name: string;
};

export const GreetingFormatter: FunctionComponent<GreetingFormatterProps> = (props: GreetingFormatterProps) => {
  const [currentGreeting, setCurrentGreeting] = useState('');
  const determineGreeting = useCallback(() => {
    const hours = new Date().getHours();
    let greeting: string;
    if (hours < 3) {
      greeting = `Hallo Nachtschwärmer`;
    } else if (hours < 11) {
      greeting = `Guten Morgen`;
    } else if (hours < 18) {
      greeting = `Guten Tag`;
    } else {
      greeting = `Guten Abend`;
    }

    if (props.name !== '') {
      setCurrentGreeting(`${greeting}, ${props.name}!`);
    } else {
      setCurrentGreeting(`${greeting}!`);
    }
  }, [props.name]);

  useEffect(() => {
    determineGreeting();
    const timer = setInterval(() => {
      determineGreeting();
    }, 1000);
    return (): void => {
      clearInterval(timer);
    };
  }, [determineGreeting]);

  return <>{currentGreeting}</>;
};
