import { useConfig } from '@enpxio/components';
import { FC, createContext, useContext } from 'react';

import { useAPI } from '~/providers';
// Import { Any } from "@enpowerx/apis/lib/google/protobuf";

const uninitializedPromise = async <T,>(): Promise<T> => { throw 'reporting not initialized'; };

export type GoogleProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};

function create_UUID() {
  let dt = Date.now();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export interface ReportingContextState {
  trackSimpleEvent(eventName: string): Promise<void>;
  trackEvent(eventName: string, event: GoogleProtobufAny): Promise<void>;
}

const defaultState: ReportingContextState = {
  trackSimpleEvent: uninitializedPromise,
  trackEvent: uninitializedPromise,
};

const ReportingContext = createContext(defaultState);
export const useReportingAPI = (): ReportingContextState => useContext(ReportingContext);

type ReportingContextProviderProps = {
  children: React.ReactNode;
};
export const ReportingContextProvider: FC<ReportingContextProviderProps> = ({ children }) => {
  const cfg = useConfig();
  const api = useAPI();

  async function trackSimpleEvent(eventName: string): Promise<void> {
    try {
      const uuid = create_UUID();
      api.userReporting.reporting.create({
        id: uuid,
        eventName,
        tenant: cfg.tenant,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function trackEvent(eventName: string, event: GoogleProtobufAny): Promise<void> {
    try {
      const uuid = create_UUID();
      api.userReporting.reporting.create({
        id: uuid,
        eventName,
        tenant: cfg.tenant,
        event: event as any,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ReportingContext.Provider
        value={{
          trackSimpleEvent,
          trackEvent,
        }}
      >
        {children}
      </ReportingContext.Provider>
  );
};
