import { gql, useApolloClient } from '@apollo/client';
import { DateMessage } from '@enpowerx/apis/lib/google/type';
import { Box, DownloadList, DownloadableFile, Hidden, PageView, PageViewCard, Typography, useAsyncEffect } from '@enpxio/components';
import { DateMessageLocaleFormatter } from '@enpxio/formatters';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAuth0 } from '~/providers';

const useStyles = makeStyles()((theme) => {
  return {
    showMoreText: {
      fontSize: '14px',
      color: theme.palette.secondary.main,
    },
    expand: {
      marginLeft: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    collapse: {
      padding: '16px',
    },
    endContent: {
      display: 'flex',
    },
    pdfWrapper: {
      backgroundColor: 'white',
      padding: '7px 13px 7px 13px',
    },
    downloadList: {
      '& .MuiLink-root': {
        color: theme.palette.text.primary,
      },
    },
  };
});

const QUERY = gql`
  query pageInvoiceExplanationCollection {
    pageInvoiceExplanationCollection(limit: 1) {
      items {
        documentsCollection {
          items {
            primaryText
            secondaryText
            date
            file {
              url
            }
          }
        }
      }
    }
  }
`;

interface Query {
  pageInvoiceExplanationCollection: {
    items: {
      documentsCollection: {
        items: InvoiceExplanationDocument[];
      };
    }[];
  };
}

interface InvoiceExplanationDocument {
  __typename: string;
  primaryText: string;
  secondaryText?: string;
  date?: string;
  file: File;
}
interface File {
  url: string;
}

const InvoiceExplanationPage: FC = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0();
  const apolloClient = useApolloClient();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<DownloadableFile[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) return;
  });

  useAsyncEffect(async () => {
    try {
      const contentfulResponse = await apolloClient.query<Query>({ query: QUERY });
      const contentfulDocs = contentfulResponse?.data.pageInvoiceExplanationCollection?.items[0]?.documentsCollection?.items ?? [];
      setFiles(contentfulDocs.map<DownloadableFile>(toDownloadableFile));
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error && error.message !== 'The user aborted a request.') {
        enqueueSnackbar(`Leider konnten die Rechnungserläuterungen nicht geladen werden`, { variant: 'error' });
      }
    }
  }, []);

  const toDownloadableFile = (document: InvoiceExplanationDocument): DownloadableFile => {
    let date: Date | undefined;
    let dateMessage: DateMessage | undefined;
    let link: unknown;

    const title = document.primaryText;
    const subtitle = document.secondaryText ?? '';
    link = {
      downloadURL: document.file.url,
    };
    if (document.date) {
      date = new Date(document.date);
    }

    if (date) {
      dateMessage = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    const downloadableFile: unknown = {
      firstColumn: (
        <>
          <Box fontWeight="bold">{title}</Box>&nbsp;
          {subtitle}
        </>
      ),
      thirdColumn: (
        <div className={classes.endContent}>
          {date ? (
            <Hidden mdDown>
              <Box paddingRight="45px" marginTop="auto" marginBottom="auto">
                <DateMessageLocaleFormatter date={dateMessage} />
              </Box>
            </Hidden>
          ) : (
            <></>
          )}
          <div className={classes.pdfWrapper}>
            <Typography variant="body2" component="div">
              <Box fontWeight="bold" fontSize="smaller">
                PDF
              </Box>
            </Typography>
          </div>
        </div>
      ),
    };
    return { ...(downloadableFile as object), ...(link as object) } as DownloadableFile;
  };

  return (
    <PageView
      title="Rechnungs­erläuterung"
      subTitle="Sie möchten Ihre Rechnung besser verstehen? Auf dieser Seite können Sie sich die Erlärungen zu den einzelnen Rechungen herunterladen."
      wide
    >
      <PageViewCard>{loading ? <></> : <DownloadList className={classes.downloadList} files={files} />}</PageViewCard>
    </PageView>
  );
};

export default InvoiceExplanationPage;
