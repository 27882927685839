import { Contract, ContractCancellationReason, Contract_State, Relocation_UseNewAddress } from '@enpowerx/apis/lib/contracting/v2';
import { Tariff } from '@enpowerx/apis/lib/market/v2';
import { ReadingAddRequest_Reading } from '@enpowerx/apis/lib/metering/v2';
import { PageView, useConfig } from '@enpxio/components';
import { FC, useEffect, useState } from 'react';

import { defaultContract, useSelectedContract } from '../providers/selectedContract';
import { CustomStep, CustomStepper, WhenClickable } from './customStepper';
import RelocationForm from './form/relocationForm';
import RelocationFormNewTariff from './form/relocationFormNewTariff';
import RelocationNotPossible from './page/RelocationNotPossible';
import RelocationStatus from './page/RelocationStatus';
import RelocationSuccess from './page/RelocationSuccess';

const PageRelocation: FC = () => {
  const config = useConfig();
  const relocationWithTariffChnageEnabled = config.feature.some((feat) => feat.key === 'relocationWithTariffChange');
  const { selectedContract } = useSelectedContract();
  return (
    <PageView title="Umzug" subTitle="Melden Sie uns Ihren Umzug so früh wie möglich online." wide>
      {selectedContract.isRelocatable || selectedContract.cancellationReason === ContractCancellationReason.RELOCATION_WITH_CONTRACT_TRANSFER ? (
        <RelocationPossible reloctionTariffChange={false} />
      ) : (
        <>{relocationWithTariffChnageEnabled ? <RelocationPossible reloctionTariffChange={relocationWithTariffChnageEnabled} /> : <RelocationNotPossible />}</>
      )}
    </PageView>
  );
};

export default PageRelocation;

export const steps: CustomStep[] = [
  {
    name: 'Daten',
    clickable: true,
    whenClickable: WhenClickable.ON_NEXT,
  },
  {
    name: 'Überprüfen',
  },
  {
    name: 'Status',
  },
  {
    name: 'Umzug erfolgreich',
  },
];

export interface RelocationValues {
  email: string;
  moveoutDate: Date;
  moveinDate: Date;
  postalCode: string;
  city: string;
  street: string;
  houseNumber: string;
  meterNumberFlag?: boolean;
  meterNumber: string;
  annualUsage: string;
  useNewAddress: Relocation_UseNewAddress;
  oldPostalCode: string;
  oldCity: string;
  oldStreet: string;
  oldHouseNumber: string;
  oldMeterNumber: string;
  oldAnnualUsage: string;
  reading?: ReadingAddRequest_Reading;
  newTariff?: Tariff;
}

export enum RelocationState {
  FORM = 0,
  CONTRACT = 1,
  METERNUMBER = 2,
  CHECK = 3,
  SUCCESS = 4,
  STATUS = 5,
}

interface RelocationPossibleProps {
  reloctionTariffChange?: boolean;
}

const RelocationPossible: FC<RelocationPossibleProps> = (props: RelocationPossibleProps) => {
  const { selectedContract, availableContracts } = useSelectedContract();
  const [relocationState, setRelocationState] = useState<RelocationState>(RelocationState.FORM);
  const [newContract, setNewContract] = useState<Contract>(defaultContract);

  useEffect(() => {
    if (selectedContract.cancellationReason === ContractCancellationReason.RELOCATION_WITH_CONTRACT_TRANSFER) {
      setRelocationState(RelocationState.STATUS);
      for (const contract of availableContracts) {
        if (
          contract.previousContract === selectedContract.id &&
          (contract.state === Contract_State.WAITING_FOR_SUPPLY || contract.state === Contract_State.ACTIVE)
        ) {
          setRelocationState(RelocationState.SUCCESS);
          setNewContract(contract);
          break;
        }
      }
    } else {
      setRelocationState(RelocationState.FORM);
    }
  }, [selectedContract, availableContracts]);

  const renderState = (relocationState: RelocationState) => {
    if (props.reloctionTariffChange) {
      return <RelocationFormNewTariff />;
    }

    switch (relocationState) {
      case RelocationState.STATUS:
        return <RelocationStatus />;
      case RelocationState.SUCCESS:
        return <RelocationSuccess />;
      default:
        return <RelocationForm />;
    }
  };

  return (
    <>
      {renderState(relocationState)}
    </>
  );
};
