import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useSelectedContract } from '../../../providers';
import { KeyValueEntries, KeyValueView } from '~/keyValueView';

const useStyles = makeStyles()(() => ({
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
}));

const DeliveryView: FC = () => {
  const { selectedContract } = useSelectedContract();
  const { classes } = useStyles();
  const deliveryRows: KeyValueEntries = [
    {
      key: 'Strasse / Hausnummer',
      value: `${selectedContract.delivery?.address?.street} ${selectedContract.delivery?.address?.houseNumber}`,
    },
    {
      key: 'Postleitzahl / Ort',
      value: `${selectedContract.delivery?.address?.postalCode} ${selectedContract.delivery?.address?.city}`,
    },
  ];

  return <KeyValueView className={classes.keyValueView} entries={deliveryRows} />;
};

export default DeliveryView;
