import { ConnectedTvOutlined } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useField, FieldConfig } from 'formik';

interface FormFieldProps extends FieldConfig {
  label: string;
  disabled?: boolean;
}
/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormField = ({ label, disabled, ...pr }: FormFieldProps) => {
  const [field, meta, ...rest] = useField(pr.name);

  return (
    <TextField
      fullWidth
      label={label}
      disabled={disabled}
      {...rest}
      {...field}
      onChange={(v) => {
        field.onChange(v);
      }}
      error={meta.touched ? Boolean(meta.error) : undefined}
      helperText={meta.touched ? meta.error : ''}
      variant="standard"
    />
  );
};
