import { Backdrop, CircularProgress, Grid, Theme, Typography } from '@enpxio/components';
import { alpha } from '@mui/system';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import './../styles/styles.css';
import { gql, useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';

import { PaperlessCommunicationDialog } from '../contracts/dialogs/paperlessCommunicationDialog';
import { ContractSelection } from '../contracts/pageControls/contractSelection';
import { PermissionsDialog } from '../marketing/dialogs/permissionsDialog';
import { PortalPages } from '../portalPages';
import Footer from './footer/footer';
import Header from './header/header';
import { useAuth0, useSelectedContract } from '~/providers';
import { PageFlags } from '~/providers/pages/pages';
import { usePages } from '~/providers/pages/provider';
import { StageRibbon } from '~/stageRibbon';

const QUERY = gql`
  query {
    logoCollection(limit: 1) {
      items {
        image {
          url
        }
      }
    }
    supportCollection(limit: 1) {
      items {
        phonenumber
      }
    }
  }
`;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: '1 0 auto',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: '80vh',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: theme.namedSpacing('large', 'xxxl'),
    paddingTop: theme.namedSpacing('small', 'xxxl'),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.namedSpacing('large', 'xxxl'),
    },
  },
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Layout: FC<PropsWithChildren> = (props) => {
  const { classes, cx } = useStyles();
  const { isLoading } = useAuth0();
  const { activePage } = usePages();
  const [antiHydrationKey, setAntiHydrationKey] = useState(0);

  useEffect(() => {
    const randomValues = new Uint32Array(1);
    window.crypto.getRandomValues(randomValues);
    setAntiHydrationKey(randomValues[0]);
  }, [isLoading]);
  
  return (
    <div key={antiHydrationKey} className={classes.wrapper}>
      <StageRibbon />
      <LoadingSpinner>
        <Header />
        <main className={cx(classes.root)}>
          <PaperlessCommunicationDialog enabled={!activePage?.hasFlag(PageFlags.AuthenticatedNoMenu)} />
          <PermissionsDialog enabled={!activePage?.hasFlag(PageFlags.AuthenticatedNoMenu)} />
          <ContractSelection enabled={!activePage?.hasFlag(PageFlags.AuthenticatedNoMenu)}>{props.children}</ContractSelection>
        </main>
        <Footer />
      </LoadingSpinner>
    </div>
  );
};

const useLoadingSpinnerStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
    backgroundColor: alpha('#000', 0.7),
  },

  failure: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },

  container: {
    padding: '20px',
  },

  logo: {
    display: 'block',
    maxWidth: '230px',
    maxHeight: '82px',
    padding: '2px',
    width: 'auto',
    height: 'auto',
  },

  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const LoadingSpinner: FC<PropsWithChildren> = (props) => {
  const { classes } = useLoadingSpinnerStyles();
  const { data, loading } = useQuery(QUERY);
  const logoUrl = data?.logoCollection?.items[0]?.image?.url;
  const phoneNumber = data?.supportCollection?.items[0]?.phonenumber;
  const { isAuthenticated, user } = useAuth0();
  const { isLoading, failure } = useSelectedContract();
  const navigate = useNavigate();

  return useMemo(() => {
    const isNewUser = !('https://claims.enpowerx.io/customer' in user) || user['https://claims.enpowerx.io/customer'] === '';
    if (isAuthenticated && isNewUser) {
      navigate(PortalPages.registration.absolutePathname);
      return <>{props.children}</>;
    }

    if (failure) {
      return (
        <div className={classes.failure}>
          <Grid container justifyContent="center" alignItems="center" className={classes.container} spacing={4}>
            <Grid item xs={2} />
            <Grid item xs={8} className={classes.logoContainer}>
              <Link to="/">{loading || !logoUrl ? null : <img src={logoUrl} className={classes.logo} />}</Link>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8} className={classes.logoContainer}>
              <Typography variant="h2">
                Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmals, oder wenden Sie sich an unseren Kundenservice unter: {phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </div>
      );
    }

    if (isAuthenticated && isLoading) {
      return (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color="inherit" disableShrink />
        </Backdrop>
      );
    }

    return <>{props.children}</>;
  }, [isLoading, data]);
};

export default Layout;
