import { useQuery, gql } from '@apollo/client';
import { AppBar, Hidden, Toolbar } from '@enpxio/components';
import { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ContractSelector } from '../../contracts/components/contractSelector';
import { NavigationBar } from './navigationBar';
import { SearchBar } from './searchBar';
import { SearchBox } from './searchBox';
import { SideMenu } from './sideMenu';
import { UserControls } from './userControls';
import { useAuth0, useSelectedContract } from '~/providers';

const QUERY = gql`
  query logoCollection {
    logoCollection(limit: 1) {
      items {
        image {
          url
        }
      }
    }
  }
`;

const Header: FunctionComponent = () => {
  const { classes } = styles();
  const { data, loading } = useQuery(QUERY);
  const logoUrl = data?.logoCollection?.items[0]?.image?.url;
  const { isAuthenticated } = useAuth0();
  const { selectedContract } = useSelectedContract();
  const [searchBarIsOpen, setSearchBarIsOpen] = useState<boolean>(false);

  const toggleOpenSearch = (): void => {
    if (searchBarIsOpen) {
      setSearchBarIsOpen(false);
    } else {
      setSearchBarIsOpen(true);
    }
  };

  return (
    <AppBar
      color="primary"
      position="fixed"
      className={isAuthenticated ? (selectedContract.id ? classes.root : classes.rootNoNav) : classes.rootUnauthenticated}
    >
      <Toolbar className={classes.toolbar}>
        <SideMenu />
        <Hidden mdDown>
          <ContractSelector />
        </Hidden>
        <Hidden mdUp>
          <SearchBox toggleOpenSearch={toggleOpenSearch} searchBarIsOpen={searchBarIsOpen} />
        </Hidden>
        <div className={classes.logoContainer}>
          <Link to="/">{loading || !logoUrl ? null : <img src={logoUrl} className={classes.logo} />}</Link>
        </div>
        <div className={classes.userControls}>
          <UserControls toggleOpenSearch={toggleOpenSearch} searchBarIsOpen={searchBarIsOpen} />
        </div>
      </Toolbar>
      <NavigationBar />
      <SearchBar toggleOpenSearch={toggleOpenSearch} searchBarIsOpen={searchBarIsOpen} />
    </AppBar>
  );
};

const styles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    position: 'inherit',
    centerTitle: true,
    maxHeight: 128,
    [theme.breakpoints.down('md')]: {
      maxHeight: 48,
    },
  },
  rootUnauthenticated: {
    flexGrow: 1,
    alignItems: 'center',
    position: 'inherit',
    centerTitle: true,
    maxHeight: 86,
    [theme.breakpoints.down('md')]: {
      maxHeight: 48,
    },
  },
  rootNoNav: {
    flexGrow: 1,
    alignItems: 'center',
    position: 'inherit',
    centerTitle: true,
    maxHeight: 86,
    [theme.breakpoints.down('md')]: {
      maxHeight: 48,
    },
  },
  toolbar: {
    maxWidth: 1300,
    width: '100%',
    height: 106,
    [theme.breakpoints.down('md')]: {
      maxHeight: 68,
    },
    flexGrow: 1,
    alignItems: 'center',
  },
  hide: {
    display: 'none',
  },
  logoContainer: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    height: 86,
    [theme.breakpoints.down('md')]: {
      maxHeight: 48,
    },
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    display: 'block',
    maxWidth: '230px',
    maxHeight: '82px',
    padding: '2px',
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '1px',
      maxHeight: '41px',
      maxWidth: '100px',
    },
  },
  userControls: {
    position: 'absolute',
    right: '50px',
    [theme.breakpoints.down('lg')]: {
      right: '10px',
    },
    [theme.breakpoints.down('md')]: {
      right: '10px',
    },
  },
}));

export default Header;
