import { Person } from '@enpowerx/apis/lib/types';

import { useCache } from '../../meters/hooks/useCache';
import { useAPI, useAuth0 } from '~/providers';

interface PersonState {
  person: Person | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const usePerson = (): PersonState => {
  const api = useAPI();
  const { isAuthenticated } = useAuth0();
  const {
    data: person,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(isAuthenticated ? 'person' : null, async () => api.identities.me.person.read());
  return {
    person,
    error,
    isLoading,
    invalidate,
  };
};
