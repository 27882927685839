import { DateMessage } from '@enpowerx/apis/lib/google/type';
import { FC, ReactNode } from 'react';

import { DateMessageLocaleFormatter, formatDateMessageLocale } from './dateMessage';

interface DateMessagePeriodProps {
  startDate: DateMessage | undefined;
  startDatePrefix?: ReactNode;
  endDate: DateMessage | undefined;
  endDatePrefix?: ReactNode;
}

export const DateMessagePeriodLocaleFormatter: FC<DateMessagePeriodProps> = (props: DateMessagePeriodProps) => {
  if (!props.startDate || !props.endDate) {
    return null;
  }

  return (
    <>
      {props.startDatePrefix}
      <DateMessageLocaleFormatter date={props.startDate} />
      {props.endDatePrefix}
      <DateMessageLocaleFormatter date={props.endDate} />
    </>
  );
};

export function getDateMessagePeriod(startDate: DateMessage | undefined, endDate: DateMessage | undefined): string {
  if (!startDate || !endDate) {
    return '';
  }

  return `${formatDateMessageLocale(startDate)} - ${formatDateMessageLocale(endDate)}`;
}

DateMessagePeriodLocaleFormatter.defaultProps = {
  endDatePrefix: ' - ',
};
