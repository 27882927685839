import { gql, useQuery } from '@apollo/client';
import { SvgIconProps } from '@enpxio/components';
import { EmojiObjects } from '@mui/icons-material';
import { FC } from 'react';

import { DivisionIcon } from './divisionIcon';

const QUERY = gql`
  query iconCurrentCollection {
    iconCurrentCollection(limit: 1) {
      items {
        image {
          url
        }
        alt
      }
    }
  }
`;

export const CurrentIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  const { data } = useQuery(QUERY);
  const iconUrl = data?.iconCurrentCollection?.items[0]?.image?.url;
  const altTxt = data?.iconCurrentCollection?.items[0]?.alt;

  if (iconUrl !== undefined) {
    return <DivisionIcon src={iconUrl} altText={altTxt} />;
  }

  return <EmojiObjects className={props.className} />;
};
