import { Invoice } from '@enpowerx/apis/lib/contracting/v2';
import { toDateMessage } from '@enpowerx/apis/lib/google/type';
import {
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  DownloadList,
  DownloadableFile,
  DownloadedFile,
  Hidden,
  IconButton,
  MimeType,
  PageViewCard,
  PageViewCardTitle,
  Typography,
} from '@enpxio/components';
import { ConsumptionLocaleFormatter, ContractNameLocaleFormatter, DateMessageLocaleFormatter, DateMessagePeriodLocaleFormatter , differenceInDays } from '@enpxio/formatters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { drop, take } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { sortInvoiceAsc } from '../../utils/invoiceUtils';
import { useInvoices } from '../hooks/useInvoices';
import { GoogleProtobufAny, useAPI, useReportingAPI, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  endContent: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  text: {
    padding: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 3, 0),
    },
  },
  showMoreText: {
    fontSize: '14px',
    color: theme.palette.secondary.main,
  },
  showMoreTextExpanded: {
    visibility: 'hidden',
    fontSize: '0px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const InvoiceList: React.FunctionComponent = () => {
  const { classes, cx } = useStyles();
  const api = useAPI();
  const { trackEvent } = useReportingAPI();
  const { invoices, isLoading } = useInvoices();

  const [sortedInvoices, setSortedInvoices] = useState<Invoice[]>([]);
  const { selectedContract } = useSelectedContract();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const downloadInvoice = useCallback(
    async (invoice: Invoice): Promise<DownloadedFile> => {
      const event: GoogleProtobufAny = {
        '@type': 'type.googleapis.com/enpowerx.reporting.v1.InvoiceDownload',
        contract: selectedContract.id,
        customer: selectedContract.customer,
        archiveId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
      };
      trackEvent('InvoiceDownload', event);
      const invoiceDoc = await api.currentContract.invoices.get(invoice.id).file.read();
      return {
        filename: `Rechnung_${invoice.invoiceNumber}`,
        payload: invoiceDoc.content,
        mimeType: invoiceDoc.mimeType as unknown as MimeType,
      };
    },
    [api]
  );

  const invoiceToDownloadableFile = (invoice: Invoice): DownloadableFile => ({
    firstColumn: 'Rechnung',
    secondColumn: <DateMessagePeriodLocaleFormatter startDate={invoice.startDate} endDate={invoice.endDate} />,
    thirdColumn: (
      <Hidden smDown>
        <div className={classes.endContent}>
          <Box minWidth="120px" paddingRight="8px" textAlign="right" marginTop="auto" marginBottom="auto">
            {invoice.annualUsage ? (
              <ConsumptionLocaleFormatter
                value={invoice.annualUsage}
                formatOptions={{
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                }}
              />
            ) : null}
          </Box>
        </div>
      </Hidden>
    ),
    downloadFileDelegate: async () => downloadInvoice(invoice),
  });

  useEffect(() => {
    if (!invoices) {
      setSortedInvoices([]);
      return;
    }

    // Only show invoices that are not cancelled AND that invoiceDate is not today
    setSortedInvoices(
      invoices
        .filter((invoice) => {
          const dif = differenceInDays(toDateMessage(new Date()), invoice.invoiceDate);
          return !invoice.isCancelled && dif !== 0;
        })
        .sort(sortInvoiceAsc)
    );
  }, [invoices, selectedContract]);

  let title = (
    <>
      Rechnungen für meinen <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} />
    </>
  );
  let text = <>Hier stehen alle Rechnungen zum Download zur Verfügung.</>;
  if (invoices?.length === 0) {
    title = <>Wir haben noch keine Rechnung für Sie</>;
    text = (
      <>
        Ihre erste Rechnung wird voraussichtlich am <DateMessageLocaleFormatter date={selectedContract.nextInvoice} /> erstellt. Wir werden Sie frühzeitig
        benachrichtigen. Bis dahin können Sie sich zurücklehnen.
      </>
    );
  }

  if (!invoices) {
    title = <>Wir haben noch keine Rechnung für Sie</>;
    text = (
      <i>
        Rechnungen zu Ihrem <ContractNameLocaleFormatter energyType={selectedContract?.delivery?.energyType} /> konnten nicht abgerufen werden
      </i>
    );
  }

  return (
    <PageViewCard>
      <PageViewCardTitle title={title} />
      <Typography variant="body1" className={classes.text}>
        {text}
      </Typography>
      {!isLoading ? (
        <>
          <DownloadList files={take(sortedInvoices, 1).map<DownloadableFile>(invoiceToDownloadableFile)} />
          <CardActions disableSpacing>
            <IconButton
              className={cx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              size="large"
            >
              <span
                className={cx(classes.showMoreText, {
                  [classes.showMoreTextExpanded]: expanded,
                })}
              >
                mehr anzeigen +
              </span>
              {expanded ? <ExpandMoreIcon color="secondary" /> : null}
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <DownloadList files={drop(sortedInvoices, 1).map<DownloadableFile>(invoiceToDownloadableFile)} />
            </CardContent>
          </Collapse>
        </>
      ) : (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </PageViewCard>
  );
};

export default InvoiceList;
