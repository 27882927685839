import { Card, CardContent, Typography, Button, Box, useConfig, RecaptchaNotice, loadRecaptcha, useAsyncEffect } from '@enpxio/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAPI } from '../../providers/api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 445,
    maxWidth: 445,
    marginLeft: 160,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: 'initial',
    },
  },
  icon: {
    color: theme.palette.success.main,
    width: '100%',
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
}));

export const SuccessCard: FC = () => {
  const { classes } = useStyles();
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const config = useConfig();
  const siteKey = config.recaptcha.siteKey;

  useAsyncEffect(async () => {
    await loadRecaptcha(siteKey);
  }, [siteKey]);

  async function sendVerificationEmail(): Promise<void> {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email') ?? '';

      const token: string = await grecaptcha.enterprise.execute(siteKey, { action: 'resend_verification_email' });

      await api.identities.users.get(email).sendVerificationEmail(token).invoke({
        id: config.oauth.clientId,
        redirectUri: window.location.origin,
      });
      enqueueSnackbar('Das hat geklappt. Bitte Überprüfen Sie Ihre Mails.', {
        variant: 'success',
      });
      close();
    } catch (e) {
      close();
      enqueueSnackbar('Die Verifizierungsmail konnte nicht gesendet werden. Versuchen Sie es später erneut.', {
        variant: 'error',
      });
    }
  }

  return (<>
    <Card className={classes.root} square>
      <CardContent>
        <CheckCircleIcon className={classes.icon} style={{ fontSize: 80 }} />

        <Typography variant="body2" className={classes.spaceXL}>
          Wir haben Ihnen eine Bestätigung Ihrer Registrierung geschickt. Bitte aktivieren Sie ihren Account, indem Sie auf den Link in dieser E-Mail klicken.
        </Typography>

        <Typography variant="body2" className={classes.spaceLG}>
          Der Versand der E-Mail kann in Einzelfällen einige Minuten dauern. Bitte haben Sie etwas Geduld und prüfen Sie im Zweifelsfall Ihren Spam-Ordner.
        </Typography>
        <Button sx={{ marginTop: 8 }} color="secondary" onClick={sendVerificationEmail} variant="outlined">
          Verifizierungsmail erneut versenden
        </Button>
      </CardContent>
    </Card>
    <Box sx={{ paddingTop: 4 }}>
      <RecaptchaNotice />
    </Box></>
  );
};
