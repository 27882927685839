import { RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { formatDateMessageLocale, mapChannel, mapOrigin, mapQuality } from '@enpxio/formatters';
import { useEffect, useState } from 'react';

import { dateMessageSortComparerByField } from '../../sortComparer/dateMessage';
import { useCache } from './useCache';
import { useMeters } from './useMeters';
import { useAPI, useSelectedContract } from '~/providers';

interface HistMeterReadingsState {
  histMeterReadings: HistReadingListMeter[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}
export interface HistReadingListMeter {
  meter?: string;
  histMeterReadings?: HistReadingList[];
}

export interface HistReadingList {
  date: string;
  meter?: string;
  meterType?: string;
  valueNt?: number;
  valueHt?: number;
  origin: string;
  channel: string;
  quality: string;
}

export const useHistMeterReadings = (meterNumber: string | undefined): HistMeterReadingsState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data,
    error: readingsError,
    revalidate,
    isValidating: isReadingsLoading,
  } = useCache(selectedContract.id ? `readings/${selectedContract.id}` : null, async () => api.currentMetering.readings.list());
  const { meters, error: metersError, isLoading: isMetersLoading } = useMeters();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [histMeterReadings, setHistMeterReadings] = useState<HistReadingListMeter[] | undefined>();

  useEffect(() => {
    setError(readingsError ?? metersError);
  }, [readingsError, metersError]);

  useEffect(() => {
    setIsLoading(isReadingsLoading || isMetersLoading);
  }, [isReadingsLoading, isMetersLoading]);

  useEffect(() => {
    if (!meterNumber || !data?.meters || !data?.meters[meterNumber] || Object.keys(data?.meters).length < 2) {
      setHistMeterReadings(undefined);
      return;
    }

    const histMeterList = meters?.filter((meter) => meter.id !== meterNumber);
    if (histMeterList === undefined) {
      setHistMeterReadings(undefined);
      return;
    }

    const histMeterReadingList: HistReadingListMeter[] = [];
    for (const meter of histMeterList) {
      let histReadingList: HistReadingList[] = [];
      const histReadingListA: HistReadingList[] = [];
      const histReadingListB: HistReadingList[] = [];
      if (Object.keys(meter.registers).length === 2) {
        Object.entries(meter.registers).forEach(([obisCode, register]) => {
          const sortedReadings = data.meters[meter.id].registers[obisCode].readings
            .sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date))
            .reverse();
          for (const reading of sortedReadings) {
            const histReadingListItem: HistReadingList = {
              date: formatDateMessageLocale(reading.date),
              meter: meter.id.toString(),
              meterType: 'DZ',
              valueHt: register.type === RegisterType.DOUBLE_TARIFF_PEAK ? reading.value : 0,
              valueNt: register.type === RegisterType.DOUBLE_TARIFF_OFF_PEAK ? reading.value : 0,
              origin: mapOrigin(reading.origin),
              channel: mapChannel(reading.channel) ? `(${mapChannel(reading.channel)})` : '',
              quality: mapQuality(reading.quality),
            };
            if (register.type === RegisterType.DOUBLE_TARIFF_PEAK) {
              histReadingListA.push(histReadingListItem);
            } else {
              histReadingListB.push(histReadingListItem);
            }
          }
        });
        histReadingListA.forEach((itemA) => {
          histReadingListB.forEach((itemB) => {
            if (itemB.date === itemA.date) {
              itemA.valueNt = itemB.valueNt;
            }
          });
        });
        histReadingList = histReadingListA;
      } else {
        const registerSingle = Object.values(data.meters[meter.id].registers)[0];
        const sortedReadings = registerSingle.readings.sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.date)).reverse();
        for (const reading of sortedReadings) {
          const histReadingListItem: HistReadingList = {
            date: formatDateMessageLocale(reading.date),
            meter: meter.id.toString(),
            meterType: 'EZ',
            valueHt: reading.value,
            valueNt: undefined,
            origin: mapOrigin(reading.origin),
            channel: mapChannel(reading.channel) ? `(${mapChannel(reading.channel)})` : '',
            quality: mapQuality(reading.quality),
          };
          histReadingList.push(histReadingListItem);
        }
      }

      histMeterReadingList.push({ meter: meter.id.toString(), histMeterReadings: histReadingList });
    }

    setHistMeterReadings(histMeterReadingList);
  }, [data, meterNumber, meters]);

  return {
    histMeterReadings,
    error,
    isLoading,
    invalidate: revalidate,
  };
};
