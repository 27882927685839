declare global {
  const grecaptcha: {
    enterprise: {
      execute(key: string, options: Record<string, unknown>): Promise<string>;
      ready(callback: unknown): unknown;
    };
  };
}

export const useRecaptcha = (siteKey: string): void => {
  const script = document.createElement('script');
  script.id = 'grecaptcha';
  script.type = 'text/javascript';
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;

  document.body.appendChild(script);
};

export const loadRecaptcha = async (siteKey: string): Promise<void> => {
  useRecaptcha(siteKey);
  const script = document.querySelector('#grecaptcha');
  return new Promise((resolve) => {
    if (script != null) {
      script.addEventListener('load', () => {
        grecaptcha.enterprise.ready(() => {
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
};
