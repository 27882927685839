import { FC, useState, useEffect } from 'react';
import { PageViewCard, PageViewCardTitle, useConfig } from '@enpxio/components';
import { useCustomer } from '../../customers/hooks/useCustomer';
import { KeyValueEntries, KeyValueView } from '~/keyValueView';
import { NumberLocaleFormatter } from '@enpxio/formatters';
import { Link } from 'react-router-dom';

const NonCommodity: FC = (): JSX.Element => {
  const [desiredDevice, setDesiredDevice] = useState({
    created_at: '',
    order_number: '',
    productTitle: '',
    amount_total: '',
    paymentType: '',
    status: '',
  });
  const config = useConfig();
  const { customer, isLoading } = useCustomer();

  useEffect(() => {
     if (!customer?.id) {
       return;
     }
    let ignoreUpdates = false;
    async function updateState() {
      try {
        const response = await fetch(`https://epilot-poc.dev.enpowerx.io/tenants/${config.tenant}/nonCommodity/v1/customers/${customer?.id}/orders`, {
          mode: 'cors',
        });
        const ePilotDevices = await response.json();
        if (ePilotDevices.length > 0) {
          const createdDate = new Date(ePilotDevices[0]._created_at);

          switch (ePilotDevices[0].status) {
            case 'placed':
              ePilotDevices[0].status = 'Auftrag erteilt';
              break;
            case 'draft':
              ePilotDevices[0].status = 'Entwurf';
              break;
            case 'quote':
              ePilotDevices[0].status = 'Anfrage';
              break;
            case 'complete':
              ePilotDevices[0].status = 'Bestellung abgeschlossen';
              break;
            case 'cancelled':
              ePilotDevices[0].status = 'Bestellung storniert';
              break;
            case 'open_for_acceptance':
              ePilotDevices[0].status = 'offen für die Annahme';
              break;
            default:
              ePilotDevices[0].status = 'Bestellung angenommen';
              break;
          }
          setDesiredDevice({
            created_at: createdDate.toLocaleDateString('DE'),
            order_number: ePilotDevices[0].order_number,
            productTitle: ePilotDevices[0].line_items[0]._product._title,
            amount_total: ePilotDevices[0].amount_total,
            paymentType: "",
            status: ePilotDevices[0].status,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    updateState();
    return () => {
      ignoreUpdates = true;
    };
  }, [customer]);

  const entries: KeyValueEntries = [
    {
      key: 'Artikel',
      value: desiredDevice.productTitle,
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
    {
      key: 'Bestelldatum',
      value: desiredDevice.created_at,
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
    {
      key: 'Bestellnummer',
      value: desiredDevice.order_number,
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
    {
      key: 'Preis',
      value: (
        <>
          <NumberLocaleFormatter value={Number(desiredDevice.amount_total) / 100} /> EUR
        </>
      ),
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
    {
      key: 'Zahlweise',
      value: desiredDevice.paymentType,
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
    {
      key: 'Auslieferungsstand ',
      value: desiredDevice.status,
      //   alternativeValues: {
      //     value: <Skeleton width={200} />,
      //     condition: () => isLoading,
      //   },
    },
  ];

  if (isLoading) {
    return <></>
  }

  return (
    <PageViewCard>
      <PageViewCardTitle title={'Sonstige Produkte'} />
      {
        desiredDevice.order_number === '' ? <>
          Suchen Sie sich nun Ihr Wunschgerät aus. <Link to={"/non-commodity"}>mehr erfahren</Link>
        </> : <KeyValueView entries={entries} />
      }
    </PageViewCard>
  );
};

export default NonCommodity;
