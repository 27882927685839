import { HelpOutlineSharp } from '@mui/icons-material';
import { Theme, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

interface TextWithTooltipProps {
  displayText: string;
  helpTitle?: string;
  helpText: string | React.ReactNode;
}

export const TextWithTooltip: FC<TextWithTooltipProps> = (props: TextWithTooltipProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleTooltip = (): void => {
    setOpen(!open);
  };

  const handleMouse = (open: boolean): void => {
    setOpen(open);
  };

  return (
    <HtmlTooltip
      title={
        <>
          {props.helpTitle ? (
            <Typography color="inherit" variant="subtitle1" component="h2">
              {props.helpTitle}
            </Typography>
          ) : null}
          {props.helpText}
        </>
      }
      open={open}
    >
      <span className={classes.inlineFlex}>
        {props.displayText}
        <HelpOutlineSharp
          className={classes.infoIcon}
          onTouchEnd={toggleTooltip}
          onMouseOver={() => {
            handleMouse(true);
          }}
          onMouseLeave={() => {
            handleMouse(false);
          }}
        />
      </span>
    </HtmlTooltip>
  );
};

const useStyles = makeStyles()(() => ({
  infoIcon: {
    cursor: 'help',
    fontSize: '15px',
    marginLeft: '2px',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
}));

const HtmlTooltip = withStyles(Tooltip, (theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    margin: '0px',
  },
}));
