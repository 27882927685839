import { FC, ReactNode, createContext, useContext, useState } from 'react';

import { useAsyncEffect } from '..';
import { Config } from './Config';

interface ConfigState {
  config: Config;
  isLoading: boolean;
}

const defaultConfigState: ConfigState = {
  config: new Config(JSON.parse('{}')),
  isLoading: true,
};

export const ConfigContext = createContext<ConfigState>(defaultConfigState);

interface ConfigContextProviderProps {
  children: ReactNode;
}

export const useConfig = (): Config => useContext(ConfigContext).config;

export const ConfigContextProvider: FC<ConfigContextProviderProps> = (props: ConfigContextProviderProps) => {
  const [config, setConfig] = useState<Config>(defaultConfigState.config);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useAsyncEffect(async () => {
    try {
      const timestamp = Math.round(Date.now() / 1000);
      const response = await fetch(`/assets/config.json?v=${timestamp}`);
      const config = new Config(await response.json());
      if (config !== null) {
        if (response.headers.has('x-maintenance-end')) {
          const maintenanceEndUnix = Number(response.headers.get('x-maintenance-end'));
          config.maintenanceEnd = new Date(maintenanceEndUnix * 1000);
        }
        const hostName = window.location.hostname;
        if (hostName === config.cobrowsing?.agentPortalDomain) {
          config.oauth.connection = 'enpowerx';
        }

        setConfig(config);
        (document.querySelector("link[rel*='icon']") as HTMLLinkElement).href = config.website.favicon;
        (document.querySelector('title') as HTMLTitleElement).innerText = config.website.title;
        (document.querySelector("meta[name*='description']") as HTMLMetaElement).content = config.website.description;
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return <ConfigContext.Provider value={{ config, isLoading }}>{isLoading ? null : props.children}</ConfigContext.Provider>;
};
