import { FC } from 'react';
import { Alert, AlertTitle, Skeleton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PageViewCard, PageViewCardTitle } from '@enpxio/components';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography,Box } from '@mui/material';
import { MoneyLocaleFormatter } from '@enpxio/formatters';
import { useAccountBalance } from '../hooks/useAccountBalance';
import { ContractBadge } from '../../contracts/components/contractBadge';
import { useSelectedContract } from '../../providers/selectedContract';
import { filter } from 'lodash-es';
import { Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { ContractTypeIcon } from "../../svg/contractTypeIcon";
interface AccountBalanceListProps {
    noteTitle: string;
    noteText: string;
}

const AccountBalanceList: FC<AccountBalanceListProps> = (props: AccountBalanceListProps): JSX.Element => {
    const { accountBalanceList, isLoading } = useAccountBalance();
    const { availableContracts } = useSelectedContract();

    return (
        <PageViewCard>
            <PageViewCardTitle
                title={
                    <Typography variant="h2" component="div">
                        Mein Kontostand
                    </Typography>
                }
            />
            <TableContainer>
                <Table sx={{ marginBottom: 4 }}>
                    <TableBody>
                        {isLoading ? (
                            <>
                                {[...Array.from({ length: 4 })].map((_, index) => (
                                    <TableRow key={index} sx={{
                                        borderBottom: 'solid #f8f8f8 2px',
                                        borderTop: 'solid #f8f8f8 2px',
                                    }}>
                                        <TableCell>
                                            <Skeleton width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton width="70%" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {accountBalanceList?.map((accBalance) => (
                                    <TableRow key={accBalance.contract} sx={{
                                        borderBottom: 'solid #f8f8f8 2px',
                                        borderTop: 'solid #f8f8f8 2px',
                                    }}>
                                        <TableCell sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gridTemplateColumns: 'auto 1fr',
                                            gridGap: '0.5em',
                                        }}>
                                            <div><ContractTypeIcon isLoading={false} contract={filter(availableContracts,(contract)=>contract.id===accBalance.contract)[0]} /></div>
                                            <div>{filter(availableContracts,(contract)=>contract.id===accBalance.contract)[0]?.tariff?.displayName}</div>
                                            <div><ContractBadge contract={filter(availableContracts,(contract)=>contract.id===accBalance.contract)[0]} exclude={[Contract_State.ACTIVE]}  /></div>
                                        </TableCell>
                                        <TableCell>{accBalance.contract}</TableCell>
                                        <TableCell>
                                            {(Number.parseInt(accBalance.amountOpen?.units ?? "", 10) === 0 && accBalance.amountOpen?.nanos === 0o0) ? (
                                                <>Alles bezahlt</>
                                            ) : Number.parseInt(accBalance.amountOpen?.units ?? "", 10) < 0 && (accBalance.amountOpen?.nanos ?? 0) <= 0 ? (
                                                <>Guthaben von</>
                                            ) : (
                                                <>Offener Betrag</>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                        }}>
                                            <MoneyLocaleFormatter withSignChange value={accBalance.amountOpen} showFractionUnit={false} hideCurrency />{' '}
                                            <Box sx={{
                                                display: 'inline-block',
                                                minWidth: '2em',
                                                textAlign: 'left',
                                            }}>EUR</Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Alert severity="info" icon={<InfoOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>{props.noteTitle ?? "Sie haben Fragen zu Ihrem Kontostand?"}</AlertTitle>
                <Typography variant="body1">
                    {props.noteText ?? `Wir beantworten sie Ihnen gerne im Detail. Melden Sie sich einfach bei unserem Kundensupport.`}
                </Typography>
            </Alert>
        </PageViewCard>
    );
};

export default AccountBalanceList

