import { Collapse, Grid, Link, PageViewCard } from '@enpxio/components';
import { FC, useState } from 'react';

import { useLatestMeterReading } from '../hooks/useLatestMeterReading';
import AddReadingDialog from './addReadingDialog';
import MeterpointInfo from './meterpointInfo';
import ReadingsTable from './readingsTable';

interface MeterpointCardProps {
  addReading: boolean;
}

const MeterpointsCard: FC<MeterpointCardProps> = (props: MeterpointCardProps) => {
  const { addReading } = props;
  const [editMode, setEditMode] = useState<boolean>(addReading);
  const [linkText, setLinkText] = useState<string>('Mehr anzeigen ＋');
  const { registers } = useLatestMeterReading();

  const [showReadingsInfo, setShowReadingsInfo] = useState<boolean>(false);
  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };

  const toggleMeterValues = (): void => {
    if (showReadingsInfo) {
      setLinkText('Mehr anzeigen ＋');
    } else {
      setLinkText('Weniger anzeigen ×');
    }

    setShowReadingsInfo(!showReadingsInfo);
  };

  return (
    <PageViewCard>
      {editMode ? <AddReadingDialog toggleFunc={toggleEditMode} /> : <MeterpointInfo toggleFunc={toggleEditMode} />}
      {registers ? (
        <Grid container justifyContent="flex-end" style={{ marginBottom: '10px' }}>
          <Link sx={{ color: (theme) => theme.palette.secondary.main, cursor: 'pointer' }} onClick={toggleMeterValues} underline="none">
            {linkText}
          </Link>
        </Grid>
      ) : null}
      <Collapse in={showReadingsInfo}>
        <ReadingsTable />
      </Collapse>
    </PageViewCard>
  );
};

export default MeterpointsCard;
