import { useQuery, gql } from '@apollo/client';
import { RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { Box, Grid, Typography } from '@enpxio/components';
import { CounterReadingLocaleFormatter, DateMessageLocaleFormatter } from '@enpxio/formatters';
import { FC, ReactNode, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useActiveMeterNumber } from '../../meters/hooks/useActiveMeter';
import { Register, useLatestMeterReading } from '../../meters/hooks/useLatestMeterReading';
import { isContractTerminated } from '../../meters/pageControls/meterpointInfo';
import { PortalPages } from '../../portalPages';
import { DashboardCardButton } from './dashboardCard';
import { SimpleDashboardCard } from './simpleDashboardCard';
import { useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  footer: {
    display: 'flex',
    [theme.breakpoints.down(1140)]: {
      display: 'block',
    },

    '& .MuiBox-root': {
      marginRight: '15px',
    },
  },
  divider: {
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    marginTop: theme.namedSpacing('small', 'md'),
    marginBottom: theme.namedSpacing('small', 'md'),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.namedSpacing('large', 'md'),
      marginBottom: theme.namedSpacing('large', 'md'),
    },
  },
}));

const QUERY = gql`
  query supportCollectionQuery {
    supportCollection(limit: 1) {
      items {
        phonenumber
      }
    }
  }
`;

const ReadingsCard: FC = () => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const { activeMeterNumber, isLoading: activeMeterNumberLoading, error: activeMeterNumberError } = useActiveMeterNumber();
  const { registers, isLoading: registersLoading, error: registersError } = useLatestMeterReading();
  const [value, setValue] = useState<ReactNode>(null);
  const [footerContent, setFooterContent] = useState<ReactNode>(null);
  const [buttonContent, setButtonContent] = useState<DashboardCardButton | undefined>();
  const { data } = useQuery(QUERY);
  const phoneNumber = data?.supportCollection?.items[0]?.phonenumber;

  useEffect(() => {
    if (!registers || !activeMeterNumber) {
      return;
    }

    if (registers.length === 1) {
      setValue(<CounterReadingLocaleFormatter value={registers[0].reading.value ?? 0} energyType={selectedContract.delivery?.energyType} />);
      setFooterContent(
        <div className={classes.footer}>
          <Typography variant="body1" component="div">
            <Box fontWeight="bold">Ablesedatum:</Box>
            <DateMessageLocaleFormatter date={registers[0].reading.date} />
          </Typography>
        </div>
      );
    } else if (registers.length === 2) {
      let peakRegister: Register | undefined;
      let offPeakRegister: Register | undefined;
      for (const register of registers) {
        switch (register.type) {
          case RegisterType.DOUBLE_TARIFF_PEAK:
            peakRegister = register;
            break;
          case RegisterType.DOUBLE_TARIFF_OFF_PEAK:
            offPeakRegister = register;
            break;
          default:
            break;
        }
      }

      if (peakRegister && offPeakRegister) {
        setValue(
          <Grid container>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <Box fontWeight="bold">Stand HT:</Box>
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <CounterReadingLocaleFormatter value={peakRegister.reading.value ?? 0} energyType={selectedContract.delivery?.energyType} />
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <Box fontWeight="bold">Ablesedatum:</Box>
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <DateMessageLocaleFormatter date={peakRegister.reading.date} />
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <div className={classes.divider} />
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <Box fontWeight="bold">Stand NT:</Box>
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <CounterReadingLocaleFormatter value={offPeakRegister.reading.value ?? 0} energyType={selectedContract.delivery?.energyType} />
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <Box fontWeight="bold">Ablesedatum:</Box>
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="body1" component="div">
                <DateMessageLocaleFormatter date={offPeakRegister.reading.date} />
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
  }, [registers, activeMeterNumber, selectedContract]);

  useEffect(() => {
    if (isContractTerminated(selectedContract)) {
      setButtonContent(undefined);
    } else {
      setButtonContent({ text: 'Zählerstand eingeben', link: PortalPages.meters.absolutePathname, state: { addReading: true } });
    }
  }, [selectedContract]);

  const EmptyView: FC = () => {
    if (!activeMeterNumber) {
      return (
        <>
          <Typography variant="subtitle2" component="h2">
            {`Uns fehlen Daten zu Ihrem Zähler. Bitte melden Sie sich bei uns telefonisch unter der Nummer ${phoneNumber}. Halten Sie dabei Ihre aktuellste Zählernummer und Ihre Kundendaten bereit.`}
          </Typography>
          <br />
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle2" component="h2">
          Information konnte nicht abgerufen werden
        </Typography>
        <br />
      </>
    );
  };

  return (
    <SimpleDashboardCard
      title="Mein Zählerstand"
      value={value}
      footer={footerContent}
      button={buttonContent}
      isLoading={activeMeterNumberLoading || registersLoading}
      isError={(activeMeterNumberError ?? registersError) !== undefined}
      isEmpty={activeMeterNumber === undefined || registers === undefined}
      isEmptyMessage={<EmptyView />}
    />
  );
};

export default ReadingsCard;
