import { EnergyType } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface EnergyUnitLocaleFormatterProps {
  energyType: EnergyType | undefined;
  prefix?: string;
  suffix?: string;
}

export const EnergyUnitLocaleFormatter: FC<EnergyUnitLocaleFormatterProps> = (props: EnergyUnitLocaleFormatterProps) => (
  <>
    {props.prefix}
    {getEnergyUnitByEnergyType(props.energyType)}
    {props.suffix}
  </>
);

export function getEnergyUnitByEnergyType(energyType: EnergyType | undefined): string {
  switch (energyType) {
    case EnergyType.GAS:
      return 'm³';
    case EnergyType.ELECTRICITY:
    default:
      return 'kWh';
  }
}
