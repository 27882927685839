import { ApiError, Trait, hasTrait } from '@enpowerx/apis';
import {
  Meter,
  MeterTokenContext,
  ReadingAddRequest_Reading,
  ReadingChannel,
  ReadingOrigin,
  ReadingQuality,
  RegisterType,
} from '@enpowerx/apis/lib/metering/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  TextFieldProps,
  Typography,
  useConfig,
} from '@enpxio/components';
import {
  CounterReadingLocaleFormatter,
  formatDateMessageLocale,
  getEnergyUnitByEnergyType,
  mapCounterType,
  validateElectricityReadingValue,
  validateGasReadingValue,
} from '@enpxio/formatters';
import { SaveOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { useActiveMeterNumber } from '../hooks/useActiveMeter';
import { Register, useLatestMeterReading } from '../hooks/useLatestMeterReading';
import { useMeterReadings } from '../hooks/useMeterReadings';
import { useMeters } from '../hooks/useMeters';
import ImplausibleReadingModal from './implausibleReadingModal';
import { GoogleProtobufAny, useAPI, useAuth0, useReportingAPI, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  title: {
    margin: theme.spacing(5, 5, 5, 5),
  },
  subTitle: {
    padding: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 0, 3, 0),
    },
  },
  formControl: {
    marginBottom: '12px',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '60%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {},
}));

interface MeterPointInputFormProps {
  meterPoint: string;
  energyType: EnergyType | undefined;
  reading?: ReadingAddRequest_Reading | undefined;
  // SetReading?: Dispatch<SetStateAction<ReadingAddRequest_Reading | undefined>> | undefined
  toggleFunc?: CallableFunction;
  token?: string;
  meterTokenContext?: MeterTokenContext;
  callback?: CallableFunction;
  setReadingValue?: (readingValue: ReadingAddRequest_Reading | undefined) => void;
}

interface SubmitValues {
  amountCounter1: string;
  amountCounter2?: string;
  date: Date;
}

const MeterPointInputForm: FC<MeterPointInputFormProps> = (props: MeterPointInputFormProps) => {
  const { meterPoint, energyType, toggleFunc, token, reading, setReadingValue, meterTokenContext, callback } = props;
  const { classes } = useStyles();
  const { isAuthenticated } = useAuth0();
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  let currentRegisters: Register[] | undefined;
  const obisCodes: string[] = [];
  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const { meters } = useMeters();
  const { activeMeterNumber } = useActiveMeterNumber();
  const { trackEvent } = useReportingAPI();
  const { selectedContract } = useSelectedContract();

  let invalidateFunc = (): void => {};

  if (isAuthenticated) {
    const { registers } = useLatestMeterReading();
    currentRegisters = registers;
    const { invalidate } = useMeterReadings(meterPoint);
    invalidateFunc = invalidate;
    if (!currentRegisters && meters) {
        let activeMeter: Meter | undefined;
        for (const meter of meters) {
          if (meter.id === activeMeterNumber) {
            activeMeter = meter;
            break;
          }
        }

        if (activeMeter) {
          const registersList: Register[] = [];
          for (const [obisCode, register] of Object.entries(activeMeter.registers)) {
            registersList.push({
              obisCode,
              type: register.type,
              reading: {
                date: undefined,
                value: 0,
                channel: ReadingChannel.READING_CHANNEL_UNSPECIFIED,
                origin: ReadingOrigin.READING_ORIGIN_UNSPECIFIED,
                quality: ReadingQuality.READING_QUALITY_UNSPECIFIED,
              },
            });
          }

          currentRegisters = registersList;
        }
      }
  } else if (meterTokenContext !== undefined && meterTokenContext.meter !== undefined && meterTokenContext.meter.registers !== undefined) {
        for (const [obisCode] of Object.entries(meterTokenContext.meter.registers)) {
          obisCodes.push(obisCode);
        }
      }

  const [firstRegister, setFirstRegister] = useState<string | undefined>(null);
  const [secondRegister, setSecondRegister] = useState<string | undefined>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
  const initialValues = {
    amountCounter1: '',
    amountCounter2: '',
    date: new Date(),
  };
  if (reading) {
    if (reading.date !== undefined) {
      initialValues.date = new Date(reading.date.year, reading.date.month - 1, reading.date.day);
    }

    let counter = 0;
    for (const value of Object.values(reading.values)) {
      switch (counter) {
        case 0:
          initialValues.amountCounter1 = value.toString();
          break;
        case 1:
          initialValues.amountCounter2 = value.toString();
          break;
        default:
          break;
      }

      counter++;
    }
  }

  const hasSecondRegister = (currentRegisters && currentRegisters[1]) || (obisCodes && obisCodes[1]);

  useEffect(() => {
    if (currentRegisters) {
      setFirstRegister(currentRegisters[0].obisCode);
      if (currentRegisters[1]) setSecondRegister(currentRegisters[1].obisCode);
    }

    if (obisCodes.length > 0) {
      setFirstRegister(obisCodes[0]);
      if (obisCodes[1]) setSecondRegister(obisCodes[1]);
    }
  }, [currentRegisters]);

  const validateMeterInput = (value: string): string => {
    if (!value) return 'Dies ist ein Pflichtfeld';
    switch (energyType?.toString()) {
      case 'GAS':
        return validateGasReadingValue(value.toString());
      case 'ELECTRICITY':
        return validateElectricityReadingValue(value.toString());
      default:
        console.error('unexpected energy type');
    }

    return '';
  };

  const readingValues: (values: SubmitValues) => Record<string, number> = (values: SubmitValues) => {
    const readingValues = new Map<string | undefined, number>();
    if (values.amountCounter1) readingValues.set(firstRegister, Number.parseFloat(values.amountCounter1));
    if (secondRegister && values.amountCounter2) readingValues.set(secondRegister, Number.parseFloat(values.amountCounter2));
    return Object.fromEntries(readingValues);
  };

  const saveReading = async (values: SubmitValues): Promise<void> => {
    setInputsDisabled(true);
    const reading = {
      channel: ReadingChannel.PORTAL,
      date: {
        year: selectedDate.getFullYear() || 0,
        month: selectedDate.getMonth() ? selectedDate.getMonth() + 1 : 1,
        day: selectedDate.getDate() || 1,
      },
      values: readingValues(values),
    };
    if (token) {
      try {
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'add_reading' });
        await api.metering.tokens
          .get(token)
          .readings(recaptchaToken)
          .add({
            contract: meterTokenContext?.contract ?? '',
            meter: meterTokenContext?.meter?.id ?? '',
            reading,
          });
        if (callback) callback();
      } catch (error_) {
        const error = error_ as ApiError;
        if (error.message?.includes('Request is invalid')) {
          setOpenModal(true);
          setInputsDisabled(false);
        } else {
          enqueueSnackbar('Der eingegebene Zählerstand ist ungültig', { variant: 'error' });
          setInputsDisabled(false);
        }
      }
    } else {
      try {
        const event: GoogleProtobufAny = {
          '@type': 'type.googleapis.com/enpowerx.reporting.v1.SubmitReading',
          contract: selectedContract.id,
          customer: selectedContract.customer,
          meterNumber: meterPoint,
        };
        trackEvent('SubmitReading', event);
        await api.currentMetering.meters.get(meterPoint).readings.add(reading);
        enqueueSnackbar('Zählerstand erfolgreich übermittelt', { variant: 'success' });
        invalidateFunc();
        setInputsDisabled(false);
        if (toggleFunc) toggleFunc();
      } catch (error_) {
        const error = error_ as ApiError;
        setInputsDisabled(false);
        console.error(JSON.stringify(error));
        if (error.errorType === 'type.googleapis.com/enpowerx.metering.v2.InvalidReadingImplausibleError') {
          setOpenModal(true);
        } else if (hasTrait(error, Trait.InvalidArgument)) {
          enqueueSnackbar('Der eingegebene Zählerstand ist ungültig', { variant: 'error' });
          if (toggleFunc) toggleFunc();
        } else {
          enqueueSnackbar('Zählerstanderfassung ist fehlgeschlagen', { variant: 'error' });
          if (toggleFunc) toggleFunc();
        }
      }
    }
  };

  const getInputLabel = (registers: Register[] | undefined, index: number): string => {
    if (!registers || registers.length === 0 || !registers[index]) {
      if (meterTokenContext !== undefined && obisCodes[index]) return obisCodes[index];

      return 'Zählerstand';
    }

    const register = registers[index];
    if (register.type === RegisterType.SINGLE_TARIFF) {
      return `Zählerstand`;
    }

    return `Zählerstand (${mapCounterType(register.type)} - OBIS ${register.obisCode})`;
  };

  const getLatestReadingLabel = (registers: Register[] | undefined) => {
    if (!registers || registers.length === 0) {
      return null;
    }

    if (registers.length === 1) {
      const register = registers[0];
      if (!register.reading.date) {
        return (
          <Typography variant="body1" gutterBottom className={classes.subTitle}>
            Noch keine Ablesedaten vorhanden
          </Typography>
        );
      }

      const dateString = formatDateMessageLocale(register.reading.date);
      return (
        <Typography variant="body1" gutterBottom className={classes.subTitle}>
          Zuletzt abgelesen am {dateString} mit <CounterReadingLocaleFormatter value={register.reading.value ?? 0} energyType={energyType} />
        </Typography>
      );
    }

    const firstRegister = registers[0];
    const secondRegister = registers[1];
    if (!firstRegister.reading.date && !secondRegister.reading.date) {
      return (
        <Typography variant="body1" gutterBottom className={classes.subTitle}>
          Noch keine Ablesedaten vorhanden
        </Typography>
      );
    }

    const dateString = formatDateMessageLocale(firstRegister.reading.date);
    return (
      <Typography variant="body1" gutterBottom className={classes.subTitle}>
        Zuletzt abgelesen am {dateString} mit <CounterReadingLocaleFormatter value={firstRegister.reading.value ?? 0} energyType={energyType} /> (
        {mapCounterType(firstRegister.type)} - OBIS: {firstRegister.obisCode}) /{' '}
        <CounterReadingLocaleFormatter value={secondRegister.reading.value ?? 0} energyType={energyType} /> ({mapCounterType(secondRegister.type)} - OBIS:{' '}
        {secondRegister.obisCode})
      </Typography>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveReading}
      validationSchema={yup.object({
        date: yup.string().required('Dies ist ein Pflichtfeld.'),
      })}
    >
      {({ values, submitForm, dirty, isValid, onChange, handleChange }) => {
        return (
          <>
            <ImplausibleReadingModal
              open={openModal}
              onClose={(): void => {
                setOpenModal(false);
              }}
              counters={obisCodes && obisCodes.length > 0 ? obisCodes : currentRegisters?.map((r) => r.obisCode) ?? []}
              values={{
                amountCounter1: values.amountCounter1,
                amountCounter2: values.amountCounter2 ? values.amountCounter2 : undefined,
                date: selectedDate,
              }}
              meterpoint={meterPoint}
              token={token}
              meterTokenContext={meterTokenContext}
              callback={callback}
            />
            <Form>
              <CardContent>
                {getLatestReadingLabel(currentRegisters)}
                <Grid container justifyContent="center" direction="row" alignItems="center">
                  <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel htmlFor="outlined-amountCounter1">{getInputLabel(currentRegisters, 0)}</InputLabel>
                    <Field
                      label={getInputLabel(currentRegisters, 0)}
                      id="outlined-amountCounter1"
                      type="text"
                      as={OutlinedInput}
                      name="amountCounter1"
                      validate={validateMeterInput}
                      startAdornment={<Typography color="textPrimary">{getEnergyUnitByEnergyType(energyType)}</Typography>}
                      onChange={(e) => {
                        handleChange(e);

                        setReadingValue({
                          channel: ReadingChannel.PORTAL,
                          date: {
                            year: selectedDate.getFullYear() || 0,
                            month: selectedDate.getMonth() ? selectedDate.getMonth() + 1 : 1,
                            day: selectedDate.getDate() || 1,
                          },
                          values: readingValues(values),
                        });
                      }}
                      onBlur={(e) => {
                        handleChange(e);

                        setReadingValue({
                          channel: ReadingChannel.PORTAL,
                          date: {
                            year: selectedDate.getFullYear() || 0,
                            month: selectedDate.getMonth() ? selectedDate.getMonth() + 1 : 1,
                            day: selectedDate.getDate() || 1,
                          },
                          values: readingValues(values),
                        });
                      }}
                      disabled={inputsDisabled}
                    />
                    <FormHelperText error>
                      <ErrorMessage name="amountCounter1" />
                    </FormHelperText>
                  </FormControl>
                  {hasSecondRegister ? (
                    <FormControl className={classes.formControl} variant="outlined">
                      <InputLabel htmlFor="outlined-amountCounter2">{getInputLabel(currentRegisters, 1)}</InputLabel>
                      <Field
                        type="text"
                        label={getInputLabel(currentRegisters, 1)}
                        id="outlined-amountCounter2"
                        as={OutlinedInput}
                        name="amountCounter2"
                        validate={validateMeterInput}
                        startAdornment={<Typography color="textPrimary">{getEnergyUnitByEnergyType(energyType)}</Typography>}
                        disabled={inputsDisabled}
                        onChange={(e) => {
                          handleChange(e);

                          setReadingValue({
                            channel: ReadingChannel.PORTAL,
                            date: {
                              year: selectedDate.getFullYear() || 0,
                              month: selectedDate.getMonth() ? selectedDate.getMonth() + 1 : 1,
                              day: selectedDate.getDate() || 1,
                            },
                            values: readingValues(values),
                          });
                        }}
                        onBlur={(e) => {
                          handleChange(e);

                          setReadingValue({
                            channel: ReadingChannel.PORTAL,
                            date: {
                              year: selectedDate.getFullYear() || 0,
                              month: selectedDate.getMonth() ? selectedDate.getMonth() + 1 : 1,
                              day: selectedDate.getDate() || 1,
                            },
                            values: readingValues(values),
                          });
                        }}
                      />
                      <FormHelperText error>
                        <ErrorMessage name="amountCounter2" />
                      </FormHelperText>
                    </FormControl>
                  ) : null}
                  <FormControl className={classes.formControl}>
                    <Field
                      component={DatePicker}
                      disableFuture
                      label="Ablesedatum"
                      name="readingDate"
                      invalidDateMessage="Bitte geben Sie das Datum in einem gültigen Format an."
                      maxDate={new Date()}
                      maxDateMessage="Bitte geben Sie kein Datum in der Zukunft an."
                      value={selectedDate}
                      onChange={(newValue: number | undefined) => {
                        const newDateValue = new Date(newValue ?? 0);
                        if (newValue) setSelectedDate(newDateValue);
                      }}
                      renderInput={(params: TextFieldProps) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
              </CardContent>
              {setReadingValue === undefined ? (
                <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={submitForm}
                    disabled={!(isValid && dirty && !inputsDisabled)}
                    startIcon={<SaveOutlined />}
                  >
                    {!inputsDisabled ? 'Speichern' : <CircularProgress color="inherit" size={34} />}
                  </Button>
                  <span style={{ width: '4px' }} />
                  {toggleFunc ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        if (toggleFunc) toggleFunc();
                      }}
                    >
                      Abbrechen
                    </Button>
                  ) : null}
                </CardActions>
              ) : null}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default MeterPointInputForm;
