import { Channel } from '@enpowerx/apis/lib/communication/v2';
import { List, ListItem, ListItemIcon, ListItemText } from '@enpxio/components';
import CheckIcon from '@mui/icons-material/Check';
import { FC } from 'react';

interface ChannelListAdConsentDoubleOptInProps {
  channels: Channel[];
}

const ChannelListAdConsentDoubleOptIn: FC<ChannelListAdConsentDoubleOptInProps> = (props) => {
  const { channels } = props;
  const channelListItem = (channel: string, index: number) => {
    return (
      <ListItem key={`channel-${index}`}>
        <ListItemIcon>
          <CheckIcon color="success" />
        </ListItemIcon>
        <ListItemText>{channel}</ListItemText>
      </ListItem>
    );
  };

  return (
    <List>
      {channels.map((channel, index) => {
        switch (channel) {
          case Channel.EMAIL:
            return channelListItem('E-Mail', index);
          case Channel.PHONE:
            return channelListItem('Telefon', index);
          case Channel.LETTER:
            return channelListItem('Brief', index);
          case Channel.SMS:
            return channelListItem('SMS', index);
          case Channel.MESSENGER:
            return channelListItem('Messenger', index);
          default:
        }
      })}
    </List>
  );
};

export default ChannelListAdConsentDoubleOptIn;
