import { ContactInquiry_Message } from '@enpowerx/apis/lib/communication/v2';
import { Person_SalutationForm } from '@enpowerx/apis/lib/types';
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  Collapse,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
  useAsyncEffect,
  useConfig,
} from '@enpxio/components';
import { PersonLocaleFormatter, SalutationLocaleFormatter, getSelectableSalutations } from '@enpxio/formatters';
import { loadRecaptcha } from '@enpxio/components';
import { EmailOutlined } from '@mui/icons-material';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';

import { useCustomer } from '../../customers/hooks/useCustomer';
import { KeyValueView } from '~/keyValueView';
import { useAPI, useAuth0, useSelectedContract } from '~/providers';
import { ViewCard } from './viewCard';

interface Values {
  isAuthenticated: boolean;
  category: string;
  salutation: Person_SalutationForm;
  firstname: string;
  lastname: string;
  email: string;
  customer: string;
  company: string;
  title: string;
  message: string;
}

interface ContactCardProps {
  title?: string;
  topics: string[];
}

export const ContactCard: FC<ContactCardProps> = (props: ContactCardProps) => {
  const { classes } = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const { customer, isLoading: customerIsLoading } = useCustomer();
  const { selectedContract } = useSelectedContract();
  const { isAuthenticated, user } = useAuth0();
  const config = useConfig();
  const siteKey = config.recaptcha.siteKey;
  const api = useAPI();
  const [initialValues, setInitialValues] = useState<Values>({
    isAuthenticated,
    category: 'unset',
    salutation: Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED,
    firstname: '',
    lastname: '',
    email: '',
    customer: '',
    company: '',
    title: '',
    message: '',
  });

  useAsyncEffect(async () => {
    await loadRecaptcha(siteKey);
  }, [siteKey]);

  useEffect(() => {
    setInitialValues({
      isAuthenticated,
      category: 'unset',
      salutation: isAuthenticated
        ? customer?.person?.salutation ?? Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED
        : Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED,
      firstname: isAuthenticated ? customer?.person?.firstname ?? '' : '',
      lastname: isAuthenticated ? customer?.person?.lastname ?? '' : '',
      email: isAuthenticated ? user.email ?? selectedContract.billing?.contact?.email ?? '' : '',
      customer: isAuthenticated ? customer?.id ?? '' : '',
      company: isAuthenticated ? customer?.person?.company ?? '' : '',
      title: isAuthenticated ? customer?.person?.title ?? '' : '',
      message: '',
    });
  }, [isAuthenticated, customer, selectedContract]);

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>): Promise<void> => {
    const request: ContactInquiry_Message = {
      topic: values.category,
      text: values.message,
    };

    try {
      const token: string = await grecaptcha.enterprise.execute(siteKey, { action: 'contactInquiry' });
      await api.communication.me.contactInquiries(token).invoke(request);
      setSuccess(true);
    } catch {
      setAlertOpen(true);
    }

    actions.setSubmitting(false);
  };

  return (
    <ViewCard>
      <Collapse in={!success}>
        <Formik
          initialValues={initialValues}
          validationSchema={createValidationSchema(props.topics)}
          onSubmit={handleSubmit}
          validateOnChange
          enableReinitialize
        >
          {({ submitForm, isSubmitting }) => (
            <Form style={{ width: '100%' }}>
              <CardContent>
                <Typography variant="h2" component="h2">
                  {props.title ?? 'Schreiben Sie uns'}
                </Typography>
              </CardContent>
              <CardContent>
                <Grid direction="row" spacing={10} container>
                  {isAuthenticated ? (
                    <Grid xs={12} item>
                      <KeyValueView
                        entries={[
                          {
                            key: 'Kundennummer',
                            value: customer?.id,
                            alternativeValues: {
                              value: <Skeleton width={200} />,
                              condition: () => customerIsLoading,
                            },
                          },
                          {
                            key: 'Name',
                            value: <PersonLocaleFormatter value={customer?.person} />,
                            alternativeValues: {
                              value: <Skeleton width={200} />,
                              condition: () => customerIsLoading,
                            },
                          },
                        ]}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid xs={12} item>
                        <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 220 }}>
                          <InputLabel htmlFor="salutation">Anrede</InputLabel>
                          <Field as={Select} name="salutation" label="Anrede">
                            <MenuItem key={Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED} value={Person_SalutationForm.SALUTATION_FORM_UNSPECIFIED}>
                              Bitte auswählen
                            </MenuItem>
                            {getSelectableSalutations().map((val) => (
                              <MenuItem key={val} value={val}>
                                <SalutationLocaleFormatter salutation={val} />
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText error>
                            <ErrorMessage name="salutation" />
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="firstname">Vorname</InputLabel>
                          <Field as={OutlinedInput} name="firstname" label="Vorname" disabled={isSubmitting} />
                          <FormHelperText error>
                            <ErrorMessage name="firstname" />
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="lastname">Nachname</InputLabel>
                          <Field as={OutlinedInput} name="lastname" label="Nachname" disabled={isSubmitting} />
                          <FormHelperText error>
                            <ErrorMessage name="lastname" />
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} item>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="email">E-Mail Adresse</InputLabel>
                          <Field as={OutlinedInput} name="email" label="E-Mail Adresse" disabled={isSubmitting} />
                          <FormHelperText error>
                            <ErrorMessage name="email" />
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid xs={12} item>
                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 250 }}>
                      <InputLabel htmlFor="category">Thema</InputLabel>
                      <Field
                        as={Select}
                        name="category"
                        label="Thema"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem key="unset" value="unset">
                          Bitte auswählen
                        </MenuItem>
                        {props.topics?.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText error>
                        <ErrorMessage name="category" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} item>
                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="message">Nachricht</InputLabel>
                      <Field as={OutlinedInput} name="message" label="Nachricht" disabled={isSubmitting} rows={7} multiline />
                      <FormHelperText error>
                        <ErrorMessage name="message" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Collapse in={alertOpen}>
                  <Alert variant="outlined" severity="error">
                    Da ist etwas schief gegangen. Bitte versuchen Sie es erneut.
                  </Alert>
                </Collapse>
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end', marginRight: 10 }}>
                <Button variant="outlined" color="secondary" startIcon={<EmailOutlined />} disabled={isSubmitting} onClick={submitForm}>
                  Absenden
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Collapse>
      <Fade in={success}>
        <Alert severity="success">Ihre Nachricht wurde erfolgreich übermittelt.</Alert>
      </Fade>
    </ViewCard>
  );
};

function createValidationSchema(contactCategories: string[]): AnyObjectSchema {
  return yup.object({
    isAuthenticated: yup.boolean(),
    category: yup.string().required('Bitte wählen Sie ein Thema aus.').oneOf(contactCategories, 'Bitte wählen Sie ein Thema aus.'),
    salutation: yup.mixed<Person_SalutationForm>().when(['isAuthenticated'], {
      is: false,
      then: yup.mixed<Person_SalutationForm>().oneOf<Person_SalutationForm>(getSelectableSalutations(), 'Bitte wählen Sie eine Anrede aus'),
      otherwise: yup.mixed<Person_SalutationForm>().notRequired(),
    }),
    firstname: yup.mixed<string>().when(['isAuthenticated'], {
      is: false,
      then: yup.string().required('Bitte geben Sie Ihren Vornamen an.'),
      otherwise: yup.string().default('').notRequired(),
    }),
    lastname: yup.mixed<string>().when(['isAuthenticated'], {
      is: false,
      then: yup.string().required('Bitte geben Sie Ihren Nachnamen an.'),
      otherwise: yup.string().notRequired(),
    }),
    email: yup.mixed<string>().when(['isAuthenticated'], {
      is: false,
      then: yup.string().required('Bitte geben Sie Ihre E-Mail Adresse an.').email('Bitte geben Sie eine gültige E-Mail Adresse an.'),
      otherwise: yup.string().notRequired(),
    }),
    message: yup.string().required('Bitte geben Sie Ihre Nachricht an uns ein.').max(10000, 'Ihre Nachricht darf nicht mehr als 10000 Zeichen enthalten.'),
  });
}

const useStyles = makeStyles()(() => ({
  formControl: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      color: '#908c8c',
    },
  },
}));

export default ContactCard;
