import { gql, useQuery } from '@apollo/client';
import { SvgIcon, SvgIconProps } from '@enpxio/components';
import { FC } from 'react';

import { DivisionIcon } from './divisionIcon';

const QUERY = gql`
  query iconHeatPumpCollection {
    iconHeatPumpCollection(limit: 1) {
      items {
        image {
          url
        }
        alt
      }
    }
  }
`;

export const HeatPumpIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  const { data } = useQuery(QUERY);
  const iconUrl = data?.iconHeatPumpCollection?.items[0]?.image?.url;
  const altTxt = data?.iconHeatPumpCollection?.items[0]?.alt;

  if (iconUrl !== undefined) {
    return <DivisionIcon src={iconUrl} altText={altTxt} />;
  }

  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path d="M46 30.6a6.71 6.71 0 01-4.31 6.59 1 1 0 01-.6-1.91A4.68 4.68 0 0044 30.6a5.94 5.94 0 00-1.32-3.53l-2.34-2.87a7.32 7.32 0 01-1.8-4.83 6.71 6.71 0 014.31-6.59 1 1 0 11.6 1.91 4.68 4.68 0 00-2.9 4.68 5.4 5.4 0 001.28 3.49l2.4 2.91A7.87 7.87 0 0146 30.6zm-16.7-7.74a5.4 5.4 0 01-1.3-3.49 4.68 4.68 0 012.91-4.68 1 1 0 00-.6-1.91A6.71 6.71 0 0026 19.37a7.32 7.32 0 001.8 4.83l2.36 2.87a5.94 5.94 0 011.34 3.53 4.68 4.68 0 01-2.91 4.68l-.19.08a4.17 4.17 0 01-1.83.36h-12.3c-1.14-.06-1.3-.62-1.28-1v-1.86h4.21a3.74 3.74 0 003.23-3.12c.35-2.07 1.49-9.43 1.48-9.43a3.48 3.48 0 00-.54-2.76 2.6 2.6 0 00-2.1-1h-.6v-3.46a2.81 2.81 0 00-5.62 0v3.41h-2.11v-3.41a2.81 2.81 0 00-5.62 0v3.44h-.63a2.58 2.58 0 00-2.08 1 3.43 3.43 0 00-.56 2.68s.63 3.88 1.1 6.79a1 1 0 002-.31C4.65 23.77 4 19.91 4 19.83a1.54 1.54 0 01.19-1.07.59.59 0 01.51-.22h2.6v-5.45a.81.81 0 011.62 0v5.41H15v-5.41a.81.81 0 011.62 0v5.44h2.57a.62.62 0 01.54.22 1.59 1.59 0 01.17 1.15c0 .07-1.15 7.43-1.5 9.49a1.7 1.7 0 01-1.4 1.47H8.55a1 1 0 000 2H11v1.76a2.9 2.9 0 00.69 2 3.43 3.43 0 002.54 1.07h12.35a6.18 6.18 0 002.7-.56 6.73 6.73 0 004.22-6.53 7.87 7.87 0 00-1.8-4.83zm6.26 0a5.4 5.4 0 01-1.28-3.49 4.68 4.68 0 012.91-4.68 1 1 0 10-.6-1.91 6.71 6.71 0 00-4.31 6.59 7.32 7.32 0 001.8 4.83l2.36 2.87a5.94 5.94 0 011.32 3.53 4.68 4.68 0 01-2.91 4.68 1 1 0 00.6 1.91 6.71 6.71 0 004.31-6.59A7.87 7.87 0 0038 25.77z" />
    </SvgIcon>
  );
};
