import { ContractPreference } from '@enpowerx/apis/lib/communication/v2';

import { useCache } from '../../meters/hooks/useCache';
import { useAPI, useSelectedContract } from '~/providers';

interface ContractPreferencesState {
  contractPreference: ContractPreference | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useContractPreferences = (): ContractPreferencesState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data: communicationPreferences,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(
    selectedContract.id ? `contracts/${selectedContract.id}/communication_preference` : null,
    async () => (await api.communication.me.contracts.get(selectedContract.id).communicationPreference.read()) ?? []
  );

  return {
    contractPreference: communicationPreferences,
    error,
    isLoading,
    invalidate,
  };
};
