import { gql, useQuery } from '@apollo/client';
import { Box, Grid, Link, Typography, WideContainer, useAsyncEffect } from '@enpxio/components';
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { PageFlags } from '../../providers/pages/pages';
import { usePages } from '../../providers/pages/provider';
import { useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexShrink: 0,
    '& .MuiGrid-item': {
      paddingBottom: theme.namedSpacing('large', 'lg'),
      [theme.breakpoints.down('xl')]: {
        paddingBottom: theme.namedSpacing('small', 'lg'),
      },
    },
  },
  container: {
    padding: theme.namedSpacing('large', 'xl', 'zero', 'zero', 'zero'),
    [theme.breakpoints.down('xl')]: {
      padding: theme.namedSpacing('small', 'xl', 'zero', 'zero', 'zero'),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.namedSpacing('small', 'xl', 'xl', 'zero', 'xl'),
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '&:hover': { textDecoration: 'underline' },
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  subFooter: {
    display: 'block',
    minWidth: '280px',
    maxWidth: '1160px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  service: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  socialMediaIcon: {
    maxWidth: "40px",
    marginRight: "10px"
  },
  subFooterWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.namedSpacing('large', 'md'),
    [theme.breakpoints.down('xl')]: {
      padding: theme.namedSpacing('small', 'md'),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.namedSpacing('small', 'md'),
    },
  },
}));

const QUERY = gql`
  query footerCollectionQuery {
    footerCollection(limit: 1) {
      items {
        socialMediaElementsCollection {
          items {
            link
            icon {
              url
            }
          }
        }
        linksCollection {
          items {
            displayText
            link
          }
        }
      }
    }
  }
`;

interface FooterCollectionQueryResult {
  footerCollection: {
    items: [
      {
        linksCollection: {
          items: FooterLink[];
        };
        socialMediaElementsCollection: {
          items: SocialMediaElement[];
        };
      }
    ];
  };
}

interface FooterLink {
  __typename: string;
  displayText: string;
  link?: string;
}

interface SocialMediaElement {
  link?: string;
  icon?: SocialMediaIcon
}

interface SocialMediaIcon {
  url?: string;
}

const Menu: FC = () => {
  const { classes } = useStyles();
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="div">
          <Box color="primary.contrastText" fontWeight="bold">
            Menü
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.dashboard.absolutePathname} className={classes.link} component={RouterLink}>
            Startseite
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.invoicesAndPayment.absolutePathname} className={classes.link} component={RouterLink}>
            Rechnung und Zahlung
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.meters.absolutePathname} className={classes.link} component={RouterLink}>
            Zähler
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.contract.absolutePathname} className={classes.link} component={RouterLink}>
            Vertrag
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.myData.absolutePathname} className={classes.link} component={RouterLink}>
            Meine Daten
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Middle: FC = () => {
  const { classes } = useStyles();
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="div">
          <Box color="primary.contrastText" fontWeight="bold">
            Services
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.meters.absolutePathname} state={{ addReading: true }} className={classes.link} component={RouterLink}>
            Zählerstand eingeben
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.invoicesAndPayment.absolutePathname} state={{ changePayment: true }} className={classes.link} component={RouterLink}>
            Abschlag ändern
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.relocation.absolutePathname} className={classes.link} component={RouterLink}>
            Umzug mitteilen
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.myData.absolutePathname} className={classes.link} component={RouterLink}>
            Persönliche Daten ändern
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Service: FC = () => {
  const { classes } = useStyles();
  return (
    <Grid container justifyContent="flex-start" className={classes.service}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="div">
          <Box color="primary.contrastText" fontWeight="bold">
            Kundenservice
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" component="div">
          <Link to={PortalPages.contact.absolutePathname} className={classes.link} component={RouterLink}>
            Hilfe und Kontakt
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

interface SocialProps {
  loading: boolean
  elements: SocialMediaElement[]
}

const Social: FC<SocialProps> = ({loading,elements}) => {
  const { classes } = useStyles();
  return (
    <>  {loading || !elements || elements.length === 0 ? null : (
      <Grid container justifyContent="flex-start" className={classes.service}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="div">
            <Box color="primary.contrastText" fontWeight="bold">
              Folgen Sie uns
            </Box>
          </Typography>
        </Grid>
        {elements.map((element: SocialMediaElement) => {
            return (
                  <Link href={element.link} target="_blank" rel="noopener noreferrer" component="a">
                      <img src={element.icon?.url} className={classes.socialMediaIcon}></img>
                  </Link>
            );
          })}
      </Grid>)}
    </>
  );
};

const useSubFooterStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& $link:last-child': {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down(835)]: {
      justifyContent: 'center',
      '& $link:not(:last-child)': {
        paddingBottom: theme.namedSpacing('small', 'xl'),
      },
      '& $link:last-child': {
        marginLeft: 'inherit',
      },
    },
  },
  link: {
    paddingRight: theme.namedSpacing('large', 'xl'),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.namedSpacing('small', 'xl'),
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': { textDecoration: 'underline' },
    },
  },
}));

interface SubFooterProps {
  loading: boolean
  links: FooterLink[]
}

const SubFooter: FC<SubFooterProps> = ({ loading, links }) => {
  const { classes } = useSubFooterStyles();

  return (
    <>
      {loading ? null : (
        <div className={classes.root}>
          {links.map((footerLink: FooterLink, index: number) => {
            return (
              <div key={`subFooter-${index}`} className={classes.link}>
                <Typography variant="body2" component="div">
                  <Link href={footerLink.link} target="_blank" rel="noopener noreferrer" component="a">
                    {footerLink.displayText}
                  </Link>
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const Footer: FC = () => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const { activePage } = usePages();
  const { data, loading, refetch } = useQuery<FooterCollectionQueryResult>(QUERY, { fetchPolicy: 'cache-first' });
  const [links, setLinks] = useState<FooterLink[]>([]);
  const [socialMediaElements, setSocialMediaElements] = useState<SocialMediaElement[]>([]);

  // Todo: workaround for graphql client (see: https://github.com/apollographql/apollo-client/issues/6209#issuecomment-783073309)
  useAsyncEffect(async () => {
    await refetch();
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setLinks(data.footerCollection.items[0].linksCollection.items);
    setSocialMediaElements(data.footerCollection.items[0].socialMediaElementsCollection.items)
  }, [data]);

  return (
    <footer className={classes.root}>
      {selectedContract.id && !activePage?.hasFlag(PageFlags.AuthenticatedNoMenu) ? (
        <div className={classes.footer}>
          <WideContainer className={classes.container}>
            <Grid container justifyContent="flex-start" spacing={5}>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3} container justifyContent="flex-start" alignItems="flex-start">
                <Menu />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3} container justifyContent="flex-start" alignItems="flex-start">
                <Middle />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3} container justifyContent="flex-start" alignItems="flex-start">
                <Service />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3} container justifyContent="flex-start" alignItems="flex-start">
                <Social loading={loading} elements={socialMediaElements} />
              </Grid>
            </Grid>
          </WideContainer>
        </div>
      ) : null}
      <div className={classes.subFooterWrapper}>
        <WideContainer>
          <SubFooter links={links} loading={loading} />
        </WideContainer>
      </div>
    </footer>
  );
};

export default Footer;
