import { Theme, alpha as alphaFn, createTheme, darken, lighten } from '@mui/material';
import { green, red } from '@mui/material/colors';

export * from './types';

export type SpacingName = 'zero' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

export interface NamedSpacing {
  (type: SpacingType, name: SpacingName): number;

  (type: SpacingType, topBottom: SpacingName, rightLeft?: SpacingName): number;

  (type: SpacingType, top: SpacingName, rightLeft?: SpacingName, bottom?: SpacingName): number;

  (type: SpacingType, top: SpacingName, right?: SpacingName, bottom?: SpacingName, left?: SpacingName): number;
}

export function createMUIThemeFromCMS(config: any): Theme {
  const { primary, background, secondary, textPrimary, textSecondary, typography, marketingEntry, button } = config.theme;
  const { fontSize, h1, h2, body1, body2 } = typography;
  const spacingOptions = [0, 10, 15, 16, 20, 24, 28, 30, 32, 40, 48, 60, 64];
  const breakpointOptions = {
    values: {
      xs: 0,
      sm: 520,
      md: 720,
      lg: 1280,
      xl: 1920,
    },
  };

  let theme = createTheme({
    spacing: spacingOptions,
    palette: {
      primary: {
        main: primary?.main ?? '#E9EDC9',
        light: primary?.light ?? '#FEFAE0',
      },
      secondary: {
        main: secondary?.main ?? '#6B9AC4',
        light: secondary?.light ?? '#FFFFFF',
      },
      marketingEntry: {
        main: marketingEntry ?? '#FEFAE0',
      },
      error: red,
      success: green,
      // Used by `getContrastText()` to maximize the contrast between the background and
      // the text.
      contrastThreshold: 3,
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
      background: {
        default: background,
      },
    },
    breakpoints: breakpointOptions,
  });

  theme = createTheme(theme, {
    typography: {
      fontSize,
      fontFamily: "Roboto",
      h1: {
        fontWeight: h1?.fontWeight ? h1?.fontWeight : 'bold',
        fontSize: h1?.fontSize,
        lineHeight: h1?.lineHeight,
        [theme.breakpoints.down('sm')]: {
          fontSize: h1?.small?.fontSize,
          lineHeight: h1?.small?.lineHeight,
        },
      },
      h2: {
        fontWeight: h2?.fontWeight ? h2?.fontWeight : 'bold',
        fontSize: h2?.fontSize,
        lineHeight: h2?.lineHeight,
        [theme.breakpoints.down('sm')]: {
          fontSize: h2?.small?.fontSize,
          lineHeight: h2?.small?.lineHeight,
        },
      },
      body1: {
        fontSize: body1?.fontSize,
        lineHeight: body1?.lineHeight,
        [theme.breakpoints.down('sm')]: {
          fontSize: body1?.small?.fontSize,
          lineHeight: body1?.small?.lineHeight,
        },
      },
      body2: {
        fontSize: body2?.fontSize,
        lineHeight: body2?.lineHeight,
        [theme.breakpoints.down('sm')]: {
          fontSize: body2?.small?.fontSize,
          lineHeight: body2?.small?.lineHeight,
        },
      },
    },
    // props: {
    //   MuiDialog: {
    //     PaperProps: {
    //       square: true,
    //     },
    //   },
    //   MuiLink: {
    //     color: 'secondary',
    //   },
    //   MuiSkeleton: {
    //     animation: 'wave',
    //   },
    //   MuiDialogContentText: {
    //     color: 'textPrimary',
    //   },
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            '.swiper-pagination-bullet-active': {
              backgroundColor: secondary ? secondary?.main : '#6B9AC4',
              opacity: 1,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            color: textPrimary,
            padding: theme.spacing(1),
            '& .MuiAlert-icon': {
              color: textPrimary,
              fontSize: '32px',
              width: '32px',
            },
            '& .MuiAlertTitle-root': {
              fontWeight: 'bold',
            },
          },
          message: {
            padding: '10px 0',
          },
          standardInfo: {
            color: textPrimary,
            backgroundColor: '#F3F3F3',
            '& .MuiAlert-icon': {
              color: textPrimary,
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            minHeight: '42px',
            [theme.breakpoints.up('sm')]: {
              minHeight: '42px',
            },
            [theme.breakpoints.down('md')]: {
              minHeight: '48px',
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: `${alphaFn('#D8D8D8', 0.7)}`,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              fontSize: '14px',
            },
            fontSize: '18px',
            textTransform: 'none',
          },
          outlined: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              fontSize: '14px',
            },
            border: button?.border ?? '1px solid',
            borderRadius: button?.borderRadius ?? '4px',
            fontSize: '18px',
            textTransform: 'none',
            '&:hover': {
              color: button?.hover?.color ?? secondary?.main,
              boxShadow: button?.hover?.boxShadow ?? '1px solid',
              backgroundColor: button?.hover?.backgroundColor ?? 'rgba(107, 154, 196, 0.04)',
              border: button?.border ?? '1px solid',
              transition: button?.hover?.transition ?? 'none',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 600,
          },
          secondary: {
            color: textPrimary,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('md')]: {
              fontSize: '0.8rem',
              padding: '4px',
              minWidth: 90,
            },
            textTransform: 'none',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paperWidthMd': {
              [theme.breakpoints.up('md')]: {
                maxWidth: '960px',
              },
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
              padding: theme.spacing(5),
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingBottom: '0px',
            '&:last-child': {
              paddingBottom: 0,
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.23)',
            '&.Mui-checked': {
              color: secondary ? secondary?.main : '#6B9AC4',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          },
          input: {
            paddingLeft: '20px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: textPrimary,
            '&.Mui-focused': {
              color: textPrimary,
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: textPrimary,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: secondary ? secondary?.main : '#6B9AC4',
            },
            '&.Mui-completed': {
              color: secondary ? secondary?.main : '#6B9AC4',
            },
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          paper: {
            boxShadow: 'none',
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            wordBreak: 'break-word',
          },
        },
      },
    },
  });
  extendTheme(theme);
  return theme;
}

export function extendTheme(theme: Theme): void {
  theme.namedSpacing = namedSpacing;
  extendPaletteBackground(theme);
  extendPaletteText(theme);
}

function extendPaletteBackground(theme: Theme): void {
  defineTonalProperty(theme, theme.palette.background, 'default', 'defaultDark', 'darken');
  defineTonalProperty(theme, theme.palette.background, 'default', 'defaultLight', 'lighten');
}

function defineTonalProperty<T, This extends T & Record<string, string>, K extends keyof T & string>(
  theme: Theme,
  target: T,
  source: K,
  property: keyof T,
  mode: 'darken' | 'lighten'
): void {
  defineProperty(target, property, {
    get: function (this: This): string {
      const value = this[source] as string;
      if (typeof theme.palette.tonalOffset === 'number') {
        return mode === 'darken' ? darken(value, theme.palette.tonalOffset) : lighten(value, theme.palette.tonalOffset);
      }

      return mode === 'darken' ? darken(value, theme.palette.tonalOffset.dark) : lighten(value, theme.palette.tonalOffset.light);
    },
  });
}

function extendPaletteText(theme: Theme): void {
  defineFadeProperty(theme.palette.text, 'primary', 'primary80', 0.8);
  defineFadeProperty(theme.palette.text, 'primary', 'primary60', 0.6);
  defineFadeProperty(theme.palette.text, 'primary', 'primary40', 0.4);
  defineFadeProperty(theme.palette.text, 'primary', 'primary25', 0.25);
}

function defineFadeProperty<T, This extends T & Record<string, string>, K extends keyof T & string>(
  target: T,
  source: K,
  property: keyof T,
  alpha: number
): void {
  defineProperty(target, property, {
    get: function (this: This): string {
      const sourceValue = this[source] as string;
      return alphaFn(sourceValue, alpha);
    },
  });
}

function defineProperty<T>(target: T, property: keyof T, attributes: PropertyDescriptor & ThisType<T>): void {
  if (property in target) {
    return;
  }

  Object.defineProperty(target, property, attributes);
}

type SKM<T extends string, V> = { [k in T]: V };
type SpacingType = 'small' | 'large';

const spaceMapping: SKM<SpacingName, SKM<SpacingType, number>> = {
  zero: {
    small: 0,
    large: 0,
  },
  sm: {
    small: 1,
    large: 3,
  },
  md: {
    small: 2,
    large: 5,
  },
  lg: {
    small: 4,
    large: 6,
  },
  xl: {
    small: 7,
    large: 8,
  },
  xxl: {
    small: 9,
    large: 10,
  },
  xxxl: {
    small: 11,
    large: 12,
  },
};

function namedSpacing(this: Theme, type: SpacingType, name: SpacingName): number;
function namedSpacing(this: Theme, type: SpacingType, topBottom: SpacingName, rightLeft?: SpacingName): string;
function namedSpacing(this: Theme, type: SpacingType, top: SpacingName, rightLeft?: SpacingName, bottom?: SpacingName): string;
function namedSpacing(this: Theme, type: SpacingType, top: SpacingName, right?: SpacingName, bottom?: SpacingName, left?: SpacingName): string;
function namedSpacing(this: Theme, type: SpacingType, ...args: Array<SpacingName | undefined>): number | string {
  switch (args.length) {
    case 1:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.spacing(spaceMapping[args[0]!][type]);
    case 2:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.spacing(spaceMapping[args[0]!][type], spaceMapping[args[1]!][type]);
    case 3:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.spacing(spaceMapping[args[0]!][type], spaceMapping[args[1]!][type], spaceMapping[args[2]!][type]);
    case 4:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.spacing(spaceMapping[args[0]!][type], spaceMapping[args[1]!][type], spaceMapping[args[2]!][type], spaceMapping[args[3]!][type]);
    default:
      throw new Error('invalid argument');
  }
}

export type DataTableClassKey = 'root' | 'paper';
export type DataTableBodyCellClassKey = 'root';

declare module '@mui/material/styles/overrides' {
  interface ComponentNameToClassKey {
    MUIDataTable: DataTableClassKey;
    MUIDataTableBodyCell: DataTableBodyCellClassKey;
  }
}
