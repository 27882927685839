import { Tariff } from '@enpowerx/apis/lib/market/v2';
import { CustomerType, EnergyType } from '@enpowerx/apis/lib/types';
import { PageView } from '@enpxio/components';
import { Alert, CircularProgress, Grid, Theme } from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { useAPI } from '../../providers/api';
import { useSelectedContract } from '../../providers/selectedContract';
import { ProductCard } from '~/tariffChange/productCard';

type FormStepNewContractProps = PropsWithChildren<{
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  isEmptyMessage?: string | ReactNode;
  withTarifChange?: boolean;
  disableNext: (disable: boolean) => void;
}>;

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    [theme.breakpoints.up('xs')]: {
      paddingRight: '40px',
    },
  },
  error: {
    fontSize: '2em',
    color: 'red',
  },
}));

export const FormStepNewContract: FC<FormStepNewContractProps> = (props: FormStepNewContractProps) => {
  const { values, initialValues, handleChange, errors, setFieldValue, setFieldTouched, setFieldError, isSubmitting } = useFormikContext();
  const { disableNext } = props;
  const [abortSignal, setAbortSignal] = useState<AbortSignal | undefined>();
  const { selectedContract } = useSelectedContract();
  const api = useAPI();

  const { classes } = useStyles();
  const [products, setProducts] = useState<ReactNode[]>([<CircularProgress key="loading-spinner" />]);
  const [selectedTariff, setSelectedTariff] = useState<Tariff>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  if (!values.postalCode) {
    navigate(PortalPages.relocation.absolutePathname);
  }

  const selectProductCallback = (tariff: Tariff): void => {
    values.newTariff = tariff;
    setSelectedTariff(tariff);
  };

  const cancelSelection = (): void => {
    values.newTariff = undefined;
    setSelectedTariff(undefined);
  };

  /**
 * {values.street} {values.houseNumber} <br />
    {values.postalCode} {values.city}
 */

  async function fetchProducts(abortSignal: AbortSignal | undefined): Promise<void> {
    try {
      const tariffList = await api.region.postalCodes
        .get(values.postalCode ?? '')
        .cities.get(values.city ?? '')
        .tariffs(selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED)
        .list(
          selectedContract.delivery?.customerType ?? CustomerType.PRIVATE,
          values.street ?? '',
          values.houseNumber ?? '',
          selectedContract.delivery?.annualUsage,
          undefined,
          undefined,
          abortSignal
        );
      const availableProducts = await api.currentContract.products.list();

      if (tariffList.tariffs.length === 0) {
        enqueueSnackbar('Tarife konnten nicht abgerufen werden', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Tarife wurden geladen', {
          variant: 'info',
        });
      }

      setProducts(
        tariffList.tariffs
          .filter((element: Tariff) => {
            return availableProducts.products.some((product) => product.id === element.product?.id);
          })
          .map((tariff) => {
            return (
              <Grid xs={12} sm={6} item key={tariff.product?.id}>
                <ProductCard
                  key={tariff.product?.id}
                  energyType={selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED}
                  tariff={tariff}
                  completion={selectProductCallback}
                  buttonText="Auswählen"
                />
              </Grid>
            );
          })
      );
    } catch (error: unknown) {
      console.error(error);
      setProducts([
        <Alert key="fetch-failure" variant="filled" severity="error">
          Da scheint was schiefgelaufen zu sein, probieren Sie es bitte später erneut!
        </Alert>,
      ]);
    }
  }

  useEffect(() => {
    const fetchData = async (abortSignal: AbortSignal | undefined) => {
      const tariffList = await api.region.postalCodes
        .get(values.postalCode ?? '')
        .cities.get(values.city ?? '')
        .tariffs(selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED)
        .list(
          selectedContract.delivery?.customerType ?? CustomerType.PRIVATE,
          values.street ?? '',
          values.houseNumber ?? '',
          selectedContract.delivery?.annualUsage,
          undefined,
          undefined,
          abortSignal
        );
      const availableProducts = await api.currentContract.products.list();

      if (tariffList.tariffs.length === 0) {
        enqueueSnackbar('Tarife konnten nicht abgerufen werden', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Tarife wurden geladen', {
          variant: 'info',
        });
      }

      setProducts(
        tariffList.tariffs
          .filter((element: Tariff) => {
            return availableProducts.products.some((product) => product.id === element.product?.id);
          })
          .map((tariff) => {
            return (
              <Grid xs={12} sm={6} item key={tariff.product?.id}>
                <ProductCard
                  key={tariff.product?.id}
                  energyType={selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED}
                  tariff={tariff}
                  completion={selectProductCallback}
                  buttonText="Auswählen"
                />
              </Grid>
            );
          })
      );
    };

    fetchData(abortSignal).catch((error: unknown) => {
      console.error(error);
      setProducts([
        <Alert key="fetch-failure" variant="filled" severity="error">
          Da scheint was schiefgelaufen zu sein, probieren Sie es bitte später erneut oder wenden Sie sich an das Kundencenter.
        </Alert>,
      ]);
    });
  }, []);

  return (
    <PageView
      title="Wählen Sie Ihren neuen Tarif"
      subTitle="Ihr Wechsel ist einfach und sicher. Wählen Sie jetzt Ihren passenden Tarif aus und bestellen Sie gleich. Hier"
      wide
    >
      <Field name="newTariff" hidden />
      <ErrorMessage name="newTariff" component="div" className={classes.error} />
      <Grid container justifyContent="center" className={classes.formControl} spacing={2}>
        {selectedTariff?.product?.id ? (
          <Grid xs={12} sm={6} item>
            <ProductCard
              key={selectedTariff.product?.id}
              energyType={selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED}
              tariff={selectedTariff}
              completion={cancelSelection}
              buttonText="Auswahl aufheben"
            />
          </Grid>
        ) : (
          products
        )}
      </Grid>
    </PageView>
  );
};
