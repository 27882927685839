import { Contract, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Typography } from '@enpxio/components';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'EpxContractBadge' })((theme) => ({
  root: {
    display: 'inline-block',
    backgroundColor: theme.palette.secondary.main,
    padding: '5px 12px 5px 12px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 'small',
    color: theme.palette.text.secondary,
  },
}));

interface ContractBadgeProps {
  contract: Contract | undefined;
  exclude?: Contract_State[];
  className?: string;
}

export const ContractBadge: FC<ContractBadgeProps> = (props: ContractBadgeProps) => {
  const { classes, cx } = useStyles();
  const [contractState, setContractState] = useState('');

  useEffect(() => {
    if (!props.contract || props.exclude?.find((state) => props.contract?.state === state)) {
      setContractState('');
      return;
    }

    switch (props.contract.state) {
      case Contract_State.ACTIVE:
        setContractState('Aktiv');
        break;

      case Contract_State.OPEN:
      case Contract_State.TERMINATING_PREVIOUS_PROVIDER:
      case Contract_State.WAITING_FOR_NETWORK_PROVIDER:
      case Contract_State.WAITING_FOR_SUPPLY:
        setContractState('Neu');
        break;

      case Contract_State.TERMINATION_REQUESTED:
        setContractState('Gekündigt');
        break;

      case Contract_State.TERMINATED:
        setContractState('Beendet');
        break;
    }
  }, [props.contract, props.exclude]);

  return !contractState ? null : (
    <div className={cx(classes.root, props.className)}>
      <Typography variant="body2" className={classes.text}>
        {contractState}
      </Typography>
    </div>
  );
};
