import { CustomerPreference_Payload } from '@enpowerx/apis/lib/communication/v2';
import { Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Alert, Button, CardActions, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useSelectedContract } from '../../../providers';
import { ConsentData } from './advertisingConsentData';
import { KeyValueView } from '~/keyValueView';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '640px',
  },
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginTop: 0,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    paddingLeft: '16px',
  },
}));

export interface InfoModeProps {
  data: ConsentData;
  preferences: CustomerPreference_Payload;
  setEdit: (edit: boolean) => void;
}

const InfoMode: FC<InfoModeProps> = (props: InfoModeProps) => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const { data, preferences } = props;
  const { marketing } = preferences;
  const noPreferencesSet = !marketing?.channels?.length && !marketing?.allowedTopics?.length;

  return (
    <>
      <PageViewCardTitle
        title={
          <Typography variant="h2" component="div">
            Meine aktuelle Werbeeinwilligung
          </Typography>
        }
      />
      <KeyValueView
        className={classes.keyValueView}
        entries={[
          {
            key: 'Werbezustimmung',
            value: (
              <Typography component="p" className={classes.cardContent}>
                {noPreferencesSet ? data.viewTextNoConsent : preferences.consentText}
              </Typography>
            ),
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => !preferences.consentText && !noPreferencesSet,
            },
          },
        ]}
      />
      <CardActions disableSpacing style={{ justifyContent: 'center' }}>
        {selectedContract.state !== Contract_State.TERMINATED ? (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              props.setEdit(true);
            }}
          >
            Werbeeinwilligung ändern
          </Button>
        ) : (
          <Alert key="fetch-failure" variant="filled" severity="info">
            Der Vertrag ist beendet.
          </Alert>
        )}
      </CardActions>
    </>
  );
};

export default InfoMode;
