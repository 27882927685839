import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useAsyncEffect, useConfig } from '@enpxio/components';
import { InfoOutlined } from '@mui/icons-material';
import { FC, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

interface Stage {
  stageNameShort: string;
  stageNameLong: string;
  helpText: ReactNode;
}

export const StageRibbon: FC = () => {
  const { classes, cx } = useStyles();
  const [hidden, setHidden] = useState(true);
  const [stage, setStage] = useState<Stage | undefined>();
  const [ribbonColor, setRibbonColor] = useState('');
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const config = useConfig();

  useAsyncEffect(async (_, isMounted) => {
    if (!isMounted.current) {
      return;
    }

    if (window.location.hostname === 'localhost') {
      config.stage = 'local';
    }

    switch (config.stage) {
      case 'local':
        setStage(localStage);
        setRibbonColor(classes.ribbonGray);
        setHidden(false);
        break;
      case 'dev':
        setStage(devStage);
        setRibbonColor(classes.ribbonOrange);
        setHidden(false);
        break;
      case 'test':
        setStage(testStage);
        setRibbonColor(classes.ribbonRed);
        setHidden(false);
        break;
    }
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <div className={cx(classes.ribbon, classes.ribbonTopLeft, classes.ribbonSticky, ribbonColor)}>
      <div>
        <span className={classes.ribbonInfo}>
          {stage?.stageNameShort}
          <InfoOutlined
            className={classes.ribbonInfoButton}
            onClick={(): void => {
              setHelpDialogOpen(true);
            }}
          />
        </span>
      </div>
      <Dialog
        open={helpDialogOpen}
        onClose={() => {
          setHelpDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Definition of <strong>{stage?.stageNameLong}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{stage?.helpText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              setHelpDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const localStage: Stage = {
  stageNameShort: 'local dev',
  stageNameLong: 'Local Development',
  helpText: (
    <span>
      This site is running locally. <br />
      <br />
      Happy coding!
      <br />
      <img alt="happy coding" src="https://media.giphy.com/media/yYSSBtDgbbRzq/giphy.gif" style={{ width: '100%' }} />
    </span>
  ),
};

const devStage: Stage = {
  stageNameShort: 'dev stage',
  stageNameLong: 'Development Stage',
  helpText: (
    <span>
      This stage is heavily used by developers to test their latest changes.
      <br />
      Assets running on this stage have no guarantee to work. <br />
      They can break at any time.
      <br />
      <br />
      <strong>
        Showing demos on this stage is not allowed!
        <br />
        Use production stage instead.
      </strong>
    </span>
  ),
};

const testStage: Stage = {
  stageNameShort: 'test stage',
  stageNameLong: 'Test Stage',
  helpText: (
    <span>
      This stage allows human testers to exercise new and changed code via either automated checks or non-automated techniques. <br />
      <br />
      <strong>
        Showing demos on this stage is not recommended!
        <br />
        Use production stage instead.
      </strong>
    </span>
  ),
};

const useStyles = makeStyles()(() => ({
  ribbon: {
    width: '160px',
    height: '150px',
    overflow: 'hidden',
    position: 'absolute',
    opacity: 0.8,
    zIndex: 2000,
    pointerEvents: 'none',
    '& ::before, ::after': {
      position: 'absolute',
      zIndex: -1,
      content: '""',
      display: 'block',
      border: '5px solid #2980b9',
    },
    '& div': {
      position: 'absolute',
      display: 'block',
      width: '240px',
      padding: '15px 0',
      backgroundColor: '#3498db',
      boxShadow: '0 5px 10px rgba(0, 0, 0, .1)',
      color: '#fff',
      font: "700 17px/1 'Lato', sans-serif",
      textShadow: '0 1px 1px rgba(0, 0, 0, .2)',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  },
  ribbonInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ribbonInfoButton: {
    paddingLeft: '5px',
    pointerEvents: 'visible',
    cursor: 'pointer',
  },
  ribbonSticky: {
    position: 'fixed',
  },
  // Colors
  ribbonRed: {
    '& div': {
      backgroundColor: '#e43',
    },
  },
  ribbonYellow: {
    '& div': {
      backgroundColor: '#ec0',
      color: '#000',
    },
  },
  ribbonOrange: {
    '& div': {
      backgroundColor: '#e82',
    },
  },
  ribbonGray: {
    '& div': {
      backgroundColor: '#999',
    },
  },

  // Orientations
  ribbonTopLeft: {
    top: '-10px',
    left: '-10px',
    '& ::before, ::after': {
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
    },
    '& ::before': {
      top: 0,
      right: 0,
    },
    '& ::after': {
      bottom: 0,
      left: 0,
    },
    '& div': {
      right: '-25px',
      top: '30px',
      transform: 'rotate(-45deg)',
    },
  },

  ribbonTopRight: {
    top: '-10px',
    right: '-10px',
    '& ::before, ::after': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
    },
    '& ::before': {
      top: 0,
      left: 0,
    },
    '& ::after': {
      bottom: 0,
      right: 0,
    },
    '& div': {
      left: '-25px',
      top: '30px',
      transform: 'rotate(45deg)',
    },
  },

  ribbonBottomLeft: {
    bottom: '-10px',
    left: '-10px',
    '& ::before, ::after': {
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
    },
    '& ::before': {
      bottom: 0,
      right: 0,
    },
    '& ::after': {
      top: 0,
      left: 0,
    },
    '& div': {
      right: '-25px',
      top: '30px',
      transform: 'rotate(225deg)',
    },
    '& div span': {
      transform: 'rotate(180deg)',
      display: 'inline-block',
    },
  },

  ribbonBottomRight: {
    top: '-10px',
    right: '-10px',
    '& ::before, ::after': {
      borderBottomColor: 'transparent',
      borderRightColor: 'transparent',
    },
    '& ::before': {
      bottom: 0,
      left: 0,
    },
    '& ::after': {
      top: 0,
      right: 0,
    },
    '& div': {
      left: '-25px',
      bottom: '30px',
      transform: 'rotate(-225deg)',
    },
    '& div span': {
      transform: 'rotate(180deg)',
      display: 'inline-block',
    },
  },
}));
