import { Link } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  div: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    fontSize: '0.75rem',
    color: '#908c8c',
    textAlign: 'center',
  },
}));

export const RecaptchaNotice: FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.div}>
      <span className={classes.text}>
        This site is protected by reCAPTCHA and the Google&nbsp;
        <Link href="https://policies.google.com/privacy" target="_blank" color="secondary">
          Privacy Policy
        </Link>{' '}
        and&nbsp;
        <Link href="https://policies.google.com/terms" target="_blank" color="secondary">
          Terms of Service
        </Link>{' '}
        apply.
      </span>
    </div>
  );
};
