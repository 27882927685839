import { Contract, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { EnergyDelivery_UsageType } from '@enpowerx/apis/lib/types';
import {
  Accordion,
  AccordionSummary,
  Box,
  Hidden,
  PageView,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@enpxio/components';
import { ContractNameLocaleFormatter } from '@enpxio/formatters';
import { ArrowForwardIos } from '@mui/icons-material';
import { sortBy, filter, groupBy } from 'lodash-es';
import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { ContractTypeIcon } from '../../svg/contractTypeIcon';
import { ContractBadge } from '../components/contractBadge';
import { ContractDeliveryAddress } from '../components/contractDeliveryAddress';
import { useAuth0, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  groupHeader: {
    padding: theme.namedSpacing('small', 'sm', 'md', 'sm', 'md'),
    [theme.breakpoints.up('lg')]: {
      padding: theme.namedSpacing('large', 'sm', 'md', 'sm', 'md'),
    },
    marginBottom: '5px',
  },
  arrowColor: {
    color: theme.palette.secondary.main,
    marginRight: '15px',
  },
  badge: {
    position: 'absolute',
    top: '11px',
    [theme.breakpoints.down('sm')]: {
      right: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      right: '40px',
    },
  },
  tableRow: {
    padding: '0px 5px 24px 5px',
    borderBottom: '0px',
  },
  meterpointCell: {
    borderBottom: '0px',
    paddingLeft: theme.namedSpacing('small', 'xxl'),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.namedSpacing('large', 'xxl'),
    },
    paddingRight: '0px',
  },
  tableCell: {
    borderBottom: '0px',
    padding: theme.namedSpacing('large', 'zero', 'zero', 'zero', 'md'),
  },
  terminatedContract: {
    '& .contractIcon, tr:nth-of-type(1) td:nth-of-type(1), tr:nth-of-type(n+2) td': {
      opacity: 0.4,
    },
  },
}));

type ContractSelectionProps = PropsWithChildren<{
  enabled: boolean;
}>;

function sortContractsByStateAndEnergyType(contracts: Contract[]): Contract[] {
  return sortBy(
    contracts,
    (contract) => {
      switch (contract.state) {
        case Contract_State.OPEN:
        case Contract_State.TERMINATING_PREVIOUS_PROVIDER:
        case Contract_State.WAITING_FOR_NETWORK_PROVIDER:
        case Contract_State.WAITING_FOR_SUPPLY:
          return 0;
        case Contract_State.ACTIVE:
          return 1;
        case Contract_State.TERMINATION_REQUESTED:
          return 2;
        case Contract_State.TERMINATED:
          return 3;
      }
    },
    (contract) => contract.delivery?.energyType
  );
}

function filterCancelledContracts(contracts: Contract[]): Contract[] {
  return filter(contracts, (contract) => contract.state != Contract_State.CANCELLED);
}

function groupContractsByAddress(contracts: Contract[]) {
  return groupBy(contracts, (contract) => JSON.stringify(contract.delivery?.address));
}

export const ContractSelection: FC<ContractSelectionProps> = (props) => {
  if (!props.enabled) {
    return <>{props.children}</>;
  }

  const { classes } = useStyles();
  const { isAuthenticated } = useAuth0();
  const { isLoading, selectedContract, availableContracts, setSelectedContract } = useSelectedContract();
  const [entries, setEntries] = useState<ReactElement[]>([]);

  useEffect(() => {
    const entries: ReactElement[] = [];
    const groupedContracts = groupContractsByAddress(sortContractsByStateAndEnergyType(filterCancelledContracts(availableContracts)));
    Object.entries(groupedContracts).forEach(([, contracts], index) => {
      entries.push(
        <Paper
          key={`contract_group_${index}`}
          elevation={0}
          square
          sx={{
            padding: (theme) => theme.namedSpacing('large', 'sm', 'md', 'sm', 'md'),
            marginBottom: '5px',
          }}
        >
          <Typography component="h3">
            <strong>
              <ContractDeliveryAddress contract={contracts[0]} />
            </strong>
          </Typography>
        </Paper>
      );

      contracts.forEach((contract) => {
        let contractNameSuffix: string;
        switch (contract.delivery?.usageType) {
          case EnergyDelivery_UsageType.STORAGE_HEATER:
          case EnergyDelivery_UsageType.HEAT_PUMP:
            contractNameSuffix = ' - Wärme';
            break;
          case EnergyDelivery_UsageType.CHARGING_CURRENT:
            contractNameSuffix = ' - Ladestrom';
            break;
          default:
            contractNameSuffix = '';
        }

        entries.push(
          <StyledAccordion
            key={contract.id}
            square
            expanded={false}
            onChange={() => {
              setSelectedContract(contract);
            }}
            className={contract.state === Contract_State.TERMINATED ? classes.terminatedContract : ''}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ArrowForwardIos sx={{ color: (theme) => theme.palette.secondary.main, marginRight: '15px' }} />}>
              <div className="contractIcon">
                <ContractTypeIcon isLoading={false} contract={contract} />
              </div>
              <div>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow key={`${contract.id}_state`} sx={{ padding: '0px 5px 24px 5px', borderBottom: '0px' }}>
                        <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                          <Typography variant="body1" component="div">
                            <Box fontWeight="bold">
                              <ContractNameLocaleFormatter energyType={contract.delivery?.energyType} suffix={contractNameSuffix} />
                            </Box>
                          </Typography>
                        </TableCell>
                        <Hidden smDown>
                          <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                            <ContractBadge contract={contract} exclude={[Contract_State.ACTIVE]} className={classes.badge} />
                          </TableCell>
                        </Hidden>
                      </TableRow>
                      <TableRow key={`${contract.id}_info`} className={classes.tableRow} sx={{ padding: '0px 5px 24px 5px', borderBottom: '0px' }}>
                        <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                          <Typography variant="body1" component="div">
                            <Box fontWeight="bold">Vertrag:</Box>
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                          <Typography variant="body1">{contract.id}</Typography>
                        </TableCell>
                        <Hidden smDown>
                          <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                            <Typography variant="body1" component="div">
                              <Box fontWeight="bold">Zähler:</Box>
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                            <Typography variant="body1">{contract.meterNumber}</Typography>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                      <Hidden smUp>
                        <TableRow key={`${contract.id}_info`} className={classes.tableRow} sx={{ padding: '0px 5px 24px 5px', borderBottom: '0px' }}>
                          <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                            <Typography variant="body1" component="div">
                              <Box fontWeight="bold">Zähler:</Box>
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: '0px', padding: (theme) => theme.namedSpacing('large', 'zero', 'zero', 'sm', 'md') }}>
                            <Typography variant="body1">{contract.meterNumber}</Typography>
                          </TableCell>
                        </TableRow>
                      </Hidden>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Hidden smUp>
                <ContractBadge contract={contract} exclude={[Contract_State.ACTIVE]} className={classes.badge} />
              </Hidden>
            </AccordionSummary>
          </StyledAccordion>
        );
      });
    });
    setEntries(entries);
  }, [availableContracts, props.enabled]);

  if (isLoading) {
    return null;
  }

  if(entries.length === 0) {
    return <>{props.children}</>
  }

  return !isAuthenticated || !props.enabled || (isAuthenticated && selectedContract.id) || !availableContracts ? (
    <>{props.children}</>
  ) : (
    <PageView title="Vertragsauswahl" subTitle="Wählen Sie aus, welchen Vertrag Sie sehen möchten.">
      {entries}
    </PageView>
  );
};

const StyledAccordion = withStyles(Accordion, (theme) => ({
  root: {
    marginBottom: theme.namedSpacing('small', 'sm'),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.namedSpacing('large', 'sm'),
    },
    '&&&:before': {
      height: '0px',
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.namedSpacing('small', 'md', 'xl', 'md', 'xl'),
      [theme.breakpoints.up('lg')]: {
        margin: theme.namedSpacing('large', 'md', 'zero', 'md', 'xl'),
      },
    },
    '& .MuiAccordionSummary-expandIcon': {
      paddingRight: 0,
      marginRight: theme.namedSpacing('small', 'md'),
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.namedSpacing('large', 'md'),
      },
    },
  },
}));
