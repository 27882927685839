import { Button, Card, CardActions, CardContent, Divider, Modal, PageViewCard, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { KeyValueView } from '~/keyValueView';
import { useAPI, useAuth0 } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  value: {
    width: 200,
  },
  optional: {
    fontSize: 14,
  },
  buttonPadding: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
  },
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
  popup: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 4),
  },
  reduceWidth: {
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
  },
  cardDivider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
  },
}));

const LoginData: FC = () => {
  const { classes } = useStyles();
  const { user } = useAuth0();
  return (
    <PageViewCard>
      <PageViewCardTitle
        title={
          <Typography variant="h2" component="div">
            Meine Zugangsdaten
          </Typography>
        }
      />
      <KeyValueView
        className={classes.keyValueView}
        entries={[
          {
            key: 'E-Mail-Adresse',
            value: <span>{user.email}</span>,
            alternativeValues: {
              value: <Skeleton width={200} />,
              condition: () => !user.email,
            },
          },
          {
            key: 'Passwort',
            value: <ChangePassword />,
          },
          {
            key: 'Zugang löschen',
            value: <DeleteAccount />,
          },
        ]}
      />
    </PageViewCard>
  );
};

const DeleteAccount: FC = () => {
  const [open, setPopupOpen] = useState(false);
  const { classes } = useStyles();

  return (
    <>
      <DeleteAccountModal
        open={open}
        close={() => {
          setPopupOpen(false);
        }}
      />
      <Typography component="p" className={classes.reduceWidth}>
        Hier können Sie Ihren Zugang zum Kundenportal löschen. Bitte beachten Sie, dass Sie damit keine Kündigung Ihres Vertrages auslösen.
      </Typography>
      <Button
        className={classes.buttonPadding}
        color="secondary"
        variant="outlined"
        onClick={() => {
          setPopupOpen(true);
        }}
      >
        Zugang löschen
      </Button>
    </>
  );
};

const ChangePassword: FC = () => {
  const [open, setPopupOpen] = useState(false);
  const { classes } = useStyles();

  return (
    <>
      <ChangePasswordModal
        open={open}
        close={() => {
          setPopupOpen(false);
        }}
      />
      <Typography component="div">• • • • • • • • • • • •</Typography>
      <Button
        className={classes.buttonPadding}
        color="secondary"
        variant="outlined"
        onClick={() => {
          setPopupOpen(true);
        }}
      >
        Passwort ändern
      </Button>
    </>
  );
};

interface ModalProps {
  open: boolean;
  close: () => void;
}

const ChangePasswordModal: FC<ModalProps> = (props: ModalProps) => {
  const { open, close } = props;
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  async function confirmPasswordChange(): Promise<void> {
    try {
      await api.identities.me.user.sendPasswordResetEmail.invoke();
      enqueueSnackbar('Das hat geklappt. Bitte Überprüfen Sie Ihre Mails.', {
        variant: 'success',
      });
      close();
    } catch {
      close();
      enqueueSnackbar('Die Passwortänderung konnte nicht gestartet werden. Versuchen Sie es später erneut.', {
        variant: 'error',
      });
    }
  }

  return (
    <Modal open={open} onClose={close} disableAutoFocus disableEnforceFocus>
      <Card className={classes.popup}>
        <CardContent>
          <Typography variant="h2">Passwort Ändern</Typography>
          <Divider className={classes.cardDivider} />
          <Typography variant="body1">
            Wenn Sie diesen Dialog bestätigen, senden wir Ihnen eine E-Mail zu. Folgen Sie den Anweisungen in der E-Mail, um Ihr Passwort zu ändern.
          </Typography>
        </CardContent>
        <CardActions className={classes.buttonPadding}>
          <Button size="small" color="secondary" onClick={confirmPasswordChange} variant="outlined">
            Passwort ändern
          </Button>
          <Button size="small" color="secondary" onClick={close} variant="outlined">
            Abbrechen
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

const DeleteAccountModal: FC<ModalProps> = (props: ModalProps) => {
  const { open, close } = props;
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();
  const { logout } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const api = useAPI();

  useEffect(() => {
    const abortController = new AbortController();
    setAbortSignal(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  async function confirmDeletion(): Promise<void> {
    try {
      await api.identities.me.user.delete(abortSignal);
      close();
      await logout({
        returnTo: window.location.origin,
      });
    } catch {
      close();
      enqueueSnackbar('Ihr Zugang konnte nicht gelöscht werden. Versuchen Sie es später erneut.', {
        variant: 'error',
      });
    }
  }

  return (
    <Modal open={open} onClose={close} disableAutoFocus disableEnforceFocus>
      <Card className={classes.popup}>
        <CardContent>
          <Typography variant="h2">Zugang Löschen</Typography>
          <Divider className={classes.cardDivider} />
          <Typography variant="body1">
            Wenn Sie diesen Dialog bestätigen, löschen wir Ihren Zugang zum Kundenportal. Bitte beachten Sie, dass dies keine Auswirkungen auf
            Vertragsverhältnisse hat.
          </Typography>
        </CardContent>
        <CardActions className={classes.buttonPadding}>
          <Button size="small" color="secondary" onClick={confirmDeletion} variant="outlined">
            Zugang löschen
          </Button>
          <Button size="small" color="secondary" onClick={close} variant="outlined">
            Abbrechen
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default LoginData;
