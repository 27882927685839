import { ApiError } from '@enpowerx/apis';
import { BillingContact, Contract } from '@enpowerx/apis/lib/contracting/v2';
import { City, Street } from '@enpowerx/apis/lib/market/v2';
import { Person, Person_SalutationForm, PostalAddress } from '@enpowerx/apis/lib/types';
import {
  Alert,
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextFieldProps,
  Typography,
  useAsyncEffect,
} from '@enpxio/components';
import { SalutationLocaleFormatter, getSelectableSalutations } from '@enpxio/formatters';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, RefObject, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { useCustomer } from '../../../customers/hooks/useCustomer';
import { useAPI, useSelectedContract } from '../../../providers';
import DeliveryView from './deliveryView';
import { MultiContractApplyButton } from '~/mydata/multiContractApplyButton';

const postalCodeRegexp = new RegExp('^[+ 0-9]{5}$');

interface EditProps {
  rootAnchor: RefObject<HTMLDivElement>;
  focusBillingAddress?: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

interface Values {
  contactPerson: Person | undefined;
  contactAddress: PostalAddress | undefined;
}

const Edit: FC<EditProps> = (props: EditProps) => {
  const { classes } = useStyles();
  const { customer } = useCustomer();
  const { availableContracts, selectedContract, invalidateContracts } = useSelectedContract();
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();
  const [saveForAllContracts, setSaveForAllContracts] = useState(true);
  const [initialValues, setInitialValues] = useState<Values>(getInitialFormValues(selectedContract));
  const [showError, setShowError] = useState(false);
  const billingAddressRef = useRef<HTMLDivElement>(null);
  const [postalCode, setPostalCode] = useState('');
  const [cities, setCities] = useState(new Array<string>());
  const [selectedCity, setSelectedCity] = useState('');
  const [streets, setStreets] = useState(new Array<string>());
  const [selectedStreet, setSelectedStreet] = useState('');

  if (props.focusBillingAddress && billingAddressRef.current) {
      billingAddressRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  useEffect(() => {
    const abortController = new AbortController();
    setAbortSignal(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  useAsyncEffect(async () => {
    const initialFormValues = getInitialFormValues(selectedContract);
    setInitialValues(initialFormValues);
    const postalCode = selectedContract.billing?.contact?.address?.postalAddress?.postalCode;
    const city = selectedContract.billing?.contact?.address?.postalAddress?.city;
    setSelectedCity(city ? city : '');
    setSelectedStreet(
      selectedContract.billing?.contact?.address?.postalAddress?.street ? selectedContract.billing?.contact?.address?.postalAddress?.street : ''
    );
    if (postalCode) {
      try {
        await onPostalCodeChange(postalCode);
      } catch (error) {
        console.error(error);
      }
    }

    if (city) {
      try {
        await onCityChange(city, postalCode);
      } catch (error) {
        console.error(error);
      }
    }
  }, [customer, selectedContract]);

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>): Promise<void> => {
    setShowError(false);
    const newBillingContact = {
      address: {
        person: values.contactPerson,
        postalAddress: values.contactAddress,
        careof: '',
      },
      email: selectedContract.billing?.contact?.email ?? '',
    } as BillingContact;
    try {
      await (saveForAllContracts ? api.contracting.me.billing.contact.set(newBillingContact) : api.currentContract.billing.contact.set(newBillingContact));

      await invalidateContracts(abortSignal);
      enqueueSnackbar('Rechnungsadresse erfolgreich geändert', { variant: 'success' });
      props.onSaved();
    } catch (error_) {
      interface ExtendedError extends ApiError {
        name: string;
      }
      const error = error_ as ExtendedError;
      if (error.name === 'AbortError') {
        enqueueSnackbar('Änderung der Rechnungsadresse abgebrochen.', { variant: 'warning' });
      } else {
        setShowError(true);
      }

      actions.setSubmitting(false);
    }
  };

  const onPostalCodeChange = async (postalCode: string): Promise<string> => {
    if (postalCodeRegexp.test(postalCode)) {
      try {
        if (!postalCode) {
          return await Promise.reject();
        }

        const cityList = await api.region.postalCodes.get(postalCode).cities.list(abortSignal);
        setCities(cityList.cities.map((city: City) => city.canonicalName));
        setPostalCode(postalCode);
        if (cityList.cities[0].canonicalName) {
          return await Promise.resolve(cityList.cities[0].canonicalName);
        }
      } catch (error) {
        console.error(error);
        setCities([]);
        setStreets([]);
      }
    }

    throw undefined;
  };

  const onCityChange = async (city: string, zip?: string): Promise<string> => {
    try {
      if ((!zip && !postalCode) || !city) {
        return await Promise.reject();
      }

      const streetsList = await api.region.postalCodes
        .get(zip || postalCode)
        .cities.get(city)
        .streets.list(abortSignal);
      setStreets(streetsList.streets.map((street: Street) => street.canonicalName));
      if (streetsList.streets[0].canonicalName) {
        setSelectedStreet(streetsList.streets[0].canonicalName);
        return await Promise.resolve(streetsList.streets[0].canonicalName);
      }
    } catch (error) {
      console.error(error);
      setStreets([]);
    }

    throw undefined;
  };

  return (
    <>
      <DeliveryView />
      <CardContent>
        <Typography variant="h2" component="h2" ref={billingAddressRef} style={{ scrollMargin: '150px' }}>
          Abweichende Rechnungsadresse
        </Typography>
      </CardContent>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange enableReinitialize>
        {({ submitForm, handleChange, isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <CardContent className={classes.alignCenter}>
              <Grid direction="row" justifyContent="center" alignItems="center" spacing={10} container>
                <Grid xs={12} item>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="contactPerson.salutation">Anrede</InputLabel>
                    <Field label="Anrede" as={Select} name="contactPerson.salutation" labelWidth={100} disabled={isSubmitting}>
                      {getSelectableSalutations().map((val) => (
                        <MenuItem key={val} value={val}>
                          <SalutationLocaleFormatter salutation={val} />
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText error>
                      <ErrorMessage name="contactPerson.salutation" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="contactPerson.firstname">Vorname</InputLabel>
                    <Field label="Vorname" as={OutlinedInput} name="contactPerson.firstname" labelWidth={100} disabled />
                    <FormHelperText error>
                      <ErrorMessage name="contactPerson.firstname" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="contactPerson.lastname">Nachname</InputLabel>
                    <Field label="Nachname" as={OutlinedInput} name="contactPerson.lastname" labelWidth={100} disabled />
                    <FormHelperText error>
                      <ErrorMessage name="contactPerson.lastname" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={4} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="contactAddress.postalCode">PLZ</InputLabel>
                    <Field
                      label="PLZ"
                      as={OutlinedInput}
                      name="contactAddress.postalCode"
                      labelWidth={30}
                      disabled={isSubmitting}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        handleChange(event);
                        onPostalCodeChange(event.target.value)
                          .then((city) => {
                            setFieldValue('contactAddress.city', city, true);
                            setSelectedCity(city);
                            onCityChange(city, event.target.value)
                              .then((street) => {
                                setFieldValue('contactAddress.street', street);
                              })
                              .catch((error) => {
                                console.error(error);
                              });
                            setFieldValue('contactAddress.city', city);
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Field
                      label="Ort"
                      component={Autocomplete}
                      name="contactAddress.city"
                      disabled={cities.length <= 0 || isSubmitting}
                      options={cities}
                      value={selectedCity}
                      renderInput={(params: TextFieldProps) => <TextField {...params} label="Ort" />}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>, value: string) => {
                        handleChange(event);
                        setSelectedCity(value);
                        onCityChange(value ? value : '')
                          .then((street) => {
                            setFieldValue('contactAddress.street', street);
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Field
                      label="Strasse"
                      component={Autocomplete}
                      name="contactAddress.street"
                      variant="outlined"
                      disabled={streets.length <= 0 || isSubmitting}
                      options={streets}
                      value={selectedStreet}
                      renderInput={(params: TextFieldProps) => <TextField {...params} label="Strasse" />}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>, value: string) => {
                        handleChange(event);
                        setSelectedStreet(value);
                        setFieldValue('contactAddress.street', value);
                      }}
                    />
                    <FormHelperText error>
                      <ErrorMessage name="contactAddress.street" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={4} item>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="contactAddress.houseNumber">Hausnummer</InputLabel>
                    <Field label="Hausnummer" as={OutlinedInput} name="contactAddress.houseNumber" labelWidth={100} disabled={isSubmitting} />
                    <FormHelperText error>
                      <ErrorMessage name="contactAddress.houseNumber" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {showError ? (
                <Alert severity="error">Leider ist ein Fehler bei der Änderung aufgetreten. Bitte versuchen Sie es später noch einmal.</Alert>
              ) : null}
            </CardContent>
            <CardActions className={classes.buttonWrapper}>
              <MultiContractApplyButton
                contracts={availableContracts}
                disabled={isSubmitting}
                setSaveForAllContracts={setSaveForAllContracts}
                submitForm={submitForm}
              />
              <Button variant="outlined" color="secondary" disabled={isSubmitting} onClick={props.onCancel} className={classes.cancelButton}>
                Abbrechen
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

function getInitialFormValues(contract: Contract): Values {
  return {
    contactPerson: contract.billing?.contact?.address?.person ?? {
      company: '',
      title: '',
      firstname: '',
      lastname: '',
      salutation: Person_SalutationForm.MR,
    },
    contactAddress: contract.billing?.contact?.address?.postalAddress ??
      contract.delivery?.address ?? {
        region: 'DE',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        postboxNumber: '',
        additionalAddressLines: [''],
        district: '',
      },
  };
}

const useStyles = makeStyles()((theme) => ({
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
  },
  buttonWrapper: {
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cancelButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
    width: 'fit-content',
  },
  formControl: {
    marginTop: '0px',
    minWidth: '120px',
  },
  buttonExpand: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

const validationSchema = yup.object({
  contactPerson: yup
    .object({
      salutation: yup.mixed<Person_SalutationForm>().required('Bitte wählen Sie eine Anrede aus.'),
      firstname: yup.string().required('Bitte geben Sie einen Vornamen an.'),
      lastname: yup.string().required('Bitte geben Sie einen Nachnamen an.'),
    })
    .required(),
  contactAddress: yup
    .object({
      street: yup.string().required('Bitte geben Sie eine Strasse an.'),
      houseNumber: yup.string().required('Bitte geben Sie eine Hausnummer an.').matches(/\d+/, 'Die Hausnummer muss mindestens eine Ziffer enthalten.'),
      postalCode: yup
        .string()
        .required('Bitte geben Sie eine Postleitzahl an.')
        .matches(/^\d{5}$/, 'Bitte geben Sie eine gültige Postleitzahl an.'),
      city: yup.string().required('Bitte geben Sie eine Stadt an.'),
    })
    .required(),
});

export default Edit;
