import { Button, CardActions, CardContent, Grid, Link, Typography } from '@enpxio/components';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FC, PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'inline-block',
    textAlign: 'center',
    maxWidth: '390px',
  },
  cardActions: {
    justifyContent: 'center',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface MarketingCardProps {
  title?: string;
  description?: string;
  linkedPage: string;
  image?: string;
  buttonText: string;
}

const MarketingEntry: FC<MarketingCardProps> = (props: MarketingCardProps) => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.wrapper} item>
      <CardContent>
        <img src={props.image} height="100" width="100" alt="marketing" />
        <Typography variant="h2">{props.title}</Typography>
      </CardContent>
      <CardContent>
        <Typography variant="body1">{props.description}</Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <LinkedPage linkedPage={props.linkedPage}>
          <Button color="secondary" endIcon={<KeyboardArrowRightIcon />}>
            {props.buttonText}
          </Button>
        </LinkedPage>
      </CardActions>
    </Grid>
  );
};

type LinkedPageProps = PropsWithChildren<{
  linkedPage: string;
}>;

export const LinkedPage: FC<LinkedPageProps> = (props: LinkedPageProps) => {
  const { classes } = useStyles();

  switch (props.linkedPage) {
    case 'Startseite':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.home.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Rechnung':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.invoicesAndPayment.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Zähler':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.meters.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Umzug':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.relocation.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Vertrag':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.contract.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Meine Daten':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.myData.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Hilfe':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.contact.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Zählerstand eingeben':
      return (
        <Link className={classes.link} component={RouterLink} state={{ addReading: true }} to={PortalPages.meters.absolutePathname} color="textPrimary">
          {props.children}
        </Link>
      );
    case 'Abschlag ändern':
      return (
        <Link
          className={classes.link}
          component={RouterLink}
          state={{ changePayment: true }}
          to={PortalPages.invoicesAndPayment.absolutePathname}
          color="secondary"
        >
          {props.children}
        </Link>
      );
    case 'Bankdaten ändern':
      return (
        <Link state={{ editBank: true }} className={classes.link} component={RouterLink} to={PortalPages.myData.absolutePathname} color="secondary">
          {props.children}
        </Link>
      );
    case 'Persönliche Daten ändern':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.myData.absolutePathname} color="secondary">
          {props.children}
        </Link>
      );
    case 'Rechnungserläuterung':
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.invoiceExplanation.absolutePathname} color="secondary">
          {props.children}
        </Link>
      );
    default:
      return (
        <Link className={classes.link} component={RouterLink} to={PortalPages.home.absolutePathname} color="secondary">
          {props.children}
        </Link>
      );
  }
};

export default MarketingEntry;
