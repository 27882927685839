import { DateMessage } from '@enpowerx/apis/lib/google/type';
import { EnergyType } from '@enpowerx/apis/lib/types';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  PageViewCard,
  PageViewCardTitle,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@enpxio/components';
import {
  ConsumptionLocaleFormatter,
  ContractNameLocaleFormatter,
  DateMessagePeriodDaysLocaleFormatter,
  DateMessagePeriodLocaleFormatter,
  NumberLocaleFormatter,
  differenceInDays,
} from '@enpxio/formatters';
import { DateRange } from '@mui/icons-material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useInvoices } from '../../invoices/hooks/useInvoices';
import { sortInvoiceAsc } from '../../utils/invoiceUtils';
import { useSelectedContract } from '~/providers';

interface Consumption {
  id: string;
  startDate: DateMessage | undefined;
  endDate: DateMessage | undefined;
  usage: number;
  dailyAverageUsage: number;
  dailyAverageUsagePercent: number;
}

export const MeterConsumptionCard: FC = () => {
  const { selectedContract } = useSelectedContract();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { invoices: allInvoices, isLoading, error } = useInvoices();
  const [consumptions, setConsumptions] = useState<Consumption[]>();

  const view = useMemo(() => {
    if (!isLoading && error) {
      return (
        <Alert severity="error">
          Aus technischen Gründen kann Ihr <ContractNameLocaleFormatter energyType={selectedContract.delivery?.energyType} suffix="verbrauch" /> derzeit nicht
          angezeigt werden. Bitte versuchen Sie es später noch einmal.
        </Alert>
      );
    }

    if (!isLoading && consumptions?.length === 0) {
      return (
        <Alert severity="info">
          Es liegen derzeit keine Informationen zu Ihrem {selectedContract.delivery?.energyType === EnergyType.GAS ? 'Gasverbrauch' : 'Stromverbrauch'} vor.
        </Alert>
      );
    }

    if (isMobile) {
      return <MeterConsumptionList consumptions={consumptions} energyType={selectedContract.delivery?.energyType} isLoading={isLoading} />;
    }

    return <MeterConsumptionTable consumptions={consumptions} isLoading={isLoading} />;
  }, [isLoading, consumptions, isMobile, error]);

  useEffect(() => {
    const filteredInvoices = allInvoices?.sort(sortInvoiceAsc).filter((invoice) => !invoice.isCancelled && invoice.annualUsage > 0) ?? [];
    const maxAverage = Math.max(...filteredInvoices.map<number>((inv) => inv.annualUsage));

    setConsumptions(
      filteredInvoices.map<Consumption>((inv) => ({
        id: inv.id,
        startDate: inv.startDate,
        endDate: inv.endDate,
        usage: inv.annualUsage,
        dailyAverageUsage: inv.annualUsage / differenceInDays(inv.startDate, inv.endDate),
        dailyAverageUsagePercent: (inv.annualUsage * 100) / maxAverage,
      }))
    );
  }, [allInvoices, error]);
  return (
    <PageViewCard>
      <PageViewCardTitle
        title={
          <>
            Mein <ContractNameLocaleFormatter energyType={selectedContract.delivery?.energyType} suffix="verbrauch" />
          </>
        }
      />
      {view}
    </PageViewCard>
  );
};

const useTableStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
    },
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
      border: 0,
      padding: theme.namedSpacing('small', 'sm'),
    },
    '& .MuiTableBody-root > .MuiTableRow-root': {
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root': {
      border: 0,
      paddingLeft: theme.namedSpacing('small', 'sm'),
    },
    '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root:first-child': {
      paddingLeft: '25px',
      whiteSpace: 'nowrap',
      borderCollapse: 'separate',
    },
  },
  progressBar: {
    '& div': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      paddingLeft: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
      [theme.breakpoints.down(840)]: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
      [theme.breakpoints.down(720)]: {
        paddingLeft: '10px',
      },
    },
  },
  tableHeadRow: {
    '& .MuiTableCell-root:nth-of-type(1)': {
      width: '160px',
    },
    '& .MuiTableCell-root:nth-of-type(2)': {
      minWidth: '80px',
    },
    '& .MuiTableCell-root:nth-of-type(3)': {
      minWidth: '160px',
      [theme.breakpoints.down(700)]: {
        minWidth: '80px',
      },
    },
    '& .MuiTableCell-root:nth-of-type(4)': {
      width: '100%',
    },
  },
}));

interface MeterConsumptionTableProps {
  consumptions: Consumption[] | undefined;
  isLoading: boolean;
}

const MeterConsumptionTable: FC<MeterConsumptionTableProps> = (props: MeterConsumptionTableProps) => {
  const { classes } = useTableStyles();
  return (
    <TableContainer className={classes.root}>
      <Table>
        <TableHead>
          {props.isLoading ? (
            <TableRow>
              <TableCell>
                <Skeleton width="40%" />
              </TableCell>
              <TableCell>
                <Skeleton width="40%" />
              </TableCell>
              <TableCell>
                <Skeleton width="40%" />
              </TableCell>
              <TableCell>
                <Skeleton width="40%" />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow className={classes.tableHeadRow}>
              <TableCell>Zeitraum</TableCell>
              <TableCell>Tage</TableCell>
              <TableCell>Verbrauch (kWh)</TableCell>
              <TableCell>Tages&shy;durch&shy;schnitt (kWh)</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {props.isLoading ? (
            <>
              {[...new Array(4)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton width="70%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="70%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="70%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="70%" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <>
              {props.consumptions?.map((consumption) => (
                <TableRow key={consumption.id}>
                  <TableCell>
                    <DateMessagePeriodLocaleFormatter startDate={consumption.startDate} endDate={consumption.endDate} />
                  </TableCell>
                  <TableCell>
                    <DateMessagePeriodDaysLocaleFormatter startDate={consumption.startDate} endDate={consumption.endDate} />
                  </TableCell>
                  <TableCell>
                    <NumberLocaleFormatter value={consumption.usage} />
                  </TableCell>
                  <TableCell>
                    <div className={classes.progressBar}>
                      <div style={{ width: `${consumption.dailyAverageUsagePercent}%` }}>
                        <NumberLocaleFormatter
                          value={consumption.dailyAverageUsage}
                          formatOptions={{
                            maximumFractionDigits: 2,
                          }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useListStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiListItem-root': {
      padding: 0,
    },
    '& .MuiListItem-divider': {
      paddingTop: theme.namedSpacing('small', 'xl'),
      marginBottom: theme.namedSpacing('small', 'xl'),
    },
    '& .MuiCard-root': {
      width: '100%',
      boxShadow: 'unset',
      padding: 0,
      borderRadius: 'unset',
    },
    '& .MuiCardContent-root': {
      padding: 0,
      paddingTop: theme.namedSpacing('small', 'sm'),
    },
    '& .MuiCardHeader-root': {
      padding: 0,
    },
    '& .MuiCardHeader-content': {
      paddingTop: 0,
    },
  },
  avatar: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.getContrastText(theme.palette.background.default),
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 'large',
    paddingBottom: '5px',
  },
  progressBar: {
    '& > div': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      paddingLeft: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
}));

interface MeterConsumptionListProps {
  consumptions: Consumption[] | undefined;
  energyType: EnergyType | undefined;
  isLoading: boolean;
}

const MeterConsumptionList: FC<MeterConsumptionListProps> = (props: MeterConsumptionListProps) => {
  const { classes } = useListStyles();

  interface Entry {
    avatar: ReactNode;
    title: ReactNode;
    subTitle: ReactNode;
    consumption: ReactNode;
    dailyAverage: ReactNode;
  }

  const view = useMemo(() => {
    const entries: Entry[] = [];
    if (props.isLoading) {
      entries.push({
        avatar: <Skeleton variant="circular" width={40} height={40} />,
        title: <Skeleton width={250} />,
        subTitle: <Skeleton width={100} />,
        consumption: <Skeleton width={125} />,
        dailyAverage: <Skeleton width="100%" height={40} />,
      });
    } else {
      props.consumptions?.forEach((consumption) =>
        entries.push({
          avatar: (
            <Avatar className={classes.avatar}>
              <DateRange className={classes.icon} />
            </Avatar>
          ),
          title: <DateMessagePeriodLocaleFormatter startDate={consumption.startDate} endDate={consumption.endDate} />,
          subTitle: <DateMessagePeriodDaysLocaleFormatter startDate={consumption.startDate} endDate={consumption.endDate} suffix=" Tage" />,
          consumption: (
            <>
              <Box>Verbrauch:&nbsp;</Box>
              <ConsumptionLocaleFormatter value={consumption.usage} />
            </>
          ),
          dailyAverage: (
            <div className={classes.progressBar}>
              <div style={{ width: `${consumption.dailyAverageUsagePercent}%`, display: 'flex' }}>
                <ConsumptionLocaleFormatter
                  value={consumption.dailyAverageUsage}
                  formatOptions={{
                    maximumFractionDigits: 2,
                  }}
                />{' '}
                <Box fontSize="small">&nbsp;Tagesdurchschnitt</Box>
              </div>
            </div>
          ),
        })
      );
    }

    return (
      <List className={classes.root}>
        {entries.map((entry, index) => (
          <>
            {index > 0 ? <ListItem divider key={`${index}-divider`} /> : null}
            <ListItem key={index}>
              <Card>
                <CardHeader
                  avatar={entry.avatar}
                  title={
                    <Typography variant="body1" className={classes.title}>
                      {entry.title}
                    </Typography>
                  }
                  subheader={<Typography variant="body2">{entry.subTitle}</Typography>}
                />
                <CardContent>
                  <Typography variant="body1" component="div" style={{ display: 'flex' }}>
                    {entry.consumption}
                  </Typography>
                  {entry.dailyAverage}
                </CardContent>
              </Card>
            </ListItem>
          </>
        ))}
      </List>
    );
  }, [props.isLoading, props.consumptions, props.energyType]);

  return <>{view}</>;
};
