import { DateMessage } from '@enpowerx/apis/lib/google/type';

/* dateMessageSortComparer compares two {@link DateMessage}.
 * Returns a negative value if a > b, zero if a = b and positive value if a > b.
 * @typeParam T {@link DateMessage}.
 */
export function dateMessageSortComparer<T extends DateMessage>(a: T | undefined, b: T | undefined): number {
  const dateA = a ? new Date(a.year, a.month - 1, a.day).getTime() : 0;
  const dateB = b ? new Date(b.year, b.month - 1, b.day).getTime() : 0;
  return dateA - dateB;
}

/* dateMessageSortComparerByField compares two {@link DateMessage}.
 * Returns a negative value if a > b, zero if a = b and positive value if a > b.
 * @param selector Function to select the @{link DateMessage} field of the given object to compare..
 */
export function dateMessageSortComparerByField<T extends {}>(a: T, b: T, selector: (obj: T) => DateMessage | undefined): number {
  return dateMessageSortComparer(selector(a), selector(b));
}
