import { Contract, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Person_SalutationForm } from '@enpowerx/apis/lib/types';
import { Alert, AlertTitle, Button, CardActions, CardContent, Link, Typography } from '@enpxio/components';
import { SalutationLocaleFormatter } from '@enpxio/formatters';
import { HouseOutlined } from '@mui/icons-material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../../portalPages';
import { useSelectedContract } from '../../../providers';
import DeliveryView from './deliveryView';
import { KeyValueEntries, KeyValueView } from '~/keyValueView';

interface ViewProps {
  onEditClicked: () => void;
  onDeleteClicked: () => void;
}

const useStyles = makeStyles()((theme) => ({
  alertPadding: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
}));

const View: FC<ViewProps> = (props: ViewProps) => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const billingAddressDiffers = (contract: Contract): boolean => {
    if (!contract.billing?.contact?.address?.postalAddress) return false;
    return (
      contract.delivery?.address?.houseNumber !== contract.billing?.contact?.address?.postalAddress.houseNumber ||
      contract.delivery?.address?.street !== contract.billing?.contact?.address?.postalAddress.street ||
      contract.delivery?.address?.city !== contract.billing?.contact?.address?.postalAddress.city ||
      contract.delivery?.address?.region !== contract.billing?.contact?.address?.postalAddress.region ||
      contract.delivery?.address?.postalCode !== contract.billing?.contact?.address?.postalAddress.postalCode
    );
  };

  const billingRows: KeyValueEntries = [
    {
      key: 'Anrede',
      value: <SalutationLocaleFormatter salutation={selectedContract.billing?.contact?.address?.person?.salutation ?? Person_SalutationForm.UNRECOGNIZED} />,
    },
    {
      key: 'Vorname',
      value: `${selectedContract.billing?.contact?.address?.person?.firstname}`,
    },
    {
      key: 'Nachname',
      value: `${selectedContract.billing?.contact?.address?.person?.lastname}`,
    },
    {
      key: 'Strasse / Hausnummer',
      value: `${selectedContract.billing?.contact?.address?.postalAddress?.street} ${selectedContract.billing?.contact?.address?.postalAddress?.houseNumber}`,
    },
    {
      key: 'Postleitzahl / Ort',
      value: `${selectedContract.billing?.contact?.address?.postalAddress?.postalCode} ${selectedContract.billing?.contact?.address?.postalAddress?.city}`,
    },
  ];
  return (
    <>
      <DeliveryView />
      {billingAddressDiffers(selectedContract) ? (
        <>
          <CardContent>
            <Typography variant="h2" component="h2">
              Abweichende Rechnungsadresse
            </Typography>
          </CardContent>
          <CardContent>
            <KeyValueView className={classes.keyValueView} entries={billingRows} />
          </CardContent>
        </>
      ) : null}
      <CardActions style={{ justifyContent: 'center' }}>
        <Button variant="outlined" color="secondary" onClick={props.onEditClicked}>
          Rechnungsadresse bearbeiten
        </Button>
        {billingAddressDiffers(selectedContract) ? (
          <Button variant="outlined" color="secondary" onClick={props.onDeleteClicked}>
            Rechnungsadresse löschen
          </Button>
        ) : null}
      </CardActions>
      {selectedContract.state === Contract_State.TERMINATED ? null : (
        <Alert className={classes.alertPadding} severity="info" icon={<HouseOutlined fontSize="inherit" />}>
          <AlertTitle>Sie ziehen um?</AlertTitle>
          <Typography variant="body1">
            Für Ihren&nbsp;
            <Link component={RouterLink} to={PortalPages.relocation.absolutePathname} color="secondary">
              Umzug
            </Link>
            &nbsp;müssen Sie nur kurz Ihre neue Anschrift mitteilen.
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default View;
