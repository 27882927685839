import { useQuery, gql } from '@apollo/client';
import { Container, Grid, Hidden } from '@enpxio/components';
import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../portalPages';
import RegistrationCard from './cards/registrationCard';
import RegistrationHelpCustomer from './components/registrationHelpCustomer';
import RegistrationHelpMeter from './components/registrationHelpMeter';
import { useAuth0 } from '~/providers';
import { RegistrationBenefitsMessage } from './registrationBenefitsMessage';

const QUERY = gql`
  query pageRegistrationCollection {
    pageRegistrationCollection(limit: 1) {
      items {
        backgroundImage {
          url
        }
      }
    }
  }
`;

const useStyles = makeStyles<{
  backgroundImageURL: string;
}>()((theme, { backgroundImageURL }) => ({
  background: {
    minWidth: 275,
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -1,
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0,0,0, 0.7)), url("${backgroundImageURL}")`,
    scrollbarWidth: 'none',
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  recaptcha: {
    paddingTop: theme.spacing(4),
  },
}));

const RegistrationPage: FunctionComponent = () => {
  const { data, loading } = useQuery(QUERY);
  const imageUrl = data?.pageRegistrationCollection?.items[0]?.backgroundImage?.url;
  const { classes } = useStyles({ backgroundImageURL: loading || !imageUrl ? '' : imageUrl });
  const [displayHelp, setDisplayHelp] = useState(false);
  const [category, setCategory] = useState('');
  const { getTokenSilently, user } = useAuth0();

  useEffect(() => {
    const isNewUser = !('https://claims.enpowerx.io/customer' in user) || user['https://claims.enpowerx.io/customer'] === '';
    if (!isNewUser) {
      window.location.href = PortalPages.dashboard.absolutePathname;
    }
  }, [user]);

  const navigateToDashboard = async (): Promise<void> => {
    await getTokenSilently({ ignoreCache: true });
    window.location.href = PortalPages.dashboard.absolutePathname;
  };

  const registrationInfo = () => {
    if (category == 'registerHelpMeter') {
      return <RegistrationHelpMeter setCategory={setCategory} />;
    }

    if (category == 'registerHelpSecret') {
      return <RegistrationHelpCustomer setCategory={setCategory} />;
    }

    return <RegistrationBenefitsMessage />;
  };

  const registrationCard = () => {
    return (
      <RegistrationCard
        setDisplaySuccess={navigateToDashboard}
        setDisplayHelp={setDisplayHelp}
        displayHelp={displayHelp}
        setCategory={setCategory}
        category={category}
      />
    );
  };

  return (
    <>
      <div className={classes.background} />
      <Container>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Hidden mdDown>{registrationInfo()}</Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {registrationCard()}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Hidden mdUp>{registrationInfo()}</Hidden>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RegistrationPage;
