import { useEffect, useState } from 'react';

import { dateMessageSortComparerByField } from '../../sortComparer/dateMessage';
import { useMeters } from './useMeters';

interface ActiveMeterState {
  activeMeterNumber: string | undefined;
  activeMeterType: string | undefined;
  activeMeterDataAcquisitionType: string | undefined;
  error: Error | undefined;
  isLoading: boolean;
}

export const useActiveMeterNumber = (): ActiveMeterState => {
  const { meters, error, isLoading } = useMeters();
  const [activeMeterNumber, setActiveMeterNumber] = useState<string>();
  const [activeMeterType, setActiveMeterType] = useState<string>();
  const [activeMeterDataAcquisitionType, setActiveMeterDataAcquisitionType] = useState<string>();

  useEffect(() => {
    if (!meters) {
      return;
    }

    const activeMeter = meters
      .sort((a, b) => dateMessageSortComparerByField(a, b, (obj) => obj.activeRange?.start))
      .reverse()
      .find((meter) => !meter.activeRange?.end);

    setActiveMeterNumber(activeMeter?.id);
    setActiveMeterType(activeMeter?.type);
    setActiveMeterDataAcquisitionType(activeMeter?.dataAcquisitionType);
  }, [meters]);

  return {
    activeMeterNumber,
    activeMeterType,
    activeMeterDataAcquisitionType,
    error,
    isLoading,
  };
};
