import { Contract, ContractCancellationReason, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { CustomerType } from '@enpowerx/apis/lib/identities/v2';
import { EnergyDelivery_UsageType, EnergyType } from '@enpowerx/apis/lib/types';
import { useAsyncEffect, useConfig } from '@enpxio/components';
import React, { FC, PropsWithChildren, useContext, useState } from 'react';

import { APIContextState } from './api';
import { useAuth0 } from './auth0';

interface SelectedContractContextState {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  availableContracts: Contract[];
  selectedContract: Contract;
  setSelectedContract: (contract: Contract) => void;
  invalidateContracts: (abortSignal?: AbortSignal) => Promise<void>;
  failure: boolean;
}

export const defaultContract: Contract = {
  id: '',
  cancellationReason: ContractCancellationReason.UNRECOGNIZED,
  customer: '',
  state: Contract_State.UNRECOGNIZED,
  tenant: '',
  meterNumber: '',
  isRelocatable: false,
  previousContract: '',
  delivery: {
    energyType: EnergyType.UNRECOGNIZED,
    customerType: CustomerType.UNRECOGNIZED,
    annualUsage: 0,
    usageType: EnergyDelivery_UsageType.UNRECOGNIZED,
    annualUsageNt: 0,
  },
};

const defaultContext: SelectedContractContextState = {
  isLoading: false,
  setIsLoading: () => {
    // Not initialized
  },
  availableContracts: [],
  selectedContract: defaultContract,
  setSelectedContract: () => {
    // Not initialized
  },
  invalidateContracts: async (): Promise<void> => Promise.resolve(),
  failure: false,
};

const SelectedContractContext = React.createContext(defaultContext);

export const useSelectedContract = (): SelectedContractContextState => useContext(SelectedContractContext);
export const SelectedContractContextProvider: FC<PropsWithChildren> = (props) => {
  const { isLoading: isAuthLoading, isAuthenticated, getTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [selectedContract, setSelectedContract] = useState<Contract>(defaultContract);
  const [failure, setFailure] = useState<boolean>(false);
  const config = useConfig();

  useAsyncEffect(
    async (abortSignal) => {
      if (isAuthLoading || !isAuthenticated) {
        return;
      }

      setIsLoading(true);
      await invalidateContracts(abortSignal);
      setIsLoading(false);
    },
    [isAuthLoading, isAuthenticated]
  );

  const invalidateContracts = async (abortSignal?: AbortSignal): Promise<void> => {
    const contractList = await getContracts(abortSignal);
    if (selectedContract?.id) {
      const found = contractList.find((contract) => contract?.id === selectedContract?.id);
      if (found) setSelectedContract(found);
      else setSelectedContract(defaultContract);
    } else if (contractList?.length === 1) {
      setSelectedContract(contractList[0]);
    }
    
    setContracts(contractList);
  };

  const getContracts = async (abortSignal?: AbortSignal): Promise<Contract[]> => {
    const api = new APIContextState(config.tenant, undefined, getTokenSilently);
    try {
      const contractList = await api.contracting.me.contracts.list(abortSignal);
      return contractList.contracts;
    } catch (error) {
      console.error(error);
      setFailure(true);
      return [];
    }
  };

  return (
    <SelectedContractContext.Provider
      value={{
        isLoading,
        setIsLoading,
        availableContracts: contracts,
        selectedContract,
        setSelectedContract: (contract) => {
          setSelectedContract(contract);
        },
        invalidateContracts: async (abortSignal?: AbortSignal) => invalidateContracts(abortSignal),
        failure,
      }}
    >
      {props.children}
    </SelectedContractContext.Provider>
  );
};
