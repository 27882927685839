import { RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { HelpOutlineSharp } from '@mui/icons-material';
import { Theme, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

interface RegisterTypeProps {
  prefix?: string;
  suffix?: string;
  value: RegisterType;
}

export const RegisterTypeLocaleFormatter: FC<RegisterTypeProps> = (props: RegisterTypeProps) => {
  let value;
  switch (props.value) {
    case RegisterType.DOUBLE_TARIFF_PEAK:
      value = (
        <DescriptiveRegisterType
          displayText="HT"
          helpTitle="Hochtarif"
          helpText={
            <>
              Die Abkürzung <strong>HT</strong> steht für <strong>Hochtarif</strong> (umgangssprachlich auch Tagstrom genannt). Diese Abkürzung finden Sie an
              sogenannten Doppel- oder auch Zweitarifzählern, die hierzulande vor allem bei Nachtspeicherheizungen und Wärmepumpen Verwendung finden.
            </>
          }
        />
      );
      break;
    case RegisterType.DOUBLE_TARIFF_OFF_PEAK:
      value = (
        <DescriptiveRegisterType
          displayText="NT"
          helpTitle="Niedertarif"
          helpText={
            <>
              Die Abkürzung <strong>NT</strong> steht für <strong>Niedertarif</strong> (umgangssprachlich auch Nachtstrom genannt). Diese Abkürzung finden Sie
              an sogenannten Doppel- oder auch Zweitarifzählern, die hierzulande vor allem bei Nachtspeicherheizungen und Wärmepumpen Verwendung finden.
            </>
          }
        />
      );
      break;
    case RegisterType.SINGLE_TARIFF:
    default:
      value = (
        <DescriptiveRegisterType
          displayText="EZ"
          helpTitle="Eintarifzähler"
          helpText={
            <>
              Die Abkürzung <strong>EZ</strong> steht für <strong>Eintarifzähler</strong>. Im Gegensatz zu Doppeltarifzählern zahlen Sie zu jeder Tages- und
              Nachtzeit den gleichen Preis für die Kilowattstunde, da der Eintarifzähler für Heiz- und Allgemeinstrom nur ein Zählwerk besitzt.
            </>
          }
        />
      );
      break;
  }

  return (
    <>
      {props.prefix}
      {value}
      {props.suffix}
    </>
  );
};

interface DescriptiveRegisterTypeProps {
  displayText: string;
  helpTitle: string;
  helpText: string | React.ReactNode;
}

const DescriptiveRegisterType: FC<DescriptiveRegisterTypeProps> = (props: DescriptiveRegisterTypeProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleTooltip = (): void => {
    setOpen(!open);
  };

  const handleMouse = (open: boolean): void => {
    setOpen(open);
  };

  return (
    <HtmlTooltip
      title={
        <>
          <Typography color="inherit" variant="subtitle1" component="h2">
            {props.helpTitle}
          </Typography>
          {props.helpText}
        </>
      }
      open={open}
    >
      <span className={classes.inlineFlex}>
        {props.displayText}{' '}
        <HelpOutlineSharp
          className={classes.infoIcon}
          onTouchEnd={toggleTooltip}
          onMouseOver={() => {
            handleMouse(true);
          }}
          onMouseLeave={() => {
            handleMouse(false);
          }}
        />
      </span>
    </HtmlTooltip>
  );
};

const useStyles = makeStyles()(() => ({
  infoIcon: {
    cursor: 'help',
    fontSize: '15px',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
}));

const HtmlTooltip = withStyles(Tooltip, (theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    margin: '0px',
  },
}));
