import { Divider, Drawer, Hidden, IconButton } from '@enpxio/components';
import { ChevronLeft } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ContractSelector } from '../../contracts/components/contractSelector';
import { PageFlags } from '../../providers/pages/pages';
import { usePages } from '../../providers/pages/provider';
import { SideMenuItems } from './sideMenuItems';
import { useAuth0 } from '~/providers';

export const SideMenu: FC = () => {
  const { isAuthenticated } = useAuth0();
  const { classes } = styles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { activePage } = usePages();

  if (activePage?.hasFlag(PageFlags.AuthenticatedNoMenu)) {
    return null;
  }

  return (
    <Hidden mdUp>
      <IconButton
        className={classes.button}
        color="inherit"
        aria-label="Menü öffnen"
        onClick={() => {
          setIsDrawerOpen(true);
        }}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        classes={{ paper: classes.drawerPaper }}
        className={classes.drawer}
        anchor="left"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <div>
          <IconButton
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            size="large"
          >
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <div hidden={!isAuthenticated}>
          <div className={classes.drawerContractSelector}>
            <ContractSelector
              selectionTriggered={() => {
                setIsDrawerOpen(false);
              }}
            />
          </div>
          <Divider />
        </div>
        <SideMenuItems
          onItemSelected={() => {
            setIsDrawerOpen(false);
          }}
        />
      </Drawer>
    </Hidden>
  );
};

const styles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    height: 70,
  },
  title: {
    flexGrow: 1,
  },
  button: {
    position: 'absolute',
    left: '50px',
    [theme.breakpoints.down('md')]: {
      left: '10px',
    },
    zIndex: 2,
  },
  toolbar: {
    maxWidth: 1300,
    width: '100%',
    height: 80,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContractSelector: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '30px',
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
}));
