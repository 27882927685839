import { Button, Card, CardContent, FilledInput, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@enpxio/components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { HttpError } from 'typedrest/errors';
import { object, string } from 'yup';

import { useAPI, useAuth0 } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: 300,
  },
  spaceLG: {
    paddingTop: theme.spacing(2),
  },
  spaceXL: {
    paddingTop: theme.spacing(4),
  },
  textfield: {
    backgroundColor: 'white',
  },
  input: {
    color: 'grey',
  },
  verticalMiddle: {
    verticalAlign: 'middle',
  },
}));

interface Props {
  setDisplaySuccess: (c: boolean) => void;
  setDisplayHelp: (c: boolean) => void;
  displayHelp: boolean;
  setCategory: (c: string) => void;
  category: string;
}

interface Values {
  customerSecret: string;
  meterNumber: string;
}

enum IdentityError {
  None,
  Client,
  Server,
}

export default function RegistrationCard({ setDisplaySuccess, setDisplayHelp, displayHelp, setCategory }: Props) {
  const { classes } = useStyles();
  const [error, setError] = useState<IdentityError>(IdentityError.None);
  const api = useAPI();
  const { user } = useAuth0();

  const getAuth0Code = async (values: Values): Promise<void> => {
    const customerSecret = values.customerSecret.replace(/\s/g, '');
    const meterNumber = values.meterNumber.replace(/\s/g, '');
    try {
      await api.identities.users
        .get(user.email ?? '')
        .linkCustomer()
        .invoke({
          contract: undefined,
          customer: customerSecret,
          meter: meterNumber,
        });
    } catch (e) {
      if ((e as HttpError).status > 499) {
        setError(IdentityError.Server);
      } else {
        setError(IdentityError.Client);
      }
      return;
    }
    setDisplaySuccess(true);
  };

  function errorText() {
    switch (error) {
      case IdentityError.Client:
        return (
          <Typography color="error">
            Die Kombination aus Kundennummer und Zählernummer ist ungültig.
            <br /> Bitte überprüfen Sie Ihre Eingabe.
          </Typography>
        );
      case IdentityError.Server:
        return <Typography color="error">Derzeit ist die Registrierung nicht möglich. Versuchen Sie es zu einem späteren Zeitpunkt erneut.</Typography>;
      default:
        return null;
    }
  }

  function displayHelpMeter(): void {
    setCategory('registerHelpMeter');
    setDisplayHelp(!displayHelp);
  }

  function displayHelpSecret(): void {
    setCategory('registerHelpSecret');
    setDisplayHelp(!displayHelp);
  }

  return (
    <Formik
      initialValues={{ customerSecret: '', meterNumber: '' }}
      onSubmit={getAuth0Code}
      validationSchema={object({
        customerSecret: string()
          .required('Bitte geben Sie Ihre Kundennummer an.')
          .min(5, 'Ihre Kundennummer sollte mindestens 5 Zeichen enthalten.')
          .max(24, 'Ihre Kundennummer sollte maximal 24 Zeichen enthalten.'),
        meterNumber: string()
          .required('Bitte geben Sie Ihre Zählernummer an.')
          .min(1, 'Ihre Zählernummer sollte mindestens 1 Zeichen enthalten.')
          .max(24, 'Ihre Zählernummer sollte maximal 30 Zeichen enthalten.'),
      })}
      validateOnChange
      enableReinitialize
    >
      {({ submitForm, isSubmitting, values, isValid, dirty }) => (
        <Form>
          <Card className={classes.root} square>
            <CardContent>
              <Typography variant="body2">
                <strong>Ihre Kundendaten</strong>
              </Typography>
              <Typography variant="body2" className={classes.spaceLG}>
                Bitte geben Sie Ihre Kundennummer ein.
              </Typography>
              <Grid item key="2" xs={12} container justify-content="space-around">
                <Grid item key="22" xs={12} justify-content="space-around">
                  <Typography variant="h2" component="h2" className={classes.spaceLG}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel className={classes.input} htmlFor="customerSecret">
                        Kundennummer
                      </InputLabel>
                      <Field className={classes.textfield} as={FilledInput} name="customerSecret" label="Kundennummer" disabled={isSubmitting} />
                      <FormHelperText error>
                        <ErrorMessage name="customerSecret" />
                      </FormHelperText>
                    </FormControl>
                    <Button color="secondary" onClick={displayHelpSecret} disabled={values.customerSecret.length > 0}>
                      <Typography variant="body2">Wo finde ich meine Kundennummer?</Typography>
                    </Button>
                  </Typography>
                  <Typography variant="body2" className={classes.spaceXL}>
                    Bitte verraten Sie uns jetzt noch Ihre Zählernummer.
                  </Typography>
                  <Typography variant="h2" component="h2" className={classes.spaceLG}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel className={classes.input} htmlFor="meterNumber">
                        Zählernummer
                      </InputLabel>
                      <Field className={classes.textfield} as={FilledInput} name="meterNumber" label="Zählernummer" disabled={isSubmitting} />
                      <FormHelperText error>
                        <ErrorMessage name="meterNumber" />
                      </FormHelperText>
                    </FormControl>
                    <Button color="secondary" onClick={displayHelpMeter} disabled={values.meterNumber.length > 0}>
                      <Typography variant="body2">Wo finde ich meine Zählernummer?</Typography>
                    </Button>
                  </Typography>
                  <Typography className={classes.spaceLG}>{errorText()}</Typography>
                </Grid>
              </Grid>
              <Typography className={classes.spaceLG}>
                <Button variant="outlined" fullWidth color="secondary" disabled={!(isValid && dirty) || isSubmitting} onClick={submitForm}>
                  Registrierung abschließen
                </Button>
              </Typography>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
