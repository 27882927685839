import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Page, Pages } from './pages';

interface PageProviderState {
  activePage: Page | undefined;
  pages: Pages;
}

const defaultState: PageProviderState = {
  activePage: undefined,
  pages: new Pages([]),
};

const pageContext = React.createContext<PageProviderState>(defaultState);
export const usePages = (): PageProviderState => useContext(pageContext);

interface PageProviderProps {
  pages: Page[];
  children?: ReactNode;
}

export const PageProvider: FC<PageProviderProps> = (props: PageProviderProps) => {
  const [activePage, setActivePage] = useState<Page | undefined>();
  const [pages] = useState(new Pages(props.pages));
  const getActivePageByLocation = useCallback(
    (pathName: string | undefined): Page | undefined => pages.findTransitive((p) => p.absolutePathname === pathName?.replace(/\/$/, '')),
    [pages]
  );
  const location = useLocation();

  useEffect(() => {
    // setActivePage(getActivePageByLocation(window.location.pathname));
    // globalHistory.listen(event => {
    //   setActivePage(getActivePageByLocation(event.location.pathname));
    // });
    setActivePage(getActivePageByLocation(location.pathname));
  }, [location]);

  return (
    <pageContext.Provider
      value={{
        activePage,
        pages,
      }}
    >
      {props.children}
    </pageContext.Provider>
  );
};
