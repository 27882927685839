import { Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Alert, Button, CardActions, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { DateMessageLocaleFormatter, MoneyLocaleFormatter } from '@enpxio/formatters';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useInstallment } from '../../installments/useInstallments';
import { KeyValueEntries, KeyValueView } from '~/keyValueView';
import { useSelectedContract } from '~/providers';

interface MonthlyPaymentViewProps {
  toggleFunc: CallableFunction;
}

const useStyles = makeStyles()(() => ({
  badge: {
    position: 'absolute',
    right: 0,
  },
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
}));

const MonthlyPaymentView: FC<MonthlyPaymentViewProps> = (props: MonthlyPaymentViewProps) => {
  const { installment, isLoading } = useInstallment();
  const { selectedContract } = useSelectedContract();
  const { classes } = useStyles();

  const rows: KeyValueEntries = [
    {
      key: 'Abschlag',
      value: <MoneyLocaleFormatter value={installment?.current?.amount} />,
      alternativeValues: [
        { value: <Skeleton width={200} />, condition: () => isLoading },
        {
          value: (
            <Typography variant="body1">
              <i>Information konnte nicht abgerufen werden</i>
            </Typography>
          ),
          condition: () => !installment?.current?.amount,
        },
      ],
    },
    {
      key: 'Fälligkeitsdatum',
      value: <DateMessageLocaleFormatter date={installment?.current?.nextDueDate} />,
      alternativeValues: [
        { value: <Skeleton width={200} />, condition: () => isLoading },
        {
          value: (
            <Typography variant="body1">
              <i>Information konnte nicht abgerufen werden</i>
            </Typography>
          ),
          condition: () => !installment?.current?.nextDueDate,
        },
      ],
    },
  ];

  return (
    <>
      <PageViewCardTitle
        title={
          <Typography variant="h2" component="div">
            Mein monatlicher Abschlag
          </Typography>
        }
      />
      <KeyValueView className={classes.keyValueView} entries={rows} />
      <CardActions disableSpacing style={{ justifyContent: 'center' }}>
        {selectedContract.state !== Contract_State.TERMINATED ? (
          <Button variant="outlined" color="secondary" onClick={() => props.toggleFunc()}>
            Abschlag ändern
          </Button>
        ) : (
          <Alert key="fetch-failure" variant="filled" severity="info">
            Der Vertrag ist beendet.
          </Alert>
        )}
      </CardActions>
    </>
  );
};

export default MonthlyPaymentView;
