import { useAsyncEffect } from '@enpxio/components';
import useSWR, { Key, useSWRConfig } from 'swr';
import { Fetcher, SWRConfiguration, SWRResponse } from 'swr/dist/types';

export const useCache = <Data, Err extends Error>(
  ...args:
    | readonly [Key]
    | readonly [Key, Fetcher<Data> | null]
    | readonly [Key, SWRConfiguration<Data, Err>]
    | readonly [Key, Fetcher<Data> | null, SWRConfiguration<Data, Err>]
): SWRResponse<Data, Err> & { revalidate: () => void } => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const swr = useSWR(...args);
  const { cache, mutate } = useSWRConfig();

  useAsyncEffect(async () => {
    if (!(cache instanceof Map)) throw new Error('cache is not a Map');
    if (!args[0] || cache.has(args[0]) || swr.isValidating) {
      return;
    }

    await mutate(args[0]);
  }, [args[0]]);

  const revalidate = async (): Promise<Data> => mutate(args[0]);

  return { ...swr, revalidate };
};
