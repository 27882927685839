import { Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@enpxio/components';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { LinkedPage } from '../../marketing/components/marketingEntry';
interface MarketingCardProps {
  title?: string;
  text?: string;
  link: string;
  image?: string;
  buttonText: string;
  linkedPage?: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '540px',
    cursor: 'pointer',
    display: 'contents',
    justifyContent: 'center',
  },
  media: {
    height: 320,
  },
  cardContent: {
    backgroundColor: 'white',
    minHeight: '230px',
    maxHeight: '270px',
  },
  cardActions: {
    backgroundColor: 'white',
    minHeight: '40px',
    padding: '8px', // Fix layout
  },
  link: {
    '&:hover': { textDecoration: 'none' },
  },
  textContainer: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

const MarketingCard: FC<MarketingCardProps> = (props: MarketingCardProps) => {
  const { classes } = useStyles();
  const content = (
    <>
      <CardMedia image={props.image} component="img" />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h2" component="h2">
          {props.title}
        </Typography>
        <Typography variant="body2" component="p" className={classes.textContainer}>
          {props.text}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          color="secondary"
          endIcon={<KeyboardArrowRightIcon />}
          sx={{
            pointerEvents: 'none',
          }}
        >
          {props.buttonText}
        </Button>
      </CardActions>
    </>
  );

  return (
    <Card className={classes.root}>
      {props.linkedPage ? (
        <LinkedPage linkedPage={props.linkedPage}>{content}</LinkedPage>
      ) : (
        <Link href={props.link} target="_blank" rel="noopener" className={classes.link} color="textPrimary">
          {content}
        </Link>
      )}
    </Card>
  );
};

export default MarketingCard;
