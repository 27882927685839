import { Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { Box, Typography } from '@enpxio/components';
import { DateMessageLocaleFormatter, MoneyLocaleFormatter } from '@enpxio/formatters';
import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useInstallment } from '../../installments/useInstallments';
import { PortalPages } from '../../portalPages';
import { DashboardCardButton } from './dashboardCard';
import { SimpleDashboardCard } from './simpleDashboardCard';
import { useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  footer: {
    display: 'flex',
    [theme.breakpoints.down(1140)]: {
      display: 'block',
    },

    '& .MuiBox-root': {
      marginRight: '15px',
    },
  },
}));

const InstallmentCard: FC = () => {
  const { classes } = useStyles();
  const { installment, isLoading, error } = useInstallment();
  const { selectedContract } = useSelectedContract();
  const [buttonContent, setButtonContent] = useState<DashboardCardButton | undefined>();

  useEffect(() => {
    if (selectedContract.state !== Contract_State.TERMINATED) {
      setButtonContent({ text: 'Abschlag ändern', link: PortalPages.invoicesAndPayment.absolutePathname, state: { changePayment: true } });
    } else {
      setButtonContent(undefined);
    }
  }, [selectedContract]);

  return (
    <SimpleDashboardCard
      title="Mein Abschlag"
      value={<MoneyLocaleFormatter value={installment?.current?.amount} minimumFractionDigits={0} maximumFractionDigits={0} />}
      footer={
        <div className={classes.footer}>
          <Typography variant="body1" component="div">
            <Box fontWeight="bold">Nächste Fälligkeit:</Box>
            <DateMessageLocaleFormatter date={installment?.current?.nextDueDate} />{' '}
          </Typography>
        </div>
      }
      isLoading={isLoading}
      isError={error !== undefined}
      button={buttonContent}
    />
  );
};

export default InstallmentCard;
