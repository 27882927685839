import { gql, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@enpxio/components';
import { Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: 300,
    color: theme.palette.text.primary,
  },
  list: {
    color: theme.palette.text.primary,
    width: '100%',
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
  meter: {
    textAlign: 'center',
    display: 'inline-block',
    width: '305px',
  },
  accordionHeader: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const QUERY = gql`
  query pageRegistrationCollectionQuery {
    pageRegistrationCollection {
      items {
        meterExamplesCollection {
          items {
            url
          }
        }
      }
    }
  }
`;

interface PageRegistrationCollectionQueryResult {
  pageRegistrationCollection: {
    items: [
      {
        meterExamplesCollection: {
          items: MeterLink[];
        };
      }
    ];
  };
}

interface MeterLink {
  url?: string;
}

interface Props {
  setCategory: (c: string) => void;
}

export default function RegistrationHelpMeter({ setCategory }: Props) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [meterHint, setMeterHint] = useState(false);
  const { data, loading } = useQuery<PageRegistrationCollectionQueryResult>(QUERY);
  const [meter1, setMeter1] = useState('');
  const [meter2, setMeter2] = useState('');
  const [meter3, setMeter3] = useState('');

  useEffect(() => {
    if (!data) {
      return;
    }

    setMeter1(data.pageRegistrationCollection.items[0].meterExamplesCollection.items[0].url ?? '');
    setMeter2(data.pageRegistrationCollection.items[0].meterExamplesCollection.items[1].url ?? '');
    setMeter3(data.pageRegistrationCollection.items[0].meterExamplesCollection.items[2].url ?? '');
  }, [data]);

  function clearCategory(): void {
    setCategory('');
  }

  function displayMeterHint(): void {
    setMeterHint(true);
  }

  const handleChange = (panel: string) => (_event: ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!meterHint) {
    return (
      <Dialog open className={classes.root}>
        <Typography align="right">
          <Button color="secondary" onClick={clearCategory} autoFocus>
            <CloseIcon fontSize="large" color="secondary" />
          </Button>
        </Typography>
        <DialogContent>
          <Typography variant="body2" align="left">
            <strong>Informationen zur Zählernummer</strong>
          </Typography>
          <Typography variant="body2" className={classes.spaceXL}>
            Ihre Zählernummer finden Sie auf Ihrem Stromzähler. Dieser befindet sich meist im Keller oder Hausflur und kann so aussehen:
          </Typography>
          <Typography variant="body2" className={classes.spaceLG}>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.accordionHeader}>
                <Typography variant="body2">Ferraris-Zähler</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {loading ? (
                    <CircularProgress size={24} key="loading-spinner" color="secondary" />
                  ) : (
                    <img src={meter3} alt="Abbildung Ferraris-Zähler" className={classes.meter} />
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className={classes.accordionHeader}>
                <Typography variant="body2">Elektronischer Haushaltszähler</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {loading ? (
                    <CircularProgress size={24} key="loading-spinner" color="secondary" />
                  ) : (
                    <img src={meter1} alt="Abbildung Elektronischer Haushaltszähler" className={classes.meter} />
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className={classes.accordionHeader}>
                <Typography variant="body2">Moderne Messeinrichtung</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {loading ? (
                    <CircularProgress size={24} key="loading-spinner" color="secondary" />
                  ) : (
                    <img src={meter2} alt="Abbildung moderne Messeinrichtung" className={classes.meter} />
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Typography>

          <Typography variant="body2" className={classes.spaceXL}>
            Nicht fündig geworden?
          </Typography>
          <Button color="secondary" onClick={displayMeterHint} className={classes.spaceXL}>
            <Typography variant="body2">{'>'} Tipps für die Identifizierung der Zählernummer</Typography>
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open className={classes.root}>
      <Typography align="right">
        <Button color="secondary" onClick={clearCategory}>
          <CloseIcon onClick={clearCategory} fontSize="large" color="secondary" />
        </Button>
      </Typography>
      <DialogContent>
        <Typography variant="body2" align="left">
          <strong>Informationen zur Zählernummer</strong>
        </Typography>

        <List className={classes.list} dense>
          <BulletPoint text="Die Zählernummer besteht aus Zahlen oder einer Kombination aus Zahlen und Buchstaben." />
          <BulletPoint text="Die Zählernummer ist NICHT durch Punkte getrennt." />
          <BulletPoint text="Meist befindet sich die Zählernummer direkt neben dem Namen des Netzbetreibers." />
          <BulletPoint text="Bei neueren Zählern befindet sie sich oft in der Nähe des Barcodes." />
          <BulletPoint text="Bei der Zählernummer handelt es sich nicht um rotierende Zahlen." />
        </List>
      </DialogContent>
    </Dialog>
  );
}

const useBulletPointStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: '15px',
    width: '100%',
  },
  variant: {
    color: theme.palette.text.primary,
  },
  bulletPoint: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    width: '20px',
    height: '20px',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}));

interface BulletPointProps {
  text: string;
}

const BulletPoint: FC<BulletPointProps> = (props: BulletPointProps) => {
  const { classes } = useBulletPointStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemIcon>
        <Avatar className={classes.bulletPoint}>
          <Check />
        </Avatar>
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">{props.text}</Typography>
      </ListItemText>
    </ListItem>
  );
};
