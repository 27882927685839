import { FC } from 'react';

interface DateLocaleFormatterProps {
  date: Date | undefined;
}

export const DateLocaleFormatter: FC<DateLocaleFormatterProps> = (props: DateLocaleFormatterProps) => <>{getDateLocale(props.date)}</>;

export function getDateLocale(date: Date | undefined): string {
  if (!date) {
    return '';
  }

  const dateFormatter = new Intl.DateTimeFormat(window.navigator.language, {
    hour12: false,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return dateFormatter.format(date);
}
