import { ErrorReportRequest, ErrorReport_Error } from '@enpowerx/apis/lib/infra/v2';
import { useEffect } from "react";
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './errorFallback';
import { useAPI, useAuth0 } from '~/providers';

const ERROR_STATE_LOCAL_STORAGE = 'epx_portal_error_state';
const ERROR_SEND_INTERVAL = 5000; // Sending errors only every x milliseconds

export interface LocalErrorState {
  errors: ErrorReport_Error[];
  lastSubmit: number;
}

const GeneralErrorBoundary: FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth0();
  const [newErrors, setNewErrors] = useState<boolean>(false);
  const api = useAPI();

  useEffect(() => {
    window.addEventListener('unhandledrejection', (ev) => {
      handleError(ev.reason);
    });
    setNewErrors(true);
  }, []);

  useEffect(() => {
    if (!newErrors) {
      return;
    }

    setNewErrors(false);
    if (!api.isInitialized || !isAuthenticated) {
      return;
    }

    const currentErrorState = getErrorState();
    if (currentErrorState.errors.length === 0) {
      return;
    }

    const currentTimestamp = Date.now();
    if (currentErrorState.lastSubmit < currentTimestamp - ERROR_SEND_INTERVAL) {
      const request: ErrorReportRequest = {
        report: {
          app: 'epx_portal',
          uri: window.location.href,
          userData: {
            userAgent: navigator.userAgent,
            os: navigator.platform,
          },
          errors: currentErrorState.errors,
        },
      };
      api.infra.errorReports.invoke(request);
      currentErrorState.lastSubmit = currentTimestamp;
      currentErrorState.errors = [];
      setErrorState(currentErrorState);
    } else {
      setTimeout(() => {
        setNewErrors(true);
      }, 5000);
    }
  }, [newErrors]);

  const getErrorState = (): LocalErrorState => {
    const localStorageErrorState = window.localStorage.getItem(ERROR_STATE_LOCAL_STORAGE);
    let currentErrorState: LocalErrorState = {
      errors: [],
      lastSubmit: 0,
    };
    if (localStorageErrorState) {
      currentErrorState = JSON.parse(localStorageErrorState);
    }

    return currentErrorState;
  };

  const setErrorState = (newErrorState: LocalErrorState): void => {
    window.localStorage.setItem(ERROR_STATE_LOCAL_STORAGE, JSON.stringify(newErrorState));
  };

  const handleError = (err: Error | undefined): void => {
    if (!err || !err.message) {
      return;
    }

    const currentErrorState = getErrorState();
    const errorObj: ErrorReport_Error = {
      msg: err.message,
      stacktrace: err.stack ? err.stack.toString() : '',
    };
    currentErrorState.errors.push(errorObj);
    setErrorState(currentErrorState);
    setNewErrors(true);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default GeneralErrorBoundary;
