import { Invoice } from '@enpowerx/apis/lib/contracting/v2';
import { Box, CircularProgress, DownloadedFile, Downloader, Link, MimeType, Typography } from '@enpxio/components';
import { DateMessageLocaleFormatter, DateMessagePeriodLocaleFormatter } from '@enpxio/formatters';
import { GetApp } from '@mui/icons-material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useInvoices } from '../../invoices/hooks/useInvoices';
import { PortalPages } from '../../portalPages';
import { sortInvoiceDesc } from './../../utils/invoiceUtils';
import { DashboardCard } from './dashboardCard';
import { SimpleDashboardCard } from './simpleDashboardCard';
import { useAPI, useSelectedContract } from '~/providers';

import {
  differenceInDays,
} from '@enpxio/formatters';
import { toDateMessage } from '@enpowerx/apis/lib/google/type';



const InvoiceCard: FC = () => {
  const { classes } = useStyles();
  const { selectedContract } = useSelectedContract();
  const { invoices, isLoading, error } = useInvoices();
  const [latestInvoice, setLatestInvoice] = useState<Invoice>();

  const view = useMemo(() => {
    if (!isLoading && (!invoices || (invoices.length === 0 && selectedContract.nextInvoice !== undefined))) {
      return (
        <DashboardCard title="Aktuelle Rechnung">
          <Typography variant="body1">
            Ihre erste Rechnung wird voraussichtlich am <DateMessageLocaleFormatter date={selectedContract.nextInvoice} /> erstellt.
          </Typography>
        </DashboardCard>
      );
    }

    return (
      <SimpleDashboardCard
        title="Aktuelle Rechnung"
        value={<DateMessageLocaleFormatter date={latestInvoice?.invoiceDate} />}
        footer={
          <div>
            <Typography variant="body1" component="div" className={classes.invoicePeriod}>
              <Box fontWeight="bold">Zeitraum:&nbsp;</Box>
              <DateMessagePeriodLocaleFormatter startDate={latestInvoice?.startDate} endDate={latestInvoice?.endDate} />
            </Typography>
            <Typography variant="body1" component="div">
              <InvoiceDownloadLink invoice={latestInvoice} />
            </Typography>
          </div>
        }
        isLoading={isLoading}
        isError={error !== undefined}
        button={{
          text: 'Alle Rechnungen',
          link: PortalPages.invoicesAndPayment.absolutePathname,
        }}
      />
    );
  }, [isLoading, error, latestInvoice]);

  useEffect(() => {
    if (!invoices || !selectedContract.nextInvoice) {
      return;
    }

    const latestInvoice = invoices
      .filter((invoice) =>  {
        const dif = differenceInDays(toDateMessage(new Date()),invoice.invoiceDate)
        return !invoice.isCancelled && invoice.annualUsage > 0 && dif !== 0 
      })
      .sort(sortInvoiceDesc)
      .reverse()
      .find(() => true);

    setLatestInvoice(latestInvoice);
  }, [invoices]);

  return view;
};

const useStyles = makeStyles()((theme) => ({
  invoicePeriod: {
    display: 'flex',
    [theme.breakpoints.down(1140)]: {
      display: 'block',
    },

    paddingBottom: theme.namedSpacing('large', 'lg'),
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.namedSpacing('small', 'lg'),
    },
    [theme.breakpoints.down(1024)]: {
      paddingBottom: theme.namedSpacing('small', 'sm'),
    },
  },
}));

const useInvoiceDownloadLinkStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
  hidden: {
    display: 'hidden',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
  },
  downloadIcon: {
    marginTop: '1px',
  },
  progressIcon: {
    color: theme.palette.secondary.main,
    marginLeft: '5px',
  },
}));

interface InvoiceDownloadLinkProps {
  invoice: Invoice | undefined;
}

const InvoiceDownloadLink: FC<InvoiceDownloadLinkProps> = (props: InvoiceDownloadLinkProps) => {
  const { classes, cx } = useInvoiceDownloadLinkStyles();
  const api = useAPI();
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadFile = useCallback(async (): Promise<DownloadedFile> => {
    if (!props.invoice) {
      throw 'invoice not available';
    }

    const invoiceDoc = await api.currentContract.invoices.get(props.invoice.id).file.read();
    return {
      filename: `Rechnung_${props.invoice.invoiceNumber}`,
      mimeType: invoiceDoc.mimeType as unknown as MimeType,
      payload: invoiceDoc.content,
    };
  }, [api, props.invoice]);
  return useMemo(() => {
    if (!props.invoice) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Downloader
          as={Link}
          setIsDownloadingDelegate={setIsDownloading}
          downloadFileDelegate={downloadFile}
          className={cx(classes.link, isDownloading ? classes.disabled : '')}
        >
          <Box sx={{ color: (theme) => theme.palette.secondary.main }} fontWeight="bold">
            Download (PDF)
          </Box>
          {isDownloading ? null : <GetApp sx={{ color: (theme) => theme.palette.secondary.main }} className={classes.downloadIcon} />}
        </Downloader>
        {isDownloading ? <CircularProgress size={24} className={classes.progressIcon} /> : null}
      </div>
    );
  }, [props, isDownloading]);
};

export default InvoiceCard;
