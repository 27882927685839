import { FC, PropsWithChildren } from 'react';

type WideContainerProps = PropsWithChildren<{
  className?: string;
}>;

export const WideContainer: FC<WideContainerProps> = (props) => (
  <div style={{ margin: '0 auto', maxWidth: '1192px' }} className={props.className}>
    {props.children}
  </div>
);
