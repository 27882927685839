import { gql, useQuery } from '@apollo/client';
import { Card, CardContent, Container, Grid, Typography } from '@enpxio/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { RegistrationBenefitsMessage } from '~/registration/registrationBenefitsMessage';

const QUERY = gql`
  query pageRegistrationCollection {
    pageRegistrationCollection(limit: 1) {
      items {
        backgroundImage {
          url
        }
      }
    }
  }
`;

const useStyles = makeStyles<{
  backgroundImageURL: string;
}>()((theme, { backgroundImageURL }) => ({
  root: {
    minWidth: 350,
  },
  background: {
    minWidth: 275,
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -1,
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0,0,0, 0.7)), url("${backgroundImageURL}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  icon: {
    color: theme.palette.success.main,
    width: '100%',
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
}));

const PasswordReset: FC = () => {
  const { data, loading } = useQuery(QUERY);
  const imageUrl = data?.pageRegistrationCollection?.items[0]?.backgroundImage?.url;
  const { classes } = useStyles({ backgroundImageURL: loading || !imageUrl ? '' : imageUrl });
  return (
    <>
      <div className={classes.background} />
      <Container>
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={6} item>
            <RegistrationBenefitsMessage />
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.root}>
              <CardContent>
                <CheckCircleIcon className={classes.icon} style={{ fontSize: 80 }} />
                <Typography variant="body2" className={classes.spaceXL}>
                  Wir haben Ihnen eine E-Mail gesendet. Wenn Sie auf den Link in dieser E-Mail klicken, können Sie ein neues Passwort wählen.
                </Typography>
                <Typography variant="body2" className={classes.spaceLG}>
                  Der Versand der E-Mail kann in Einzelfällen einige Minuten dauern. Bitte haben Sie etwas Geduld. Sollten Sie auch nach 10 Minuten keine E-Mail
                  erhalten haben, starten Sie den Vorgang erneut.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PasswordReset;
