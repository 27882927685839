/// <reference types="vite/client" />

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app';

type LogFunction = (...values: unknown[]) => void;

function getModifiedLog(original: LogFunction) {
  return (...args: unknown[]) => {
    const modified = [...args].map((value: unknown) => {
      if (typeof value === 'string') {
        const lines = value.split('\n');
        return lines.length > 1 ? lines.slice(0, 3).join('\n') : value;
      }

      return value;
    });

    Function.prototype.apply.call(original, console, modified);
  };
}

// Reduce verbosity of error/warn log messages
console.error = getModifiedLog(console.error);
console.warn = getModifiedLog(console.warn);

// Debug Version Info
function logVersion() {
  const desc = import.meta.env.VITE_GIT_VERSION as string;
  const date = new Date(parseInt((import.meta.env.VITE_GIT_DATE as string) + '000', 10));
  console.log(`VERSION: ${desc} – ${date.toLocaleString()}`);
}

logVersion();

// React v18 mode:
// - Note: StrictMode is disabled for now. The double execution of useEffect breaks our own asyncEffect right now.
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
