import { DependencyList, MutableRefObject, useEffect, useRef, useState } from 'react';

/**
 * Accepts a function that contains imperative, possibly effectful async code.
 * @param abortSignal A signal to pass to underlying components.
 * @param isMounted Indicating whether the component is mounted or not.
 */
export type AsyncEffectCallback = (abortSignal: AbortSignal, isMounted: MutableRefObject<boolean>) => Promise<void> | (() => Promise<void>);
export type CleanupFunction = () => void;

// Helper to have old React v17 behavior on useEffect for our useAsyncEffect helper.
// This one breaks with the native useEffect in React v18 StrictMode otherwise.
// FIXME: This has to be replaced in the future! Just a work around. For data loading
// needs we should probably switch to SWR or React Query. For all other needs it
// has to be verified why a "native" useEffect is not working correctly.
// Via: https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
export const useEffectOnce = (effect: () => void | (() => void), deps: DependencyList = []) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [_val, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // Only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // This forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // If the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }

      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, deps);
};

/**
 * Executing an longer running effect with aborting functionality?
 *
 * @deprecated
 * @param effect
 * @param deps
 * @param cleanup
 */
export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList, cleanup?: CleanupFunction): void {
  const abortController = new AbortController();
  const isMountedRef = useRef(false);

  useEffectOnce(() => {
    isMountedRef.current = true;
    effect(abortController.signal, isMountedRef);
    return (): void => {
      isMountedRef.current = false;
      abortController.abort();
      if (cleanup) {
        cleanup();
      }
    };
  }, deps);
}
