import { Money } from '@enpowerx/apis/lib/google/type';
import Big from 'big.js';
import { FC } from 'react';

interface MoneyLocaleFormatterProps {
  value: Money | undefined;
  hideCurrency?: boolean;
  // Shows the fraction unit when unit equals zero (e.g. 0,15 Cent instead of 0,15 EUR).
  showFractionUnit?: boolean;
  // change -150,13 to 150,13
  withSignChange?:boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  prefix?: string;
  suffix?: string;
}

const nanoFactor = new Big(1_000_000_000);

export const MoneyLocaleFormatter: FC<MoneyLocaleFormatterProps> = (props: MoneyLocaleFormatterProps) => {
  if (!props.value) {
    return null;
  }
  if(props.withSignChange) {
    return <>{FormatMoneyWithSignChange(props)}</>;
  }

  return <>{FormatMoney(props)}</>;
};

export function FormatMoneyWithSignChange(opts: MoneyLocaleFormatterProps): string {
  if (!opts.value) {
    return '';
  }

  const numberFormatter = new Intl.NumberFormat(window.navigator.language, {
    currency: opts.value?.currencyCode,
    minimumFractionDigits: opts.minimumFractionDigits,
    maximumFractionDigits: opts.maximumFractionDigits,
  });
  const [moneyValue, currency] = getNumberFromMoney(opts.value, opts.showFractionUnit);
  if(moneyValue.toNumber() === 0) {
    return `${opts.prefix ?? ''}${numberFormatter.format(moneyValue.toNumber())}${opts.hideCurrency ? '' : ` ${currency}`}${opts.suffix ?? ''}`;
  }
  return `${opts.prefix ?? ''}${numberFormatter.format(-moneyValue.toNumber())}${opts.hideCurrency ? '' : ` ${currency}`}${opts.suffix ?? ''}`;
}

export function FormatMoney(opts: MoneyLocaleFormatterProps): string {
  if (!opts.value) {
    return '';
  }

  const numberFormatter = new Intl.NumberFormat(window.navigator.language, {
    currency: opts.value?.currencyCode,
    minimumFractionDigits: opts.minimumFractionDigits,
    maximumFractionDigits: opts.maximumFractionDigits,
  });
  const [moneyValue, currency] = getNumberFromMoney(opts.value, opts.showFractionUnit);
  return `${opts.prefix ?? ''}${numberFormatter.format(moneyValue.toNumber())}${opts.hideCurrency ? '' : ` ${currency}`}${opts.suffix ?? ''}`;
}

export function getNumberFromMoney(money?: Money, withFractionUnit = false): [Big, string] {
  let moneyValue: Big;
  let currency = money?.currencyCode ?? 'EUR';
  if (withFractionUnit && (money?.units === '0' || money?.units === undefined)) {
    moneyValue = new Big(money?.nanos ?? 0).div(nanoFactor).mul(100);
    currency = getFractionCurrencyUnit(money?.currencyCode);
  } else if (money?.nanos) {
    if (money?.units) {
      moneyValue = new Big(money.units).add(new Big(money?.nanos).div(nanoFactor));
    } else {
      moneyValue = new Big(money?.nanos ?? '0').div(nanoFactor);
    }
  } else {
    moneyValue = new Big(money?.units ?? '0');
  }

  return [moneyValue, currency];
}

function getFractionCurrencyUnit(currencyCode?: string): string {
  switch (currencyCode) {
    case 'EUR':
      return 'Cent';
    default:
      return '';
  }
}

MoneyLocaleFormatter.defaultProps = {
  hideCurrency: false,
  showFractionUnit: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
