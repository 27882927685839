import { ApiError, Trait, hasTrait } from '@enpowerx/apis';
import { Money } from '@enpowerx/apis/lib/google/type';
import {
  Backdrop,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@enpxio/components';
import { DateMessageLocaleFormatter, MoneyLocaleFormatter } from '@enpxio/formatters';
import { SaveOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useInstallment } from '../../installments/useInstallments';
import { GoogleProtobufAny, useAPI, useReportingAPI, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  title: {
    margin: theme.spacing(5, 5, 5, 5),
  },
  subTitle: {
    padding: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 0, 3, 0),
    },
  },
  formControl: {
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '60%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(5, 0, 5, 5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface MonthlyPaymentEditProps {
  toggleFunc: CallableFunction;
}

const MonthlyPaymentEdit: FC<MonthlyPaymentEditProps> = (props: MonthlyPaymentEditProps) => {
  const installmentRegex = /^\d+$/;
  const { classes } = useStyles();
  const api = useAPI();
  const { installment, invalidate: invalidateInstallmentCache } = useInstallment();
  const currentInstallment = installment?.pending ?? installment?.current;
  const { trackEvent } = useReportingAPI();

  const { selectedContract } = useSelectedContract();

  const [amountHelperText, setAmountHelperText] = useState<ReactNode | string>('');
  const [amount, setAmount] = useState(currentInstallment?.amount ? currentInstallment?.amount?.units.toString() : '');
  const [isRequestPending, setIsRequestPending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setAmount(event.target.value);
  };

  function validateInstallmentValue(monthlyInstallment: string): ReactNode {
    // Check whether input is a number without decimals
    if (installmentRegex.exec(monthlyInstallment) === null) {
      return 'Bitte verwenden Sie zur Eingabe ausschließlich Ziffern.';
    }

    // Check whether input is higher than lower limit
    if (
      installment?.minimumAmount &&
      installment.minimumAmount.units &&
      Number.parseInt(monthlyInstallment) < Number.parseInt(installment.minimumAmount.units)
    ) {
      return (
        <MoneyLocaleFormatter
          prefix="Der gewählte Abschlag ist zu niedrig. Bitte wählen Sie mindestens "
          value={installment.minimumAmount}
          suffix=" oder wenden Sie sich an unseren Kundenservice."
        />
      );
    }

    // Check whether input is lower than upper limit
    if (
      installment?.maximumAmount &&
      installment.maximumAmount.units &&
      Number.parseInt(monthlyInstallment) > Number.parseInt(installment.maximumAmount.units)
    ) {
      return (
        <MoneyLocaleFormatter
          prefix="Der gewählte Abschlag ist zu hoch. Bitte wählen Sie höchstens "
          value={installment.maximumAmount}
          suffix=" oder wenden Sie sich an unseren Kundenservice."
        />
      );
    }

    return '';
  }

  function validateInstallment(): boolean {
    const message = validateInstallmentValue(amount.trim());
    setAmountHelperText(message);
    return message === '';
  }

  async function saveInstallment(): Promise<void> {
    if (!validateInstallment()) {
      return;
    }

    const newAmount: Money = {
      currencyCode: currentInstallment?.amount ? currentInstallment?.amount.currencyCode : 'EUR',
      nanos: 0,
      units: amount,
    };

    try {
      const event: GoogleProtobufAny = {
        '@type': 'type.googleapis.com/enpowerx.reporting.v1.InstallmentChange',
        contract: selectedContract.id,
        customer: selectedContract.customer,
        amount: newAmount,
      };
      trackEvent('InstallmentChange', event);
      setIsRequestPending(true);
      await api.currentContract.installment.set(newAmount);
      await invalidateInstallmentCache();
      props.toggleFunc();
      enqueueSnackbar('Abschlagsänderung erfolgreich übermittelt', {
        variant: 'success',
      });
      props.toggleFunc();
      setIsRequestPending(false);
    } catch (error_) {
      const error = error_ as ApiError;
      console.error(`Error occurred: ${JSON.stringify(error)}`);
      if (hasTrait(error, Trait.InvalidArgument)) {
        setAmountHelperText(error.message ?? 'Der gewählte Abschlag ist ungültig');
      } else {
        setAmountHelperText('Der Abschlag konnte nicht geändert werden. Versuchen Sie es nochmal');
      }

      setIsRequestPending(false);
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isRequestPending}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardContent>
        <Typography style={{ display: 'inline-block' }} variant="h2" component="h2">
          Ihr aktueller Abschlag: <MoneyLocaleFormatter value={currentInstallment?.amount} />
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.subTitle}>
          Geben Sie bitte Ihren gewünschten monatlichen Abschlag an:
        </Typography>
      </CardContent>
      <CardContent>
        <Grid container justifyContent="space-around">
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Abschlag</InputLabel>
            <OutlinedInput
              label="Abschlag"
              id="outlined-adornment-amount"
              value={amount}
              onChange={handleChange}
              startAdornment={<Typography color="textPrimary">€</Typography>}
            />
            <FormHelperText disabled={!amount} error>
              {amountHelperText}
            </FormHelperText>
          </FormControl>
        </Grid>
        <br />
        <Grid container justifyContent="space-around" className={classes.formControl}>
          <div className={classes.formControl}>
            <h4>
              Nächste Fälligkeit: <DateMessageLocaleFormatter date={currentInstallment?.nextDueDate} />
            </h4>
          </div>
        </Grid>
      </CardContent>
      <CardActions disableSpacing style={{ justifyContent: 'center' }}>
        <Button variant="outlined" color="secondary" onClick={async () => saveInstallment()} startIcon={<SaveOutlined />}>
          Speichern
        </Button>
        <span style={{ width: '4px' }} />
        <Button variant="outlined" color="secondary" onClick={() => props.toggleFunc()}>
          Abbrechen
        </Button>
      </CardActions>
    </div>
  );
};

export default MonthlyPaymentEdit;
