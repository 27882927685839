import { Hidden, Tab, Tabs, Toolbar } from '@enpxio/components';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, withStyles } from 'tss-react/mui';

import { PageFlags } from '../../providers/pages/pages';
import { usePages } from '../../providers/pages/provider';
import { useAuth0, useSelectedContract } from '~/providers';

export const NavigationBar: FunctionComponent = () => {
  const { classes, cx } = styles();
  const { activePage, pages } = usePages();
  const { isAuthenticated } = useAuth0();
  const { selectedContract } = useSelectedContract();
  const [activePagePathname, setActivePagePathname] = useState<string | false>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (activePage?.hasFlag(PageFlags.NavigationBar)) {
      setActivePagePathname(activePage?.relativePathname);
      return;
    }

    setActivePagePathname(false);
  }, [activePage]);

  if (!isAuthenticated || (isAuthenticated && !selectedContract.id)) {
    return null;
  }

  const tabs = pages
    .filterTransitive((page) => page.hasFlag(PageFlags.NavigationBar))
    .map((page) => {
      return (
        <StyledTab
          label={page.displayName}
          key={page.displayName}
          onClick={() => {
            navigate(page.absolutePathname);
          }}
          hidden={page.hasFlag(PageFlags.Authenticated) ? !isAuthenticated : false}
          value={page.relativePathname}
        />
      );
    });

  return (
    <Hidden mdDown>
      {!activePage?.hasFlag(PageFlags.AuthenticatedNoMenu) ? (
        <Toolbar className={cx(classes.tabContainer)}>
          <Tabs value={activePagePathname} className={classes.tabsFlexContainer} indicatorColor="primary" textColor="inherit">
            {tabs.map((tab) => tab)}
          </Tabs>
        </Toolbar>
      ) : null}
    </Hidden>
  );
};

const styles = makeStyles()((theme) => ({
  tabContainer: {
    width: '100%',
    minHeight: '42px',
    height: '42px',
    backgroundColor: theme.palette.primary.light,
    justifyContent: 'center',
  },
  tabsFlexContainer: {
    height: '100%',
    minHeight: '42px',
  },
}));

const StyledTab = withStyles(Tab, (theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'white',
      opacity: 1,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    fontSize: '20px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: '5px',
    paddingTop: '2px',
    minWidth: '0px',
  },
  textColorInherit: {
    color: `${theme.palette.text.primary} !important`,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  selected: {
    backgroundColor: 'white',
    opacity: 1,
  },
}));
