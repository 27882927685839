import { gql, useQuery } from '@apollo/client';
import { ApiError } from '@enpowerx/apis';
import { MeterInfo } from '@enpowerx/apis/lib/contracting/v2';
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  PageView,
  PageViewCard,
  Skeleton,
  Typography,
  useConfig,
} from '@enpxio/components';
import { RecaptchaNotice, loadRecaptcha } from '@enpxio/components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { PortalPages } from '../portalPages';
import { useAPI } from '~/providers';

const QUERY = gql`
  query supportCollectionQuery {
    supportCollection {
      items {
        email
      }
    }
  }
`;

interface Values {
  previousProvider: string;
  meterNumber: string;
}

type PageState = 'loading' | 'form' | 'success' | 'error';

function LoadingState() {
  return (
    <>
      <PageView title="Jetzt Daten ergänzen..." wide>
        <PageViewCard>
          <CardContent>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h2" component="h2">
                  <Skeleton width="25" />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container justifyContent="center" direction="row" alignItems="center">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5} />
              <Grid item xs={2}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={5} />
            </Grid>
          </CardContent>
        </PageViewCard>
      </PageView>
      <RecaptchaNotice />
    </>
  );
}

interface FormStateProps {
  meterInfoTokenContext: MeterInfo;
  siteKey: string;
  token: string;
  setState: (value: PageState) => void;
  setError: (value: unknown) => void;
}

function FormState({ meterInfoTokenContext, siteKey, token, setState, setError }: FormStateProps) {
  const api = useAPI();

  const initialValues: Values = {
    previousProvider: meterInfoTokenContext?.previousProvider ?? '',
    meterNumber: meterInfoTokenContext?.meterNumber ?? '',
  };

  const handleSubmit = async (values: Values): Promise<void> => {
    try {
      const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'set_meter_info' });
      await api.contracting.tokens
        .get(token ?? '')
        .meterInfo(recaptchaToken)
        .set({ meterNumber: values.meterNumber, previousProvider: initialValues.previousProvider ?? '' });
      setState('success');
    } catch (error) {
      console.log(JSON.stringify(error));
      setState('error');
      setError(error);
    }
  };

  return (
    <>
      <PageView title="Jetzt Daten ergänzen" subTitle="Bitte ergänzen Sie jetzt die fehlenden Daten für Ihren Wechsel zu uns." wide>
        <PageViewCard>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={yup.object({
              meterNumber: yup.string().required('Dies ist ein Pflichtfeld.'),
            })}
          >
            {({ submitForm, dirty, isValid }) => (
              <Form>
                <CardContent>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid display="flex" item md={12} lg={12} justifyContent="center">
                      <FormControl variant="outlined" sx={{ minWidth: '60%', marginBottom: '12px' }}>
                        <InputLabel htmlFor="outlined-meterNumber">Zählernummer</InputLabel>
                        <Field id="outlined-meterNumber" as={OutlinedInput} name="meterNumber" />
                        <FormHelperText error>
                          <ErrorMessage name="meterNumber" />
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                  <Button variant="outlined" color="secondary" onClick={submitForm} disabled={!(isValid && dirty)}>
                    Speichern
                  </Button>
                  <span style={{ width: '4px' }} />
                  <Button variant="outlined" color="secondary">
                    Abbrechen
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        </PageViewCard>
      </PageView>
      <RecaptchaNotice />
    </>
  );
}

interface ErrorStateProps {
  error: unknown;
}

function ErrorState({ error }: ErrorStateProps) {
  const { data } = useQuery(QUERY);
  const invalidToken = error instanceof ApiError && error.message === 'verification token not found';
  const email = data?.supportCollection?.items[0]?.email || '...';
  const title = invalidToken ? 'Die Datenerfassung kann nicht mehr durchgeführt werden' : 'Unbekannter Fehler';
  const subTitle = invalidToken
    ? `Diese Seite wurde entweder bereits verwendet, oder sie ist wegen der zeitlichen Begrenzung abgelaufen. Bei Fragen und Fehlern kontaktieren Sie bitte unseren Kundenservice unter der Email-Adresse ${email}.`
    : `Bitte kontaktieren Sie unseren Kundenservice unter der Email-Adresse ${email}.`;

  return (
    <PageView title={title} subTitle={subTitle} wide>
      <Typography>
        Hier geht&apos;s zur
        <Link href={PortalPages.home.absolutePathname} color="secondary">
          Startseite
        </Link>
        .
      </Typography>
      <br />
    </PageView>
  );
}

function SuccessState() {
  return (
    <PageView title="Vielen Dank!" subTitle="Wir haben Ihre Zählernummer gespeichert." wide>
      <Typography>
        Hier geht&apos;s zur
        <Link href={PortalPages.home.absolutePathname} color="secondary">
          Startseite
        </Link>
        .
      </Typography>
      <br />
    </PageView>
  );
}

const PostAcquisitionPage: FC = () => {
  const params = useParams();
  let token = '';
  if (params && 'token' in params) {
    token = params.token ?? '';
  }

  const config = useConfig();
  const siteKey = config.recaptcha.siteKey;

  const api = useAPI();

  const [state, setState] = useState<PageState>('loading');
  const [error, setError] = useState<unknown>();
  const [meterInfoTokenContext, setMeterInfoTokenContext] = useState<MeterInfo>();

  useEffect(() => {
    /** Ignore destroyed component updates */
    let ignoreUpdates = false;

    async function updateState() {
      try {
        await loadRecaptcha(siteKey);
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'get_meter_info' });
        const tokenContext = await api.contracting.tokens
          .get(token ?? '')
          .meterInfo(recaptchaToken)
          .read();

        if (ignoreUpdates) {
          return;
        }

        setMeterInfoTokenContext(tokenContext);
        setState('form');
      } catch (error) {
        if (ignoreUpdates) {
          return;
        }

        console.error(error);
        setError(error);
        setState('error');
      }
    }

    updateState();
    return () => {
      ignoreUpdates = true;
    };
  }, [token, siteKey]);

  switch (state) {
    case 'loading':
      return <LoadingState />;

    case 'form':
      return <FormState meterInfoTokenContext={meterInfoTokenContext!} setState={setState} setError={setError} siteKey={siteKey} token={token} />;

    case 'error':
      return <ErrorState error={error} />;

    case 'success':
      return <SuccessState />;
  }
};

export default PostAcquisitionPage;
