import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

interface DivisionIconProp {
  src: string;
  altText?: string;
}

export const DivisionIcon: FC<DivisionIconProp> = (props: DivisionIconProp) => {
  const { classes } = useStyles();
  return (
    <div className={classes.iconContainer}>
      <img src={props.src} className={classes.iconClass} alt={props.altText} />
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  iconContainer: {
    width: '20px',
    height: '30px',
  },
  iconClass: {
    maxWidth: '20px',
    maxHeight: '20px',
  },
}));
