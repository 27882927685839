import { useQuery, gql } from '@apollo/client';
import { Contract, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { RegisterType } from '@enpowerx/apis/lib/metering/v2';
import { Alert, Button, CardActions, PageViewCardTitle, Skeleton, Table, TableCell, TableContainer, TableRow, Typography } from '@enpxio/components';
import { CounterNameLocaleFormatter, CounterReadingLocaleFormatter, DateMessageLocaleFormatter } from '@enpxio/formatters';
import { styled } from '@mui/material';
import { FC } from 'react';

import { useActiveMeterNumber } from '../hooks/useActiveMeter';
import { useLatestMeterReading } from '../hooks/useLatestMeterReading';
import { KeyValueEntries, KeyValueEntryDivider, KeyValueView } from '~/keyValueView';
import { useSelectedContract } from '~/providers';

// FIXME: Switch to usage of TSS to be in sync with other code
const AlignedKeyValues = styled(KeyValueView)(() => ({
  '& td:nth-of-type(odd)': {
    textAlign: 'left',
    width: '26%',
  },
  '& td:nth-of-type(even)': {
    textAlign: 'left',
  },
}));

interface MeterpointInfoProps {
  toggleFunc: CallableFunction;
}

const QUERY = gql`
  query supportCollectionQuery {
    supportCollection(limit: 1) {
      items {
        phonenumber
      }
    }
  }
`;

const MeterpointInfo: FC<MeterpointInfoProps> = (props: MeterpointInfoProps) => {
  const { toggleFunc } = props;
  const { selectedContract } = useSelectedContract();
  const { registers } = useLatestMeterReading();
  const { activeMeterNumber, isLoading } = useActiveMeterNumber();
  const readonly = isContractTerminated(selectedContract);
  const entries: KeyValueEntries = [];
  const { data } = useQuery(QUERY);
  const phoneNumber = data?.supportCollection?.items[0]?.phonenumber;

  const EmptyView: FC = () => {
    return (
      <>
        <Typography variant="subtitle2" component="h2">
          {`Uns fehlen Daten zu Ihrem Zähler. Bitte melden Sie sich bei uns telefonisch unter der Nummer ${phoneNumber}. Halten Sie dabei Ihre aktuellste Zählernummer und Ihre Kundendaten bereit.`}
        </Typography>
        <br />
      </>
    );
  };

  if (activeMeterNumber) {
    entries.push({
      key: 'Zählernummer',
      value: activeMeterNumber,
    });
  }

  if (registers) {
    for (const register of registers) {
      let key = '';
      switch (register.type) {
        case RegisterType.SINGLE_TARIFF:
          key = 'Letzter Zählerstand';
          break;
        case RegisterType.DOUBLE_TARIFF_PEAK:
          key = 'Letzter Zählerstand (HT)';
          break;
        case RegisterType.DOUBLE_TARIFF_OFF_PEAK:
          key = 'Letzter Zählerstand (NT)';
          break;
        default:
          key = 'Letzter Zählerstand';
          console.error(`Unknown register type ${register.type}`);
      }

      entries.push({
        key,
        value: <CounterReadingLocaleFormatter value={register.reading.value ?? 0} energyType={selectedContract.delivery?.energyType} obisCode={register.obisCode} />,
      });
    }

    entries.push({
      key: 'Ablesedatum',
      value: <DateMessageLocaleFormatter date={registers[0].reading.date} />,
    });

    if (selectedContract.state === Contract_State.TERMINATED) {
      entries.push({
        key: 'Vertragsstatus',
        value: 'beendet',
      });
    }

    entries.push(KeyValueEntryDivider);

    if (readonly) {
      entries.push({
        key: '',
        value: (
          <Alert key="fetch-failure" variant="filled" severity="info" style={{ width: 'fit-content' }}>
            Bei diesem Zähler können keine Zählerstände erfasst werden.
          </Alert>
        ),
      });
    }
  }

  return (
    <>
      <PageViewCardTitle
        title={
          <>
            Mein <CounterNameLocaleFormatter energyType={selectedContract.delivery?.energyType} />
          </>
        }
      />
      {isLoading ? (
        <TableContainer>
          <Table>
            {[...new Array(3)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton width="30%" />
                </TableCell>
                <TableCell>
                  <Skeleton width="90%" />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      ) : null}
      {entries.length === 0 && !isLoading ? (
        <EmptyView />
      ) : (
        <>
          {!isLoading ? (
            <>
              <AlignedKeyValues entries={entries} />
              <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                {!readonly ? (
                  <Button
                    key="addReadingButton"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      toggleFunc();
                    }}
                  >
                    Zählerstand eingeben
                  </Button>
                ) : null}
              </CardActions>
            </>
          ) : null}
        </>
      )}
      {}
    </>
  );
};

export const isContractTerminated = (contract: Contract): boolean => {
  if (contract.state === Contract_State.TERMINATED) {
    return true;
  }

  if (contract.activeRange?.end) {
    const currentDate = new Date();
    const contractDate = new Date();
    contractDate.setFullYear(contract.activeRange?.end.year, contract.activeRange?.end.month, contract.activeRange?.end.day);
    if (currentDate > contractDate) {
      return true;
    }
  }

  return false;
};

export default MeterpointInfo;
