import { Person, Person_SalutationForm } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

import { SalutationLocaleFormatter } from './salutation';

interface PersonLocaleFormatterProps {
  value: Person | undefined;
}

export const PersonLocaleFormatter: FC<PersonLocaleFormatterProps> = (props: PersonLocaleFormatterProps) => {
  if (!props.value) {
    return null;
  }

  if (
    props.value.salutation !== Person_SalutationForm.MR &&
    props.value.salutation !== Person_SalutationForm.MRS &&
    props.value.salutation !== Person_SalutationForm.FAMILY
  ) {
    return (
      <>
        {props.value.firstname} {props.value.lastname}
      </>
    );
  }

  return (
    <>
      <SalutationLocaleFormatter salutation={props.value.salutation} />
      &nbsp;
      {props.value.firstname} {props.value.lastname}
    </>
  );
};
