import { gql, useQuery } from '@apollo/client';
import { PageView } from '@enpxio/components';
import { Location } from 'history';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MarketingViewCard from '../marketing/components/marketingViewCard';
import AddressCard from './pageControls/address/addressCard';
import AdvertisingConsent from './pageControls/advertisingConsent/advertisingConsent';
import CustomerInfoCard from './pageControls/customer/customerInfoCard';
import LoginData from './pageControls/loginData';
import PaymentInfoCard from './pageControls/payment/paymentInfoCard';

const QUERY = gql`
  query pageMyDataCollectionQuery {
    pageMyDataCollection(limit: 1) {
      items {
        marketingEntryCollection {
          items {
            title
            description
            buttonText
            linkedPage {
              linkedPage
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

interface LocationState extends Location {
  editBillingAddress?: boolean;
  editBank?: boolean;
}

const PageMyData: FC = () => {
  const { data, loading } = useQuery(QUERY);
  const marketingItems = data?.pageMyDataCollection?.items[0]?.marketingEntryCollection?.items;
  const location = useLocation();

  return (
    <>
      <PageView title="Meine Daten" subTitle="Hier finden Sie alle Details zu Ihren Daten." wide>
        <CustomerInfoCard />
        <PaymentInfoCard
          edit={(location.state as LocationState)?.editBank ?? (location.state as LocationState)?.editBillingAddress}
          focusBankAccount={(location.state as LocationState)?.editBank}
        />
        <LoginData />
        <AddressCard
          edit={(location.state as LocationState)?.editBank ?? (location.state as LocationState)?.editBillingAddress}
          focusBillingAddress={(location.state as LocationState)?.editBillingAddress}
        />
        <AdvertisingConsent />
      </PageView>
      {loading || marketingItems?.length === 0 ? null : <MarketingViewCard marketingItems={marketingItems} />}
    </>
  );
};

export default PageMyData;
