import { gql, useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, INLINES } from '@contentful/rich-text-types';
import { Grid, Link, PageView } from '@enpxio/components';
import { RecaptchaNotice } from '@enpxio/components';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ContactCard from './cards/contactCard';
import FAQCard from './cards/faqCard';

const QUERY = gql`
  query pageContactCollectionQuery {
    pageContactCollection {
      items {
        topics
        faqCollection {
          items {
            key
            richText {
              json
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  recaptcha: {
    paddingTop: theme.spacing(4),
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface FAQElement {
  key: string;
  richText: {
    json: Document;
  };
}

export interface FAQ {
  question: string;
  answer: ReactNode;
}

export const PageContact: FC = () => {
  const { data, loading } = useQuery(QUERY);
  const [topics, setTopics] = useState<string[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { classes } = useStyles();
  const params = useSearchParams();

  useEffect(() => {
    if (params.length > 1) {
      setSearchText(params[0].get('search') ?? '');
    }

    if (data !== undefined) {
      setTopics(data?.pageContactCollection?.items[0]?.topics);
      const faqElements: FAQElement[] = data?.pageContactCollection?.items[0]?.faqCollection.items;
      const faqsTmp: FAQ[] = [];

      for (const [, faqElement] of faqElements.entries()) {
        const faq = {
          question: faqElement.key,
          answer: documentToReactComponents(faqElement.richText.json, {
            renderNode: {
              [INLINES.HYPERLINK]: (node, children) => {
                if (node.data.uri.startsWith('/')) {
                  return (
                    <Link className={classes.link} to={node.data.uri} component={RouterLink}>
                      {children}
                    </Link>
                  );
                }

                return (
                  <Link className={classes.link} href={node.data.uri} target="_blank" rel="noopener noreferrer" component="a">
                    {children}
                  </Link>
                );
              },
            },
          }),
        };
        faqsTmp.push(faq);
      }

      setFaqs(faqsTmp);
    }
  }, [data]);

  return (
    <PageView title="Hilfe & Kontakt" wide>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <Grid spacing={10} container>
            <Grid xl={6} lg={6} md={12} sm={12} item>
              <FAQCard title="Wird Ihre Frage hier schon beantwortet?" faqs={faqs} loading={loading} searchText={searchText} />
            </Grid>
            <Grid xl={6} lg={6} md={12} sm={12} item>
              <ContactCard title="Oder schreiben Sie uns" topics={topics} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.recaptcha}>
        <RecaptchaNotice />
      </div>
    </PageView>
  );
};

export default PageContact;
