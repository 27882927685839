import { Invoice } from '@enpowerx/apis/lib/contracting/v2';

import { useCache } from '../../meters/hooks/useCache';
import { useAPI, useSelectedContract } from '~/providers';

interface InvoicesState {
  invoices: Invoice[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  invalidate: () => void;
}

export const useInvoices = (): InvoicesState => {
  const api = useAPI();
  const { selectedContract } = useSelectedContract();
  const {
    data: invoices,
    error,
    revalidate: invalidate,
    isValidating: isLoading,
  } = useCache(selectedContract.id ? `invoices/${selectedContract.id}` : null, async () => (await api.currentContract.invoices.list(100))?.invoices ?? []);

  return {
    invoices,
    error,
    invalidate,
    isLoading,
  };
};
