import { gql, useQuery } from '@apollo/client';
import { ApiError } from '@enpowerx/apis';
import { Contract } from '@enpowerx/apis/lib/contracting/v2';
import { MeterTokenContext } from '@enpowerx/apis/lib/metering/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';
import { CardContent, Grid, Link, PageView, PageViewCard, Skeleton, Typography, useConfig } from '@enpxio/components';
import { RecaptchaNotice, loadRecaptcha } from '@enpxio/components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PortalPages } from '../portalPages';
import { ContractTypeIcon } from '../svg/contractTypeIcon';
import MeterPointInputForm from './pageControls/meterpointInputForm';
import { useAPI, defaultContract } from '~/providers';

const QUERY = gql`
  query supportCollectionQuery {
    supportCollection {
      items {
        email
      }
    }
  }
`;

type PageState = 'loading' | 'form' | 'success' | 'error'

interface ExtendedError extends ApiError {
  value: {
    traits: string | string[];
  };
}

function LoadingState() {
  return (
    <>
      <PageView title="Zählerstandserfassung..." wide>
        <PageViewCard>
          <CardContent>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h2" component="h2">
                  <Skeleton width="25" />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container justifyContent="center" direction="row" alignItems="center">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5} />
              <Grid item xs={2}>
                <Skeleton height="60px" />
              </Grid>
              <Grid item xs={5} />
            </Grid>
          </CardContent>
        </PageViewCard>
      </PageView>
      <RecaptchaNotice />
    </>
  );
}

const buildFakeContract = (context: MeterTokenContext | undefined): Contract => {
  if (context === undefined) {
    return defaultContract;
  }

  const fakeContract = defaultContract;
  fakeContract.id = context.meter?.id ?? '';
  if (fakeContract.delivery !== undefined) {
    fakeContract.delivery.energyType = context.energyType;
  }

  return fakeContract;
};

const buildMeterName = (context?: MeterTokenContext) => {
  if (context === undefined) {
    return '';
  }

  switch (context.energyType) {
    case EnergyType.ELECTRICITY:
      return 'Strom-Zählernummer';
    case EnergyType.GAS:
      return 'Gas-Zählernummer';
  }
};

interface FormStateProps {
  meterTokenContext?: MeterTokenContext;
  token: string;
  onSuccess: () => void;
}

function FormState({ meterTokenContext, token, onSuccess }: FormStateProps) {
  return (
    <>
      <PageView title="Zählerstandserfassung" subTitle="Bitte geben Sie ihren aktuellen Verbrauch an." wide>
        <PageViewCard>
          <CardContent>
            <Typography variant="h2" component="h2">
              {/* Note: Passing `classes` is not supported by ContractTypeIcon: classes={iconClasses}. */}
              <ContractTypeIcon contract={buildFakeContract(meterTokenContext)} isLoading={false} /> {buildMeterName(meterTokenContext)}:{' '}
              {meterTokenContext?.meter?.id}
            </Typography>
          </CardContent>
          <MeterPointInputForm
            meterPoint={meterTokenContext?.meter?.id ? meterTokenContext.meter.id : ''}
            energyType={meterTokenContext?.energyType}
            token={token}
            meterTokenContext={meterTokenContext}
            callback={onSuccess}
          />
        </PageViewCard>
      </PageView>
      <RecaptchaNotice />
    </>
  );
}

interface ErrorStateProps {
  error: unknown;
}

function HomePageLink() {
  return (
    <Typography>
      Hier geht&apos;s zur{' '}
      <Link href={PortalPages.home.absolutePathname} color="secondary">
        Startseite
      </Link>
      .
    </Typography>
  );
}

function ErrorState({ error }: ErrorStateProps) {
  const { data } = useQuery(QUERY);

  const apiErrorOrNative = error as ExtendedError;
  const email = data?.supportCollection?.items[0]?.email;
  const notFoundError = apiErrorOrNative.value?.traits?.includes('not_found');

  const title = notFoundError ? 'Die Zählerstandserfassung kann nicht mehr durchgeführt werden' : 'Unbekannter Fehler';
  const subTitle = notFoundError
    ? `Diese Seite wurde entweder bereits verwendet, oder sie ist wegen der zeitlichen Begrenzung abgelaufen. Bei Fragen und Fehlern kontaktieren Sie bitte unseren Kundenservice unter der Email-Adresse ${email}.`
    : `Bitte kontaktieren Sie unseren Kundenservice unter der Email-Adresse ${email}.`;
  return (
    <PageView title={title} subTitle={subTitle} wide>
      <HomePageLink />
    </PageView>
  );
}

interface SuccessStateProps {
  meterTokenContext: MeterTokenContext;
}

function SuccessState({ meterTokenContext }: SuccessStateProps) {
  return (
    <PageView title="Vielen Dank!" subTitle={`Wir haben Ihren Zählerstand für den Zähler mit der Nummer ${meterTokenContext?.meter?.id} gespeichert.`} wide>
      <HomePageLink />
    </PageView>
  );
}

const PageLoginlessReading: FC = () => {
  const params = useParams();
  const token = params?.token ?? '';

  const config = useConfig();
  const { siteKey } = config.recaptcha;
  const api = useAPI();

  const [state, setState] = useState<PageState>('loading');
  const [error, setError] = useState<unknown>();
  const [meterTokenContext, setMeterTokenContext] = useState<MeterTokenContext>();

  useEffect(() => {
    /** Ignore destroyed component updates */
    let ignoreUpdates = false;

    async function updateState() {
      try {
        await loadRecaptcha(siteKey);
        const recaptchaToken: string = await grecaptcha.enterprise.execute(siteKey, { action: 'get_meter_context' });
        const newMeterTokenContext = await api.metering.tokens
          .get(token ?? '')
          .meter(recaptchaToken)
          .read();

        if (ignoreUpdates) {
          return;
        }

        setMeterTokenContext(newMeterTokenContext);
        setState('form');
      } catch (err) {
        if (ignoreUpdates) {
          return;
        }

        console.error(err);
        setError(err);
        setState('error');
      }
    }

    updateState();
    return () => {
      ignoreUpdates = true;
    };
  }, [token, siteKey]);

  switch (state) {
    case 'loading':
      return <LoadingState />;

    case 'form':
      return (
        <FormState
          meterTokenContext={meterTokenContext}
          token={token}
          onSuccess={() => {
            setState('success');
          }}
        />
      );

    case 'error':
      return <ErrorState error={error} />;

    case 'success':
      return <SuccessState meterTokenContext={meterTokenContext!} />;
  }
};

export default PageLoginlessReading;
