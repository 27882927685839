import { Invoice } from '@enpowerx/apis/lib/contracting/v2';
import { Money } from '@enpowerx/apis/lib/google/type';

export function sortAndFilterInvoices(invoices: Invoice[] | undefined): Invoice[] {
  if (invoices === undefined) {
    return [];
  }

  return invoices
    .sort((a: Invoice, b: Invoice) =>
      a.endDate?.year || b.endDate?.year ? (!a.endDate?.year ? -1 : !b.endDate?.year ? 1 : a.endDate.year - b.endDate.year) : 0
    )
    .filter((invoice: Invoice) => {
      return !invoice.isCancelled && invoice.annualUsage > 0;
    });
}

export const sortInvoiceAsc = (a: Invoice, b: Invoice): number => {
  return sortInvoice(a, b, 'ascending');
};

export const sortInvoiceDesc = (a: Invoice, b: Invoice): number => {
  return sortInvoice(a, b, 'descending');
};

const sortInvoice = (a: Invoice, b: Invoice, direction: 'ascending' | 'descending' = 'ascending'): number => {
  switch (direction) {
    case 'ascending':
      return a.endDate?.year || b.endDate?.year ? (!b.endDate?.year ? -1 : !a.endDate?.year ? 1 : b.endDate.year - a.endDate.year) : 0;
    case 'descending':
      return a.endDate?.year || b.endDate?.year ? (!a.endDate?.year ? -1 : !b.endDate?.year ? 1 : a.endDate.year - b.endDate.year) : 0;
  }
};

export const getUnitCurrency = (money?: Money): string => {
  const currency = (money === null || money === void 0 ? void 0 : money.currencyCode) !== null && money !== void 0 ? money.currencyCode : 'EUR';
  if ((money === null || money === void 0 ? void 0 : money.units) === '0' || (money === null || money === void 0 ? void 0 : money.units) === undefined) {
    switch (money?.currencyCode) {
      case 'EUR':
        return 'ct';
      default:
        return '';
    }
  }
  return currency;
};

