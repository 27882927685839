import React from 'react';

const defaultElement = 'div';

type PolymorphicElementOwnProps<E extends React.ElementType = React.ElementType> = {
  as?: E;
};

type PolymorphicElementProps<E extends React.ElementType> = PolymorphicElementOwnProps<E> & Omit<React.ComponentProps<E>, keyof PolymorphicElementOwnProps>;

export const PolymorphicElement: <E extends React.ElementType = typeof defaultElement>(props: PolymorphicElementProps<E>) => JSX.Element | null =
  React.forwardRef(function PolymorphicElement(props: PolymorphicElementOwnProps, ref: React.Ref<Element>) {
    const Element = props.as || defaultElement;
    return <Element ref={ref} {...props} as={undefined} />;
  });

export type PolymorphicFunctionComponentProps<E extends React.ElementType, P> = P & PolymorphicElementProps<E>;

export type PolymorphicFunctionComponent<P, D extends React.ElementType = 'div'> = <E extends React.ElementType = D>(
  props: PolymorphicFunctionComponentProps<E, P>
) => JSX.Element | null;

export type PFC<P, D extends React.ElementType = 'div'> = PolymorphicFunctionComponent<P, D>;
