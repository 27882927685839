import { Button, Grid, Hidden } from '@enpxio/components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { SearchBox } from './searchBox';
import { useAuth0 } from '~/providers';

interface UserControlsProps {
  toggleOpenSearch: () => void;
  searchBarIsOpen: boolean;
}

export const UserControls: FC<UserControlsProps> = (props: UserControlsProps) => {
  const { isAuthenticated, logout } = useAuth0();
  const { classes } = useStyles();

  const logoutUser = async (): Promise<void> => {
    await logout({ returnTo: `${window.location.origin}`, federated: true });
  };

  if (isAuthenticated) {
    return (
      <Grid container>
        <Hidden mdDown>
          <SearchBox toggleOpenSearch={props.toggleOpenSearch} searchBarIsOpen={props.searchBarIsOpen} />
        </Hidden>
        <Button
          sx={{
            color: (theme) => theme.palette.primary.contrastText, "&:hover": {
              color: "primary.light",
            },
          }}
          startIcon={<ExitToAppIcon />}
          onClick={async () => logoutUser()}
          className={classes.btnUserControls}
        >
          Abmelden
        </Button>
      </Grid>
    );
  }

  return (
    <Button sx={{
      color: (theme) => theme.palette.primary.contrastText, "&:hover": {
        color: "primary.light",
      },
    }} href={PortalPages.dashboard.absolutePathname} className={classes.btnUserControls}>
      Anmelden
    </Button>
  );
};

const useStyles = makeStyles()((theme) => ({
  btnUserControls: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
}));
