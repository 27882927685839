import { gql, useQuery } from '@apollo/client';
import { SvgIcon, SvgIconProps } from '@enpxio/components';
import { FC } from 'react';

import { DivisionIcon } from './divisionIcon';

const QUERY = gql`
  query iconGasCollection {
    iconGasCollection(limit: 1) {
      items {
        image {
          url
        }
        alt
      }
    }
  }
`;

export const GasIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  const { data } = useQuery(QUERY);
  const iconUrl = data?.iconGasCollection?.items[0]?.image?.url;
  const altTxt = data?.iconGasCollection?.items[0]?.alt;

  if (iconUrl !== undefined) {
    return <DivisionIcon src={iconUrl} altText={altTxt} />;
  }

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M 16.857 17.977 L 16.857 20.777 L 13.123 20.777 L 13.123 17.977 L 11.257 17.977 L 11.257 20.777 L 7.523 20.777 L 7.523 17.977 L 5.657 17.977 L 5.657 21.711 C 5.657 22.226 6.074 22.644 6.59 22.644 L 17.79 22.644 C 18.305 22.644 18.723 22.226 18.723 21.711 L 18.723 17.977 Z" />
      <path d="M 12.19 16.111 C 16.377 16.111 19.19 13.672 19.19 10.044 C 19.19 8.529 18.209 6.014 17.091 4.661 C 16.952 4.492 16.715 4.443 16.52 4.541 L 14.328 5.65 L 12.633 0.563 C 12.52 0.222 12.08 0.132 11.841 0.401 C 11.808 0.438 11.781 0.481 11.761 0.527 L 9.178 6.554 L 7.293 5.349 C 7.084 5.215 6.807 5.268 6.663 5.469 C 5.82 6.639 5.19 8.849 5.19 10.044 C 5.19 13.672 8.003 16.111 12.19 16.111 Z M 12.19 7.711 C 12.19 7.711 14.99 10.831 14.99 12.377 C 14.99 14.533 12.657 15.88 10.79 14.802 C 9.924 14.302 9.39 13.378 9.39 12.377 C 9.39 10.831 12.19 7.711 12.19 7.711 Z" />
    </SvgIcon>
  );
};
