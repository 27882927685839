import { gql, useQuery } from '@apollo/client';
import { PageView, useConfig } from '@enpxio/components';
import { FC } from 'react';

import MarketingViewCard from '../marketing/components/marketingViewCard';
import ContractCard from './pageControls/contractCard';
import ContractDocumentsCard from './pageControls/contractDocumentsCard';
import { TariffChangeCard } from './pageControls/tariffChangeCard';
import NonCommodity from './pageControls/nonCommodity';

const QUERY = gql`
  query pageContractCollectionQuery {
    pageContractCollection(limit: 1) {
      items {
        marketingEntryCollection {
          items {
            title
            description
            buttonText
            linkedPage {
              linkedPage
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

const PageContractOverview: FC = () => {
  const config = useConfig();
  const tariffChangeEnabled = config.feature.some((feat) => feat.key === 'tariffChange');
  const nonCommodityEnabled = config.feature.some((feat) => feat.key === 'ePilot');

  const { data, loading } = useQuery(QUERY);
  const marketingItems = data?.pageContractCollection?.items[0]?.marketingEntryCollection?.items;

  return (
    <>
      <PageView title="Vertrag & Tarif" subTitle="Hier finden Sie alle Details zu Ihrem Vertrag und Ihrem Tarif." wide>
        <ContractCard />
        {tariffChangeEnabled ? <TariffChangeCard /> : null}
        {nonCommodityEnabled ? <NonCommodity /> : null}
        <ContractDocumentsCard />
      </PageView>
      {loading || marketingItems?.length === 0 ? null : <MarketingViewCard marketingItems={marketingItems} />}
    </>
  );
};

export default PageContractOverview;
