import { Contract, Contract_State } from '@enpowerx/apis/lib/contracting/v2';
import { PaymentMethod } from '@enpowerx/apis/lib/types';
import { Alert, Button, CardActions } from '@enpxio/components';
import { BillingPaymentMethodLocaleFormatter, IbanObfuscationFormatter } from '@enpxio/formatters';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { KeyValueEntries, KeyValueView } from '~/keyValueView';

interface ViewProps {
  contract: Contract;
  onButtonClick: () => void;
}

const useStyles = makeStyles()(() => ({
  keyValueView: {
    '& td:nth-of-type(odd)': {
      textAlign: 'left',
      width: '26%',
    },
    '& td:nth-of-type(even)': {
      textAlign: 'left',
    },
  },
}));

const View: FC<ViewProps> = (props: ViewProps) => {
  const { contract, onButtonClick } = props;
  const { classes } = useStyles();
  const rows: KeyValueEntries = [
    {
      key: 'Zahlart',
      value: <BillingPaymentMethodLocaleFormatter paymentMethod={contract.billing?.paymentMethod} />,
    },
  ];

  if (contract.billing?.paymentMethod === PaymentMethod.DIRECT_DEBIT) {
    rows.push(
      {
        key: 'Kontoinhaber',
        value: contract.billing?.bankAccount?.accountHolder,
      },
      {
        key: 'IBAN',
        value: <IbanObfuscationFormatter iban={contract.billing?.bankAccount?.iban} />,
      },
      {
        key: 'BIC',
        value: contract.billing?.bankAccount?.bic,
      },
      {
        key: 'Kreditinstitut',
        value: contract.billing?.bankAccount?.bank,
      }
    );
  }

  return (
    <>
      <KeyValueView className={classes.keyValueView} entries={rows} />
      <CardActions style={{ justifyContent: 'center' }} disableSpacing>
        {contract.state !== Contract_State.TERMINATED ? (
          <Button variant="outlined" color="secondary" onClick={onButtonClick}>
            Bearbeiten
          </Button>
        ) : (
          <Alert key="fetch-failure" variant="filled" severity="info">
            Der Vertrag ist beendet.
          </Alert>
        )}
      </CardActions>
    </>
  );
};

export default View;
