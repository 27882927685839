interface IConfig<T> {
  [key: string]: T;
}

export class Config implements IConfig<unknown> {
  [index: string]: unknown;
  apiURL = '';
  stage = '';
  tenant = '';
  cobrowsing: Cobrowsing | undefined = {
    prefix: '',
    agentPortalDomain: '',
    allowedOrigins: '',
  };
  website: WebsiteConfig = {
    description: '',
    favicon: '',
    title: '',
  };
  content: ContentConfig = {
    spaceId: '',
    token: '',
  };
  feature: FeatureConfig[] = [
    {
      key: '',
    },
  ];
  recaptcha: RecaptchaConfig = {
    siteKey: '',
  };
  oauth: OAuthConfig = {
    connection: '',
    authority: '',
    clientId: '',
    useRefreshTokens: false,
  };
  theme: ThemeConfig = {
    logo: '',
    primary: undefined,
    secondary: undefined,
    textPrimary: '',
    textSecondary: '',
    button: undefined,
    typography: undefined,
  };
  profile: ProfileConfig = {
    payment: {
      sepaConsentLong: '',
      sepaConsentShort: '',
    },
  };
  contract: ContractConfig = {
    dashboardInfo: {
      waitingForNetworkProvider: '',
      isRelocated: '',
      tariffChange: '',
    },
  };
  registration: RegistrationConfig = {
    text: '',
  };
  customerCommunicationPreferences: CustomerCommunicationPreferencesConfig = {
    channels: [],
    editDescription: '',
    editEnd: '',
    editHeading: '',
    editPreChannels: '',
    editPreTopics: '',
    topics: [],
    viewHeading: '',
    viewTextEnd: '',
    viewTextHeading: '',
    viewTextNoConsent: '',
    viewTextPreChannels: '',
    viewTextPreTopics: '',
  };
  maintenanceEnd?: Date;
  constructor(config: JSON) {
    for (const key of Object.keys(config)) {
      this[key] = (config as unknown as Record<string, unknown>)[key];
    }
  }
}

interface WebsiteConfig {
  title: string;
  description: string;
  favicon: string;
}

interface ContentConfig {
  spaceId: string;
  token: string;
}

interface FeatureConfig {
  key: string;
}

interface RecaptchaConfig {
  siteKey: string;
}

interface CustomerCommunicationPreferencesConfig {
  viewTextNoConsent: string;
  viewHeading: string;
  viewTextHeading: string;
  viewTextPreChannels: string;
  viewTextPreTopics: string;
  viewTextEnd: string;

  editHeading: string;
  editDescription: string;
  editPreChannels: string;
  editPreTopics: string;
  editEnd: string;
  channels: Channel[];
  topics: Topic[];
}

interface Selectable {
  key: string;
  displayName: string;
  order: number;
}

interface Channel extends Selectable {
  key: 'CHANNEL_UNSPECIFIED' | 'LETTER' | 'EMAIL' | 'SMS' | 'MESSENGER' | 'PHONE' | 'UNRECOGNIZED';
}
interface Topic extends Selectable {
  key: string;
}

interface ThemeConfig {
  textPrimary: string;
  textSecondary: string;
  logo: string;
  primary?: ColorConfig;
  secondary?: ColorConfig;
  background?: string;
  button?: ButtonConfig;
  marketingEntry?: string;
  typography?: TypographyConfig;
}

interface TypographyConfig {
  fontSize: number;
  h1: TypographyElementConfig;
  h2: TypographyElementConfig;
  body1: TypographyElementConfig;
  body2: TypographyElementConfig;
}

interface TypographyElementConfig {
  fontWeight?: string;
  fontSize: string;
  lineHeight: string;
  small?: TypographyElementConfig;
}

interface ColorConfig {
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}

interface ColorConfig {
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}

interface ButtonConfig {
  border?: string;
  borderRadius?: string;
  hover?: HoverConfig;
}

interface HoverConfig {
  backgroundColor?: string;
  color?: string;
  transition?: string;
  boxShadow?: string;
}

interface OAuthConfig {
  authority: string;
  clientId: string;
  connection: string;
  useRefreshTokens: boolean;
}

interface ProfileConfig {
  payment: PaymentConfig;
}

interface ContractConfig {
  dashboardInfo: {
    isRelocated: string;
    waitingForNetworkProvider: string;
    tariffChange: string;
  };
}

interface PaymentConfig {
  sepaConsentShort: string;
  sepaConsentLong: string;
}

interface RegistrationConfig {
  text: string;
}

interface Cobrowsing {
  prefix: string;
  agentPortalDomain: string;
  allowedOrigins: string;
}
