import { Tariff } from '@enpowerx/apis/lib/market/v2';
import { EnergyType } from '@enpowerx/apis/lib/types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@enpxio/components';
import { DurationLocaleFormatter, MoneyLocaleFormatter } from '@enpxio/formatters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import React, { FunctionComponent } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface ProductCardProps {
  energyType: EnergyType;
  tariff: Tariff;
  completion(tariff: Tariff): void;
  buttonText: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  priceBox: {
    backgroundColor: 'rgba(107,154,196,0.1)',
    maxWidth: '484px',
    height: '209px',
  },
  buyButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  showMoreText: {
    fontSize: '18px',
    color: theme.palette.secondary.main,
  },
  showMoreTextExpanded: {
    visibility: 'hidden',
    fontSize: '0px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

export const ProductCard: FunctionComponent<ProductCardProps> = (props: ProductCardProps) => {
  const { energyType, tariff } = props;
  const { classes, cx } = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [linkText, setLinkText] = React.useState('Mehr anzeigen ＋');

  const toggleDocuments = (): void => {
    if (expanded) {
      setLinkText('Mehr anzeigen ＋');
    } else {
      setLinkText('Weniger anzeigen ×');
    }

    setExpanded(!expanded);
  };

  return (
    <Grow in timeout={500}>
      <Card style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1em', maxWidth: '544px' }}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h2" component="h2" color="secondary">
            {tariff.product?.displayName}
          </Typography>
          <Box marginTop="40px">
            <Typography align="center" color="textPrimary">
              <DurationLocaleFormatter duration={tariff.product?.priceGuarantee} /> Preisgarantie
            </Typography>
          </Box>
          <Box marginTop="31px" />
          <div className={classes.priceBox}>
            <Box paddingTop="58px" />
            <Typography align="center" color="secondary" variant="h2" component="p">
              <MoneyLocaleFormatter value={tariff.fixedCost?.price?.gross} />
            </Typography>
            <Box paddingTop="18px" />
            <Typography align="center" color="textPrimary">
              Monatlicher Bruttopreis inkl. MwSt.
            </Typography>
          </div>
        </CardContent>
        <Box marginTop="47px" />
        <CardActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              props.completion(tariff);
            }}
            className={classes.buyButton}
          >
            {props.buttonText ? props.buttonText : 'Auswählen'}
          </Button>
        </CardActions>
        <CardActions disableSpacing>
          <IconButton className={cx(classes.expand)} onClick={toggleDocuments} aria-expanded={expanded} aria-label="show more" size="large">
            <span className={cx(classes.showMoreText)}>{linkText}</span>
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body2" color="textPrimary" component="div">
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<MoneyLocaleFormatter value={tariff.fixedCost?.price?.gross} />}
                    secondary={<Typography>jährlicher Grundpreis</Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<MoneyLocaleFormatter value={tariff.workingPrice?.gross} showFractionUnit suffix=" pro kWh" />}
                    secondary={<Typography>Verbrauchspreis</Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<MoneyLocaleFormatter value={tariff.estimatedTotalCost?.price?.gross} />}
                    secondary={<Typography>jährlicher Gesamtpreis</Typography>}
                  />
                </ListItem>
                {tariff.product?.contractTerm ? (
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<DurationLocaleFormatter duration={tariff.product?.contractTerm} />}
                      secondary={<Typography>Vertragslaufzeit</Typography>}
                    />
                  </ListItem>
                ) : null}
                {tariff.product?.priceGuarantee ? (
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<DurationLocaleFormatter duration={tariff.product?.priceGuarantee} />}
                      secondary={<Typography>Preisgarantie</Typography>}
                    />
                  </ListItem>
                ) : null}
                {tariff.ecoPercentage > 0 ? (
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${tariff.ecoPercentage}%`}
                      secondary={<Typography>{energyType === EnergyType.GAS ? 'Ökogasanteil' : 'Ökostromanteil'}</Typography>}
                    />
                  </ListItem>
                ) : null}
              </List>
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Grow>
  );
};
