import { useQuery, gql } from '@apollo/client';
import { Box, Card, CardContent, Container, Typography, useAsyncEffect } from '@enpxio/components';
import ErrorIcon from '@mui/icons-material/Error';
import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../portalPages';
import { useAuth0 } from '~/providers';

const QUERY = gql`
  query authErrorCollection {
    pageRegistrationCollection(limit: 1) {
      items {
        backgroundImage {
          url
        }
      }
    }
    supportCollection {
      items {
        phonenumber
        email
        # add the fields you want to query
      }
    }
  }
`;

const useStyles = makeStyles<{
  backgroundImageURL: string;
}>()((theme, { backgroundImageURL }) => ({
  background: {
    minWidth: 275,
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -1,
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${backgroundImageURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  root: {},
  icon: {
    color: theme.palette.success.main,
    width: '100%',
  },
  spaceLG: {
    paddingTop: theme.spacing(5),
  },
  spaceXL: {
    paddingTop: theme.spacing(8),
  },
  center: {
    display: 'grid',
    justifyItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

const PageAuth0Error: FC = (): JSX.Element => {
  const { data, loading } = useQuery(QUERY);
  const imageUrl = data?.pageRegistrationCollection?.items[0]?.backgroundImage?.url;
  const phonenumber = data?.supportCollection?.items[0]?.phonenumber;
  const email = data?.supportCollection?.items[0]?.email;
  const mailto = `mailto:${email}`;
  const { classes } = useStyles({ backgroundImageURL: loading || !imageUrl ? '' : imageUrl });

  const [urlClient_id, setUrlClient_id] = useState<string>('');
  const [urlConnection, setUrlConnection] = useState<string>('');
  const [urlLang, setUrlLang] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [urlErrorDescription, setUrlErrorDescription] = useState<string>('');
  const [urlTracking, setUrlTracking] = useState<string>('');

  const { isAuthenticated } = useAuth0();
  const query = useSearchParams()[0];
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (isAuthenticated || (query.get('supportSignUp') === 'true' && query.get('success') === 'true')) {
      await navigate(PortalPages.dashboard.absolutePathname);
    }

    const urlParams = new URLSearchParams(window.location.search);
    setUrlClient_id(urlParams.get('client_id') ?? '');
    setUrlConnection(urlParams.get('connection') ?? '');
    setUrlLang(urlParams.get('lang') ?? '');
    setUrlError(urlParams.get('error') ?? '');
    setUrlErrorDescription(urlParams.get('error_description') ?? '');
    setUrlTracking(urlParams.get('tracking') ?? '');
  }, [isAuthenticated]);

  return (
    <>
      <div className={classes.background} />
      <Container>
        <Card className={classes.root} square>
          <CardContent>
            <ErrorIcon className={classes.icon} style={{ fontSize: 80 }} />
            <Box component="div" className={classes.center}>
              <Typography variant="h2" className={classes.spaceXL}>
                Leider ist bei der Authentifizierung ein Fehler aufgetreten.
              </Typography>

              <Typography variant="body2" className={classes.spaceLG}>
                Bitte versuchen Sie es später erneut. Oder wenden Sie sich an unseren Kundenservice.
              </Typography>
              {phonenumber ? (
                <Typography variant="body2" className={classes.spaceLG}>
                  <a className={classes.link} href={phonenumber}>
                    {phonenumber}
                  </a>{' '}
                  oder{' '}
                  <a className={classes.link} href={mailto}>
                    {email}
                  </a>
                </Typography>
              ) : null}

              <Typography variant="body2" className={classes.spaceLG}>
                <div hidden>{urlClient_id}</div>
                <div hidden>{urlConnection}</div>
                <div hidden>{urlLang}</div>
                <div hidden>{urlError}</div>
                <div hidden>{urlErrorDescription}</div>
                <div hidden>{urlTracking}</div>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PageAuth0Error;
