import {
  ConfigContextProvider,
  ContentProvider,
  CssBaseline,
  MuiStyleEngine,
  StyledEngineProvider,
  ThemeProvider,
  useAsyncLayoutEffect,
  useConfig,
} from '@enpxio/components';
import { createMUIThemeFromCMS } from '@enpxio/theme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import { SnackbarProvider } from 'notistack';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';

import Layout from './layout/layout';
import { PortalPages } from './portalPages';
import Auth from "./auth";
import { APIContextProvider, SelectedContractContextProvider,ReportingContextProvider } from './providers';
import { Page } from './providers/pages/pages';
import { PageProvider, usePages } from './providers/pages/provider';
import SEO from './seo/seo';
import GeneralErrorBoundary from './utils/errorBoundary/generalErrorBoundary';

const pages: Page[] = [
  PortalPages.home,
  PortalPages.login,
  PortalPages.dashboard,
  PortalPages.invoicesAndPayment,
  PortalPages.invoicesRedirect,
  PortalPages.meters,
  PortalPages.contract,
  PortalPages.tariffChange,
  PortalPages.myData,
  PortalPages.relocation,
  PortalPages.relocationSuccess,
  PortalPages.contact,
  PortalPages.marketingOptIn,
  PortalPages.marketingOptInWithoutToken,
  PortalPages.registration,
  PortalPages.verification,
  PortalPages.passwordReset,
  PortalPages.notFound,
  PortalPages.reading,
  PortalPages.postAcquisition,
  PortalPages.IntentDeclaration,
  PortalPages.error,
  PortalPages.doubleOptin,
  PortalPages.cancellation,
  PortalPages.errorAuth,
  PortalPages.invoiceExplanation,
  PortalPages.nonCommodity
];


function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X | null, prop: Y): obj is X & Record<Y, unknown> {
  return obj != null && obj.hasOwnProperty(prop);
}

const useResetScroll = (): void => {
  const navigate = useNavigate();
  const { pathname: href, state } = useLocation();

  const updateState = useCallback(async () => {
    await navigate(href, {
      state: Object.assign({}, state, { scrolled: true }),
      replace: true,
    });
    window.scrollTo(0, 0);
  }, [href, state, navigate]);

  useAsyncLayoutEffect(async () => {
    if (typeof state === 'object' && !(hasOwnProperty(state, 'scrolled') && state.scrolled)) {
      await updateState();
    }
  }, [state, updateState]);
};

function Content() {
  const { pages } = usePages();
  const [routerElement, setRouterElement] = useState<ReactElement | null>(null);

  useResetScroll();

  useEffect(() => {
    setRouterElement(pages.buildRouter());
  }, []);
  return routerElement;
}

const App: FC = () => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
      }}
    >
      <MuiStyleEngine>
        <ConfigContextProvider>
          <Auth>
            <ContentProvider>
              <PageProvider pages={pages}>
                <SelectedContractContextProvider>
                  <APIContextProvider>
                  <ReportingContextProvider>
                    <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                      <AppContent />
                    </LocalizationProvider>
                    </ReportingContextProvider>
                  </APIContextProvider>
                </SelectedContractContextProvider>
              </PageProvider>
            </ContentProvider>
          </Auth>
        </ConfigContextProvider>
      </MuiStyleEngine>
    </SWRConfig>
  );
};

function AppContent() {
  const config = useConfig();
  const theme = createMUIThemeFromCMS(config);

  return (
    <GeneralErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Layout>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              preventDuplicate
            >
              <SEO />
              <Content />
            </SnackbarProvider>
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    </GeneralErrorBoundary>
  );
}

export default App;
