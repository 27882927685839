import { gql, useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import MarketingCard from './marketingCard';

import 'swiper/css';
import './swiper.css';

const QUERY = gql`
  query dashboardCollectionQuery {
    dashboardCollection(limit: 1) {
      items {
        blogPostsCollection {
          items {
            title
            image {
              url
            }
            description
            buttonText
            linkedPage {
              linkedPage
            }
            link
          }
        }
      }
    }
  }
`;

interface DashboardCollectionResult {
  dashboardCollection: {
    items: [
      {
        blogPostsCollection: {
          items: MarketingItem[];
        };
      }
    ];
  };
}

interface LinkedPage {
  linkedPage: string;
}

interface MarketingImage {
  url?: string;
}

interface MarketingItem {
  image?: MarketingImage;
  title?: string;
  description?: string;
  buttonText: string;
  link: string;
  linkedPage: LinkedPage;
}

const MarketingSlider: FC = () => {
  const { data, loading } = useQuery<DashboardCollectionResult>(QUERY);
  const [marketingItems, setMarketingItems] = useState<MarketingItem[]>([]);
  SwiperCore.use([Navigation, Pagination, A11y]);

  useEffect(() => {
    setMarketingItems(data?.dashboardCollection.items[0].blogPostsCollection.items ?? []);
  }, [data]);

  return (
    <>
      {loading || marketingItems.length <= 0 ? null : (
        <Swiper
          spaceBetween={20}
          loopedSlides={marketingItems.length}
          pagination
          navigation
          breakpoints={{
            720: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
        >
          {marketingItems.map((item: MarketingItem, index: number) => {
            const shortDescription = item.description;
            return (
              <SwiperSlide key={String(index)}>
                <MarketingCard
                  title={item.title}
                  image={item.image?.url}
                  text={shortDescription}
                  buttonText={item.buttonText}
                  link={item.link}
                  linkedPage={item?.linkedPage?.linkedPage}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};

export default MarketingSlider;
