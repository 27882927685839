import { Card } from '@enpxio/components';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  card: {
    padding: '10px',
  },
}));

interface ViewCardProps {
  children?: ReactNode;
}

export const ViewCard: React.FC<ViewCardProps> = (props: ViewCardProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>{props.children}</Card>
    </div>
  );
};
