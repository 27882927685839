import { Duration } from '@enpowerx/apis/lib/types';
import { FC } from 'react';

interface DurationLocaleFormatterProps {
  duration: Duration | undefined;
}

export const DurationLocaleFormatter: FC<DurationLocaleFormatterProps> = (props: DurationLocaleFormatterProps) => {
  const { duration } = props;

  return !duration ? null : <>{`${duration.units} ${humanReadable(duration.type)}`}</>;
};

const humanReadable = (type: string): string => {
  switch (type) {
    case 'DAY':
      return 'Tage';
    case 'WEEK':
      return 'Wochen';
    case 'MONTH':
      return 'Monate';
    case 'YEAR':
      return 'Jahre';
    default:
      return type;
  }
};

export function formatDurationLocale(duration: Duration | undefined): string {
  if (!duration) {
    return '';
  }

  return `${duration.units} ${humanReadable(duration.type)}`;
}
