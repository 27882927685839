import { Tariff } from '@enpowerx/apis/lib/market/v2';
import { CustomerType, EnergyType } from '@enpowerx/apis/lib/types';
import { Alert, Button, CircularProgress, Grid, PageView, TextField, TextFieldProps, Theme, useAsyncEffect } from '@enpxio/components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../portalPages';
import { ProductCard } from './productCard';
import { useAPI, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    [theme.breakpoints.up('xs')]: {
      paddingRight: '40px',
    },
  },
  button: {
    justifyContent: 'center',
    paddingTop: '30px',
    marginBottom: '20px',
  },
}));

const PageTariffChange: FC = () => {
  const { selectedContract } = useSelectedContract();
  const api = useAPI();
  const { classes } = useStyles();
  const [products, setProducts] = useState<ReactNode[]>([<CircularProgress key="loading-spinner" />]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTariff, setSelectedTariff] = useState<Tariff>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  if (!selectedContract.delivery?.address) {
    navigate(PortalPages.contract.absolutePathname);
  }

  const selectProductCallback = (tariff: Tariff): void => {
    setSelectedTariff(tariff);
  };

  const cancelSelection = (): void => {
    setSelectedTariff(undefined);
  };

  async function completion(): Promise<void> {
    try {
      if (!(selectedDate?.getDate() && selectedDate?.getFullYear() && selectedTariff?.product?.id)) {
        enqueueSnackbar('Tarife konnten nicht abgerufen werden', {
          variant: 'error',
        });
        return;
      }

      await api.currentContract.products.get(selectedTariff?.product?.id).invoke({
        campaign: '',
        scheduledDate: {
          day: selectedDate?.getDate(),
          month: selectedDate?.getMonth() + 1,
          year: selectedDate?.getFullYear(),
        },
      });
      enqueueSnackbar('Tarif wurde erfolgreich übernommen', {
        variant: 'success',
      });
      enqueueSnackbar('Bitte erfassen Sie Ihren Zählerstand für die Schlussabrechnung', {
        variant: 'info',
      });
      await navigate(PortalPages.meters.absolutePathname, {
        state: { addReading: true },
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Tarifwechsel konnte nicht ausgeführt werden', {
        variant: 'error',
      });
      navigate(PortalPages.contract.absolutePathname);
    }
  }

  async function fetchProducts(abortSignal: AbortSignal | undefined): Promise<void> {
    try {
      const tariffList = await api.region.postalCodes
        .get(selectedContract.delivery?.address?.postalCode ?? '')
        .cities.get(selectedContract.delivery?.address?.city ?? '')
        .tariffs(selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED)
        .list(
          selectedContract.delivery?.customerType ?? CustomerType.PRIVATE,
          selectedContract.delivery?.address?.street ?? '',
          selectedContract.delivery?.address?.houseNumber ?? '',
          selectedContract.delivery?.annualUsage,
          undefined,
          undefined,
          abortSignal
        );
      const availableProducts = await api.currentContract.products.list();

      if (tariffList.tariffs.length === 0) {
        enqueueSnackbar('Tarife konnten nicht abgerufen werden', {
          variant: 'error',
        });
      }

      setProducts(
        tariffList.tariffs
          .filter((element: Tariff) => {
            return availableProducts.products.some((product) => product.id === element.product?.id);
          })
          .map((tariff) => {
            return (
              <ProductCard
                key={tariff.product?.id}
                energyType={selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED}
                tariff={tariff}
                completion={selectProductCallback}
                buttonText="Auswählen"
              />
            );
          })
      );
    } catch (error) {
      console.error(error);
      setProducts([
        <Alert key="fetch-failure" variant="filled" severity="error">
          Da scheint was schiefgelaufen zu sein, probieren Sie es bitte später erneut!
        </Alert>,
      ]);
    }
  }

  useAsyncEffect(
    async (abortSignal) => {
      try {
        await fetchProducts(abortSignal);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedContract]
  );

  return (
    <PageView
      title="Wählen Sie Ihren neuen Tarif"
      subTitle="Ihr Wechsel ist einfach und sicher. Wählen Sie jetzt Ihren passenden Tarif aus und bestellen Sie gleich."
      wide
    >
      <Grid container justifyContent="space-around" className={classes.formControl}>
        <Grid xs={12} sm={9} item>
          <div className={classes.formControl}>
            <DatePicker
              label="Wunschdatum"
              value={selectedDate}
              onChange={(newValue: number | null) => {
                const newDateValue= new Date(newValue ?? 0)
                if (newValue) setSelectedDate(newDateValue);
              }}
              renderInput={(params: TextFieldProps) => <TextField {...params} />}
            />
          </div>
        </Grid>
        <Grid xs={12} sm={3} item className={classes.button}>
          <Button variant="outlined" color="secondary" size="small" onClick={completion}>
            Tarifwechsel ausführen
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
        {selectedTariff?.product?.id ? (
          <ProductCard
            key={selectedTariff.product?.id}
            energyType={selectedContract.delivery?.energyType ?? EnergyType.UNRECOGNIZED}
            tariff={selectedTariff}
            completion={cancelSelection}
            buttonText="Auswahl aufheben"
          />
        ) : (
          products
        )}
      </Grid>
    </PageView>
  );
};

export default PageTariffChange;
