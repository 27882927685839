import { CustomerPreference_Payload } from '@enpowerx/apis/lib/communication/v2';
import { PageViewCard, useAsyncEffect, useConfig } from '@enpxio/components';
import { FC, useState } from 'react';

import { useAPI } from '../../../providers';
import { ConsentData } from './advertisingConsentData';
import EditMode from './advertisingConsentEdit';
import InfoMode from './advertisingConsentView';

const AdvertisingConsent: FC = () => {
  const nullPreferences: CustomerPreference_Payload = {
    consentText: '',
    marketing: {
      channels: [],
      allowedTopics: [],
      deniedTopics: [],
    },
  };

  const api = useAPI();
  const config = useConfig();
  const customerCommunicationPreferences = config.customerCommunicationPreferences;
  const [editMode, setEditMode] = useState(false);
  const [preferences, setPreferences] = useState(nullPreferences);

  const data = {
    viewTextNoConsent: customerCommunicationPreferences.viewTextNoConsent,
    viewHeading: customerCommunicationPreferences.viewHeading,
    viewTextHeading: customerCommunicationPreferences.viewTextHeading,
    viewTextPreChannels: customerCommunicationPreferences.viewTextPreChannels,
    viewTextPreTopics: customerCommunicationPreferences.viewTextPreTopics,
    viewTextEnd: customerCommunicationPreferences.viewTextEnd,

    editHeading: customerCommunicationPreferences.editHeading,
    editDescription: customerCommunicationPreferences.editDescription,
    editPreChannels: customerCommunicationPreferences.editPreChannels,
    editPreTopics: customerCommunicationPreferences.editPreTopics,
    editEnd: customerCommunicationPreferences.editEnd,
    channels: customerCommunicationPreferences.channels,
    topics: customerCommunicationPreferences.topics,
  } as ConsentData;

  useAsyncEffect(
    async (abortSignal) => {
      if (!api.isInitialized) {
        return;
      }

      try {
        const prefs = await api.communication.me.communicationPreference.read(abortSignal);
        if (prefs.payload) setPreferences(prefs.payload);
      } catch (error) {
        console.error(error);
      }
    },
    [api.isInitialized]
  );

  return (
    <PageViewCard>
      {editMode ? (
        <EditMode data={data} setEdit={setEditMode} preferences={preferences} setPreferences={setPreferences} />
      ) : (
        <InfoMode data={data} setEdit={setEditMode} preferences={preferences} />
      )}
    </PageViewCard>
  );
};

export default AdvertisingConsent;
