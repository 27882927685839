import { useAsyncEffect, useConfig } from '@enpxio/components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PortalPages } from '../portalPages';
import { useAuth0 } from '~/providers';

export const LoginPage: FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const config = useConfig();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        appState: {
          locationPathname: PortalPages.dashboard.absolutePathname,
        },
        connection: config.oauth.connection,
      });
      return;
    }

    await navigate(PortalPages.dashboard.absolutePathname);
  }, [isAuthenticated]);
  return null;
};
