import { FailedPreconditionTariffChangeNotAllowedForContractError } from '@enpowerx/apis';
import { Alert, Button, CardActions, CardContent, Link, PageViewCard, PageViewCardTitle, Skeleton, Typography } from '@enpxio/components';
import { ContractNameLocaleFormatter, DateMessageLocaleFormatter } from '@enpxio/formatters';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { useAvailableProducts } from '../hooks/useAvailableProducts';
import { KeyValueEntries, KeyValueView } from '~/keyValueView';
import { useSelectedContract } from '~/providers';

export const TariffChangeCard: FC = () => {
  const { selectedContract } = useSelectedContract();
  const { availableProducts, error, isLoading } = useAvailableProducts();
  const [tariffChangePossible, setTariffChangePossible] = useState(false);
  const view = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      if (error instanceof FailedPreconditionTariffChangeNotAllowedForContractError) {
        return <TariffChangeNotPossibleContent />;
      }

      return <Alert severity="warning">Aus technischen Gründen ist ein Tarifwechsel derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.</Alert>;
    }

    if (selectedContract.futureTariff) {
      return <UpcomingTariffContent />;
    }

    if (tariffChangePossible) {
      return <ChangeTariffContent />;
    }
  }, [selectedContract.id, tariffChangePossible, error, isLoading]);

  useEffect(() => {
    if (!availableProducts) {
      return;
    }

    setTariffChangePossible(availableProducts.length > 0);
  }, [selectedContract.id, availableProducts]);

  return (
    <PageViewCard>
      <PageViewCardTitle title="Tarifwechsel" />
      {view}
    </PageViewCard>
  );
};

const UpcomingTariffContent: FC = () => {
  const { selectedContract, isLoading } = useSelectedContract();
  const entries: KeyValueEntries = [
    {
      key: 'Zukünftiges Produkt',
      value: selectedContract.futureTariff?.displayName,
      alternativeValues: {
        value: <Skeleton width={200} />,
        condition: () => isLoading,
      },
    },
    {
      key: 'Startdatum',
      value: <DateMessageLocaleFormatter date={selectedContract.futureTariff?.activeRange?.start} />,
      alternativeValues: {
        value: <Skeleton width={200} />,
        condition: () => isLoading,
      },
    },
  ];

  return (
    <CardContent>
      <KeyValueView entries={entries} />
    </CardContent>
  );
};

const useChangeTariffContentStyles = makeStyles()((theme) => ({
  cardActions: {
    justifyContent: 'center',
    paddingTop: theme.namedSpacing('large', 'md'),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const ChangeTariffContent: FC = () => {
  const { classes } = useChangeTariffContentStyles();
  const { selectedContract } = useSelectedContract();
  return (
    <div>
      <Typography variant="body1">
        Finden Sie den <ContractNameLocaleFormatter energyType={selectedContract.delivery?.energyType} />, der perfekt zu Ihnen passt!
        <br />
        Lassen Sie sich von unseren Angeboten, Produkten und Lösungen überzeugen!
      </Typography>
      <CardActions className={classes.cardActions}>
        <Link className={classes.link} to={PortalPages.tariffChange.absolutePathname} component={RouterLink}>
          <Button variant="outlined" color="secondary">
            Tarife anzeigen
          </Button>
        </Link>
      </CardActions>
    </div>
  );
};

const TariffChangeNotPossibleContent: FC = () => {
  return <Alert severity="info">Da Ihr aktueller Vertrag noch länger als 6 Monate läuft, ist ein Wechsel zur Zeit noch nicht möglich.</Alert>;
};

const Loading: FC = () => {
  return (
    <Typography variant="body1">
      <Skeleton width="40%" />
      <Skeleton width="80%" />
      <Skeleton width="80%" />
    </Typography>
  );
};
