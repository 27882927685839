import { Avatar, List, ListItem, ListItemIcon, ListItemText, Theme } from '@enpxio/components';
import { Check } from '@mui/icons-material';
import { FC, FunctionComponent } from 'react';
import { makeStyles } from 'tss-react/mui';

const useListStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: 410,
  },

  list: {
    color: theme.palette.text.secondary,
    width: '100%',
    '& .MuiListItemText-primary': {
      fontWeight: 'normal',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
    },
  },
}));

export const RegistrationBenefitsMessage: FunctionComponent = () => {
  const { classes } = useListStyles();

  return (
    <List className={classes.list} dense>
      <BulletPoint text="Einfach registrieren" />
      <BulletPoint text="... mit E-Mail-Adresse und Kundennummer" />
      <BulletPoint text="Rechnung herunterladen" />
      <BulletPoint text="Zählerstand eingeben" />
      <BulletPoint text="Abschlag ändern" />
      <BulletPoint text="Daten sicher verwalten" />
    </List>
  );
};

const useBulletPointStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: '25px',
  },

  bulletPoint: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.success.main,
    width: '24px',
    height: '24px',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}));

interface BulletPointProps {
  text: string;
}

const BulletPoint: FC<BulletPointProps> = (props: BulletPointProps) => {
  const { classes } = useBulletPointStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemIcon>
        <Avatar className={classes.bulletPoint}>
          <Check />
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
};
