import { CardContent, FormControl, InputAdornment, Skeleton, TextField, Typography } from '@enpxio/components';
import Search from '@mui/icons-material/Search';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FAQ } from '../pageContact';
import FAQQuestions from './faqQuestions';
import { ViewCard } from './viewCard';

interface FAQCardProps {
  title?: string;
  loading?: boolean;
  searchText?: string;
  faqs: FAQ[];
}

export const FAQCard: FC<FAQCardProps> = (props: FAQCardProps) => {
  const { classes } = useStyles();
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const inputChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  }, []);

  useEffect(() => {
    setSearchPhrase(props.searchText ?? '');
  }, [props.searchText]);

  return (
    <ViewCard>
      <CardContent>
        <Typography variant="h2" component="p">
          {props.title ?? 'Häufig gestellte Fragen'}
        </Typography>
      </CardContent>
      <CardContent>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            variant="outlined"
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            tabIndex={0}
            label="Suche"
            helperText="Geben Sie ein mehrere Stichwörter ein"
            value={searchPhrase}
            onChange={inputChange}
            fullWidth
          />
        </FormControl>
      </CardContent>
      <CardContent>{props.loading ? <Skeleton width="100%" /> : <FAQQuestions faqs={props.faqs} searchPhrase={searchPhrase} />}</CardContent>
    </ViewCard>
  );
};

const useStyles = makeStyles()(() => ({
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
  },
  formControl: {
    marginTop: '0px',
    minWidth: '120px',
  },
}));

export default FAQCard;
