/**
 * Concatenate values with comma and lastly 'und'.
 *
 * [] => ''
 * [a] => 'a'
 * [a,b] => 'a und b'
 * [a,b,c] => 'a, b und c'
 * [a,b,c,d] => 'a, b, c und d'
 */
export function concatValues(channels: string[]): string {
  const len = channels.length;
  if (len === 0) return '';
  if (len === 1) return channels[0];
  const last = channels[len - 1];
  const firstN = channels.slice(0, len - 1);
  return `${firstN.join(', ')} und ${last}`;
}
