import { Channel } from '@enpowerx/apis/lib/communication/v2';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@enpxio/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useContractPreferences } from '../hooks/useContractPreferences';
import { useAPI, useAuth0, useSelectedContract } from '~/providers';

const useStyles = makeStyles()((theme) => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      ' & .MuiGrid-container': {
        justifyContent: 'center',
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: '25px',
    },
  },
  advantages: {
    paddingTop: '10px',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiListItemText-primary': {
      fontWeight: 'normal',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
    },
  },
  inline: {
    display: 'inline',
  },
}));

type EnableProps = {
  enabled: boolean;
};

export const PaperlessCommunicationDialog: FunctionComponent<EnableProps> = (props: EnableProps) => {
  if (!props.enabled) {
    return null;
  }

  const { classes } = useStyles();
  const api = useAPI();
  const { user, isAuthenticated } = useAuth0();
  const { selectedContract } = useSelectedContract();
  const { contractPreference } = useContractPreferences();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const closed = !isAuthenticated || !selectedContract.id || !user.email || !contractPreference || contractPreference.transactionalChannel === Channel.EMAIL;
    setIsDialogOpen(!closed);
  }, [isAuthenticated, user.email, contractPreference, selectedContract.id]);

  const handleDialogAbort = (_: unknown, reason: string): void => {
    if (reason !== 'backdropClick') {
      closeDialog();
    }
  };

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  async function handleSave(): Promise<void> {
    try {
      setIsSubmitting(true);
      await updateCommunicationPreferences();
      setIsDialogOpen(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  const updateCommunicationPreferences = async (): Promise<void> => {
    if (!contractPreference) {
      return;
    }

    contractPreference.transactionalChannel = Channel.EMAIL;
    try {
      await api.communication.me.contracts.get(selectedContract.id).communicationPreference.set(contractPreference);
    } catch {
      setIsDialogOpen(false);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogAbort}
      aria-labelledby="papcom-dialog-title"
      aria-describedby="papcom-dialog-description"
      fullWidth
      maxWidth="md"
      scroll="body"
      disableScrollLock
      disableRestoreFocus
    >
      <DialogTitle id="papcom-dialog-title">
        <Typography component="h1" variant="h1">
          <strong>E-Mail statt Post.</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary" id="papcom-dialog-description">
          Helfen Sie uns, die Umwelt zu schützen: Erhalten Sie Ihre vertraglichen Dokumente und Rechnungen zukünftig per E-Mail statt per Post.
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Grid container spacing={2}>
          <Grid item>
            <Button name="submit" onClick={handleSave} color="secondary" variant="outlined" disabled={isSubmitting}>
              Jetzt umstellen
            </Button>
          </Grid>
          <Grid item>
            <Button name="abort" onClick={closeDialog} color="secondary" disabled={isSubmitting}>
              Jetzt nicht {' >'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      <DialogContent>
        <div>
          Dazu verwenden wir folgende E-Mail Adresse:&nbsp;
          <strong>{user.email}</strong>
        </div>
        <div className={classes.advantages}>
          <Typography component="h2" variant="h2">
            <strong>Ihre Vorteile</strong>
          </Typography>
        </div>
        <List className={classes.list} dense>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Umweltschutz durch weniger Papierverbrauch" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Schnellerer Rechnungsempfang" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Jederzeit abrufbar" />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};
