const loadEPilot = customerID => {
    const existingScript = document.getElementById('ePilot');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://embed.journey.epilot.io/bundle.js';
      script.id = 'ePilot';
      script.setAttribute('data-ep-init', 'false');
      document.body.appendChild(script);
      script.onload = () => {
        __epilot.init([
          {
            journeyId: '5b448970-01da-11ed-9df4-6d51696cab3a',
            mode: 'inline',
            contextData: {
              customerID: customerID,
            },
          },
        ]);
        return;
      };
    }
  };
  export default loadEPilot;
  