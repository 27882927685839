import { Button } from '@enpxio/components';
import { SaveOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

interface Props {
  disableNext?: boolean;
  hasPrevious?: boolean;
  isTariffChange?: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep?: boolean;
}

export const FormNavigation = (props: Props) => {
  const { onBackClick, hasPrevious, isLastStep, isTariffChange } = props;
  const { isValid } = useFormikContext();

  const saveText = isTariffChange === true ? 'Jetzt zahlungspflichtig bestellen' : 'Speichern';

  if (hasPrevious) {
    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button color="secondary" variant="outlined" type="button" onClick={onBackClick}>
            Zurück
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" color="secondary" variant="outlined" disabled={props.disableNext ?? false} startIcon={isLastStep ? <SaveOutlined /> : <></>}>
            {isLastStep ? saveText : 'Weiter'}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button type="submit" color="secondary" variant="outlined" disabled={!isValid} startIcon={isLastStep ? <SaveOutlined /> : <></>}>
          {isLastStep ? saveText : 'Weiter'}
        </Button>
      </Grid>
    </Grid>
  );
};
