import { TextField } from '@enpxio/components';
import { styled } from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PortalPages } from '../../portalPages';
import { useAuth0 } from '~/providers';

interface SearchBarProps {
  toggleOpenSearch: () => void;
  searchBarIsOpen: boolean;
}

export const SearchBar: FC<SearchBarProps> = (props: SearchBarProps) => {
  const { classes } = useStyles();
  const [searchText, setSearchText] = useState<string>('');
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const changeSearchText = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);
  const keyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter' && searchText !== '') {
      props.toggleOpenSearch();
      handleSearch();
    }
  };

  const handleSearch = (): void => {
    const urlSearchParameters = new URLSearchParams({ search: searchText });
    setSearchText('');
    navigate({ pathname: PortalPages.contact.absolutePathname, search: urlSearchParameters.toString() });
  };

  if (isAuthenticated) {
    return (
      <div className={props.searchBarIsOpen ? classes.searchBarContainer : classes.searchBarContainerNone}>
        <div className={props.searchBarIsOpen ? classes.dropDownSearchBar : classes.dropDownSearchBarNone}>
          <CustTextField
            id="seachTextField"
            label="Suche"
            variant="standard"
            value={searchText}
            onChange={changeSearchText}
            onKeyDown={keyDown}
            className={props.searchBarIsOpen ? classes.searchInputTf : classes.searchInputTfClosed}
          />
        </div>
        <div className={props.searchBarIsOpen ? classes.searchBackdrop : classes.searchBackdropNone} onClick={props.toggleOpenSearch}>
          {' '}
        </div>
      </div>
    );
  }

  return null;
};

// FIXME: Switch to usage of TSS to be in sync with other code
const CustTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
});

const useStyles = makeStyles()((theme) => ({
  searchInputTf: {
    color: 'black',
    width: 'calc(50% - 40px)',
    maxWidth: '526px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  searchInputTfClosed: {
    visibility: 'hidden',
  },
  searchBarContainerNone: {
    height: '0px',
  },
  searchBarContainer: {
    width: '100vw',
    display: 'flex',
    position: 'relative',
    float: 'right',
    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: '3',
  },
  dropDownSearchBar: {
    display: 'flex',
    color: theme.palette.text.primary,
    backgroundColor: 'rgb(255, 255, 255)',
    zIndex: '11',
    height: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: ' 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  dropDownSearchBarNone: {
    height: '0px',
    overflow: 'hidden',
  },
  searchBackdrop: {
    position: 'absolute',
    zIndex: '2',
    width: '100vw',
    height: '120vh',
    top: '-150px',
  },
  searchBackdropNone: {
    height: '0px',
  },
}));
