import { gql, useQuery } from '@apollo/client';
import { PageView } from '@enpxio/components';
import { Location } from 'history';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MarketingViewCard from '../marketing/components/marketingViewCard';
import { MeterConsumptionCard } from './pageControls/meterConsumptionCard';
import MeterpointsCard from './pageControls/meterpointsCard';

interface LocationState extends Location {
  addReading?: boolean;
}

const QUERY = gql`
  query pageMeterCollectionQuery {
    pageMeterCollection(limit: 1) {
      items {
        marketingEntryCollection {
          items {
            title
            description
            buttonText
            linkedPage {
              linkedPage
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

const PageMeter: FC = () => {
  let addReading = false;
  const { data, loading } = useQuery(QUERY);
  const marketingItems = data?.pageMeterCollection?.items[0]?.marketingEntryCollection?.items;
  const location = useLocation();

  if ((location.state as LocationState)?.addReading) {
    addReading = true;
  }

  return (
    <>
      <PageView title="Zähler" subTitle="Hier finden Sie die Verbrauchsdaten zu Ihrem ausgewählten Vertrag." wide>
        <MeterpointsCard addReading={addReading} />
        <MeterConsumptionCard />
      </PageView>
      {loading || marketingItems?.length === 0 ? null : <MarketingViewCard marketingItems={marketingItems} />}
    </>
  );
};

export default PageMeter;
