import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@enpxio/components';
import { ArrowDropDownSharp } from '@mui/icons-material';
import React, { FC, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface SplitButtonItem {
  text: string;
  onClick: () => void;
}

export interface SplitButtonProps {
  disabled?: boolean;
  icon?: React.ReactNode;
  options: SplitButtonItem[];
}

export const SplitButton: FC<SplitButtonProps> = (props: SplitButtonProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { classes } = useStyles();

  const handleClick = (): void => {
    props.options[selectedIndex].onClick();
  };

  const handleMenuItemClick = (index: number): void => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="outlined" color="secondary" ref={anchorRef} aria-label="split button" disabled={props.disabled}>
        <Button onClick={handleClick} startIcon={props.icon} className={classes.fitcontent}>
          {props.options[selectedIndex].text}
        </Button>
        <Button
          color="secondary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.fitcontent}
        >
          <ArrowDropDownSharp />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={option.text}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={() => {
                        handleMenuItemClick(index);
                      }}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  fitcontent: {
    width: 'fit-content',
  },
}));
