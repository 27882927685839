import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { PFC, PolymorphicElement } from './PolymorphicElement';

export type MimeType = 'application/pdf' | 'text/plain' | 'image/jpg' | 'image/png';

export interface DownloadedFile {
  filename: string;
  payload: string;
  mimeType: MimeType;
}

export type DownloadFileDelegate = () => Promise<DownloadedFile>;

type DownloaderProps = {
  downloadFileDelegate: DownloadFileDelegate;
  setIsDownloadingDelegate?: (val: boolean) => void;
};

export const Downloader: PFC<DownloaderProps> = (props: DownloaderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { downloadFileDelegate, setIsDownloadingDelegate, ...rest } = props;
  const [downloading, setDownloading] = useState(false);
  const setIsDownloading = useCallback(
    (val: boolean) => {
      setDownloading(val);
      if (setIsDownloadingDelegate) {
        setIsDownloadingDelegate(val);
      }
    },
    [setIsDownloadingDelegate]
  );

  const download = useCallback(async (): Promise<void> => {
    if (downloading) {
      return;
    }

    try {
      setIsDownloading(true);
      const downloadedFile = await downloadFileDelegate();
      const blob = base64toBlob(downloadedFile.payload, downloadedFile.mimeType);
      const fileEnding = getFileEndingFromMimeType(downloadedFile.mimeType);
      saveAs(blob, `${downloadedFile.filename}${fileEnding}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Datei konnte nicht heruntergeladen werden.', { variant: 'error', preventDuplicate: false });
    } finally {
      setIsDownloading(false);
    }
  }, [props, downloading]);
  return <PolymorphicElement {...rest} onClick={async () => download()} />;
};

function base64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function getFileEndingFromMimeType(mimeType: MimeType): string {
  switch (mimeType) {
    case 'application/pdf':
      return '.pdf';
    case 'text/plain':
      return '.txt';
    case 'image/jpg':
      return '.jpg';
    case 'image/png':
      return '.png';
    default:
      return '';
  }
}
